import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DealerApi } from 'src/app/api/dealer.api';
import { DepartmentApi } from 'src/app/api/department.api';
import { UserRoleApi } from 'src/app/api/user-role.api';
import { UserApi } from 'src/app/api/user.api';
import { AppController } from 'src/app/app.controller';

@Component({
  selector: 'app-modal-form-user-quick-add',
  templateUrl: './modal-form-user-quick-add.component.html',
  styleUrls: ['./modal-form-user-quick-add.component.scss']
})
export class ModalFormUserQuickAddComponent implements OnInit {

  form: FormGroup;

  roles: any[];
  departments: any[];
  dealers: any[];

  constructor(
    public dialogRef: MatDialogRef<ModalFormUserQuickAddComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private readonly _userApi: UserApi,
    private readonly _userRoleApi: UserRoleApi,
    private readonly _dealerApi: DealerApi,
    private readonly _departmentApi: DepartmentApi,
    private readonly _formBuilder: FormBuilder,
    public readonly appController: AppController) {
  }

  ngOnInit() {

    this.getDepartments();
    this.getRoles();
    this.getDealers();

    this.form = this._formBuilder.group({
      name: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required]],
      profile: this._formBuilder.group({
        dealerId: [null],
        departmentId: [null]
      }),
      role: [null, [Validators.required]]
    });
  }

  getDealers() {
    this._dealerApi.findAll().subscribe((results) => {
      console.debug("dealers", results);
      this.dealers = results.data;
    }, (error) => {
      console.error("failed to obtain departments", error);
    });
  }
  getRoles() {
    this._userRoleApi.findAll().subscribe((results) => {
      console.debug("roles", results);
      this.roles = results.data;
    }, (error) => {
      console.error("failed to obtain departments", error);
    });
  }
  getDepartments() {
    this._departmentApi.findAll().subscribe((results) => {
      console.debug("departments", results);
      this.departments = results.data;
    }, (error) => {
      console.error("failed to obtain departments", error);
    });
  }

  save() {
    if (this.form.valid) {
      let data = this.form.value;
      this._userApi.create(data).subscribe((results: any) => {
        console.debug("saved user", results);
        this.appController.notice(`Successfully created user, ${results.name}`);
        this.dialogRef.close(results);
      }, (error) => {
        console.error("failed to save user", error);
        this.appController.notice("Failed to save user");
      });
    }
  }
}
