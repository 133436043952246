import { Component, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import DataSource from 'devextreme/data/data_source';
import dxDataGrid from 'devextreme/ui/data_grid';
import { ApiService } from 'src/app/api/api.service';
import { TicketApi } from 'src/app/api/ticket.api';
import { AppConstant } from 'src/app/app.constant';
import { AppController } from 'src/app/app.controller';
import { AppData } from 'src/app/app.data';
import { ModalFormSelectComponent } from 'src/app/modal/modal-form-select/modal-form-select.component';
import { ModalFormTicketQuickAddComponent } from 'src/app/modal/modal-form-ticket-quick-add/modal-form-ticket-quick-add.component';
import { ModalSelectUserComponent } from 'src/app/modal/modal-select-user/modal-select-user.component';
import { SecurityService } from 'src/app/service/security.service';
import { environment } from 'src/environments/environment';

@Component({
    moduleId: module.id,
    selector: 'app-ticket-list',
    templateUrl: './ticket-list.component.html',
    styleUrls: ['./ticket-list.component.scss']
})
export class TicketListComponent implements OnInit {

    readonly defaultFilter = [["complete", false], ["spam", false]];

    readonly dataSource = new DataSource({
        store: AppData.createStore("id", environment.api_erp_tickets),
        sort: [{ selector: "created", desc: true }]
    });
    readonly users = {
        store: AppData.createStore("id", {
            loadUrl: ApiService.getEndpoint(environment.api_erp_user)
        }),
        select: ["id", "name"],
        searchExpr: "name",
        sort: "name"
    };

    stats: any = {};

    showSelectedAction = false;
    selectedRowKeys: string[];
    selectedRowsData: any[];

    constructor(
        private readonly _ticketApi: TicketApi,
        private readonly _securityService: SecurityService,
        private readonly _appController: AppController) {
    }

    ngOnInit() {
        AppData.query(environment.api_erp_tickets_stats).then(result => {
            this.stats = result;
            this.stats.user = result.user.find(_ => _.user === this._securityService.getAuthenticatedUser()?.given_name)?.total
        });
    }

    public add() {
        this._appController.openModal(() => {
            this.dataSource.reload();
        }, ModalFormTicketQuickAddComponent, {
            width: AppConstant.SIZE.MODAL_MEDIUM
        });
    }

    delete(e: { component: dxDataGrid; rowIndex: number }) {
        AppData.deleteV2(this._appController, e);
    }

    public escalate(ticket: any) {
        this._appController.openModal((userId: string) => {
            if (userId) {
                this.update(ticket, { escalated: true, escalatedUserId: userId });
            }
        }, ModalSelectUserComponent, {
            width: AppConstant.SIZE.MODAL_SMALL
        });
    }

    public complete(ticket: any) {
        this.update(ticket, { status: "complete" });
    }

    public assign(ticket: any) {
        this._appController.openModal((userId: string) => {
            if (userId) {
                this.update(ticket, { userId: userId });
            }
        }, ModalSelectUserComponent, {
            width: AppConstant.SIZE.MODAL_SMALL
        });
    }

    public merge(ticket: any | any[]) {
        if (!Array.isArray(ticket)) {
            ticket = [ticket]
        }

        this._appController.openModal((value: string) => {
            if (value) {
                this._ticketApi.merge({ targetId: value, ticketId: ticket.map(_ => _.id) })
                    .subscribe(() => {
                        this.dataSource.reload();
                        this._appController.notice(`Merged ticket/s into ${value}`);
                    });
            }
        }, ModalFormSelectComponent, {
            width: AppConstant.SIZE.MODAL_SMALL,
            data: {
                label: "Tickets",
                valueKey: "id",
                labelKey: "shortId",
                dataSource: new DataSource({
                    store: AppData.createStore("id", {
                        loadUrl: ApiService.getEndpoint(environment.api_erp_tickets)
                    }),
                    select: ["id", "shortId"],
                    sort: "shortId",
                    filter: this.selectedRowKeys.map(ticketId => ['id', '<>', ticketId]).concat([['status', '<>', 'complete']]),
                })
            }
        });
    }

    public split(ticket: any | any[]) {
        if (!Array.isArray(ticket)) {
            ticket = [ticket]
        }

        this._appController.getInput("Number of Tickets").then(quantity => {
            this._ticketApi.split({ ticketId: ticket.map(_ => _.id), quantity: quantity })
                .subscribe(() => {
                    this.dataSource.reload();
                    this._appController.notice(`Split ticket/s`);
                });
        });
    }

    public spam(ticket: any) {
        this.update(ticket, { spam: true });
    }

    spamSelected() {
        this.selectedRowsData.forEach(ticket => {
            this.spam(ticket);
        });
    }

    assignSelected() {
        this._appController.openModal((userId: string) => {
            if (userId) {
                this.selectedRowsData.forEach(ticket => {
                    this.update(ticket, { userId: userId });
                });
            }
        }, ModalSelectUserComponent, {
            width: AppConstant.SIZE.MODAL_SMALL
        });
    }

    mergeSelected() {
        this.merge(this.selectedRowsData);
    }

    splitSelected() {
        this.split(this.selectedRowsData);
    }

    public filterUnassigned(e: MatCheckboxChange) {
        if (e.checked) {
            this.dataSource.filter([[["userId", null], "or", ["status", "new"]], ["complete", false]]);
        } else {
            this.dataSource.filter(null);
        }

        this.dataSource.reload();
    }

    public filterUser(e: MatCheckboxChange) {
        if (e.checked) {
            this.dataSource.filter([["userId", this._securityService.getAuthenticatedUserId()], ["complete", false]]);
        } else {
            this.dataSource.filter(null);
        }

        this.dataSource.reload();
    }

    public filterSLA(e: MatCheckboxChange) {
        if (e.checked) {
            this.dataSource.filter([["serviceAgreementId", "<>", null], ["complete", false]]);
        } else {
            this.dataSource.filter(null);
        }

        this.dataSource.reload();
    }

    calculateStateFilterExpression = function (filterValue, selectedFilterOperation) {
        if (filterValue && Array.isArray(filterValue) && filterValue.length > 0) {
            var f = [];
            var parts = filterValue.map(_ => ["state", "contains", _]);
            parts.forEach(part => {
                f.push(part);
                f.push("or")
            });
            f.pop();
            return f;
        }

        return [["complete", false], ["spam", false]];
    };

    public onSelectionChanged(e) {
        this.showSelectedAction = e.selectedRowKeys.length > 1;
        this.selectedRowKeys = e.selectedRowKeys;
        this.selectedRowsData = e.selectedRowsData;
    }

    onEditorPreparing(e) {
        if (e.parentType === "filterRow") {
            if (e.name === "state") {
                e.editorName = "dxTagBox";
                e.editorOptions.value = [];
                e.editorOptions.dataSource = ["complete", "spam"];
                e.editorOptions.placeholder = "State";
            }
        }
    }

    public onRowPrepared(e: { rowType: string; rowElement: HTMLTableRowElement; data: any; }) {
        if (e.rowType === "data") {
            if (e.data.escalated) {
                e.rowElement.classList.add("overdue");
            }

            if (e.data.escalatedUserId) {
                e.rowElement.classList.add("escalated");
            }
        }
    }

    private update(ticket: any, data: any) {
        this.dataSource.store().update(ticket.id, data).then(() => {
            this.dataSource.reload();
        });
    }
}
