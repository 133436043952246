import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';

@Injectable()
export class UserApi extends ApiService {
    constructor(httpClient: HttpClient) {
        super(environment.api_erp_user, httpClient);
    }

    moveLeads(from: string, to: string) {
        return this.post(
            this.getEndpoint(environment.api_erp_user_move_leads),
            { from: from, to: to });
    }

    moveTickets(from: string, to: string) {
        return this.post(
            this.getEndpoint(environment.api_erp_user_move_tickets),
            { from: from, to: to });
    }
}
