import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';

@Injectable()
export class CustomerTaskApi extends ApiService {
  constructor(httpClient: HttpClient) {
    super(environment.api_erp_customer_tasks, httpClient);
  }

  countOutstanding(account: string) {
    var apiEndpoint = `${this.endpoint}/${account}/outstanding`;
    return this.get(apiEndpoint);
  }
}
