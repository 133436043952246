import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/api/api.service';
import { PurchaseOrderLineApi } from 'src/app/api/purchase-order-line.api';
import { PurchaseOrderApi } from 'src/app/api/purchase-order.api';
import { SupplierApi } from 'src/app/api/supplier.api';
import { TaxCodeApi } from 'src/app/api/tax-code.api';
import { UserApi } from 'src/app/api/user.api';
import { SecurityService } from 'src/app/service/security.service';

@Component({
  selector: 'app-purchase-order-form',
  templateUrl: './purchase-order-form.component.html',
  styleUrls: ['./purchase-order-form.component.scss']
})
export class PurchaseOrderFormComponent implements OnInit {

  apis: { [key: string]: ApiService };
  orderNumber: string;

  constructor(
    private readonly _supplierApi: SupplierApi,
    private readonly _userApi: UserApi,
    private readonly _purchaseOrderLineApi: PurchaseOrderLineApi,
    private readonly _purchaseOrderApi: PurchaseOrderApi,
    private readonly _taxCodeApi: TaxCodeApi,
    private readonly _activatedRoute: ActivatedRoute,
    public readonly securityService: SecurityService) {

    this.apis = {
      supplierApi: _supplierApi,
      userApi: _userApi,
      purchaseOrderLineApi: _purchaseOrderLineApi,
      purchaseOrderApi: _purchaseOrderApi,
      taxCodeApi: _taxCodeApi
    }
  }

  ngOnInit() {
    this._activatedRoute.params.subscribe(params => {
      if (params.orderNumber) {
        this.orderNumber = params.orderNumber;
      }
    });
  }
}
