import { Component, Input, OnInit } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { AppData } from 'src/app/app.data';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-partial-sla-list',
    templateUrl: './partial-sla-list.component.html'
})
export class PartialSlaListComponent implements OnInit {

    @Input() account?: string;

    public readonly customers = AppData.customers();
    public readonly plans = AppData.createStore("id", environment.api_erp_service_level_agreement_plans);
    public dataSource: DataSource;

    ngOnInit() {
        this.dataSource = new DataSource({
            store: AppData.createStore("id", environment.api_erp_service_level_agreements, {
                loadParams: {
                    account: this.account
                }
            })
        });
    }

    onInitNewRow(e) {
        e.data.account = this.account;
    }

    onAccountChanged = function (newData, value, currentRowData) {
        this.defaultSetCellValue(newData, value);
    }

    getLocations(options: any) {
        if (options.data) {
            return AppData.createStore("id", environment.api_erp_customers_locations, {
                loadParams: {
                    account: options.data.account
                }
            });
        }

        return [];
    }
}
