import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';

@Injectable()
export class InvoiceApi extends ApiService {
    constructor(httpClient: HttpClient) {
        super(environment.api_erp_invoices, httpClient);
    }

    hasOutstanding(account: string) {
        var apiEndpoint = this.getEndpoint(environment.api_erp_invoices_outstanding);
        return this.get(apiEndpoint, {
            params: new HttpParams().set("account", account)
        });
    }
}
