import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import DataSource from 'devextreme/data/data_source';
import { ApiService } from 'src/app/api/api.service';
import { CustomerContractApi } from 'src/app/api/customer-contract.api';
import { AppController } from 'src/app/app.controller';
import { AppData } from 'src/app/app.data';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-modal-form-contract-item-quick-add',
    templateUrl: './modal-form-contract-item-quick-add.component.html',
    styleUrls: ['./modal-form-contract-item-quick-add.component.scss']
})
export class ModalFormContractItemQuickAddComponent implements OnInit {

    public readonly inventory = new DataSource(AppData.inventory());
    public readonly suppliers = new DataSource(AppData.suppliers());
    public serials: DataSource;
    public locations: DataSource;

    private readonly subject: any;
    private readonly contractId: number;
    public readonly contractType: "hardware" | "services";
    private readonly account: string;

    form: FormGroup;
    serialRequired = false;

    constructor(
        public dialogRef: MatDialogRef<ModalFormContractItemQuickAddComponent>,
        @Inject(MAT_DIALOG_DATA) data: any,
        private readonly _contractApi: CustomerContractApi,
        private readonly _formBuilder: FormBuilder,
        private readonly _appController: AppController) {

        this.subject = data.subject;
        this.contractId = data.contractId;
        this.contractType = data.contractType;
        this.account = data.account;
    }

    ngOnInit() {
        this.initForm();

        this.locations = new DataSource({
            store: AppData.createStore("id", {
                loadUrl: ApiService.getEndpoint(environment.api_erp_customers_locations),
                loadParams: { account: this.account }
            })
        });
    }

    initForm() {
        switch (this.contractType) {
            case "hardware":
                this.form = this._formBuilder.group({
                    contractId: [this.contractId, [Validators.required]],
                    locationId: [null, [Validators.required]],
                    code: [null, [Validators.required]],
                    description: [null, [Validators.required]],
                    altDescription: [null],
                    serial: [null],
                    billable: [false],
                    supplierReference: [null],
                    installationDate: [null],
                    escalation: [null],
                    salesAccCode: [null],
                    cosAccCode: [null],
                    created: [null]
                });
                break;

            case "services":
                this.form = this._formBuilder.group({
                    id: [null],
                    contractId: [this.contractId, [Validators.required]],
                    locationId: [null, [Validators.required]],
                    accountRef: [null],
                    code: [null, [Validators.required]],
                    description: [null, [Validators.required]],
                    altDescription: [null],
                    serial: [null],
                    quantity: [1],
                    initialPrice: [null],
                    currentPrice: [null],
                    escalation: [null],
                    salesAccCode: [null],
                    cosAccCode: [null],
                    startDate: [null],
                    endDate: [null],

                    supplier: [null],
                    supplierStartDate: [null],
                    supplierEndDate: [null],
                    created: [null]
                });
                break;
        }

        if (this.subject) {
            this.form.patchValue(this.subject);
        }

        this.form.get("code").valueChanges
            .subscribe((code: string) => {
                this.inventory.store().byKey(code).then(product => {
                    if (product) {
                        this.serialRequired = product.serialized;

                        this.form.patchValue({
                            description: product.description,
                            altDescription: product.altDescription
                        });

                        AppData.createStore("productClass", environment.api_erp_product_class).byKey(product.productClass).then(productClass => {
                            this.form.patchValue({
                                salesAccCode: productClass.salesAccCode,
                                cosAccCode: productClass.cosAccCode
                            });
                        });

                        this.getInventoryItemSerials(product.code);
                    }
                });
            });
    }

    getInventoryItemSerials(code: string) {
        this.serials = new DataSource({
            store: AppData.createStore("serial", {
                loadUrl: ApiService.getEndpoint(environment.api_erp_inventory_serials),
                loadParams: {
                    code: code
                }
            })
        });
    }

    public save() {
        if (this.form.valid) {
            let data = this.form.value;
            if (this.shouldCreate(data)) {
                delete data.created;

                this._contractApi
                    .create(data, this.contractType)
                    .subscribe((results: any) => {
                        this._appController.notice(`Contract ${this.contractType} Saved `);
                        this.dialogRef.close(results);
                    });
            } else {
                this._contractApi
                    .update(this.getKey(data), data, [this.contractType])
                    .subscribe((results: any) => {
                        this._appController.notice(`Contract ${this.contractType} Updated `);
                        this.dialogRef.close(results);
                    });
            }
        }
    }

    private shouldCreate(data) {
        return this.contractType === "hardware"
            ? !data.created
            : !data.id;
    }

    private getKey(data) {
        return this.contractType === "hardware"
            ? { contractId: this.contractId, code: data.code, serial: data.serial }
            : data.id;
    }
}
