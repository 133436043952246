import { Component } from '@angular/core';
import DataSource, { DataSourceOptions } from 'devextreme/data/data_source';
import { AppData } from 'src/app/app.data';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-supplier-debit-note',
    templateUrl: './supplier-debit-note.component.html'
})
export class SupplierDebitNoteComponent {
    public readonly dataSource = new DataSource(AppData.createStore("invoice", environment.api_erp_supplier_invoices));
    public readonly suppliers = AppData.suppliers();

    getGrn(options: any) {
        if (options.data) {
            return {
                store: AppData.createStore("goodsReceivedNumber", environment.api_erp_goods_received),
                filter: ["account", options.data.account]
            } as DataSourceOptions;
        }

        return [];
    }

    onEditorPreparing(e) {
        if (e.parentType === "dataRow") {
            if (e.dataField === "account") {
                e.editorName = "dxLookup";
                e.editorOptions.disabled = !e.row.isNewRow;
            }

            if (e.dataField === "goodsReceivedNumber") {
                e.editorName = "dxLookup";
                e.editorOptions.disabled = !e.row.isNewRow || !e.row.data.account;
            }
        }
    }

    onAccountChanged = function (newData, value, currentRowData) {
        this.defaultSetCellValue(newData, value);
    };
}
