import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SecurityApi } from 'src/app/api/security.api';
import { AppController } from 'src/app/app.controller';

@Component({
  selector: 'app-reset-password-form',
  templateUrl: './reset-password-form.component.html',
  styleUrls: ['./reset-password-form.component.scss']
})
export class ResetPasswordFormComponent implements OnInit {

  public form: FormGroup;
  public busy: boolean;

  constructor(
    private readonly _securityApi: SecurityApi,
    private readonly _formBuilder: FormBuilder,
    private readonly _router: Router,
    private readonly appController: AppController) {
  }

  ngOnInit() {
    this.form = this._formBuilder.group({
      email: [null, [Validators.email, Validators.required]]
    });
  }

  submit() {
    if (this.form.valid) {
      this._securityApi.reset(this.form.value.email)
        .subscribe(() => {
          this.appController.notice("Password reset email sent.");
        })
    }
  }
}
