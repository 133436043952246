import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';

@Component({
    selector: 'app-partial-quotation-accessory',
    templateUrl: './partial-quotation-accessory.component.html',
    styleUrls: ['./partial-quotation-accessory.component.scss']
})
export class PartialQuotationAccessoryComponent implements OnInit {

    @ViewChild("accessory_grid") dataGrid: DxDataGridComponent;

    @Input() selectableAccessories: any[];
    @Input() defaultAccessories: any[];
    @Input() subject: any;
    @Input() calculate: EventEmitter<void>;
    @Output() changed = new EventEmitter<any>();

    public dataSource: DataSource;

    ngOnInit() {
        this.dataSource = new DataSource({
            store: new ArrayStore({
                data: this.subject?.accessories || this.defaultAccessories
            }),
            reshapeOnPush: true
        });

        this.calculate.subscribe(async () => {
            await this.dataGrid.instance.refresh();
            this.dataGridChanged();
        });
    }

    add() {
        this.dataGrid.instance.addRow();
    }

    accessorySelectionChanged(e, info) {
        if (e.selectedItem) {
            info.data.code = e.selectedItem.code;
            info.data.description = e.selectedItem.description;

            if (!info.data.quantity) {
                info.data.quantity = 1;
            }

            if (!info.data.price) {
                info.data.price = e.selectedItem.price;
            }
        }
    }

    dataGridChanged() {
        this.changed.emit({
            accessories: this.dataSource.items(),
            total: this.dataGrid.instance.getTotalSummaryValue("price")
        });
    }
}
