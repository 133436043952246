import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';

@Injectable()
export class SaleOrderApi extends ApiService {
    constructor(httpClient: HttpClient) {
        super(environment.api_erp_sales_orders, httpClient);
    }

    public deliver(orderNumber: number): Promise<{ report: string; invoice: string }> {
        return new Promise((resolve, reject) => {
            this.post(this.getEndpoint(environment.api_erp_sales_orders) + "/" + orderNumber + "/deliver", null, { observe: "response" })
                .subscribe(response => {
                    resolve({ report: response.headers.get("location"), invoice: response.body.invoice });
                }, error => {
                    reject(error);
                });
        });
    }
}
