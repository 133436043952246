import { Component } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import dxDataGrid from 'devextreme/ui/data_grid';
import { AppController } from 'src/app/app.controller';
import { AppData } from 'src/app/app.data';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-warehouse-list',
  templateUrl: './warehouse-list.component.html',
  styleUrls: ['./warehouse-list.component.scss']
})
export class WarehouseListComponent {

  public readonly dataSource = new DataSource({
    store: AppData.createStore("warehouse", environment.api_erp_warehouse),
    filter: ["warehouse", "<>", "**"]
  });

  constructor(private readonly _appController: AppController) {
  }

  onInitNewRow(e, warehouse: string) {
    e.data.warehouse = warehouse;
  }

  onEditorPreparing(e) {
    if (e.parentType === "dataRow") {
      if (e.dataField === "warehouse" || e.dataField === "bin") {
        e.editorOptions.disabled = !e.row.isNewRow;
      }
    }
  }

  public edit(e: { component: dxDataGrid; rowIndex: number }) {
    e.component.editRow(e.rowIndex);
  }

  public delete(e: { component: dxDataGrid; rowIndex: number }) {
    AppData.deleteV2(this._appController, e);
  }

  getWarehouseBinDataSource(warehouse: any) {
    if (!warehouse.dataSource) {
      warehouse.dataSource = new DataSource({
        store: AppData.createStore(["warehouse", "bin"], environment.api_erp_warehouse_bin, {
          loadParams: {
            warehouse: warehouse.warehouse
          }
        })
      });
    }

    return warehouse.dataSource;
  }
}
