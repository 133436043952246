import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';

@Injectable()
export class LeadPoolApi extends ApiService {
    constructor(httpClient: HttpClient) {
        super(environment.api_erp_lead_pool, httpClient);
    }

    public convert(data: number[]) {
        return this.post(
            this.getEndpoint(environment.api_erp_lead_pool_convert),
            data);
    }

    public assign(data: { filter: any; departmentId: number; take: number; assignUntilDate: Date }) {
        return this.post(
            this.getEndpoint(environment.api_erp_lead_pool_assign),
            data);
    }
}
