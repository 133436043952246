// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,

    google_api_key: "AIzaSyDyx-SLIJ5lxEOKe_Nofei-GpBxN0D-DTY",

    api_erp_host: 'https://prometheansociety.com',

    api_erp_report: '/erp/reports/report',
    api_erp_auth: '/erp/api/auth',

    api_erp_pbx: '/erp/api/pbxreports',
    api_erp_reports: '/erp/api/reports',
    api_erp_filesv2: '/erp/api/filesv2',

    api_erp_search: '/erp/api/search',
    api_erp_help: '/erp/api/help',

    api_erp_activity: '/erp/api/activity',

    api_erp_finance_periods: '/erp/api/periods/finance',
    api_erp_sales_periods: '/erp/api/periods/sales',

    api_erp_user: '/erp/api/users',
    api_erp_user_password: '/erp/api/users/password',
    api_erp_user_role: '/erp/api/users/roles',
    api_erp_user_move_leads: '/erp/api/users/move/leads',
    api_erp_user_move_tickets: '/erp/api/users/move/tickets',

    api_erp_comission_sales: '/erp/api/commission',
    api_erp_comission_incentive: '/erp/api/commission/incentive',
    api_erp_comission_annuity: '/erp/api/commission/annuity',

    api_erp_locations_contacts: '/erp/api/locations/contacts',

    api_erp_leads: '/erp/api/leads',
    api_erp_leads_agg: '/erp/api/leads/aggregate',
    api_erp_leads_agg_pipeline: '/erp/api/leads/aggregate/pipeline',
    api_erp_leads_locations: '/erp/api/leads/locations',
    api_erp_leads_tasks: '/erp/api/leads/tasks',
    api_erp_leads_comments: '/erp/api/leads/comments',

    api_erp_customers: '/erp/api/customers',
    api_erp_customers_locations: '/erp/api/customers/locations',
    api_erp_customer_tasks: '/erp/api/customers/tasks',
    api_erp_customer_comments: '/erp/api/customers/comments',

    api_erp_customer_contract: '/erp/api/customers/contracts',
    api_erp_customer_contract_calculation: '/erp/api/customers/contracts/calculation',
    api_erp_customer_contract_hardware: '/erp/api/customers/contracts/hardware',

    api_erp_lead_pool: '/erp/api/leads/pool',
    api_erp_lead_pool_convert: '/erp/api/leads/pool/convert',
    api_erp_lead_pool_assign: '/erp/api/leads/pool/assign',
    api_erp_lead_prospects: '/erp/api/leads/prospects',

    api_quotes: '/quotes',
    api_quotes_quotes: '/quotes/api/quotes',
    api_quotes_move: '/quotes/api/quotes/move',

    api_erp_quotes_lookup_terms: '/quotes/api/lookup/terms',
    api_erp_quotes_lookup_reports: '/quotes/api/lookup/reports',

    api_quotes_quotes_static_data_security: '/quotes/api/data/security',
    api_quotes_quotes_static_data_infrastructure: '/quotes/api/data/infrastructure',
    api_quotes_quotes_static_data_hardware: '/quotes/api/data/hardware',
    api_quotes_quotes_static_data_voice: '/quotes/api/data/voice',
    api_quotes_quotes_static_data_rates: '/quotes/api/data/rates',
    api_quotes_quotes_static_data_solution: '/quotes/api/data/solution',
    api_quotes_quotes_static_data_cpa: '/quotes/api/data/cpa',
    api_quotes_quotes_static_data_print: '/quotes/api/data/print',

    api_quotes_convert: '/erp/api/quotes',

    api_erp_tasks_agg: '/erp/api/tasks/aggregate',
    api_erp_product_class: '/erp/api/productclass',
    api_erp_pricing: '/erp/api/pricing',
    api_erp_job_class: '/erp/api/jobclass',
    api_erp_tax_codes: '/erp/api/taxcodes',
    api_erp_dealers: '/erp/api/dealers',
    api_erp_departments: '/erp/api/departments',

    api_erp_tickets: '/erp/api/tickets',
    api_erp_tickets_stats: '/erp/api/tickets/stats',
    api_erp_ticket_notes: '/erp/api/tickets/notes',
    api_erp_ticket_tasks: '/erp/api/tickets/tasks',
    api_erp_ticket_comments: '/erp/api/tickets/comments',
    api_erp_ticket_email: '/erp/api/tickets/emails',
    api_erp_ticket_split: '/erp/api/tickets/split',
    api_erp_ticket_merge: '/erp/api/tickets/merge',

    api_erp_suppliers: '/erp/api/suppliers',
    api_erp_supplier_invoices: '/erp/api/supplier/invoices',
    api_erp_supplier_invoice_lines: '/erp/api/supplier/invoices/lines',
    api_erp_supplier_invoice_lines_serials: '/erp/api/supplier/invoices/lines/available/serials',

    api_erp_service_level_agreements: '/erp/api/service/agreements',
    api_erp_service_level_agreement_plans: '/erp/api/service/plans',
    api_erp_service_level_agreement_targets: '/erp/api/service/agreements/targets',
    api_erp_service_level_agreement_escalations: '/erp/api/service/agreements/escalations',
    api_erp_service_level_agreement_limits: '/erp/api/service/agreements/limits',

    api_erp_inventory: '/erp/api/inventory',
    api_erp_inventory_pricecodes: '/erp/api/inventory/pricecodes',
    api_erp_inventory_pricing: '/erp/api/inventory/prices',
    api_erp_inventory_customers: '/erp/api/inventory/customers',
    api_erp_inventory_suppliers: '/erp/api/inventory/suppliers',
    api_erp_inventory_warehouses: '/erp/api/inventory/warehouses',
    api_erp_inventory_links: '/erp/api/inventory/links',
    api_erp_inventory_movements: '/erp/api/inventory/movements',
    api_erp_inventory_serials: '/erp/api/inventory/serials',
    api_erp_inventory_serials_movements: '/erp/api/inventory/serials/movements',
    api_erp_inventory_movements_adjustment: '/erp/api/movements/adjustment',
    api_erp_inventory_movements_receive: '/erp/api/movements/receive',
    api_erp_inventory_movements_transfer: '/erp/api/movements/transfer',
    api_erp_inventory_comments: '/erp/api/inventory/comments',
    api_erp_inventory_loans: '/erp/api/inventory/loans',

    api_erp_inventory_pricecode: '/erp/api/inventory/pricecode',

    api_erp_inventory_harware_reading: '/erp/api/customers/contracts/hardware/meters/readings',
    api_erp_inventory_voice_reading: '/erp/api/customers/contracts/service/readings',

    api_erp_warehouse: '/erp/api/warehouses',
    api_erp_warehouse_bin: '/erp/api/warehouses/bins',

    api_erp_project_requests: '/erp/api/requests/projects',
    api_erp_project_requests_purchaseorder: '/erp/api/requests/projects/purchaseorder',
    api_erp_salesorder_requests: '/erp/api/requests/salesorders',
    api_erp_salesorder_requests_purchaseorder: '/erp/api/requests/salesorders/purchaseorder',

    api_erp_purchase_orders: '/erp/api/purchaseorders',
    api_erp_purchase_order_lines: '/erp/api/purchaseorders/lines',
    api_erp_purchase_order_receive: '/erp/api/purchaseorders/receive',

    api_erp_list_search: '/erp/api/list/search',
    api_erp_list_titles: '/erp/api/list/titles',
    api_erp_list_documentnames: '/erp/api/list/documentnames',
    api_erp_list_job_classes: '/erp/api/list/job/classes',
    api_erp_list_customer_classes: '/erp/api/list/customer/classes',
    api_erp_list_supplier_classes: '/erp/api/list/supplier/classes',
    api_erp_list_cancellationterms: '/erp/api/list/cancellationterms',
    api_erp_list_creditreasons: '/erp/api/list/creditreasons',

    api_erp_jobs: '/erp/api/jobs',

    api_erp_projects: '/erp/api/customers/projects',
    api_erp_projects_salesorder: '/erp/api/customers/projects/salesorder',
    api_erp_projects_deliver: '/erp/api/customers/projects/deliver',

    api_erp_project_steps: '/erp/api/projects/steps',
    api_erp_project_documents: '/erp/api/projects/documents',
    api_erp_project_tasks: '/erp/api/projects/tasks',
    api_erp_project_orders: '/erp/api/projects/orders',
    api_erp_project_orders_serials: '/erp/api/projects/orders/available/serials',
    api_erp_project_comments: '/erp/api/projects/comments',
    api_erp_project_template: '/erp/api/projects/templates',

    api_erp_invoices: '/erp/api/invoices',
    api_erp_invoices_balances: '/erp/api/invoices/balances',
    api_erp_invoices_outstanding: '/erp/api/invoices/outstanding',
    api_erp_invoice_payments: '/erp/api/invoices/payments',

    api_erp_goods_received: '/erp/api/goodsreceived',
    api_erp_goods_received_lines: '/erp/api/goodsreceived/lines',

    api_erp_billing: '/erp/api/billing',
    api_erp_billing_lines: '/erp/api/billing/lines',

    api_erp_sales_orders: '/erp/api/salesorders',
    api_erp_sales_order_lines: '/erp/api/salesorders/lines',
    api_erp_sales_order_lines_serials: '/erp/api/salesorders/lines/available/serials',
    api_erp_sales_order_invoices: '/erp/api/salesorders/invoices/order',
    api_erp_sales_order_invoice_lines: '/erp/api/salesorders/invoices/lines',
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
