import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import dxDataGrid from 'devextreme/ui/data_grid';
import { AppConstant } from 'src/app/app.constant';
import { AppController } from 'src/app/app.controller';
import { AppData } from 'src/app/app.data';
import { ModalFormSaleOrderLineComponent } from 'src/app/modal/modal-form-sale-order-line/modal-form-sale-order-line.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-partial-sale-order-items',
  templateUrl: './partial-sale-order-items.component.html',
  styleUrls: ['./partial-sale-order-items.component.scss']
})
export class PartialSaleOrderItemsComponent implements OnInit {

  @Input() public addCreditLine: EventEmitter<any>;
  @Input() public orderNumber: string;
  @Input() public orderType: string;
  @Input() public account: string;

  public dataSource: DataSource;

  constructor(private readonly _appController: AppController) {
  }

  ngOnInit() {
    this.dataSource = new DataSource(AppData.createStore(["orderNumber", "orderLine"], environment.api_erp_sales_order_lines, {
      loadParams: {
        orderNumber: this.orderNumber
      }
    }));

    this.addCreditLine.subscribe(line => {
      line.orderNumber = this.orderNumber;
      line.salesOrder = null;
      line.admTaxCode = null;

      this.dataSource.store().insert(line).then(() => {
        this.dataSource.reload();
        this._appController.notice("Line added");
      });
    });
  }

  add(element?: any) {
    this._appController.openModal(() => {
      this.dataSource.reload();
    }, ModalFormSaleOrderLineComponent, {
      width: AppConstant.SIZE.MODAL_MEDIUM,
      data: {
        subject: element,
        orderNumber: this.orderNumber,
        orderType: this.orderType,
        account: this.account
      }
    });
  }

  delete(e: { component: dxDataGrid; rowIndex: number }) {
    AppData.deleteV2(this._appController, e);
  }
}
