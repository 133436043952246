import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-partial-permission-list',
  templateUrl: './partial-permission-list.component.html',
  styleUrls: ['./partial-permission-list.component.scss']
})
export class PartialPermissionListComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
