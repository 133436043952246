import { FormGroup } from '@angular/forms';
import * as _ from "lodash";

export class BaseForm {
  getValue(form: FormGroup, key: string, defaultValue: any = ""): any {
    let formControl = form.get(key);
    if (formControl) {
      return formControl.value || defaultValue;
    }

    return defaultValue;
  }

  getFloat(form: FormGroup, key: string, defaultValue: any = 0.0): number {
    let formControl = form.get(key);
    if (formControl) {
      return parseFloat(formControl.value || defaultValue);
    }
    return defaultValue;
  }

  getBoolean(form: FormGroup, key: string, defaultValue: any = false): boolean {
    let formControl = form.get(key);
    return formControl ? formControl.value : defaultValue;
  }

  setValue(form: FormGroup, key: string, value: any) {
    form.get(key).setValue(value);
  }

  combine(subject: any, keys: string[], seperator: string = ", "): string {
    let combined = "";
    keys.forEach((key) => {
      let value = subject[key];
      combined += value ? value + seperator : "";
    });

    return combined === "" ? null : _.trimEnd(combined, seperator);
  }
}
