import { HttpParams } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomerContractApi } from 'src/app/api/customer-contract.api';
import { CustomerApi } from 'src/app/api/customer.api';
import { InventoryReadingVoiceApi } from 'src/app/api/inventory-reading-voice.api';
import { AppController } from 'src/app/app.controller';

@Component({
    selector: 'app-modal-form-inventory-reading-voice',
    templateUrl: './modal-form-inventory-reading-voice.component.html',
    styleUrls: ['./modal-form-inventory-reading-voice.component.scss']
})
export class ModalFormInventoryReadingVoiceComponent implements OnInit {

    private readonly subject: any;

    form: FormGroup;

    updatable: boolean = false;
    customers: any[];
    contracts: any[];
    meters: any[];

    constructor(
        public dialogRef: MatDialogRef<ModalFormInventoryReadingVoiceComponent>,
        @Inject(MAT_DIALOG_DATA) data: any,
        private readonly _customerApi: CustomerApi,
        private readonly _customerContractApi: CustomerContractApi,
        private readonly _inventoryReadingVoiceApi: InventoryReadingVoiceApi,
        private readonly _formBuilder: FormBuilder,
        public readonly appController: AppController) {

        this.subject = data.subject;
    }

    ngOnInit() {
        this.getCustomers();

        this.form = this._formBuilder.group({
            id: [null],
            account: [null, [Validators.required]],
            service: [null, [Validators.required]],
            // meter: [null],
            // date: [null, [Validators.required]],
            // previousReading: [null, [Validators.required]],
            // currentReading: [null, [Validators.required]],
            // invoice: [null]
        });

        if (this.subject) {
            this.form.patchValue(this.subject);
            // this.updatable = true;
        }

        this.form.get("account").valueChanges
            .subscribe((account) => {
                this._customerContractApi
                    .findAll(new HttpParams().set("account", account), "services")
                    .subscribe((results) => {
                        this.contracts = results.data;
                    });
            });
    }

    save() {
        if (this.form.valid) {
            let data = this.form.value;
            if (!data.id) {
                this._inventoryReadingVoiceApi.create(data).subscribe((results) => {
                    // this.updatable = true;
                    this.dialogRef.close(results);
                    this.appController.notice(`Inventory Reading created`);
                }, (error) => {
                    this.appController.notice(`Failed to save hardware reading`);
                });
            } else {
                let data = this.form.value;

                this._inventoryReadingVoiceApi.update(data.id, data).subscribe((results) => {
                    // this.updatable = true;
                    this.dialogRef.close(results);
                    this.appController.notice(`Inventory Reading updated`);
                }, (error) => {
                    this.appController.notice(`Failed to update hardware reading`);
                });
            }
        }
    }

    getCustomers() {
        this._customerApi.findAll().subscribe((results) => {
            this.customers = results.data;
        });
    }
}
