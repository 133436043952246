import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';

@Injectable()
export class DepartmentApi extends ApiService {
    constructor(httpClient: HttpClient) {
        super(environment.api_erp_departments, httpClient);
    }

    // create(data: any): Observable<any> {
    //     let body: HttpParams = new HttpParams().set("values", JSON.stringify(data));
    //     return this.post(body.toString(), {
    //         headers: {
    //             'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    //         }
    //     });
    // }

    // findAll(params?: any): Observable<any> {
    //     return this.get({
    //         headers: {
    //             'Content-Type': 'application/json; charset=utf-8'
    //         },
    //         params: params
    //     })
    // }

    // delete(id: number): Observable<any> {
    //     let body: HttpParams = new HttpParams().set("key", id.toString(10));
    //     return this.post(body.toString(), {
    //         headers: {
    //             'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    //         }
    //     });
    // }
}
