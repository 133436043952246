import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-modal-form-inventory-movement',
    templateUrl: './modal-form-inventory-movement.component.html',
    styleUrls: ['./modal-form-inventory-movement.component.scss']
})
export class ModalFormInventoryMovementComponent implements OnInit {

    form: FormGroup;
    code?: string;
    defaultWarehouse?: string;
    movement?: string;

    constructor(
        private readonly dialogRef: MatDialogRef<ModalFormInventoryMovementComponent>,
        @Inject(MAT_DIALOG_DATA) data: any) {
        this.code = data?.code;
        this.defaultWarehouse = data?.defaultWarehouse;
        this.movement = data?.movement;
    }

    ngOnInit() {
        this.form = new FormGroup({
            movementType: new FormControl(this.movement || "R")
        });
    }
}
