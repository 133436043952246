import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AppData } from 'src/app/app.data';

@Component({
  selector: 'app-modal-select-user',
  templateUrl: './modal-select-user.component.html',
  styleUrls: ['./modal-select-user.component.scss']
})
export class ModalSelectUserComponent implements OnInit {
  public readonly dataSource = AppData.users(true);

  form: FormGroup;

  constructor(private readonly dialogRef: MatDialogRef<ModalSelectUserComponent>) {
  }

  ngOnInit() {
    this.form = new FormGroup({
      userId: new FormControl(null, [Validators.required])
    });
  }

  proceed() {
    if (this.form.valid) {
      this.dialogRef.close(this.form.value.userId);
    }
  }
}
