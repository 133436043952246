import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DxHtmlEditorComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import { ApiService } from 'src/app/api/api.service';
import { AppData } from 'src/app/app.data';
import { SecurityService } from 'src/app/service/security.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-partial-activity-list',
    templateUrl: './partial-activity-list.component.html',
    styleUrls: ['./partial-activity-list.component.scss']
})
export class PartialActivityListComponent implements OnInit {

    @ViewChild(DxHtmlEditorComponent) editor: DxHtmlEditorComponent;

    @Input() account?: string;
    @Input() leadId?: number;
    @Input() ticketId?: string;
    @Input() projectId?: number;
    @Input() code?: string;

    public readonly users = AppData.users();
    public dataSource: DataSource;
    public form: FormGroup;

    constructor(
        private readonly _formBuilder: FormBuilder,
        private readonly _securityService: SecurityService) {
    }

    ngOnInit() {
        this.form = this._formBuilder.group({
            account: [this.account],
            leadId: [this.leadId],
            ticketId: [this.ticketId],
            projectId: [this.projectId],
            code: [this.code],
            userId: [this._securityService.getAuthenticatedUserId(), [Validators.required]],
            text: [null, [Validators.required]],
            mentions: this._formBuilder.array([])
        });

        this.dataSource = new DataSource({
            store: AppData.createStore("id", {
                loadUrl: ApiService.getEndpoint(environment.api_erp_activity),
                loadParams: {
                    entityType: this.getEntityType(),
                    entityKey: this.account || this.leadId || this.ticketId || this.projectId || this.code,
                }
            }),
            sort: [{ selector: "activityTime", desc: true }],
            searchExpr: ["user", "activityTime"],
            pageSize: 5
        });
    }

    getEntityType() {
        if (this.account) {
            return "Customer";
        }

        if (this.leadId) {
            return "Lead";
        }

        if (this.ticketId) {
            return "Ticket";
        }

        if (this.projectId) {
            return "Project";
        }

        if (this.code) {
            return "Inventory";
        }
    }

    getUrl() {
        if (this.account) {
            return ApiService.getEndpoint(environment.api_erp_customer_comments);
        }

        if (this.leadId) {
            return ApiService.getEndpoint(environment.api_erp_leads_comments);
        }

        if (this.ticketId) {
            return ApiService.getEndpoint(environment.api_erp_ticket_comments);
        }

        if (this.projectId) {
            return ApiService.getEndpoint(environment.api_erp_project_comments);
        }

        if (this.code) {
            return ApiService.getEndpoint(environment.api_erp_inventory_comments);
        }
    }

    onValueChanged(value: string) {
        this.form.get("text").setValue(value);

        if (value) {
            this.form.get("text").markAsDirty();
        } else {
            this.form.get("text").markAsPristine();
        }

        const s = document.createElement("span");
        s.innerHTML = value;

        const mentions = this.form.get("mentions") as FormArray;
        mentions.clear();

        s.querySelectorAll(".dx-mention").forEach(_ => {
            mentions.push(this._formBuilder.group({
                userId: [_.getAttribute("data-id"), [Validators.required]]
            }));
        });
    }

    save() {
        if (this.form.valid) {
            AppData.createStore("id", this.getUrl()).insert(this.form.value).then(() => {
                this.dataSource.reload();
                this.editor.instance.reset();
            });
        }
    }
}
