import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { BaseDataListing } from 'src/app/page/base-data-list.component';

@Component({
  selector: 'app-widget-data-list',
  templateUrl: './widget-data-list.component.html',
  styleUrls: ['./widget-data-list.component.scss']
})
export class WidgetDataListComponent extends BaseDataListing {

  @Input() dataSource: any;
  @Output() onPage: EventEmitter<any>;
  @Output() onSort: EventEmitter<any>;
  @Input() dataSourceKey: string;
  @Input() pageSize: number;

  @Input() editable: Function;
  @Input() deletable: Function;

  constructor() {
    super();

    this.onPage = new EventEmitter<any>();
    this.onSort = new EventEmitter<any>();
  }

  page(pageEvent: PageEvent) {
    console.debug("table paged", pageEvent);
    this.onPage.emit({
      page: (pageEvent.pageIndex * pageEvent.pageSize),
      size: pageEvent.pageSize,
      event: pageEvent
    });
  }

  invokeCallBack(callBack: Function, data?: any) {
    console.debug("invokeCallBack ", callBack.toString(), " with data ", data);
    callBack.call(this, data);
  }
}
