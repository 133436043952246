import { HttpParams } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { PurchaseOrderLineApi } from 'src/app/api/purchase-order-line.api';
import { AppConstant } from 'src/app/app.constant';
import { AppController } from 'src/app/app.controller';
import { Confirmation } from 'src/app/modal/modal-confirmation/confirmation';
import { ModalFormPurchaseOrderLineComponent } from 'src/app/modal/modal-form-purchase-order-line/modal-form-purchase-order-line.component';
import { BaseDataListing } from 'src/app/page/base-data-list.component';

@Component({
  selector: 'app-partial-purchase-order-items',
  templateUrl: './partial-purchase-order-items.component.html',
  styleUrls: ['./partial-purchase-order-items.component.scss']
})
export class PartialPurchaseOrderItemsComponent extends BaseDataListing implements OnInit {

  @Input() public orderNumber: string;
  @Input() public account: string;

  constructor(
    private readonly _purchaseOrderLineApi: PurchaseOrderLineApi,
    private readonly _appController: AppController) {
    super();
    this.actionMenu = [
      {
        label: "Edit", callBack: (element: any) => {
          console.debug("edit", element);
          this.add(element);
        }
      },
      {
        label: "Delete", callBack: (element: any) => {
          console.debug("delete", element);
          this._appController.confirm((proceed) => {
            if (proceed) {
              console.debug("delete", element);

              this._purchaseOrderLineApi.remove(JSON.stringify({ orderNumber: element.orderNumber, orderLine: element.orderLine })).subscribe((results) => {
                console.debug("deleted ", results);
                // this.datasourceAssignData(this.datasourceRemoveByKeyValue(this.dataSource, "account", element.account));
                // console.debug("update datasource ", this.dataSource);
                this._appController.notice("Successfully delete purchase order item");
                this.refresh();
              }, (error) => {
                console.error("failed to delete ", error);
                this._appController.notice("Failed to delete purchase order item");
              })
            }
          }, new Confirmation("delete_outline",
            `Are you sure you want delete line item?`,
            "Cancel", "Delete")
          );
        }
      },
    ];
  }

  ngOnInit() {
    this.queryData(this.queryLimit, this.queryCurrentPage, true, this.querySort, this.queryFilter);
  }

  get columnLabels(): string[] {
    return [
      "Warehouse", "Code", "Description", "UDM", "Quantity", "Price", "Total", ""
    ];
  }

  get columnKeys(): string[] {
    return [
      "warehouse", "code", "description", "unitOfMeasure", "orderQuantity", "price", "total", "action"
    ];
  }

  queryData(take: number, skip: number, count: boolean, sort: any[], filter?: any[]) {
    if (this._purchaseOrderLineApi) {
      this.query = new HttpParams()
        .set("take", `${take}`)
        .set("skip", `${skip}`)
        .set("requireTotalCount", `${count}`);

      this.query = this.queryAdd(this.query, { orderNumber: this.orderNumber });
      this.query = this.queryAddSort(this.query, sort);
      this.query = this.queryAddFilter(this.query, filter);

      console.debug("query ", this.query.toString());

      this._purchaseOrderLineApi.findAll(this.query).subscribe((results: any) => {
        console.debug("Purchase Order Line", results);
        this.dataSource = results;
      }, (error) => {
        console.debug("failed to obtain Purchase Order Line", error);
      });
    } else {
      console.error("unable to obtain Purchase Order Line api");
    }
  }

  onSelect(selected: any[]): void {
    console.debug("item selected", selected);
  }

  onSearch(value: string): void {
    // this.queryFilter = null;
    // console.debug("searching for ", value);

    // if (value !== '') {

    //   this.queryFilter = [
    //     ["account", "contains", `${value}`], "or",
    //     ["name", "contains", `${value}`], "or",
    //     // ["tradingName", "contains", `${value}`], "or", 
    //     // ["accountRef", "contains", `${value}`], "and",
    //     ["companyName", "=", `${this.subject.tradingName}`]
    //   ];
    //   this.queryData(this.queryLimit, this.queryCurrentPage, true, this.querySort, this.queryFilter);
    // }
  }

  add(element?: any) {
    this._appController.openModal((results: any) => {
      console.debug("modal result", results);
      this.refresh();
      // if (results) {
      //   this.appController.notice(`Successfully saved lead ${results.company}`, null, 2000);
      // }
    }, ModalFormPurchaseOrderLineComponent, {
      width: AppConstant.SIZE.MODAL_MEDIUM,
      data: {
        subject: element,
        orderNumber: this.orderNumber,
        account: this.account
      }
    });
  }
}
