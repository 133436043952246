import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { SecurityApi } from 'src/app/api/security.api';
import { UserPermissionApi } from 'src/app/api/user-permission.api';
import { AppController } from 'src/app/app.controller';
import { User } from 'src/app/model/user';
import { SecurityService } from 'src/app/service/security.service';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {

  public form: FormGroup;
  public busy: boolean;

  constructor(
    private readonly _securityApi: SecurityApi,
    private readonly _userPermissionApi: UserPermissionApi,
    private readonly _formBuilder: FormBuilder,
    private readonly _router: Router,
    private readonly appController: AppController) {
  }

  ngOnInit() {
    this.form = this._formBuilder.group({
      email: [null, [Validators.email, Validators.required]],
      password: [null, [Validators.required]]
    });
  }

  submit() {
    if (this.form.valid) {
      let data = this.form.value;
      this.busy = true;

      return this._securityApi
        .authenticate(data.email, data.password)
        .subscribe(result => {
          if (result.access_token) {
            var user = new JwtHelperService().decodeToken(result.access_token) as User;
            user.image = user["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/uri"];

            localStorage.setItem(SecurityService.AccessTokenKey, result.access_token);
            localStorage.setItem(SecurityService.UserKey, JSON.stringify(user));

            this._userPermissionApi.getActions(user.nameid).subscribe(permissions => {
              var flatten = [];

              permissions.forEach(namespace => {
                namespace.items.forEach(controller => {
                  controller.items.forEach(action => {
                    flatten.push(action.key);
                  });
                })
              });

              localStorage.setItem(SecurityService.PermissionsKey, JSON.stringify(flatten));
              this._router.navigateByUrl("");
              this.busy = false;
            });
          }
        }, error => {
          this.appController.notice(error);
          this.busy = false;
        });
    }
  }
}
