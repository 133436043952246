import { HttpParams } from '@angular/common/http';
import * as _ from "lodash";
import * as moment from "moment";
import { ApiService } from 'src/app/api/api.service';
import { WidgetDataTableComponentColumn, WidgetDataTableComponentConfiguration } from '../widget/widget-data-grid/widget-data-grid.component';

export class BaseDataListing {

  queryParams: any;
  dataSource: any;
  query: HttpParams;
  queryFilter: any[];
  queryLimit: number = 20;
  querySort: any;
  queryCurrentPage: number = 0;
  actionMenu: any;
  toolbar: any[];
  selected: any[];
  apis: { [key: string]: ApiService };

  constructor() {
    this.apis = {};
  }

  addService(key: string, service: ApiService) {
    this.apis[key] = service;
  }

  queryAdd(query: HttpParams, queryParams: any): HttpParams {
    if (queryParams) {
      _.keys(queryParams).forEach((key) => {
        query = query.set(key, queryParams[key]);
      })
    }
    return query;
  }

  queryAddSort(query: HttpParams, sort: any[]): HttpParams {
    if (sort) {
      if (sort.length > 0) {
        query = query.set("sort", JSON.stringify(sort));
      }
    }

    return query;
  }

  queryAddFilter(query: HttpParams, filter: any[]): HttpParams {
    if (filter) {
      if (filter.length > 0) {
        query = query.set("filter", JSON.stringify(filter));
      }
    }

    return query;
  }

  queryObject(subject: any): string {
    return JSON.stringify(subject);
  }

  queryAddSelect(query: HttpParams, select: string[]): HttpParams {
    if (select) {
      if (select.length > 0) {
        query = query.set("select", JSON.stringify(select));
      }
    }

    return query;
  }

  queryData(take: number, skip: number, count: boolean, sort: any[], filter?: any[]) {
  }

  page(event: any) {
    this.queryCurrentPage = event.page;
    this.queryLimit = event.size;
    this.queryData(this.queryLimit, this.queryCurrentPage, true, this.querySort, this.queryFilter);
  }

  sort(event: any) {
    this.querySort = [{ "selector": `${event.field}`, "desc": event.desc }];
    this.queryData(this.queryLimit, this.queryCurrentPage, true, this.querySort, this.queryFilter);
  }

  refresh() {
    this.queryData(this.queryLimit, this.queryCurrentPage, true, this.querySort, this.queryFilter);
  }

  get columnLabels(): string[] {
    return [];
  }

  get columnKeys(): string[] {
    return [];
  }

  get columns(): WidgetDataTableComponentColumn[] {
    return [];
  }

  get widgetDataTableComponentConfiguration(): WidgetDataTableComponentConfiguration {
    return {} as WidgetDataTableComponentConfiguration;
  }

  datasourceRemoveByKeyValue(source: any, key: string, value: any) {
    for (var i = 0; i < source.data.length; i++) {
      if (source.data[i][key] === value) {
        (source.data as any[]).splice(i, 1);
        break;
      }
    }

    return source;
  }

  datasourceAdd(source: any, item: any) {
    if (source.data) {
      if (item) {
        (source.data as any[]).unshift(item);
      }
    }
  }

  datasourceAssignData(source: any) {
    if (this.dataSource) {
      this.dataSource.data = source.data;
      this.dataSource.totalCount = source.data.length;
    }
  }

  //utility function, move/replace with utility classes

  getValue(item: any, key: string): any {

    if (key.indexOf("|") !== -1) {
      let keyParts: string[] = key.split("|");
      // console.debug("key parts ", keyParts, " key ", key);
      switch (keyParts[1]) {
        case 'date': return moment(_.get(item, keyParts[0])).format(keyParts[2]);
        case 'avatar': return (_.get(item, keyParts[0])) ? `<img matTooltip="${_.get(item, keyParts[0])}" class="header_profile-image-container_image header_profile-image-container_image--size24 remove--p-top border--white border--width-2 border--solid border--rounded"
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSf_Bf0-x44hsGqqcQwrTcNeLUSnYjlDuoql-hQHydDdBwxeCT2&s">`: null;

        default: return `no formatter keyParts[1]`;
      }
    } if (key.indexOf("?") !== -1) {
      let keyParts: string[] = key.split("?");
      let keyCondition: string[] = keyParts[1].split(":");
      let value: boolean = _.get(item, keyParts[0]) as boolean;
      // console.debug("conditional split", keyParts, " condition ", keyCondition, " value ", value);
      if (value) {
        return keyCondition[0];
      } else {
        return (keyCondition[1] !== 'null') ? keyCondition[1] : null;
      }
    }

    return _.get(item, key);
  }

  getKeyValue(item: any, key: string, seperator: string = " "): any {
    let value: any = "";
    if (key.indexOf(",") === -1) {
      value = this.getValue(item, key);
    } else {
      key.split(",").forEach((itemKey) => {
        let valueItem = this.getValue(item, itemKey);
        if (valueItem) {
          value += valueItem + seperator
        }
      })
    }
    return _.isString(value) ? _.trimEnd(value, seperator) : value;
  }

  hasSecondary(key: string): boolean {
    if (key.indexOf("^")) {
      return true;
    }
    return false;
  }

  getSecondary(key: string): string[] {
    return key.split("^");
  }

  invokeCallBack(callBack: Function, data?: any) {
    callBack.call(this, data);
  }

  merge(subject, source): any {
    return _.merge(subject, source);
  }

  jsonString(value: any): string {
    return JSON.stringify(value);
  }

  pick(value: any, pick: string[]): any {
    return _.pick(value, pick);
  }

  omit(data: any, omit: string[]): any {
    return _.omit(data, omit);
  }
}
