import { AgmCoreModule } from '@agm/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppUiDevExtremeModule } from '../app.ui.devextreme.module';
import { AppMaterialModule } from '../app.ui.material.module';
import { SafeHtmlPipe } from '../SafeHtmlPipe';
import { GlobalNotificationsComponent } from './global-notifications/global-notifications.component';
import { GlobalSearchComponent } from './global-search/global-search.component';
import { WidgetAddressFieldComponent } from './widget-address-field/widget-address-field.component';
import { WidgetDataGridComponent } from './widget-data-grid/widget-data-grid.component';
import { WidgetDataListComponent } from './widget-data-list/widget-data-list.component';
import { WidgetDataTableComponent } from './widget-data-table/widget-data-table.component';
import { WidgetMenuColumnComponent } from './widget-menu/menu-column.component';
import { WidgetMenuDeleteComponent } from './widget-menu/menu-delete.component';
import { WidgetMenuEditComponent } from './widget-menu/menu-edit.component';
import { WidgetSearchFieldComponent } from './widget-search-field/widget-search-field.component';

@NgModule({
    declarations: [
        SafeHtmlPipe,
        GlobalNotificationsComponent,
        GlobalSearchComponent,
        WidgetDataGridComponent,
        WidgetDataTableComponent,
        WidgetSearchFieldComponent,
        WidgetDataListComponent,
        WidgetAddressFieldComponent,
        WidgetMenuEditComponent,
        WidgetMenuDeleteComponent,
        WidgetMenuColumnComponent
    ],
    exports: [
        SafeHtmlPipe,
        GlobalNotificationsComponent,
        GlobalSearchComponent,
        WidgetDataGridComponent,
        WidgetDataTableComponent,
        WidgetSearchFieldComponent,
        WidgetDataListComponent,
        WidgetAddressFieldComponent,
        WidgetMenuEditComponent,
        WidgetMenuDeleteComponent,
        WidgetMenuColumnComponent
    ],
    imports: [
        CommonModule,
        AppMaterialModule,
        AppUiDevExtremeModule,
        AgmCoreModule.forRoot({
            apiKey: environment.google_api_key,
            libraries: ["places"]
        })
    ]
})
export class WidgetModule { }
