import { Component } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { AppController } from 'src/app/app.controller';
import { AppData } from 'src/app/app.data';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-sale-order-list',
    templateUrl: './sale-order-list.component.html',
    styleUrls: ['./sale-order-list.component.scss']
})
export class SaleOrderListComponent {
    public readonly dataSource = new DataSource(AppData.createStore("orderNumber", environment.api_erp_sales_orders));
    public readonly orderTypes = [{ text: "Quote", value: "Q" }, { text: "Order", value: "O" }, { text: "Credit Note", value: "C" }];
    public readonly orderStatuses = [{ text: "Open", value: "O" }, { text: "Complete", value: "C" }];

    constructor(private readonly _appController: AppController) {
    }

    public delete(order: any) {
        AppData.delete(this._appController, this.dataSource, order.orderNumber, `Are you sure you want delete '${order.orderNumber}'?`, "Sales order deleted");
    }
}
