import { Injectable } from '@angular/core';
import query from 'devextreme/data/query';
import { ApiService } from 'src/app/api/api.service';
import { AppData } from 'src/app/app.data';
import { environment } from 'src/environments/environment';

@Injectable()
export class QuoteCalculationHelper {
  private cpas: any[];

  constructor() {
    AppData.createStore(null, {
      loadUrl: ApiService.getEndpoint(environment.api_quotes_quotes_static_data_cpa)
    }).load().then(result => {
      this.cpas = result;
    });
  }

  public calculateMargin(margin: number, marginType: string, total: number): number {
    if (marginType === "Percentage") {
      return total * (1 - (margin / 100));
    }

    return margin;
  }

  public calculateFactor(total: number, margin: number, marginType: string, period: number, escalation: number): number {
    if (marginType === "Value") {
      total += margin;
    }

    var items = query(this.cpas).filter([
      ["min", "<=", total],
      ["max", ">=", total]
    ]).toArray();

    items = this.flatten(items, function (item) {
      return item.terms;
    }).filter(function (term) {
      return term.esc === escalation;
    });

    if (items.length > 0) {
      return items[0][period] || 0;
    }

    return 0;
  }

  private flatten(array: any[], callbackfn) {
    return [].concat.apply([], array.map(callbackfn));
  }
}
