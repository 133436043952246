import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import DataSource from 'devextreme/data/data_source';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { InvoiceApi } from 'src/app/api/invoice.api';
import { LocationContactApi } from 'src/app/api/location-contact.api';
import { TicketTaskApi } from 'src/app/api/ticket-task.api';
import { AppConstant } from 'src/app/app.constant';
import { AppController } from 'src/app/app.controller';
import { AppData } from 'src/app/app.data';
import { forbiddenNameValidator } from 'src/app/helper/forbidden-name-validator';
import { CustomerFormComponent } from 'src/app/page/customer/customer-form/customer-form.component';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-ticket-view',
    templateUrl: './ticket-view.component.html',
    styleUrls: ['./ticket-view.component.scss']
})
export class TicketViewComponent implements OnInit {

    public readonly users = AppData.users(true);
    public readonly customers = new DataSource(AppData.customers());
    public contracts: DataSource;

    private readonly tickets = AppData.tickets().store;

    form: FormGroup;
    updating = false;
    customerNotes: string;
    hasSla: boolean;
    hasOutstandingPayments: boolean;

    types = ["unassigned", "voice", "print", "security", "connectivity", "IT"];
    priorities = [
        { value: "unassigned", label: "unassigned" },
        { value: "1", label: "1 - High" },
        { value: "2", label: "2 - Med" },
        { value: "3", label: "3 - Low" }
    ];

    locations: any[];
    locationContacts: any[];

    constructor(
        private readonly _formBuilder: FormBuilder,
        private readonly _locationContactApi: LocationContactApi,
        private readonly _invoiceApi: InvoiceApi,
        private readonly _ticketTaskApi: TicketTaskApi,
        private readonly _activatedRoute: ActivatedRoute,
        private readonly appController: AppController) {
    }

    ngOnInit() {
        this.form = this._formBuilder.group({
            id: [null],
            shortId: [null],
            userId: [null, [Validators.required]],
            user: [{ value: null, disabled: true }],
            locationId: [null, [Validators.required]],
            account: [null, [Validators.required]],
            accountName: [null, [Validators.required]],
            status: ["new", [Validators.required]],
            contactId: [null],
            contact: [{ value: null, disabled: true }],
            serial: [null],
            priority: ["unassigned", [Validators.required, forbiddenNameValidator(/unassigned/i)]],
            type: ["unassigned", [Validators.required, forbiddenNameValidator(/unassigned/i)]],
            escalated: [false],
            escalatedUserId: [null],
            spam: [false],
            tags: [null],
            serviceAgreementId: [null],
            serviceAgreementDescription: [null]
        });

        this._activatedRoute.params.subscribe((params) => {
            if (params.ticketId) {
                this.form.patchValue({ id: params.ticketId });
                this.getTicket(params.ticketId);
            }
        });

        this.form.get("account").valueChanges
            .subscribe((account: string) => {
                this.customers.store().byKey(account).then(customer => {
                    if (customer) {
                        this.locations = customer.locations;
                        this.customerNotes = customer.notes;
                        this.hasSla = customer.serviceAgreements.length > 0;
                    }
                });

                this._invoiceApi.hasOutstanding(account).subscribe(result => {
                    this.hasOutstandingPayments = result;
                });

                this.contracts = new DataSource(AppData.createStore("serial", environment.api_erp_customer_contract_hardware, {
                    loadParams: {
                        account: account
                    }
                }));
            });

        this.form.get("locationId").valueChanges
            .subscribe((locatonId: string) => {
                this.getLocationContacts(locatonId);
            });
    }

    watchChanges() {
        this.form.valueChanges
            .pipe(distinctUntilChanged(), debounceTime(500))
            .subscribe(async () => {
                if (this.updating) {
                    return;
                }

                if (this.form.valid) {
                    this.tickets.update(this.form.value.id, this.form.value).then(async ticket => {
                        this.updating = true;
                        this.form.patchValue(ticket);
                        setTimeout(() => this.updating = false, 1000);
                    });
                }
            });
    }

    getTicket(ticketId: string) {
        this.tickets.byKey(ticketId).then(ticket => {
            this.form.patchValue(ticket);
            this.watchChanges();
        });
    }

    getLocationContacts(locationId: string) {
        this._locationContactApi
            .findAll(new HttpParams()
                .set("locationId", locationId)
                .set("select", JSON.stringify(["id", "name"])))
            .subscribe(result => {
                this.locationContacts = result.data;
            });
    }

    public getTags() {
        var tags = this.form.value.tags;
        if (tags) {
            return tags.split(/[;,]/);
        }

        return [];
    }

    public openCustomer() {
        this.appController.openModal(
            null,
            CustomerFormComponent,
            {
                width: AppConstant.SIZE.MODAL_XLARGE,
                height: "calc(100vh - 45px)",
                disableClose: false,
                data: {
                    account: this.form.value.account
                }
            });
    }

    onTicketUpdateRequested() {
        this.tickets.byKey(this.form.value.id).then(ticket => {
            this.updating = true;
            this.form.patchValue(ticket);
            setTimeout(() => this.updating = false, 1000);
        });
    }
}
