import { Component } from '@angular/core';
import config from 'devextreme/core/config';
import { locale } from "devextreme/localization";
import dxDataGrid, { dxDataGridOptions } from 'devextreme/ui/data_grid';
import { AppController } from 'src/app/app.controller';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent {
    constructor(public readonly appController: AppController) {
        locale("en-ZA");
        config({
            defaultCurrency: "ZAR",
            serverDecimalSeparator: "."
        });

        dxDataGrid.defaultOptions({
            options: {
                hoverStateEnabled: true,
                editing: {
                    confirmDelete: false
                }
            } as dxDataGridOptions
        });
    }
}
