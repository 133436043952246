import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import DataSource from 'devextreme/data/data_source';
import marked, { Renderer } from 'marked';
import { ApiService } from 'src/app/api/api.service';
import { AppData } from 'src/app/app.data';
import { SecurityService } from 'src/app/service/security.service';
import { environment } from 'src/environments/environment';

@Component({
    templateUrl: "./container.component.html"
})
export class ContainerComponent implements OnInit {

    @ViewChild("drawer", { static: true }) drawer: MatDrawer;

    private readonly renderer = new Renderer();
    private readonly md = marked.setOptions({ renderer: this.renderer });

    public readonly helpDataSource = new DataSource({
        store: AppData.createStore(null, {
            loadUrl: ApiService.getEndpoint(environment.api_erp_help)
        }),
        map: (dataItem) => {
            dataItem.html = this.md(dataItem.content);
            return dataItem;
        }
    });

    reports = [];

    constructor(
        private readonly _router: Router,
        public readonly securityService: SecurityService) {

        _router.events.subscribe(async val => {
            if (val instanceof NavigationEnd) {
                this.drawer.close();
            }
        });
    }

    async ngOnInit() {
        if (this.securityService.isInRole(['administrator', 'admin'])) {
            new DataSource(AppData.createStore(null, ApiService.getEndpoint(environment.api_erp_reports))).load().then(result => {
                this.reports = result;
            });
        }
    }

    async logout() {
        this.securityService.logout();
        this._router.navigateByUrl("login");
    }

    profile() {
        const id = this.securityService.getAuthenticatedUserId();
        this._router.navigate(["/settings/user/", id]);
    }
}
