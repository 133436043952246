import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import DataSource from "devextreme/data/data_source";
import { JobApi } from 'src/app/api/job.api';
import { AppConstant } from 'src/app/app.constant';
import { AppController } from 'src/app/app.controller';
import { AppData } from 'src/app/app.data';
import { ModalFormJobQuickAddComponent } from 'src/app/modal/modal-form-job-quick-add/modal-form-job-quick-add.component';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-partial-ticket-jobs',
    templateUrl: './partial-ticket-jobs.component.html',
    styleUrls: ['./partial-ticket-jobs.component.scss']
})
export class PartialTicketJobsComponent implements OnInit {

    @Input() ticketId: string;
    @Input() contact?: any;
    @Input() serviceAgreementId?: number;
    @Output() updateTicketRequest = new EventEmitter<void>();

    dataSource: DataSource
    limits: any[];

    constructor(
        private readonly _jobApi: JobApi,
        private readonly _appController: AppController) {
    }

    ngOnInit() {
        const ds = new DataSource(AppData.jobs(this.ticketId));
        ds.on("changed", () => this.updateLimits());

        this.dataSource = ds;
    }

    accept(job: any) {
        this.dataSource.store().update(job.id, { status: "accepted" }).then(() => {
            this.dataSource.reload();
            this.updateTicketRequest.emit();
        });
    }

    pause(job: any) {
        this._appController.getInput("Pause reason").then(comment => {
            this.dataSource.store().update(job.id, { status: "hold", statusComment: comment }).then(() => {
                this.dataSource.reload();
                this.updateTicketRequest.emit();
            });
        });
    }

    start(job: any) {
        this._jobApi.progress(job.id, { status: "JobStart" }).subscribe(() => {
            this.dataSource.reload();
            this._appController.notice(`Job started`);
        });
    }

    end(job: any) {
        this._jobApi.progress(job.id, { status: "JobEnd" }).subscribe(() => {
            this.dataSource.reload();
            this._appController.notice(`Job ended`);
        });
    }

    complete(e: MatCheckboxChange, job: any) {
        if (e.checked) {
            this._appController.getInput("Resolution").then(resolution => {
                if (this.contact) {
                    this._jobApi.complete(job.id, { name: this.contact.name, resolution: resolution }).subscribe(() => {
                        this.dataSource.reload();
                        this._appController.notice(`Job completed`);

                        this.updateTicketRequest.emit();
                    });
                }
            }, () => {
                e.source.checked = false;
            });
        }
    }

    edit(job?: any) {
        this._appController.openModal(() => {
            this.dataSource.reload();
            this.updateTicketRequest.emit();
        }, ModalFormJobQuickAddComponent, {
            width: AppConstant.SIZE.MODAL_MEDIUM,
            data: {
                subject: job,
                ticketId: this.ticketId
            }
        });
    }

    delete(job: any) {
        AppData.delete(this._appController, this.dataSource, job.id, `Are you sure you want delete '${job.jobCode}'?`, "Job deleted");
    }

    updateLimits() {
        AppData.createStore("id", environment.api_erp_service_level_agreement_limits, {
            loadParams: {
                serviceAgreementId: this.serviceAgreementId
            }
        }).load().then(limits => {
            this.limits = limits;
        });
    }
}
