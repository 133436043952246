import { Component } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import dxDataGrid from 'devextreme/ui/data_grid';
import { JobApi } from 'src/app/api/job.api';
import { AppController } from 'src/app/app.controller';
import { AppData } from 'src/app/app.data';

@Component({
    selector: 'app-job-list',
    templateUrl: './job-list.component.html',
    styleUrls: ['./job-list.component.scss']
})
export class JobListComponent {
    public readonly dataSource = new DataSource(AppData.jobs());

    constructor(
        private readonly _jobApi: JobApi,
        private readonly _appController: AppController) {
    }

    accept(job: any) {
        this.dataSource.store().update(job.id, { status: "accepted" }).then(() => {
            this.dataSource.reload();
        });
    }

    complete(job: any) {
        this._appController.getInput("Resolution").then(resolution => {
            this._jobApi.complete(job.id, { name: "Auto", resolution: resolution }).subscribe(() => {
                this.dataSource.reload();
                this._appController.notice(`Job completed`);
            });
        });
    }

    delete(e: { component: dxDataGrid; rowIndex: number }) {
        AppData.deleteV2(this._appController, e);
    }
}
