import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';
import { HttpService } from './HttpService';

@Injectable()
export class ProspectApi extends HttpService {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public convert(id: number, data: any[]) {
    var apiEndpoint = `${this.getEndpoint(environment.api_erp_lead_prospects)}/${id}/convert`;
    return this.post(
      apiEndpoint,
      data);
  }
}
