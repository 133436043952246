import { Component } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { AppData } from 'src/app/app.data';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-finance-period-list',
    templateUrl: './finance-period-list.component.html'
})
export class FinancePeriodListComponent {
    public readonly dataSource = new DataSource(AppData.createStore("periodCode", environment.api_erp_finance_periods));

    onEditorPreparing(e) {
        if (e.parentType === "dataRow") {
            if (e.dataField === "periodCode") {
                e.editorOptions.disabled = !e.row.isNewRow;
            }
        }
    }
}
