import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import DataSource from 'devextreme/data/data_source';
import { CustomerTaskApi } from 'src/app/api/customer-task.api';
import { CustomerApi } from 'src/app/api/customer.api';
import { LocationCustomerApi } from 'src/app/api/location-customer.api';
import { AppController } from 'src/app/app.controller';
import { AppData } from 'src/app/app.data';
import { SecurityService } from 'src/app/service/security.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-customer-form',
    templateUrl: './customer-form.component.html',
    styleUrls: ['./customer-form.component.scss']
})
export class CustomerFormComponent implements OnInit {
    public readonly users = AppData.users(true);
    public readonly customerClasses = new DataSource(AppData.createStore("value", environment.api_erp_list_customer_classes));
    public readonly ratings = [{ value: 1, text: "bronze" }, { value: 2, text: "silver" }, { value: 3, text: "gold" }];

    form: FormGroup;
    account: string;
    canUpdate = true;
    showContracts = false;

    outstandingTaskCount = 0;

    constructor(
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _formBuilder: FormBuilder,
        private readonly _customerLocationApi: LocationCustomerApi,
        private readonly _customerTaskApi: CustomerTaskApi,
        private readonly _customerApi: CustomerApi,
        private readonly _securityService: SecurityService,
        private readonly _router: Router,
        private readonly _appController: AppController,
        @Optional() @Inject(MAT_DIALOG_DATA) private data?: any) {
    }

    ngOnInit() {
        this.form = this._formBuilder.group({
            account: [null, [Validators.required]],

            name: [null, [Validators.required]],
            tradingName: [null, [Validators.required]],

            taxStatus: [null, [Validators.required]],
            vatNumber: [null, [Validators.required]],
            ckNumber: [null, [Validators.required]],
            customerClass: [null, [Validators.required]],
            billingDay: [null],
            billingType: [null],
            invoiceTerms: [null],
            priceCode: [null],
            onHold: [false],
            warnOutstandingPayments: [false],
            rating: [null],
            accountRef: [null],
            notes: [null],

            salesperson1Id: [null, [Validators.required]],
            salesperson1: [{ value: null, disabled: true }],
            salesperson2Id: [null],
            salesperson2: [{ value: null, disabled: true }],
            salesperson3Id: [null],
            salesperson3: [{ value: null, disabled: true }],

            billingAddress1: [null],
            billingAddress2: [null],
            billingAddress3: [null],
            billingAddressCode: [null],

            // not sent to server
            billingAddress: [null]
        });

        var loadCustomer = (account: string) => {
            this._customerApi.find(account).subscribe(customer => {
                if (customer) {
                    customer.billingAddress = [customer.billingAddress1, customer.billingAddress2, customer.billingAddress3, customer.billingAddressCode].join();
                    this.form.patchValue(customer);
                    this.form.get("account").disable();

                    if (this._securityService.isInRole("sales")) {
                        if (customer.salesperson1Id !== this._securityService.getAuthenticatedUserId()) {
                            this.canUpdate = false;
                        }
                    }
                }
            });

            this.getOutstandingTasks(account);
        };

        if (this.data?.account) {
            loadCustomer(this.data.account);
        }

        this._activatedRoute.params.subscribe(params => {
            if (params.account) {
                this.account = params.account;
                loadCustomer(params.account);
            }

            if (params.tab === "contracts") {
                this.showContracts = true;
            }
        });
    }

    getOutstandingTasks(account: string) {
        this._customerTaskApi.countOutstanding(account).subscribe((results) => {
            this.outstandingTaskCount = results;
        });
    }

    addressChanged(value: string[]) {
        if (value) {
            this.form.patchValue({
                billingAddress1: value[0],
                billingAddress2: value[1] + "," + value[2],
                billingAddress3: value[4],
                billingAddressCode: value[3]
            });

            this.form.markAsDirty();
        }
    }

    submit() {
        if (this.form.valid) {
            let data = this.form.value;
            if (!this.account) {
                this._customerApi.create(data)
                    .subscribe(customer => {
                        this._appController.notice("Customer created");
                        this._router.navigate(["/customer/", customer.account]);
                    }, error => {
                        this._appController.error(error);
                    });
            } else {
                this._customerApi.update(this.account, data)
                    .subscribe(customer => {
                        this.form.patchValue(customer);
                        this._appController.notice("Customer updated");
                    }, error => {
                        this._appController.error(error);
                    });
            }
        }
    }
}
