import { Component } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import dxDataGrid from 'devextreme/ui/data_grid';
import { AppController } from 'src/app/app.controller';
import { AppData } from 'src/app/app.data';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-inventory-list',
  templateUrl: './inventory-list.component.html',
  styleUrls: ['./inventory-list.component.scss']
})
export class InventoryListComponent {
  public readonly dataSource = new DataSource(AppData.createStore("code", environment.api_erp_inventory));

  constructor(private readonly _appController: AppController) {
  }

  public delete(e: { component: dxDataGrid; rowIndex: number }) {
    AppData.deleteV2(this._appController, e);
  }
}
