import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';

@Injectable()
export class InventoryMovementApi extends ApiService {
    constructor(httpClient: HttpClient) {
        super(environment.api_erp_inventory_movements, httpClient);
    }

    public adjustment(data) {
        return this.post(
            this.getEndpoint(environment.api_erp_inventory_movements_adjustment),
            data)
    }

    public transfer(data) {
        return this.post(
            this.getEndpoint(environment.api_erp_inventory_movements_transfer),
            data)
    }

    public receive(data) {
        return this.post(
            this.getEndpoint(environment.api_erp_inventory_movements_receive),
            data)
    }
}
