import { Component } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { ApiService } from 'src/app/api/api.service';
import { AppData } from 'src/app/app.data';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.scss']
})
export class InvoiceListComponent {

  public readonly dataSource = new DataSource({
    store: AppData.createStore(["account", "invoice", "documentType"], {
      loadUrl: ApiService.getEndpoint(environment.api_erp_invoices)
    })
  });
}
