import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';

@Injectable()
export class QuoteDataApi extends ApiService {
  constructor(httpClient: HttpClient) {
    super(environment.api_quotes_quotes, httpClient);
  }

  getSecurityProducts() {
    var apiEndpoint = this.getEndpoint(environment.api_quotes_quotes_static_data_security);
    return this.get(apiEndpoint);
  }

  getInfrastructureProducts() {
    var apiEndpoint = this.getEndpoint(environment.api_quotes_quotes_static_data_infrastructure);
    return this.get(apiEndpoint);
  }

  getHarware() {
    var apiEndpoint = this.getEndpoint(environment.api_quotes_quotes_static_data_hardware);
    return this.get(apiEndpoint);
  }

  getVoice() {
    var apiEndpoint = this.getEndpoint(environment.api_quotes_quotes_static_data_voice);
    return this.get(apiEndpoint);
  }

  getRates() {
    var apiEndpoint = this.getEndpoint(environment.api_quotes_quotes_static_data_rates);
    return this.get(apiEndpoint);
  }

  getSolution() {
    var apiEndpoint = this.getEndpoint(environment.api_quotes_quotes_static_data_solution);
    return this.get(apiEndpoint);
  }

  getPrint() {
    var apiEndpoint = this.getEndpoint(environment.api_quotes_quotes_static_data_print);
    return this.get(apiEndpoint);
  }
}
