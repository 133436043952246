import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';

@Injectable()
export class BillingApi extends ApiService {
  constructor(httpClient: HttpClient) {
    super(environment.api_erp_billing, httpClient);
  }

  calculate(id: number) {
    var apiEndpoint = `${this.endpoint}/${id}/calculate`;
    return this.post(apiEndpoint, null);
  }

  email(id: number) {
    var apiEndpoint = `${this.endpoint}/${id}/email`;
    return this.post(apiEndpoint, null);
  }
}
