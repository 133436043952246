import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import DataSource from 'devextreme/data/data_source';
import { AppData } from 'src/app/app.data';

@Component({
  selector: 'app-modal-inventory-user',
  templateUrl: './modal-select-inventory.component.html',
  styleUrls: ['./modal-select-inventory.component.scss']
})
export class ModalSelectInventoryComponent implements OnInit {
  public readonly dataSource = new DataSource(AppData.inventory());

  form: FormGroup;

  constructor(private readonly dialogRef: MatDialogRef<ModalSelectInventoryComponent>) {
  }

  ngOnInit() {
    this.form = new FormGroup({
      code: new FormControl(null, [Validators.required])
    });
  }

  proceed() {
    if (this.form.valid) {
      this.dialogRef.close(this.form.value.code);
    }
  }
}
