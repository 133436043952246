import { Component } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { AppData } from 'src/app/app.data';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-job-class-list',
    templateUrl: './job-class-list.component.html'
})
export class JobClassListComponent {
    public readonly dataSource = new DataSource(AppData.createStore("jobClass", environment.api_erp_job_class));

    onEditorPreparing(e) {
        if (e.parentType === "dataRow") {
            if (e.dataField === "jobClass") {
                e.editorOptions.disabled = !e.row.isNewRow;
            }
        }
    }
}
