import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ApiModule } from '../api/api.module';
import { AppUiDevExtremeModule } from '../app.ui.devextreme.module';
import { AppMaterialModule } from '../app.ui.material.module';
import { PartialModule } from '../partial/partial.module';
import { WidgetModule } from '../widget/widget.module';
import { ModalConfirmationComponent } from './modal-confirmation/modal-confirmation.component';
import { ModalCsvDataImportComponent } from './modal-csv-data-import/modal-csv-data-import.component';
import { ModalFormContractItemQuickAddComponent } from './modal-form-contract-item-quick-add/modal-form-contract-item-quick-add.component';
import { ModalFormInputComponent } from './modal-form-input/modal-form-input.component';
import { ModalFormInventoryMovementComponent } from './modal-form-inventory/modal-form-inventory-movement/modal-form-inventory-movement.component';
import { ModalFormInventoryReadingHardwareComponent } from './modal-form-inventory/modal-form-inventory-reading-hardware/modal-form-inventory-reading-hardware.component';
import { ModalFormInventoryReadingVoiceComponent } from './modal-form-inventory/modal-form-inventory-reading-voice/modal-form-inventory-reading-voice.component';
import { ModalFormJobQuickAddComponent } from './modal-form-job-quick-add/modal-form-job-quick-add.component';
import { ModalFormLeadConvertToCustomerComponent } from './modal-form-lead-convert-to-customer/modal-form-lead-convert-to-customer.component';
import { ModalFormLocationQuickAddComponent } from './modal-form-location/modal-form-location-quick-add/modal-form-location-quick-add.component';
import { ModalFormProjectStepDocumentQuickAddComponent } from './modal-form-project/modal-form-project-step-document-quick-add/modal-form-project-step-document-quick-add.component';
import { ModalFormProjectStepTaskQuickAddComponent } from './modal-form-project/modal-form-project-step-task-quick-add/modal-form-project-step-task-quick-add.component';
import { ModalFormPurchaseOrderLineComponent } from './modal-form-purchase-order-line/modal-form-purchase-order-line.component';
import { ModalFormSaleOrderLineComponent } from './modal-form-sale-order-line/modal-form-sale-order-line.component';
import { ModalFormSelectComponent } from './modal-form-select/modal-form-select.component';
import { ModalFormServiceLevelAgreementPlanEscalationComponent } from './modal-form-service-level-agreement-plan/modal-form-service-level-agreement-plan-escalation/modal-form-service-level-agreement-plan-escalation.component';
import { ModalFormServiceLevelAgreementPlanLimitComponent } from './modal-form-service-level-agreement-plan/modal-form-service-level-agreement-plan-limit/modal-form-service-level-agreement-plan-limit.component';
import { ModalFormServiceLevelAgreementPlanTargetComponent } from './modal-form-service-level-agreement-plan/modal-form-service-level-agreement-plan-target/modal-form-service-level-agreement-plan-target.component';
import { ModalFormTaskCallQuickAddComponent } from './modal-form-task/modal-form-task-call-quick-add/modal-form-task-call-quick-add.component';
import { ModalFormTaskEmailQuickAddComponent } from './modal-form-task/modal-form-task-email-quick-add/modal-form-task-email-quick-add.component';
import { ModalFormTaskMeetingQuickAddComponent } from './modal-form-task/modal-form-task-meeting-quick-add/modal-form-task-meeting-quick-add.component';
import { ModalFormTaskOtherQuickAddComponent } from './modal-form-task/modal-form-task-other-quick-add/modal-form-task-other-quick-add.component';
import { ModalFormTicketQuickAddComponent } from './modal-form-ticket-quick-add/modal-form-ticket-quick-add.component';
import { ModalFormUserQuickAddComponent } from './modal-form-user-quick-add/modal-form-user-quick-add.component';
import { ModalProductSelectComponent } from './modal-product-select/modal-product-select.component';
import { ModalProjectStepComponent } from './modal-project-step/modal-project-step.component';
import { ModalSelectCustomerComponent } from './modal-select-customer/modal-select-customer.component';
import { ModalSelectInventoryComponent } from './modal-select-inventory/modal-select-inventory.component';
import { ModalSelectLeadComponent } from './modal-select-lead/modal-select-lead.component';
import { ModalSelectSupplierComponent } from './modal-select-supplier/modal-select-supplier.component';
import { ModalSelectUserComponent } from './modal-select-user/modal-select-user.component';

@NgModule({
    declarations: [
        ModalFormTaskMeetingQuickAddComponent,
        ModalFormTaskOtherQuickAddComponent,
        ModalFormTaskCallQuickAddComponent,
        ModalConfirmationComponent,
        ModalFormLocationQuickAddComponent,
        ModalFormUserQuickAddComponent,
        ModalSelectCustomerComponent,
        ModalSelectSupplierComponent,
        ModalSelectInventoryComponent,
        ModalSelectLeadComponent,
        ModalSelectUserComponent,
        ModalFormProjectStepDocumentQuickAddComponent,
        ModalProjectStepComponent,
        ModalFormProjectStepTaskQuickAddComponent,
        ModalFormTaskEmailQuickAddComponent,
        ModalFormContractItemQuickAddComponent,
        ModalFormTicketQuickAddComponent,
        ModalFormJobQuickAddComponent,
        ModalFormInventoryMovementComponent,
        ModalFormInventoryReadingHardwareComponent,
        ModalFormInventoryReadingVoiceComponent,
        ModalFormPurchaseOrderLineComponent,
        ModalFormSelectComponent,
        ModalCsvDataImportComponent,
        ModalFormServiceLevelAgreementPlanTargetComponent,
        ModalFormServiceLevelAgreementPlanEscalationComponent,
        ModalFormServiceLevelAgreementPlanLimitComponent,
        ModalFormLeadConvertToCustomerComponent,
        ModalFormInputComponent,
        ModalProductSelectComponent,
        ModalFormSaleOrderLineComponent
    ],
    imports: [
        CommonModule,
        AppMaterialModule,
        AppUiDevExtremeModule,
        WidgetModule,
        ApiModule,
        PartialModule
    ]
})
export class ModalModule { }
