import { Component, Input, OnInit } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import dxDataGrid from 'devextreme/ui/data_grid';
import { AppConstant } from 'src/app/app.constant';
import { AppController } from 'src/app/app.controller';
import { AppData } from 'src/app/app.data';
import { ModalFormLocationQuickAddComponent } from 'src/app/modal/modal-form-location/modal-form-location-quick-add/modal-form-location-quick-add.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-partial-location-list',
  templateUrl: './partial-location-list.component.html',
  styleUrls: ['./partial-location-list.component.scss']
})
export class PartialLocationListComponent implements OnInit {

  public readonly titles = AppData.createStore("value", environment.api_erp_list_titles);

  @Input() account?: string;
  @Input() leadId?: number;

  public dataSource: DataSource;

  constructor(private readonly _appController: AppController) {
  }

  ngOnInit() {
    var endpoint = this.account
      ? environment.api_erp_customers_locations
      : environment.api_erp_leads_locations;

    this.dataSource = new DataSource({
      store: AppData.createStore("id", endpoint, {
        loadParams: {
          account: this.account,
          leadId: this.leadId
        }
      })
    });
  }

  edit(location?: any) {
    this._appController.openModal(() => {
      this.dataSource.reload();
    }, ModalFormLocationQuickAddComponent, {
      width: AppConstant.SIZE.MODAL_MEDIUM,
      data: {
        subject: location,
        account: this.account,
        leadId: this.leadId
      }
    });
  }

  editContact(e: { component: dxDataGrid; rowIndex: number }) {
    e.component.editRow(e.rowIndex);
  }

  delete(e: { component: dxDataGrid; rowIndex: number }) {
    AppData.deleteV2(this._appController, e);
  }

  deleteContact(e: { component: dxDataGrid; rowIndex: number }) {
    AppData.deleteV2(this._appController, e);
  }

  onInitNewRow(e, locationId: number) {
    e.data.locationId = locationId;
  }

  onCustomItemCreating(e) {
    e.customItem = { value: e.text, text: e.text };
  }

  getContactDataSource(location: any) {
    if (!location.dataSource) {
      location.dataSource = new DataSource({
        store: AppData.createStore("id", environment.api_erp_locations_contacts, {
          loadParams: {
            locationId: location.id
          }
        })
      });
    }

    return location.dataSource;
  }
}
