import { Component, Input, OnInit } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import dxDataGrid from 'devextreme/ui/data_grid';
import { TemplateProjectApi } from 'src/app/api/template-project.api';
import { AppController } from 'src/app/app.controller';
import { AppData } from 'src/app/app.data';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-partial-project-list',
  templateUrl: './partial-project-list.component.html',
  styleUrls: ['./partial-project-list.component.scss']
})
export class PartialProjectListComponent implements OnInit {

  @Input() account: string;

  public dataSource: DataSource;
  public templates: any[];

  constructor(
    private readonly _templateApi: TemplateProjectApi,
    private readonly _appController: AppController) {
  }

  ngOnInit() {
    this.dataSource = new DataSource({
      store: AppData.createStore("id", environment.api_erp_projects, {
        loadParams: {
          account: this.account
        }
      })
    });

    this.getTemplates();
  }

  getTemplates() {
    this._templateApi.findAll().subscribe((results: any) => {
      this.templates = results.data;
    });
  }

  public add(element: any) {
    this._templateApi.customer(this.account, element.id).subscribe(() => {
      this.dataSource.reload();
    });
  }

  delete(e: { component: dxDataGrid; rowIndex: number }) {
    AppData.deleteV2(this._appController, e);
  }
}
