import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { HttpTransportType, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { AppController } from 'src/app/app.controller';
import { AppData } from 'src/app/app.data';
import { SecurityService } from 'src/app/service/security.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'global-notifications',
    templateUrl: './global-notifications.component.html',
    styleUrls: ['./global-notifications.component.scss']
})
export class GlobalNotificationsComponent implements OnInit, OnDestroy {

    private readonly connection = new HubConnectionBuilder()
        .withUrl(environment.api_erp_host + "/erp/hubs/activity?url=" + encodeURI(this._router.url), { accessTokenFactory: () => this._securityService.getAuthenticatedUserToken(), transport: HttpTransportType.LongPolling })
        .withAutomaticReconnect()
        .build();

    users?: string[];
    notifications?: string[];

    constructor(
        private readonly _router: Router,
        private readonly _appController: AppController,
        private readonly _securityService: SecurityService) {

        _router.events.subscribe(async val => {
            if (val instanceof NavigationEnd) {
                if (this.connection.state === HubConnectionState.Connected) {
                    await this.connection.send("UpdatePage", _router.url);
                }
            }
        });
    }

    async ngOnInit() {
        this._appController.notificationsRead.subscribe(() => {
            this.notifications = null;
        });

        this.connection.on("UserConnected", (users: string[]) => {
            this.users = users;
        });

        this.connection.on("Notification", (notifications: string[]) => {
            if (notifications.length === 0) {
                notifications = null;
            }

            this.notifications = notifications;
        });

        await this.connection.start();
    }

    @HostListener('unloaded')
    async ngOnDestroy() {
        await this.connection.stop();
    }

    onItemSelected(e) {
        switch (e.itemData.source) {
            case "C":
                this._router.navigate(["/customer/", e.itemData.sourceKey]);
                break;
            case "L":
                this._router.navigate(["/customer/lead/", e.itemData.sourceKey]);
                break;
            case "T":
                this._router.navigate(["/support/ticket/", e.itemData.sourceKey]);
                break;
            case "J":
                AppData.jobs().store.byKey(e.itemData.sourceKey).then(job => {
                    this._router.navigate(["/support/ticket/", job.ticketId]);
                });

                break;
            case "P":
                this._router.navigate(["/project/", e.itemData.sourceKey]);
                break;
            case "I":
                this._router.navigate(["/inventory/", e.itemData.sourceKey]);
                break;
        }
    }
}
