import { Component, Input, OnInit } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { AppConstant } from 'src/app/app.constant';
import { AppController } from 'src/app/app.controller';
import { AppData } from 'src/app/app.data';
import { ModalFormInventoryMovementComponent } from 'src/app/modal/modal-form-inventory/modal-form-inventory-movement/modal-form-inventory-movement.component';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-partial-inventory-loan',
    templateUrl: './partial-inventory-loan.component.html'
})
export class PartialInventoryLoanComponent implements OnInit {

    @Input() code: string;
    @Input() defaultWarehouse: string;
    @Input() account: string;

    public readonly inventory = AppData.inventory();
    public dataSource: DataSource;

    constructor(private readonly _appController: AppController) {
    }

    ngOnInit() {
        this.dataSource = new DataSource({
            store: AppData.createStore("id", environment.api_erp_inventory_loans, {
                loadParams: {
                    code: this.code,
                    account: this.account
                }
            })
        });
    }

    add() {
        this._appController.openModal(() => {
            this.dataSource.reload();
        }, ModalFormInventoryMovementComponent, {
            width: AppConstant.SIZE.MODAL_LARGE,
            data: {
                code: this.code,
                defaultWarehouse: this.defaultWarehouse,
                movement: "T"
            }
        });
    }
}
