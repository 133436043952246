import { HttpParams } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api/api.service';
import { AppConstant } from 'src/app/app.constant';
import { AppController } from 'src/app/app.controller';
import { AppUtility } from 'src/app/app.utility';
import { Confirmation } from 'src/app/modal/modal-confirmation/confirmation';
import { ModalFormServiceLevelAgreementPlanTargetComponent } from 'src/app/modal/modal-form-service-level-agreement-plan/modal-form-service-level-agreement-plan-target/modal-form-service-level-agreement-plan-target.component';
import { BaseDataListing } from 'src/app/page/base-data-list.component';

@Component({
  selector: 'app-partial-service-level-agreement-target',
  templateUrl: './partial-service-level-agreement-target.component.html',
  styleUrls: ['./partial-service-level-agreement-target.component.scss']
})
export class PartialServiceLevelAgreementTargetComponent extends BaseDataListing implements OnInit {

  @Input() services: { [key: string]: ApiService };
  @Input() subject: any;
  @Input() queryParams: any;

  constructor(public readonly appController: AppController, public readonly appUtility: AppUtility) {
    super();

    this.actionMenu = [
      {
        label: "Edit", callBack: (element: any) => {
          this.add(element);
        }
      },
      {
        label: "Delete", callBack: (element: any) => {
          this.appController.confirm((proceed) => {
            if (proceed) {
              console.debug("delete", element);
              this.services.serviceLevelAgreementTargetApi.remove(element.id).subscribe((results) => {
                console.debug("deleted ", results);
                // this.datasourceRemoveByKeyValue(this.dataSource, "id", element.id);
                this.refresh();
                this.appController.notice(`Successfully Deleted SLA Plan Target`);
              }, (error) => {
                console.error("failed to delete ", error);
                this.appController.notice(`Failed to delete SLA Plan Target `);
              });
            }
          }, new Confirmation("delete_outline",
            `Are you sure you want delete SLA Plan Target?`,
            "Cancel", "Delete")
          );
        }
      }
    ];
  }

  ngOnInit() {
    console.debug("PartialServiceLevelAgreementTargetComponent", this.subject, this.queryParams);

    this.queryData(this.queryLimit, this.queryCurrentPage, true, this.querySort, this.queryFilter);
  }

  queryData(take: number, skip: number, count: boolean, sort: any[], filter?: any[]) {
    this.query = new HttpParams()
      .set("take", `${take}`)
      .set("skip", `${skip}`)
      .set("requireTotalCount", `${count}`)

    this.query = this.queryAdd(this.query, this.queryParams);
    this.query = this.queryAddSort(this.query, sort);
    this.query = this.queryAddFilter(this.query, filter);

    console.debug("query ", this.query.toString());

    this.services.serviceLevelAgreementTargetApi
      .findAll(this.query)
      .subscribe((results: any) => {
        console.debug(" commision", results);
        this.dataSource = results;
      }, (error) => {
        console.debug("failed to obtain  commision", error);
      });
  }

  onSelect(selected: any[]): void {
    console.debug("item selected", selected);
  }

  get columnLabels(): string[] {
    return [
      "Priority",
      "Respond Time",
      "Time Unit",
      "Resolve Time",
      "Time Unit",
      "Business Hours Rate",
      "After hours Rate",
      ""
    ];
  }

  get columnKeys(): string[] {
    return [
      "priority",
      "respondTime",
      "respondTimeUnit",
      "resolveTime",
      "resolveTimeUnit",
      "businessHoursRate",
      "afterHoursRate",
      "action"
    ];
  }

  add(element?: any) {
    this.appController.openModal((results: any) => {
      console.debug("add SLA Plan Target", results);
      this.save(results);
    }, ModalFormServiceLevelAgreementPlanTargetComponent,
      {
        width: AppConstant.SIZE.MODAL_MEDIUM,
        height: AppConstant.SIZE.MODAL_MEDIUM,
        data: { subject: element, services: this.services, queryParams: { servicePlanId: this.subject.id } }
      });
  }

  save(subject: any) {
    if (subject) {
      if (!subject.id) {
        this.services.serviceLevelAgreementTargetApi.create(subject).subscribe((results: any) => {
          console.debug("saved SLA Plan", results);
          this.appController.notice(`Successfully created SLA Plan Target`);
          this.refresh();
        }, (error) => {
          console.error("failed to save SLA", error);
          this.appController.notice("Failed to save SLA Plan Target");
        });
      } else {
        this.services.serviceLevelAgreementTargetApi.update(subject.id, this.appUtility.omit(subject, ["id"])).subscribe((results: any) => {
          console.debug("updated SLA", results);
          this.appController.notice(`Successfully updated SLA Plan Target`);
          this.refresh();
        }, (error) => {
          console.error("failed to save SLA Plan", error);
          this.appController.notice("Failed to save SLA Plan");
        });
      }
    }
  }

}
