import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from './app.component';
import { AppController } from "./app.controller";
import { AppMaterialModule } from './app.ui.material.module';
import { AppUtility } from './app.utility';
import { AuthorizationInterceptor } from "./interceptor/authorization.interceptor";
import { LoaderInterceptor } from './interceptor/loader.interceptor';
import { PageModule } from './page/page.module';
import { PartialModule } from './partial/partial.module';
import { FormGroupService } from './service/form.service';
import { QuoteService } from './service/quote.service';
import { SecurityService } from './service/security.service';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'esapartnernowwena' }),
    AppMaterialModule,
    AppRoutingModule,

    PageModule,
    PartialModule,
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: "en-ZA"
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    },

    AppController,
    AppUtility,

    SecurityService,
    FormGroupService,
    QuoteService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
