import { Component, Input, OnInit } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { ApiService } from 'src/app/api/api.service';
import { AppData } from 'src/app/app.data';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-partial-inventory-serials',
  templateUrl: './partial-inventory-serials.component.html',
  styleUrls: ['./partial-inventory-serials.component.scss']
})
export class PartialInventorySerialsComponent implements OnInit {
  @Input() code: string;

  public dataSource: DataSource;

  ngOnInit() {
    this.dataSource = new DataSource({
      store: AppData.createStore(["code", "serial"], {
        loadUrl: ApiService.getEndpoint(environment.api_erp_inventory_serials),
        loadParams: {
          code: this.code
        }
      })
    });
  }

  getMovementsDataSource(serial: any) {
    if (!serial.dataSource) {
      serial.dataSource = new DataSource({
        store: AppData.createStore(["journal", "journalEntry"], {
          loadUrl: ApiService.getEndpoint(environment.api_erp_inventory_serials_movements),
          loadParams: {
            code: this.code,
            serial: serial.serial
          }
        })
      });
    }

    return serial.dataSource;
  }
}
