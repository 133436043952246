import { Directive, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import query from "devextreme/data/query";
import dxTagBox from 'devextreme/ui/tag_box';

@Directive()
export abstract class MultiSelect {
    @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;

    public dataSource: DataSource;

    add() {
        this.dataGrid.instance.addRow();
    }

    onItemValueChanged(e, cellInfo) {
        if (e.event.type === "change") {
            this.addItems();
        }
        else {
            cellInfo.setValue(e.value);
        }
    }

    addItem(e: Event, data) {
        e.preventDefault();
        e.stopPropagation();

        var items = this.itemPopup.option("items") || [];
        items.push(data);

        this.itemPopup.option({
            items: items,
            value: items
        });
    }

    addItems() {
        var current = this.dataSource.items()
        var changes = [];

        var items = this.itemPopup.option("value");
        query(items)
            .groupBy("description")
            .toArray()
            .forEach(group => {
                var item = group.items[0];
                var quantity = group.items.length;

                var c = current.find(_ => _.description === item.description)
                if (c) {
                    changes.push({
                        type: "update",
                        key: c,
                        data: {
                            quantity: c.quantity + quantity,
                            esaQuantity: c.esaQuantity + quantity
                        }
                    });
                } else {
                    changes.push({
                        type: "insert",
                        data: {
                            ...item,
                            quantity: quantity,
                            currentQuantity: 1,
                            esaQuantity: quantity,
                            esaPrice: item.price
                        }
                    });
                }
            });

        if (changes.length > 0) {
            this.itemPopup.option("value", null);

            var store = this.dataSource.store();
            store.push(changes);

            this.dataGrid.instance.cancelEditData();
        }
    }

    itemPopup: dxTagBox;
    itemToolbar = [
        {
            toolbar: "bottom",
            location: "before",
            widget: "dxTagBox",
            options: {
                onInitialized: (e) => {
                    this.itemPopup = e.component;
                },
                onValueChanged(e) {
                    e.component.option("items", e.value);
                },
                displayExpr: "description",
                maxDisplayedTags: 2,
                width: "500px"
            }
        },
        {
            toolbar: "bottom",
            location: "after",
            widget: "dxButton",
            options: {
                text: "Add",
                onClick: () => {
                    this.addItems();
                }
            }
        }
    ];
}
