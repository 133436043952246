import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import query from 'devextreme/data/query';
import { QuoteLine } from 'src/app//model/quote';
import { QuoteDataApi } from 'src/app/api/quote-data.api';
import { QuoteCalculationHelper } from 'src/app/helper/quote-calculation.helper';
import { FormGroupService } from 'src/app/service/form.service';
import { MultiSelect } from '../MultiSelect';

@Component({
    selector: 'app-partial-quotation-hardware',
    templateUrl: './partial-quotation-hardware.component.html',
    styleUrls: ['./partial-quotation-hardware.component.scss']
})
export class PartialQuotationHardwareComponent extends MultiSelect implements OnInit {

    @Input() subject: any;
    @Input() calculate: EventEmitter<void>;
    @Output() changed = new EventEmitter<any>();

    public selectableHardware: any[];
    public hardware: ArrayStore;

    constructor(
        private readonly _quoteDataApi: QuoteDataApi,
        private readonly _quoteCalculationHelper: QuoteCalculationHelper,
        public formGroupService: FormGroupService) {
        super();
    }

    ngOnInit() {
        this.dataSource = new DataSource({
            store: new ArrayStore({
                data: this.subject?.hardware || []
            }),
            group: "category",
            reshapeOnPush: true
        });

        this.getHardware();

        this.calculate.subscribe(async () => {
            await this.dataGrid.instance.refresh();
            this.dataGridChanged();
        });
    }

    onCustomItemCreating(e) {
        e.customItem = { code: e.text, description: e.text, quantity: 1, price: 0 };
    }

    getHardware() {
        this._quoteDataApi.getHarware().subscribe(results => {
            this.selectableHardware = query(results.data).groupBy("category").toArray();

            if (!this.subject) {
                this.addDefaults(results.data);
            }
        });
    }

    addDefaults(selectableHardware: any[]) {
        let sundries = selectableHardware.find(_ => _.description === "Sundries");
        let dealAdministrationFee = selectableHardware.find(_ => _.description === "Deal Administration Fee");

        if (sundries) {
            this.dataSource.store().insert({
                code: sundries.code,
                description: sundries.description,
                quantity: 1,
                price: parseFloat(sundries.price)
            }).then(() => {
                this.dataSource.reload().then(() => {
                    this.dataGridChanged();
                });
            });
        }

        if (dealAdministrationFee) {
            this.dataSource.store().insert({
                code: dealAdministrationFee.code,
                description: dealAdministrationFee.description,
                quantity: 1,
                price: parseFloat(dealAdministrationFee.price)
            }).then(() => {
                this.dataSource.reload().then(() => {
                    this.dataGridChanged();
                });
            });
        }
    }

    hardwareSelectionChanged(e, cellInfo) {
        if (e.selectedItem) {
            cellInfo.data.code = e.selectedItem.code;
            cellInfo.data.description = e.selectedItem.description;

            if (!cellInfo.data.quantity) {
                cellInfo.data.quantity = 1;
            }

            if (!cellInfo.data.price) {
                cellInfo.data.price = e.selectedItem.price;
            }
        }
    }

    hardwareTotalCellValue(e: QuoteLine) {
        if (e.quantity && e.price) {
            return e.quantity * e.price;
        }

        return 0;
    }

    calculateSummary = (e) => {
        if (e.name === "total") {
            if (e.summaryProcess === "start") {
                e.totalValue = 0;
            }

            if (e.summaryProcess === "calculate") {
                e.totalValue += this.hardwareTotalCellValue(e.value);
            }

            if (e.summaryProcess === "finalize") {
                e.totalValue += this.formGroupService.getFloat("settlement");
            }
        }

        if (e.name === "totalWithMargin") {
            if (e.summaryProcess === "start") {
                e.totalValue = 0;
            }

            if (e.summaryProcess === "calculate") {
                e.totalValue += this.hardwareTotalCellValue(e.value);
            }

            if (e.summaryProcess === "finalize") {
                var margin = this._quoteCalculationHelper.calculateMargin(
                    this.formGroupService.getFloat("margin"),
                    this.formGroupService.getValue("marginType"),
                    e.totalValue) || 0;
                e.totalValue += margin;
                e.totalValue += this.formGroupService.getFloat("settlement");
            }
        }
    }

    dataGridChanged() {
        this.changed.emit({
            hardware: this.dataSource.items(),
            total: this.dataGrid.instance.getTotalSummaryValue("total") || 0,
            totalWithMargin: this.dataGrid.instance.getTotalSummaryValue("totalWithMargin") || 0
        });
    }
}
