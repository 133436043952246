import { Component } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { AppController } from 'src/app/app.controller';
import { AppData } from 'src/app/app.data';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-purchase-order-list',
    templateUrl: './purchase-order-list.component.html',
    styleUrls: ['./purchase-order-list.component.scss']
})
export class PurchaseOrderListComponent {
    public readonly dataSource = new DataSource(AppData.createStore("orderNumber", environment.api_erp_purchase_orders));
    public readonly orderStatuses = [{ text: "New", value: "N" }, { text: "Acknowledged", value: "A" }, { text: "Complete", value: "C" }];

    constructor(private readonly _appController: AppController) {
    }

    public delete(order: any) {
        AppData.delete(this._appController, this.dataSource, order.orderNumber, `Are you sure you want delete '${order.orderNumber}'?`, "Purchase order deleted");
    }
}
