import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { QuoteDataApi } from 'src/app/api/quote-data.api';
import { AppConstant } from 'src/app/app.constant';
import { AppController } from 'src/app/app.controller';
import { QuoteCalculationHelper } from 'src/app/helper/quote-calculation.helper';
import { ModalProductSelectComponent } from 'src/app/modal/modal-product-select/modal-product-select.component';
import { FormGroupService } from 'src/app/service/form.service';

@Component({
  selector: 'app-quote-form-solution',
  templateUrl: './quote-form-solution.component.html',
  styleUrls: ['./quote-form-solution.component.scss']
})
export class QuoteFormSolutionComponent implements OnInit {

  readonly calculateRequested: EventEmitter<boolean> = new EventEmitter();

  @Input() subject: any;

  private _products: any[];
  public product: any;

  constructor(
    private readonly _quoteDataApi: QuoteDataApi,
    private readonly _appController: AppController,
    private readonly _formBuilder: FormBuilder,
    private readonly _quoteCalculationHelper: QuoteCalculationHelper,
    public formGroupService: FormGroupService) {
  }

  ngOnInit() {
    this.getProducts();
    this.formGroupService.mergeFormControls(this.getForm(this.subject));
  }

  ngAfterViewInit() {
    this.formGroupService.formGroup.valueChanges
      .subscribe(() => {
        this.calculate(true);
      });
  }

  private getForm(subject?: any): FormGroup {
    let formGroup: FormGroup = this._formBuilder.group({
      product: [null],
      productDescription: [null],
      productCost: [null],
      hardware: [null],
      hardwareTotal: [0.0],
      customLines: [null],
      customLinesTotal: [0.0]
    });

    if (subject) {
      formGroup.patchValue(subject);
    }

    return formGroup;
  }

  getProducts() {
    this._quoteDataApi.getVoice().subscribe(results => {
      this._products = (results.data as any[]).map(item => ({
        code: item.code,
        description: item.description,
        longDescription: item.long_description,
        image: "https://prometheansociety.com" + item.image,
        price: item.price,
        item: item
      }));

      if (this.subject && this.subject.product) {
        var product = this._products.find(_ => _.description === this.subject.product);
        this.setSelectedProduct(product);
      }
    });
  }

  selectProduct() {
    this._appController.openModal((modalItem: any) => {
      this.setSelectedProduct(modalItem);
    }, ModalProductSelectComponent, {
      width: AppConstant.SIZE.MODAL_LARGE,
      data: { products: this._products }
    });
  }

  setSelectedProduct(product: any) {
    this.product = product;

    if (product) {
      this.formGroupService.setValue("product", product.description);
      this.formGroupService.setValue("productDescription", product.description);
    }
  }

  hardwareChanged(e) {
    this.formGroupService.setValue("hardware", e.hardware, false);
    this.formGroupService.setValue("hardwareTotal", e.total, false);
    this.calculate(true);
  }

  adHocChanged(e) {
    this.formGroupService.setValue("customLines", e.adHocs, false);
    this.formGroupService.setValue("customLinesTotal", e.total, false);
    this.calculate(true);
  }

  calculate(silent?: boolean) {
    if (!silent) {
      this.calculateRequested.emit();
    }

    let termsType = this.formGroupService.getValue("termsType");

    let total = this.formGroupService.getFloat("hardwareTotal") + this.formGroupService.getFloat("customLinesTotal");
    let margin = this.formGroupService.getFloat("margin");
    let marginType = this.formGroupService.getValue("marginType");
    let period = this.formGroupService.getFloat("period");
    let esc = this.formGroupService.getFloat("escalation", 0);

    let totalMargin = this._quoteCalculationHelper.calculateMargin(margin, marginType, total) || 0;
    this.formGroupService.setValue("totalMargin", totalMargin, false);

    total += totalMargin;

    if (termsType === "Rental") {
      var factor = this._quoteCalculationHelper.calculateFactor(total, margin, marginType, period, esc);
      this.formGroupService.setValue("factor", factor, false);

      total *= factor;
    }

    this.formGroupService.setValue("totalMonthly", total, false);
    this.formGroupService.setValue("totalAnnual", total * 12, false);
  }
}
