import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
    selector: 'app-widget-search-field',
    templateUrl: './widget-search-field.component.html',
    styleUrls: ['./widget-search-field.component.scss']
})
export class WidgetSearchFieldComponent implements OnInit {
    @Output() onSearch = new EventEmitter<string>();

    search = new FormControl();

    ngOnInit() {
        this.search.valueChanges
            .pipe(
                distinctUntilChanged(),
                debounceTime(500),
            )
            .subscribe((value) => {
                this.onSearch.emit(value);
            });
    }
}
