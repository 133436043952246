import { AfterViewInit, Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import query from 'devextreme/data/query';
import { QuoteCalculationHelper } from 'src/app/helper/quote-calculation.helper';
import { QuoteInfrastructure, QuoteSummary } from 'src/app/model/quote';
import { FormGroupService } from 'src/app/service/form.service';

@Component({
    selector: 'app-quote-form-voice',
    templateUrl: './quote-form-voice.component.html',
    styleUrls: ['./quote-form-voice.component.scss']
})
export class QuoteFormVoiceComponent implements OnInit, AfterViewInit {

    readonly calculateRequested = new EventEmitter();
    readonly updateAnalysisRequested = new EventEmitter<QuoteSummary[]>();

    @Input() subject: any;

    constructor(
        private readonly _formBuilder: FormBuilder,
        private readonly _quoteCalculationHelper: QuoteCalculationHelper,
        public formGroupService: FormGroupService) {
    }

    ngOnInit() {
        this.formGroupService.mergeFormControls(this.getForm(this.subject));
    }

    ngAfterViewInit() {
        this.formGroupService.formGroup.valueChanges
            .subscribe(() => {
                this.calculate(true);
            });
    }

    private getForm(subject?: any): FormGroup {
        let formGroup: FormGroup = this._formBuilder.group({
            rates: [null],
            ratesCurrent: 0,
            ratesEsa: 0,
            infrastructure: [],
            infrastructureGrouped: [[]],
            hardware: [null],
            hardwareTotal: [0.0],
            hardwareTotalWithMargin: [0.0],
            customLines: [null],
            customLinesTotal: [0.0],
            analysis: [null]
        });

        if (subject) {
            formGroup.patchValue(subject);
        }

        return formGroup;
    }

    ratesChanged(e) {
        this.formGroupService.setValue("rates", e.rates, false);
        this.formGroupService.setValue("ratesCurrent", e.current, false);
        this.formGroupService.setValue("ratesEsa", e.esa, false);
        this.calculate(true);
    }

    infrastructureChanged(e) {
        this.formGroupService.setValue("infrastructure", e.infrastructure, false);
        this.formGroupService.setValue("infrastructureGrouped", e.grouped, false);
        this.calculate(true);
    }

    hardwareChanged(e) {
        this.formGroupService.setValue("hardware", e.hardware, false);
        this.formGroupService.setValue("hardwareTotal", e.total, false);
        this.formGroupService.setValue("hardwareTotalWithMargin", e.totalWithMargin, false);
        this.calculate(true);
    }

    adHocChanged(e) {
        this.formGroupService.setValue("customLines", e.adHocs, false);
        this.formGroupService.setValue("customLinesTotal", e.total, false);
        this.calculate(true);
    }

    async calculate(silent?: boolean) {
        if (!silent) {
            this.calculateRequested.emit();
        }

        let termsType = this.formGroupService.getValue("termsType");
        let summaryItems = new Array<QuoteSummary>();

        summaryItems.push(new QuoteSummary("Current Calls", this.formGroupService.getFloat("ratesCurrent"), 0, "current"));
        this.formGroupService.getValue("infrastructureGrouped").forEach((group: { key: string, items: QuoteInfrastructure[] }) => {
            var total = 0;

            if (termsType === "Special") {
                var items = query(group.items).filter(["summaryOnly", false]).toArray() as QuoteInfrastructure[];
                items.forEach(_ => {
                    total += _.currentQuantity * _.currentPrice;
                });

                if (items.length > 0) {
                    summaryItems.push(new QuoteSummary(group.key, total || 0, 0, "current"));
                }
            } else {
                group.items.forEach(_ => {
                    total += _.currentQuantity * _.currentPrice;
                });

                summaryItems.push(new QuoteSummary(group.key, total || 0, 0, "current"));
            }
        });

        summaryItems.push(new QuoteSummary("ESA Calls", 0, this.formGroupService.getFloat("ratesEsa"), "esa"));
        this.formGroupService.getValue("infrastructureGrouped").forEach((group: { key: string, items: QuoteInfrastructure[] }) => {
            var total = 0;

            if (termsType === "Special") {
                var items = query(group.items).filter(["summaryOnly", false]).toArray() as QuoteInfrastructure[];
                items.forEach(_ => {
                    total += _.esaQuantity * _.esaPrice;
                });

                if (items.length > 0) {
                    summaryItems.push(new QuoteSummary(group.key, 0, total || 0, "esa"));
                }
            } else {
                group.items.forEach(_ => {
                    total += _.esaQuantity * _.esaPrice;
                });

                summaryItems.push(new QuoteSummary(group.key, 0, total || 0, "esa"));
            }
        });

        let total = this.formGroupService.getFloat("hardwareTotal") + this.formGroupService.getFloat("customLinesTotal");
        let margin = this.formGroupService.getFloat("margin");
        let marginType = this.formGroupService.getValue("marginType");
        let period = this.formGroupService.getFloat("period");
        let esc = this.formGroupService.getFloat("escalation", 0);

        let totalMargin = this._quoteCalculationHelper.calculateMargin(margin, marginType, total) || 0;
        this.formGroupService.setValue("totalMargin", totalMargin, false);

        switch (termsType) {
            case "Rental":
                var factor = this._quoteCalculationHelper.calculateFactor(total, margin, marginType, period, esc);
                this.formGroupService.setValue("factor", factor, false);

                total = this.formGroupService.getFloat("hardwareTotalWithMargin") + this.formGroupService.getFloat("customLinesTotal");
                total *= factor;

                summaryItems.push(new QuoteSummary("ESA Hardware Rental", 0, total, "esa"));
                break;

            case "Special":
                var factor = this._quoteCalculationHelper.calculateFactor(total, margin, marginType, period, esc);
                this.formGroupService.setValue("factor", factor, false);

                let settlement = this.formGroupService.getFloat("settlement");
                total -= settlement;
                total += settlement * factor;

                summaryItems.push(new QuoteSummary("ESA Hardware Rental", 0, total, "esa"));
                break;

            default:
                total = this.formGroupService.getFloat("hardwareTotalWithMargin");
                break;
        }

        var savings = await query(summaryItems).sum("current") - await query(summaryItems).sum("esa");

        this.formGroupService.setValue("totalHardware", total, false);
        this.formGroupService.setValue("totalMonthly", savings, false);
        this.formGroupService.setValue("totalAnnual", savings * 12, false);

        this.formGroupService.setValue("analysis", summaryItems, false);
        this.updateAnalysisRequested.emit(summaryItems);
    }
}
