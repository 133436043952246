import { Component } from '@angular/core';
import { Router } from '@angular/router';
import DataSource from 'devextreme/data/data_source';
import dxDataGrid from 'devextreme/ui/data_grid';
import { ProjectApi } from 'src/app/api/project.api';
import { AppConstant } from 'src/app/app.constant';
import { AppController } from 'src/app/app.controller';
import { AppData } from 'src/app/app.data';
import { ModalFormSelectComponent } from 'src/app/modal/modal-form-select/modal-form-select.component';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-project-list',
    templateUrl: './project-list.component.html',
    styleUrls: ['./project-list.component.scss']
})
export class ProjectListComponent {
    public readonly dataSource = new DataSource(AppData.createStore("id", environment.api_erp_projects));

    constructor(
        private readonly _projectApi: ProjectApi,
        private readonly _appController: AppController,
        private readonly _router: Router) {
    }

    delete(e: { component: dxDataGrid; rowIndex: number }) {
        AppData.deleteV2(this._appController, e);
    }

    convertToContract(project) {
        this._appController.openModal((orders?: number[]) => {
            if (orders && orders.length > 0) {
                this._projectApi.convertToContract(project.id, orders).subscribe(() => {
                    this._appController.notice("Contract created");
                    this._router.navigate(["/customer/", project.account, "contracts"]);
                }, error => {
                    this._appController.notice(error);
                });
            }
        }, ModalFormSelectComponent, {
            width: AppConstant.SIZE.MODAL_SMALL,
            data: {
                label: "Orders",
                valueKey: "id",
                labelKey: "code",
                multiple: true,
                dataSource: new DataSource({
                    store: AppData.createStore("id", environment.api_erp_project_orders, {
                        loadParams: {
                            projectId: project.id
                        }
                    }),
                    select: ["id", "code"]
                })
            }
        });
    }
}
