import { HttpParams } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DxFileUploaderComponent, DxHtmlEditorComponent } from 'devextreme-angular';
import { TicketEmailApi } from 'src/app/api/ticket-email.api';
import { TicketNoteApi } from 'src/app/api/ticket-note.api';
import { AppController } from 'src/app/app.controller';
import { AppData } from 'src/app/app.data';

@Component({
  selector: 'app-partial-ticket-comment',
  templateUrl: './partial-ticket-comment.component.html',
  styleUrls: ['./partial-ticket-comment.component.scss']
})
export class PartialTicketCommentComponent implements OnInit {

  @ViewChild(DxHtmlEditorComponent) editor: DxHtmlEditorComponent;
  @ViewChild(DxFileUploaderComponent) uploader: DxFileUploaderComponent;

  @Input() ticketId: string;

  public notes: any[] = [];
  public form: FormGroup;

  constructor(
    private readonly _ticketNoteApi: TicketNoteApi,
    private readonly _ticketEmailApi: TicketEmailApi,
    private readonly _formBuilder: FormBuilder,
    public readonly appController: AppController) {
  }

  ngOnInit() {
    this.form = this._formBuilder.group({
      ticketId: [this.ticketId, [Validators.required]],
      file: [null],
      fileName: [null],
      note: [null, [Validators.required]],
      emailCopy: [null],
      sendEmail: [false]
    });

    if (this.ticketId) {
      this.getNotes(this.ticketId);
    }
  }

  getNotes(ticketId: string) {
    this.notes = [];

    AppData.tickets().store.byKey(ticketId).then((ticket: any) => {
      this.form.get("emailCopy").setValue(ticket.emailCopy);

      if (ticket.emailHtml) {
        this.notes.push({
          note: false,
          internal: false,
          text: this.removeUnsafeImg(ticket.emailHtml),
          name: ticket.emailFrom,
          created: new Date(ticket.created)
        });
      }
    });

    this._ticketEmailApi.findAll(new HttpParams().set("ticketId", ticketId)).subscribe((emails: any) => {
      this.notes.push(...emails.data.map(_ => ({
        note: false,
        internal: false,
        text: this.removeUnsafeImg(_.bodyHtml),
        name: _.from,
        created: new Date(_.created)
      })));
    });

    this._ticketNoteApi.findAll(new HttpParams().set("ticketId", ticketId)).subscribe((notes: any) => {
      this.notes.push(...notes.data.map(_ => ({
        note: true,
        internal: !_.emailMessageId,
        text: _.note,
        name: _.user.name,
        image: _.user.image,
        file: _.file,
        fileName: _.fileName,
        created: new Date(_.created)
      })));
    });
  }

  public sort(notes: any[]) {
    return notes.sort((a, b) => (a.created < b.created) ? 1 : -1);
  }

  public onFileUploaded(e: { value?: File[] }) {
    if (e.value && e.value[0]) {
      var file = e.value[0];

      var fr = new FileReader();
      fr.onload = args => {
        var content = args.target.result as string;
        var base64 = content.split(',')[1];
        this.form.get("file").setValue(base64);
        this.form.get("fileName").setValue(file.name);
      };
      fr.readAsDataURL(file);
    }
  }

  onValueChanged(value: string) {
    this.form.get("note").setValue(value);
  }

  save() {
    if (this.form.valid) {
      let data = this.form.value;
      this._ticketNoteApi.create(data).subscribe(() => {
        this.form.reset({ ticketId: this.ticketId });

        this.uploader.instance.reset();
        this.editor.instance.reset();

        this.getNotes(this.ticketId);
      });
    }
  }

  private removeUnsafeImg(text: string) {
    return text.replace(/<img src="cid[^>]*>/g, "");
  }
}
