import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import DataSource from 'devextreme/data/data_source';
import dxDataGrid from 'devextreme/ui/data_grid';
import { ApiService } from 'src/app/api/api.service';
import { PurchaseOrderLineApi } from 'src/app/api/purchase-order-line.api';
import { AppController } from 'src/app/app.controller';
import { AppData } from 'src/app/app.data';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-partial-purchase-order-receive',
    templateUrl: './partial-purchase-order-receive.component.html',
    styleUrls: ['./partial-purchase-order-receive.component.scss']
})
export class PartialPurchaseOrderReceiveComponent implements OnInit {

    @Input() public orderNumber: string;

    public dataSource: DataSource;
    public form: FormGroup;

    constructor(
        private readonly _purchaseOrderLineApi: PurchaseOrderLineApi,
        private readonly _formBuilder: FormBuilder,
        private readonly _appController: AppController) {
    }

    ngOnInit() {
        this.form = this._formBuilder.group({
            reference: [null, [Validators.required]],
            referenceDate: [null]
        });

        this.dataSource = new DataSource({
            store: AppData.createStore(["orderNumber", "orderLine"], {
                loadUrl: ApiService.getEndpoint(environment.api_erp_purchase_order_receive),
                loadParams: {
                    orderNumber: this.orderNumber
                }
            })
        });
    }

    formatNumber(e) {
        return new Intl.NumberFormat('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 4 }).format(e.value);
    }

    getBins(options: any) {
        if (options.data) {
            return AppData.bins(options.data.warehouse);
        }

        return [];
    }

    onEditorPreparing(e) {
        if (e.parentType === "dataRow") {
            if (e.dataField === "bin") {
                e.cancel = e.row.data.isNonStocked;
                e.editorOptions.disabled = !e.row.data.receive;
            }

            if (e.dataField === "receive") {
                if (e.row.data.orderQuantity === e.row.data.receivedQuantity) {
                    e.editorOptions.disabled = true;
                }

                e.editorOptions.min = 1;
                e.editorOptions.max = e.row.data.orderQuantity - e.row.data.receivedQuantity;
            }

            if (e.dataField === "serials") {
                if (e.row.data.isNonStocked || !e.row.data.serialized) {
                    e.cancel = true;
                }
                else {
                    e.editorName = "dxTagBox";
                    e.editorOptions.value = e.value;
                    e.editorOptions.onValueChanged = args => {
                        e.setValue(args.value);
                    };
                }
            }
        }
    }

    onEditorPrepared(e) {
        if (e.parentType === "dataRow") {
            if (e.dataField === "receive") {
                if (!e.value) {
                    setTimeout(() => {
                        e.component.cellValue(e.row.rowIndex, "receive", e.row.data.outstandingQuantity);
                    });
                }
            }
        }
    }

    onReceiveChanged = function (newData, value, currentRowData) {
        this.defaultSetCellValue(newData, value);
    }

    onSerialsChanged = function (newData, value, currentRowData) {
        newData.receive = value?.length;
        this.defaultSetCellValue(newData, value);
    }

    onCustomItemCreating(e) {
        e.customItem = e.text;
    }

    onSaving(e: any) {
        e.cancel = true;

        if (e.changes.length > 0 && this.form.valid) {
            e.promise = this.processBatchRequest(e.changes, e.component);
        }
        else {
            this._appController.notice("The reference field is required");
        }
    }

    processBatchRequest(changes: any[], component: dxDataGrid): Promise<any> {
        const data = changes.map(_ => ({
            key: _.key,
            reference: this.form.value.reference,
            referenceDate: this.form.value.referenceDate,
            bin: _.data.bin,
            price: _.data.price,
            receive: _.data.receive,
            serials: _.data.serials
        }));

        return new Promise((resolve, reject) => {
            this._purchaseOrderLineApi.receive(this.orderNumber, data)
                .subscribe((result) => {
                    component.refresh(true).then(() => {
                        component.cancelEditData();
                    });

                    if (result.goodsReceivedNumber) {
                        var endpoint = ApiService.getEndpoint(environment.api_erp_report + `?name=GoodsReceived&GRN=${result.goodsReceivedNumber}`);
                        window.open(endpoint, '_blank');
                    }

                    resolve(result);
                }, error => {
                    reject(error);
                });
        });
    }
}
