import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SecurityService } from 'src/app/service/security.service';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
  constructor(private readonly securityService: SecurityService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.securityService.getAuthenticatedUserToken()}`
      }
    });

    return next.handle(request);
  }
}
