import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import dxFileUploader from 'devextreme/ui/file_uploader';
import * as Papa from "papaparse";
import { AppController } from 'src/app/app.controller';

@Component({
    selector: 'app-modal-csv-data-import',
    templateUrl: './modal-csv-data-import.component.html',
    styleUrls: ['./modal-csv-data-import.component.scss']
})
export class ModalCsvDataImportComponent {

    public dataSource: any;
    public columnKeys: string[];
    public columnLabels: string[];
    public imported: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<ModalCsvDataImportComponent>,
        @Inject(MAT_DIALOG_DATA) data: any,
        public appController: AppController) {

        this.columnKeys = data.columnKeys;
        this.columnLabels = data.columnLabels;
    }

    public onFileUploaded(e: { component: dxFileUploader; value?: File[] }) {
        if (e.value && e.value[0]) {
            var file = e.value[0];
            this.importCSVDataFromFile(file);

            e.component.reset();
        }
    }

    importCSVDataFromFile(file: File) {
        this.appController.notice(`Importing CSV data from file ${file.name}`);
        this.imported = false;
        Papa.parse(file, {
            worker: true,
            dynamicTyping: true,
            header: true,
            skipEmptyLines: "greedy",
            complete: (results) => {
                this.appController.notice(`CSV data import complete, click "Proceed" to save data`);
                console.log("imported csv data ", results);
                this.dataSource = results;
                this.imported = true;
            }
        });
    }

    proceed() {
        if (this.imported) {
            this.dialogRef.close(this.dataSource.data);
        }
    }
}
