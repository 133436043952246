import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import DataSource from 'devextreme/data/data_source';
import { ApiService } from 'src/app/api/api.service';
import { SaleOrderApi } from 'src/app/api/sale-order.api';
import { AppConstant } from 'src/app/app.constant';
import { AppController } from 'src/app/app.controller';
import { AppData } from 'src/app/app.data';
import { ModalFormSelectComponent } from 'src/app/modal/modal-form-select/modal-form-select.component';
import { SecurityService } from 'src/app/service/security.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-sale-order-form',
    templateUrl: './sale-order-form.component.html',
    styleUrls: ['./sale-order-form.component.scss']
})
export class SaleOrderFormComponent implements OnInit {

    public readonly addCreditLineRequest = new EventEmitter();

    public readonly customers = new DataSource(AppData.customers());
    public readonly billingCustomers = new DataSource(AppData.customers());
    public readonly users = AppData.users(true);
    public readonly creditreasons = new DataSource(AppData.createStore("value", environment.api_erp_list_creditreasons));
    public invoices: DataSource;
    public projects: DataSource;
    public invoiceLines: DataSource;

    private readonly store = AppData.createStore("orderNumber", environment.api_erp_sales_orders);

    form: FormGroup;
    showCreditLines = false;

    constructor(
        private readonly _formBuilder: FormBuilder,
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _router: Router,
        private readonly _salesOrderApi: SaleOrderApi,
        private readonly appController: AppController,
        private readonly securityService: SecurityService) {
    }

    ngOnInit() {
        this.form = this._formBuilder.group({
            orderNumber: [null],
            orderType: ["O", [Validators.required]],
            orderStatus: [{ value: 'O', disabled: true }, [Validators.required]],

            account: [null, [Validators.required]],
            billingAccount: [null, [Validators.required]],
            accountName: [null, [Validators.required]],
            customerPurchaseOrder: [null],
            taxStatus: [null, [Validators.required]],
            invoiceTerms: [null],

            orderDate: [new Date()],
            shipDate: [new Date()],

            docFormat: [1],
            printed: [false],

            address1: [null],
            address2: [null],
            address3: [null],
            addressCode: [null],

            contactName: [null],
            contactEmail: [null, [Validators.email]],
            contactPhone: [null],

            ticketId: [null],

            creditType: [null],
            creditInvoice: [null],
            creditProjectId: [null],
            creditReason: [null],

            salespersonId: [this.securityService.getAuthenticatedUserId(), [Validators.required]],
            notes: [null],

            // not sent to server
            address: [null]
        });

        this._activatedRoute.params.subscribe(params => {
            if (params.orderNumber) {
                this.store.byKey(params.orderNumber).then(salesOrder => {
                    salesOrder.address = [salesOrder.address1, salesOrder.address2, salesOrder.address3, salesOrder.addressCode].join();
                    this.form.patchValue(salesOrder);
                    this.form.get("orderType").disable();
                    this.form.get("account").disable();
                    this.form.get("taxStatus").disable();

                    this.form.get("creditInvoice").disable();
                    this.form.get("creditProjectId").disable();

                    if (salesOrder.orderStatus !== "C") {
                        this.form.get("orderStatus").enable();
                    }
                });
            } else if (params.ticketId) {
                AppData.tickets().store.byKey(params.ticketId).then(ticket => {
                    delete ticket.notes;

                    this.form.patchValue(ticket);
                    this.form.get("account").disable();
                    this.form.get("ticketId").setValue(params.ticketId);
                });
            }
        });

        this.form.get("account").valueChanges
            .subscribe(account => {
                this.customers.store().byKey(account).then(customer => {
                    if (customer) {
                        this.form.patchValue({
                            accountName: customer.name,
                            taxStatus: customer.taxStatus,
                            invoiceTerms: customer.invoiceTerms,
                            address: [customer.billingAddress1, customer.billingAddress2, customer.billingAddress3, customer.billingAddressCode].join(),
                            address1: customer.billingAddress1,
                            address2: customer.billingAddress2,
                            address3: customer.billingAddress3,
                            addressCode: customer.billingAddressCode,
                        });

                        if (customer.locations && customer.locations.length > 0) {
                            this.form.patchValue({
                                contactName: customer.locations[0].contacts[0].name,
                                contactEmail: customer.locations[0].contacts[0].email,
                                contactPhone: customer.locations[0].contacts[0].phone1
                            });
                        }
                    }

                    this.updateInvoiceOrProjects(account);
                });
            });

        this.form.get("creditType").valueChanges
            .subscribe(() => {
                this.form.patchValue({
                    creditInvoice: null,
                    creditProjectId: null
                });
            });

        this.form.get("creditInvoice").valueChanges
            .subscribe((invoice: string) => {
                this.updateInvoiceLines(invoice, null);
            });

        this.form.get("creditProjectId").valueChanges
            .subscribe((projectId: number) => {
                this.updateInvoiceLines(null, projectId);
            });
    }

    updateInvoiceOrProjects(account: string) {
        this.invoices = new DataSource({
            store: AppData.createStore("invoice", environment.api_erp_invoices, {
                loadParams: {
                    account: account
                }
            }),
            filter: ["documentType", "I"]
        });

        this.projects = new DataSource({
            store: AppData.createStore("id", environment.api_erp_projects, {
                loadParams: {
                    account: account
                }
            })
        });
    }

    updateInvoiceLines(invoice?: string, projectId?: number) {
        if (invoice) {
            this.invoiceLines = new DataSource(AppData.createStore(["invoice", "orderNumber"], environment.api_erp_sales_order_invoice_lines, {
                loadParams: {
                    invoice: invoice
                }
            }));
        }

        if (projectId) {
            this.invoiceLines = new DataSource({
                store: AppData.createStore("id", environment.api_erp_project_orders, {
                    loadParams: {
                        projectId: projectId
                    }
                }),
                filter: [
                    ["quantityAllocated", ">", 0],
                    ["complete", true]
                ],
                postProcess(data) {
                    for (const item of data) {
                        item.projectOrderId = item.id;
                        item.orderQuantity = item.quantity;
                        item.shipQuantity = item.quantityAllocated;
                        item.priceCode = ".";
                        item.price = 0;
                        item.total = 0;
                    }

                    return data;
                }
            });
        }
    }

    addressChanged(value: string[]) {
        if (value) {
            this.form.patchValue({
                address1: value[0],
                address2: value[1] + "," + value[2],
                address3: value[4],
                addressCode: value[3]
            });

            this.form.markAsDirty();
        }
    }

    selectLocation() {
        this.appController.openModal((location: any) => {
            if (location) {
                this.form.patchValue({
                    address: [location.address1, location.address2, location.address3, location.addressCode].join(),
                    address1: location.address1,
                    address2: location.address2,
                    address3: location.address3,
                    addressCode: location.addressCode
                });
            }
        }, ModalFormSelectComponent, {
            width: AppConstant.SIZE.MODAL_SMALL,
            data: {
                label: "Location",
                labelKey: "name",
                dataSource: AppData.createStore("id", environment.api_erp_customers_locations, {
                    loadParams: {
                        account: this.form.getRawValue().account
                    }
                })
            }
        });
    }

    save = () => {
        if (this.form.valid) {
            let data = this.form.value;
            data.account = this.form.getRawValue().account;

            if (!data.orderNumber) {
                this.store.insert(data).then(salesOrder => {
                    this._router.navigate(["/sales-order/", salesOrder.orderNumber]);
                });
            } else {
                this.store.update(data.orderNumber, data).then(salesOrder => {
                    this.form.patchValue(salesOrder);

                    if (salesOrder.printed) {
                        new DataSource(AppData.createStore(["orderNumber", "invoice"], environment.api_erp_sales_order_invoices, {
                            loadParams: {
                                orderNumber: data.orderNumber
                            }
                        })).load().then(invoices => {
                            var endpoint = ApiService.getEndpoint(environment.api_erp_report + `?name=SalesOrder_Invoice&Invoice=${invoices[0].invoice}`);
                            window.open(endpoint, '_blank');
                        });

                        this._router.navigate(["/sales-order/", salesOrder.orderNumber]);
                    }
                }, error => {
                    this.appController.notice(error);
                });
            }
        }
    }

    deliveryNote = () => {
        this._salesOrderApi.deliver(this.form.value.orderNumber).then(result => {
            this.appController.notice("Delivery note created", "success", 3000);

            window.open(result.report, '_blank');
        }).catch(error => {
            this.appController.notice(error);
        });
    }

    proForma = () => {
        var endpoint = ApiService.getEndpoint(environment.api_erp_report + `?name=SalesOrder_${(this.form.value.docFormat || 1)}&SalesOrder=${this.form.value.orderNumber}`);
        window.open(endpoint, '_blank');
    }

    addCreditLine = (e) => {
        this.addCreditLineRequest.emit({ ...e.row.data, ...{ orderQuantity: e.row.data.shipQuantity * -1, shipQuantity: 0, serials: null } });
    }
}
