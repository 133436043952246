import { Component, OnInit, Input } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { ApiService } from 'src/app/api/api.service';
import { AppData } from 'src/app/app.data';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-partial-pbx-list',
    templateUrl: './partial-pbx-list.component.html'
})
export class PartialPbxListComponent implements OnInit {

    @Input() account?: string;
    @Input() leadId?: number;
    @Input() ticketId?: string;
    @Input() userId?: number;

    public dataSource: DataSource;

    ngOnInit() {
        this.dataSource = new DataSource({
            store: AppData.createStore(["callId", "event"], {
                loadUrl: ApiService.getEndpoint(environment.api_erp_pbx),
                loadParams: {
                    account: this.account,
                    leadId: this.leadId,
                    ticketId: this.ticketId,
                    userId: this.userId
                }
            })
        });
    }

    canDownload(e) {
        return e.row.data.event === "NewCdr";
    }

    download(e) {
        window.open(e.row.data.download, "_blank");
    }
}
