import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import DataSource from 'devextreme/data/data_source';
import { debounceTime } from 'rxjs/operators';
import { InventoryApi } from 'src/app/api/inventory.api';
import { PurchaseOrderLineApi } from 'src/app/api/purchase-order-line.api';
import { AppController } from 'src/app/app.controller';
import { AppData } from 'src/app/app.data';
import { SecurityService } from 'src/app/service/security.service';

@Component({
    selector: 'app-modal-form-purchase-order-line',
    templateUrl: './modal-form-purchase-order-line.component.html',
    styleUrls: ['./modal-form-purchase-order-line.component.scss']
})
export class ModalFormPurchaseOrderLineComponent implements OnInit {

    public readonly warehouses = new DataSource(AppData.warehouses());
    public readonly taxCodes = new DataSource(AppData.taxCodes());
    public inventory: DataSource;

    private readonly subject: any;
    private readonly orderNumber: string;
    private readonly account: string;

    form: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<ModalFormPurchaseOrderLineComponent>,
        @Inject(MAT_DIALOG_DATA) data: any,
        private readonly _inventoryApi: InventoryApi,
        private readonly _purchaseOrderLineApi: PurchaseOrderLineApi,
        private readonly _formBuilder: FormBuilder,
        private readonly _appController: AppController,
        private readonly _securityService: SecurityService) {
        this.subject = data.subject;
        this.orderNumber = data.orderNumber;
        this.account = data.account;
    }

    ngOnInit() {
        this.form = this._formBuilder.group({
            orderNumber: [this.orderNumber, [Validators.required]],
            orderLine: [null],

            warehouse: [null, [Validators.required]],
            code: [{ value: null, disabled: true }, [Validators.required]],
            description: [{ value: null, disabled: true }, [Validators.required]],
            unitOfMeasure: [{ value: null, disabled: true }, [Validators.required]],
            orderQuantity: [null, [Validators.required]],
            taxCode: [null, [Validators.required]],
            price: [null, [Validators.required]],
            total: [null]
        });

        if (this.subject) {
            this.form.patchValue(this.subject);

            this.form.get("warehouse").disable();
            this.form.get("code").disable();
            this.form.get("description").setValue(null);
            this.form.get("unitOfMeasure").disable();

            this.inventory = new DataSource(AppData.inventory(this.subject.warehouse));
        }

        this.form.get("warehouse").valueChanges
            .pipe(debounceTime(500))
            .subscribe((warehouse: string) => {
                this.inventory = new DataSource(AppData.inventory(warehouse));

                this.form.get("code").setValue(null);
                this.form.get("code").enable();
                this.form.get("description").setValue(null);
                this.form.get("unitOfMeasure").setValue(null);

                if (warehouse === "**") {
                    this.form.get("description").enable();
                    this.form.get("unitOfMeasure").enable();
                } else {
                    this.form.get("description").disable();
                    this.form.get("unitOfMeasure").disable();
                }
            });

        this.form.get("code").valueChanges
            .subscribe((code: string) => {
                if (this.form.value.warehouse === "**") {
                    return;
                }

                this.inventory.store().byKey(code).then(product => {
                    if (product) {
                        this.form.patchValue({
                            description: product.description,
                            unitOfMeasure: product.unitOfMeasure,
                            taxCode: product.taxCode
                        });
                    }
                });

                this._inventoryApi.purchasePrice(code, this.account).subscribe((price: number) => {
                    this.form.get("price").setValue(price || 0);
                });
            });

        if (!this.subject) {
            var defaultWarehouse = this._securityService.getAuthenticatedUser().warehouse;
            if (defaultWarehouse) {
                this.form.get("warehouse").setValue(defaultWarehouse);
            }
        }
    }

    save() {
        if (this.form.valid) {
            let data = this.form.value;
            if (data.orderNumber && data.orderLine) {
                this._purchaseOrderLineApi.update(JSON.stringify({ orderNumber: data.orderNumber, orderLine: data.orderLine }), data).subscribe((results) => {
                    console.debug("successfully updated product ", results);
                    this.dialogRef.close(results);
                }, (error) => {
                    console.error("failed to update product ", error);
                });
            } else {
                this._purchaseOrderLineApi.create(data).subscribe((results) => {
                    console.debug("successfully saved product ", results);
                    this.dialogRef.close(results);
                }, (error) => {
                    console.error("failed to save product ", error);
                    this._appController.notice("failed to update line item");
                });
            }
        }
    }
}
