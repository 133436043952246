import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import DataSource from "devextreme/data/data_source";
import { LeadApi } from "src/app/api/lead.api";
import { QuoteApi } from "src/app/api/quote.api";
import { TemplateProjectApi } from "src/app/api/template-project.api";
import { AppConstant } from "src/app/app.constant";
import { AppController } from "src/app/app.controller";
import { AppData } from "src/app/app.data";
import { ModalFormSelectComponent } from "src/app/modal/modal-form-select/modal-form-select.component";
import { ModalSelectCustomerComponent } from "src/app/modal/modal-select-customer/modal-select-customer.component";
import { environment } from "src/environments/environment";

@Injectable()
export class QuoteService {
  constructor(
    private readonly _templateApi: TemplateProjectApi,
    private readonly _leadApi: LeadApi,
    private readonly _quoteApi: QuoteApi,
    private readonly _router: Router,
    private readonly _appController: AppController) {
  }

  public async convertToProject(quoteId: number) {
    let account: string;

    var quote = await this._quoteApi.find(quoteId).toPromise();
    if (quote.leadId) {
      var lead = await this._leadApi.find(quote.leadId).toPromise();
      if (lead.account) {
        account = lead.account
      }
    }

    if (!account) {
      account = await this.getAccount();
    }

    this._appController.openModal((templateId?: number) => {

      const createProject = (quoteId, account, projectId) => {
        this._quoteApi.convertToProject(quoteId, account, projectId)
          .subscribe(project => {
            this._appController.notice("Project created");
            this._router.navigate(["/project/", project.id]);
          }, error => {
            this._appController.notice(error);
          });
      };

      if (templateId) {
        this._templateApi.customer(account, templateId).subscribe(project => {
          createProject(quoteId, account, project.id);
        });
      } else {
        createProject(quoteId, account, null);
      }

    }, ModalFormSelectComponent, {
      width: AppConstant.SIZE.MODAL_SMALL,
      data: {
        label: "Template",
        valueKey: "id",
        labelKey: "name",
        dataSource: new DataSource({
          store: AppData.createStore("id", environment.api_erp_project_template),
          select: ["id", "name"],
          sort: "name"
        })
      }
    });
  }

  async getAccount(): Promise<string> {
    return new Promise((resolve, reject) => {
      this._appController.openModal((account: string) => {
        if (account) {
          resolve(account);
        } else {
          reject();
        }
      }, ModalSelectCustomerComponent, {
        width: AppConstant.SIZE.MODAL_SMALL
      });
    });
  }
}
