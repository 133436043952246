import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PartialContractListComponent } from '../partial/partial-contract/partial-contract-list/partial-contract-list.component';
import { PartialInventoryMovementsComponent } from '../partial/partial-inventory/partial-inventory-movements/partial-inventory-movements.component';
import { PartialPbxListComponent } from '../partial/partial-pbx-list/partial-pbx-list.component';
import { QuotationListComponent } from '../partial/partial-quotation/partial-quotation-list/quotation-list.component';
import { PartialSlaListComponent } from '../partial/partial-service-level-agreement/partial-sla-list/partial-sla-list.component';
import { SecurityService } from '../service/security.service';
import { BillingListComponent } from './billing/billing-list/billing-list.component';
import { InvoiceListComponent } from './billing/invoice-list/invoice-list.component';
import { ContainerComponent } from './container.component';
import { CustomerFormComponent } from './customer/customer-form/customer-form.component';
import { CustomerListComponent } from './customer/customer-list/customer-list.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DealerListComponent } from './dealer/dealer-list/dealer-list.component';
import { DepartmentListComponent } from './department/department-list/department-list.component';
import { InventoryListComponent } from './inventory/inventory-list/inventory-list.component';
import { InventoryViewComponent } from './inventory/inventory-view/inventory-view.component';
import { RequestListComponent } from './inventory/request-list/request-list.component';
import { JobClassListComponent } from './job/job-class-list/job-class-list.component';
import { JobListComponent } from './job/job-list/job-list.component';
import { LeadFormComponent } from './lead/lead-form/lead-form.component';
import { LeadPipelineComponent } from './lead/lead-pipeline/lead-pipeline.component';
import { LeadPoolListComponent } from './lead/lead-pool/lead-pool-list.component';
import { LoginFormComponent } from './login/login-form/login-form.component';
import { ResetPasswordFormComponent } from './login/reset-password-form/reset-password-form.component';
import { NotificationListComponent } from './notification/notification-list/notification-list.component';
import { FinancePeriodListComponent } from './period/finance-period-list/finance-period-list.component';
import { SalesPeriodListComponent } from './period/sales-period-list/sales-period-list.component';
import { PriceUpdateComponent } from './pricing/price-update/price-update.component';
import { ProductClassListComponent } from './product-class/product-class-list/product-class-list.component';
import { ProjectFormComponent } from './project/project-form/project-form.component';
import { ProjectListComponent } from './project/project-list/project-list.component';
import { PurchaseOrderFormComponent } from './purchase-order/purchase-order-form/purchase-order-form.component';
import { PurchaseOrderListComponent } from './purchase-order/purchase-order-list/purchase-order-list.component';
import { QuoteViewComponent } from './quote/quote-view/quote-view.component';
import { ReadingsListComponent } from './readings/readings-list/readings-list.component';
import { SaleOrderFormComponent } from './sale/sale-order-form/sale-order-form.component';
import { SaleOrderListComponent } from './sale/sale-order-list/sale-order-list.component';
import { ServiceLevelAgreementListComponent } from './service-level-agreement/service-level-agreement-list/service-level-agreement-list.component';
import { SupplierDebitNoteComponent } from './supplier/supplier-debit-note/supplier-debit-note.component';
import { SupplierListComponent } from './supplier/supplier-list/supplier-list.component';
import { TaskListComponent } from './task/task-list/task-list.component';
import { TaxCodeListComponent } from './tax-code/tax-code-list/tax-code-list.component';
import { TicketListComponent } from './ticket/ticket-list/ticket-list.component';
import { TicketViewComponent } from './ticket/ticket-view/ticket-view.component';
import { UserListComponent } from './user/user-list/user-list.component';
import { UserProfileComponent } from './user/user-profile/user-profile.component';
import { WarehouseListComponent } from './warehouse/warehouse-list/warehouse-list.component';

const routes: Routes = [
    {
        path: "",
        component: ContainerComponent,
        canActivate: [SecurityService],
        children: [
            { path: "", redirectTo: "dashboard", pathMatch: "full" },
            { path: "dashboard", component: DashboardComponent },

            { path: "customer/lead/pipeline", component: LeadPipelineComponent },
            { path: "customer/lead/pool", component: LeadPoolListComponent },
            { path: "customer/lead", component: LeadFormComponent },
            { path: "customer/lead/:id", component: LeadFormComponent },

            { path: "quotes", pathMatch: "full", component: QuotationListComponent },
            { path: "quote/:leadId", pathMatch: "full", component: QuoteViewComponent },
            { path: "quote/:leadId/:quoteId", pathMatch: "full", component: QuoteViewComponent },

            { path: "customer/list", component: CustomerListComponent },
            { path: "customer", component: CustomerFormComponent },
            { path: "customer/:account", component: CustomerFormComponent },
            { path: "customer/:account/:tab", component: CustomerFormComponent },

            { path: "contracts/list", component: PartialContractListComponent },

            { path: "project/list", component: ProjectListComponent },
            { path: "project/:projectId", component: ProjectFormComponent },
            { path: "project/:projectId/:tab", component: ProjectFormComponent },

            { path: "notification/list", component: NotificationListComponent },

            { path: "settings/finance-period/list", component: FinancePeriodListComponent },
            { path: "settings/sales-period/list", component: SalesPeriodListComponent },
            { path: "settings/product-class/list", component: ProductClassListComponent },
            { path: "settings/job-class/list", component: JobClassListComponent },
            { path: "settings/price-update", component: PriceUpdateComponent },
            { path: "settings/tax-code/list", component: TaxCodeListComponent },
            { path: "settings/dealer/list", component: DealerListComponent },
            { path: "settings/department/list", component: DepartmentListComponent },
            { path: "settings/user/list", component: UserListComponent },
            { path: "settings/user", component: UserProfileComponent },
            { path: "settings/user/:id", component: UserProfileComponent },
            { path: "settings/pbx/list", component: PartialPbxListComponent },

            { path: "support/ticket/list", component: TicketListComponent },
            { path: "support/ticket/:ticketId", component: TicketViewComponent },
            { path: "support/sla/plan/list", component: ServiceLevelAgreementListComponent },
            { path: "support/sla/customer/list", component: PartialSlaListComponent },
            { path: "support/job/list", component: JobListComponent },
            { path: "support/task/list", component: TaskListComponent },

            { path: "finance/invoice", component: InvoiceListComponent },
            { path: "finance/billing", component: BillingListComponent },

            { path: "sales-order/list", component: SaleOrderListComponent },
            { path: "sales-order", component: SaleOrderFormComponent },
            { path: "sales-order/:orderNumber", component: SaleOrderFormComponent },
            { path: "sales-order/ticket/:ticketId", component: SaleOrderFormComponent },

            { path: "warehouse/list", component: WarehouseListComponent },

            { path: "inventory/movements", component: PartialInventoryMovementsComponent },
            { path: "inventory/list", component: InventoryListComponent },
            { path: "inventory", component: InventoryViewComponent },
            { path: "inventory/:code", component: InventoryViewComponent },

            { path: "warehouse/requests", component: RequestListComponent },
            { path: "warehouse/readings", component: ReadingsListComponent },

            { path: "supplier/list", component: SupplierListComponent },
            { path: "supplier/debit-note/list", component: SupplierDebitNoteComponent },

            { path: "purchase-order/list", component: PurchaseOrderListComponent },
            { path: "purchase-order", component: PurchaseOrderFormComponent },
            { path: "purchase-order/:orderNumber", component: PurchaseOrderFormComponent }
        ]
    },
    { path: "login", component: LoginFormComponent },
    { path: "login/reset-password", component: ResetPasswordFormComponent }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PageRoutingModule { }
