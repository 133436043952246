import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import DataSource from 'devextreme/data/data_source';
import { AppData } from 'src/app/app.data';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-modal-form-job-quick-add',
    templateUrl: './modal-form-job-quick-add.component.html',
    styleUrls: ['./modal-form-job-quick-add.component.scss']
})
export class ModalFormJobQuickAddComponent implements OnInit {
    public readonly users = AppData.users(true);
    public readonly jobCodes = new DataSource(AppData.createStore("value", environment.api_erp_list_job_classes));
    public readonly statuses = ["assigned", "accepted", "rejected", "progress", "hold", "complete"];

    private readonly ticketId: string;
    private readonly subject: any;

    form: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<ModalFormJobQuickAddComponent>,
        @Inject(MAT_DIALOG_DATA) data: any,
        private readonly _formbuilder: FormBuilder) {

        this.ticketId = data.ticketId;
        this.subject = data.subject;
    }

    ngOnInit() {
        this.form = this._formbuilder.group({
            id: [null],
            description: [null, [Validators.required]],
            ticketId: [this.ticketId, [Validators.required]],
            userId: [null, [Validators.required]],
            jobCode: [null, [Validators.required]],
            status: [{ value: "assigned", disabled: true }, Validators.required],
            statusComment: [{ value: null, disabled: true }],
            scheduleTime: [null, [Validators.required]],
            notes: [null, [Validators.required]],
            billable: [false],
            serviceAgreement: [false]
        });

        if (this.subject) {
            this.form.patchValue(this.subject);

            this.form.get("status").enable();

            if (this.subject.status === "accepted") {
                this.form.get("statusComment").enable();
            }
        }
    }

    save() {
        if (this.form.valid) {
            let data = this.form.value;
            if (!data.id) {
                AppData.jobs().store.insert(data).then(() => {
                    this.dialogRef.close();
                });
            } else {
                AppData.jobs().store.update(data.id, data).then(() => {
                    this.dialogRef.close();
                });
            }
        }
    }
}
