import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import DataSource from 'devextreme/data/data_source';
import { AppController } from 'src/app/app.controller';
import { AppData } from 'src/app/app.data';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-modal-form-lead-convert-to-customer',
  templateUrl: './modal-form-lead-convert-to-customer.component.html',
  styleUrls: ['./modal-form-lead-convert-to-customer.component.scss']
})
export class ModalFormLeadConvertToCustomerComponent implements OnInit {
  public readonly customerClasses = new DataSource(AppData.createStore("value", environment.api_erp_list_customer_classes));

  private readonly prospectId: number;

  form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ModalFormLeadConvertToCustomerComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private readonly _formBuilder: FormBuilder,
    public readonly appController: AppController) {

    this.prospectId = data.prospectId;
  }

  ngOnInit() {
    this.form = this._formBuilder.group({
      prospectId: [this.prospectId, [Validators.required]],
      customerClass: [null, [Validators.required]],
      account: [null, [Validators.required]]
    });
  }

  save() {
    if (this.form.valid) {
      this.dialogRef.close(this.form.value);
    }
  }
}
