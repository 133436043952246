import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import DataSource from 'devextreme/data/data_source';
import { ProjectFileApi } from 'src/app/api/project-file.api';
import { AppController } from 'src/app/app.controller';
import { AppData } from 'src/app/app.data';
import { SecurityService } from 'src/app/service/security.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-modal-form-project-step-document-quick-add',
    templateUrl: './modal-form-project-step-document-quick-add.component.html',
    styleUrls: ['./modal-form-project-step-document-quick-add.component.scss']
})
export class ModalFormProjectStepDocumentQuickAddComponent implements OnInit {

    public readonly documentNames = new DataSource(AppData.createStore("value", environment.api_erp_list_documentnames));
    public readonly users = AppData.users(true);

    private readonly subject: any;
    public readonly account: string;
    private readonly stepId: number;

    form: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<ModalFormProjectStepDocumentQuickAddComponent>,
        @Inject(MAT_DIALOG_DATA) data: any,
        private readonly _fileApi: ProjectFileApi,
        private readonly _formBuilder: FormBuilder,
        private readonly _appController: AppController,
        private readonly _securityService: SecurityService) {
        this.subject = data.subject;
        this.account = data.account;
        this.stepId = data.stepId;
    }

    ngOnInit() {
        this.form = this._formBuilder.group({
            id: [null],
            userId: [this._securityService.getAuthenticatedUserId(), [Validators.required]],
            stepId: [this.stepId, [Validators.required]],
            notApplicable: [false],
            name: [null, [Validators.required]],
            description: [null],
            fileName: [null]
        });

        if (this.subject) {
            this.form.patchValue(this.subject);
        }
    }

    onCustomItemCreating(e) {
        e.customItem = e.text;
    }

    public save() {
        if (this.form.valid) {
            let data = this.form.value;
            if (!data.id) {
                this._fileApi.create(data).subscribe((results) => {
                    this._appController.notice(`File uploaded`);
                    this.dialogRef.close(results);
                });
            }
            else {
                this._fileApi.update(data.id, data).subscribe((results) => {
                    this._appController.notice(`File updated`);
                    this.dialogRef.close(results);
                });
            }
        }
    }

    onFileChanged(e) {
        if (e.currentSelectedItemKeys && e.currentSelectedItemKeys.length > 0) {
            this.form.get("fileName").setValue(e.currentSelectedItemKeys[0]);
        } else {
            this.form.get("fileName").setValue(null);
        }
    }
}
