import { HttpParams } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/api/api.service';
import { AppController } from 'src/app/app.controller';
import { AppData } from 'src/app/app.data';
import { BaseForm } from 'src/app/page/base-form.component';
import { SecurityService } from 'src/app/service/security.service';

@Component({
    selector: 'app-modal-form-task-meeting-quick-add',
    templateUrl: './modal-form-task-meeting-quick-add.component.html',
    styleUrls: ['./modal-form-task-meeting-quick-add.component.scss'],
})
export class ModalFormTaskMeetingQuickAddComponent extends BaseForm implements OnInit {

    public readonly users = AppData.users();

    private title: string;
    private subject: any;
    private readonly services: { taskApi: ApiService; locationApi: ApiService; };
    private readonly queryParams: any;

    form: FormGroup;

    locations: any[];
    meetingTypes = [
        "New Meeting",
        "Quote Presentation",
        "Deal Signing",
        "Site Survey",
        "Other"
    ];

    constructor(
        public dialogRef: MatDialogRef<ModalFormTaskMeetingQuickAddComponent>,
        @Inject(MAT_DIALOG_DATA) data: any,
        private readonly _formBuilder: FormBuilder,
        private readonly appController: AppController,
        private readonly securityService: SecurityService) {
        super();

        this.title = data.title;
        this.subject = data.subject;
        this.services = data.services;
        this.queryParams = data.queryParams;
    }

    ngOnInit() {
        var start = this.roundMinutes(new Date());

        let end = new Date(start);
        end.setHours(end.getHours() + 1);

        this.form = this._formBuilder.group({
            id: [null],
            title: [`Meeting: ${this.title}`, [Validators.required]],
            type: ["meeting", [Validators.required]],

            userId: [this.securityService.getAuthenticatedUserId(), [Validators.required]],
            locationId: [null, [Validators.required]],
            meetingType: [null, [Validators.required]],

            startTime: [start, [Validators.required]],
            endTime: [end, [Validators.required]],

            description: [null, [Validators.required]],

            email: [null],
            emailMessage: [null],
            sendEmail: [true]
        });

        this.getLocations();

        if (this.subject) {
            this.form.patchValue(this.subject);
        }

        this.form.get("startTime").valueChanges
            .subscribe((date: Date) => {
                let d = new Date(date);
                d.setHours(d.getHours() + 1);

                this.form.get("endTime").setValue(d);
            });
    }

    roundMinutes(date: Date) {
        date.setHours(date.getHours() + Math.round(date.getMinutes() / 60));
        date.setMinutes(0, 0, 0);

        return date;
    }

    getLocations() {
        let query = new HttpParams();

        if (this.queryParams) {
            Object.keys(this.queryParams).forEach((key) => {
                query = query.set(key, this.queryParams[key]);
            })
        }

        this.services.locationApi.findAll(query).subscribe((results) => {
            if (results.data.length < 1) {
                this.appController.notice("A location is required for a meeting. Please create a location.");
                this.dialogRef.close();
            }

            this.locations = results.data;

            if (!this.form.value.id) {
                var mainLocation = results.data.find(_ => _.name.toLowerCase().startsWith("main") || _.description?.toLowerCase().startsWith("main"));
                if (mainLocation) {
                    this.form.get("locationId").setValue(mainLocation.id);
                }
            }
        });
    }

    save() {
        if (this.form.valid) {
            let data = this.form.value;
            if (!data.id) {
                this.services.taskApi
                    .create({ ...data, ...this.queryParams })
                    .subscribe((results: any) => {
                        results = { ...data, ...results };
                        this.appController.notice(`Meeting saved successfully`);
                        this.form.patchValue(results);
                        this.dialogRef.close(results);
                    });
            } else {
                this.services.taskApi
                    .update(data.id, { ...data, ...this.queryParams })
                    .subscribe((results: any) => {
                        results = { ...data, ...results };
                        this.appController.notice(`Meeting updated successfully`);
                        this.form.patchValue(results);
                        this.dialogRef.close(results);
                    });
            }
        }
    }
}
