import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';
import { HttpService } from "./HttpService";

@Injectable()
export class UserPermissionApi extends HttpService {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  getActions(userId: string) {
    var apiEndpoint = this.getEndpoint(`${environment.api_erp_user}/${userId}/permissions/actions`);
    return this.get(apiEndpoint);
  }
}
