import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs/operators';
import { ListJobClassApi } from 'src/app/api/list-job-class.api';
import { ServiceLevelAgreementEscalationApi } from 'src/app/api/service-level-agreement-escalation.api';
import { ServiceLevelAgreementLimitApi } from 'src/app/api/service-level-agreement-limit.api';
import { ServiceLevelAgreementPlanApi } from 'src/app/api/service-level-agreement-plan.api';
import { ServiceLevelAgreementTargetApi } from 'src/app/api/service-level-agreement-target.api';
import { ServiceLevelAgreementApi } from 'src/app/api/service-level-agreement.api';
import { UserApi } from 'src/app/api/user.api';
import { AppController } from 'src/app/app.controller';
import { Confirmation } from 'src/app/modal/modal-confirmation/confirmation';
import { BaseDataListing } from 'src/app/page/base-data-list.component';
import { SecurityService } from 'src/app/service/security.service';

@Component({
  selector: 'app-service-level-agreement-list',
  templateUrl: './service-level-agreement-list.component.html',
  styleUrls: ['./service-level-agreement-list.component.scss']
})
export class ServiceLevelAgreementListComponent extends BaseDataListing implements OnInit {

  form: FormGroup;
  currentSLA: any;
  hideTables: boolean = true;

  constructor(
    private readonly _serviceLevelAgreementApi: ServiceLevelAgreementApi,
    private readonly _serviceLevelAgreementPlanApi: ServiceLevelAgreementPlanApi,
    private readonly _serviceLevelAgreementTargetApi: ServiceLevelAgreementTargetApi,
    private readonly _serviceLevelAgreementLimitApi: ServiceLevelAgreementLimitApi,
    private readonly _serviceLevelAgreementEscalationApi: ServiceLevelAgreementEscalationApi,
    private readonly _userApi: UserApi,
    private readonly _listJobClassApi: ListJobClassApi,
    public readonly securityService: SecurityService,


    private readonly _formBuilder: FormBuilder,
    public readonly appController: AppController) {
    super();

    this.apis = {
      serviceLevelAgreementApi: _serviceLevelAgreementApi,
      serviceLevelAgreementPlanApi: _serviceLevelAgreementPlanApi,
      serviceLevelAgreementTargetApi: _serviceLevelAgreementTargetApi,
      serviceLevelAgreementLimitApi: _serviceLevelAgreementLimitApi,
      serviceLevelAgreementEscalationApi: _serviceLevelAgreementEscalationApi,
      userApi: _userApi,
      listJobClassApi: _listJobClassApi
    };

    this.querySort = [{ "selector": "created", "desc": false }];
    this.actionMenu = {
      edit: (element: any) => {
        console.debug("edit ", element);
        this.currentSLA = element;
        this.form.patchValue(this.currentSLA);
        //Need for child table rendering, since we rendering on the fly
      }
    };

  }

  initActionMenu() {
    this.actionMenu = [];
  }

  ngOnInit() {
    this.form = this._formBuilder.group({
      id: [null],
      name: [null, [Validators.required]],
      description: [null],
      minUsers: [null, [Validators.required]],
      maxUsers: [null, [Validators.required]],
      price: [null, [Validators.required]],
      targets: [null],
      escalations: [null],
      limits: [null],
      SLAId: [null]
    });

    this.form.get("id").valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((id) => {
        this.hideTables = true;
        setTimeout(() => {
          if (id) {
            this.hideTables = false;
          }
        }, 100);
      });

    this.queryData(this.queryLimit, this.queryCurrentPage, true, this.querySort, this.queryFilter);
  }

  get columnLabels(): string[] {
    return ['Name', 'Price', ''];
  }

  get columnKeys(): string[] {
    return ['name', 'price', 'action'];
  }

  queryData(take: number, skip: number, count: boolean, sort: any[], filter?: any[]) {
    this.query = new HttpParams()
      .set("take", `${take}`)
      .set("skip", `${skip}`)
      .set("requireTotalCount", `${count}`);

    this.query = this.queryAdd(this.query, this.queryParams);
    this.query = this.queryAddSort(this.query, sort);
    this.query = this.queryAddFilter(this.query, filter);

    console.debug("query ", this.query);

    this.apis.serviceLevelAgreementPlanApi.findAll(this.query,

    ).subscribe((results: any) => {
      console.debug("Service Level Aggreements", results);
      this.dataSource = results;
    }, (error) => {
      console.debug("failed to obtain service level agreements", error);
    })
  }

  onSearch(value: string) {
    console.debug("search ", value);
    this.queryFilter = null;
    console.debug("searching for ", value);
    if (value !== '') {
      this.queryFilter = [["account", "contains", `${value}`], "or", ["name", "contains", `${value}`], "or", ["accountName", "contains", `${value}`], "or", ["description", "contains", `${value}`]]
      this.queryData(this.queryLimit, this.queryCurrentPage, true, this.querySort, this.queryFilter);
    } else {
      this.queryData(this.queryLimit, this.queryCurrentPage, true, this.querySort);
    }
  }

  submit() {
    if (this.form.valid) {
      let data = this.form.value
      if (!data.id) {
        this.apis.serviceLevelAgreementPlanApi.create(data).subscribe((results: any) => {
          console.debug("saved SLA Plan", results);
          this.appController.notice(`Successfully created SLA Plan, ${results.name}`);
          this.form.patchValue(results);
          this.refresh();
        }, (error) => {
          console.error("failed to save SLA", error);
          this.appController.notice("Failed to save SLA Plan");
        });
      } else {
        this.apis.serviceLevelAgreementPlanApi.update(data.id, data).subscribe((results: any) => {
          console.debug("updated SLA", results);
          this.appController.notice(`Successfully updated SLA Plan, ${results.name}`);
          this.form.patchValue(results);
          this.refresh();
        }, (error) => {
          console.error("failed to save SLA Plan", error);
          this.appController.notice("Failed to save SLA Plan");
        });
      }
    }
  }

  newSLA() {
    this.resetForm();
  }

  resetForm() {
    this.currentSLA = null;
    this.form.reset();
  }

  deleteSLA(subject: any) {
    this.appController.confirm((proceed) => {
      if (proceed) {
        console.debug("delete", subject);
        this.apis.serviceLevelAgreementPlanApi.remove(subject.id).subscribe((results) => {
          console.debug("deleted ", results);
          this.appController.notice(`SLA Plan ${subject.name} was successfully deleted`);
          this.refresh();
          this.resetForm();
        }, (error) => {
          console.error("failed to delete ", error);
          this.appController.notice(`Failed to delete SLA Plan ${subject.name}`);
        })
      }
    }, new Confirmation("delete_outline",
      `Are you sure you want delete SLA Plan ${subject.name}?`,
      "Cancel", "Delete")
    );
  }

  getFormValue(form: FormGroup, key: string): any {
    return form.get(key).value;
  }
}
