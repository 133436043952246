import { Component } from '@angular/core';
import { FormGroupService } from 'src/app/service/form.service';
import { SecurityService } from 'src/app/service/security.service';

@Component({
  selector: 'app-partial-quotation-margin',
  templateUrl: './partial-quotation-margin.component.html',
  styleUrls: ['./partial-quotation-margin.component.scss']
})
export class PartialQuotationMarginComponent {
  constructor(
    public formGroupService: FormGroupService,
    public readonly securityService: SecurityService) {
  }
}
