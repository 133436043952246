import { HttpParams } from '@angular/common/http';
import { Component, ComponentFactoryResolver, ComponentRef, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import DataSource from 'devextreme/data/data_source';
import * as _ from "lodash";
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { LeadAggApi } from 'src/app/api/lead-agg.api';
import { LeadTaskApi } from 'src/app/api/lead-task.api';
import { LeadApi } from "src/app/api/lead.api";
import { LocationLeadApi } from 'src/app/api/location-lead.api';
import { ProspectApi } from 'src/app/api/prospect.api';
import { AppConstant } from 'src/app/app.constant';
import { AppController } from 'src/app/app.controller';
import { AppData } from 'src/app/app.data';
import { Confirmation } from 'src/app/modal/modal-confirmation/confirmation';
import { ModalFormLeadConvertToCustomerComponent } from 'src/app/modal/modal-form-lead-convert-to-customer/modal-form-lead-convert-to-customer.component';
import { ModalFormTaskMeetingQuickAddComponent } from 'src/app/modal/modal-form-task/modal-form-task-meeting-quick-add/modal-form-task-meeting-quick-add.component';
import { BaseDataListing } from 'src/app/page/base-data-list.component';
import { PartialTaskDashboardComponent } from 'src/app/partial/partial-task-dashboard/partial-task-dashboard.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: "app-lead-pipeline",
  templateUrl: "./lead-pipeline.component.html",
  styleUrls: ["./lead-pipeline.component.scss"]
})
export class LeadPipelineComponent extends BaseDataListing implements OnInit {

  public readonly dataSourceNew: DataSource;
  public readonly dataSourceMeeting: DataSource;
  public readonly dataSourceQuoted: DataSource;
  public readonly dataSourceNeg: DataSource;

  public expandedElement: any;
  public filterStage: FormControl;
  public filterDateType: FormGroup;
  public leadStages = ["All", "Won", "Lost"];
  public pipeline: any[];
  public filterDateTypes = ["All", "Closing Date", "Created Date", "Meeting Date", "Quoted Date"];
  public searching = false;

  constructor(
    private readonly _cmpFctryRslvr: ComponentFactoryResolver,
    private readonly _leadApi: LeadApi,
    private readonly _leadTaskApi: LeadTaskApi,
    private readonly _locationLeadApi: LocationLeadApi,
    private readonly _prospectApi: ProspectApi,
    private readonly _leadAggApi: LeadAggApi,
    private readonly _formBuilder: FormBuilder,
    public readonly appController: AppController) {
    super();

    this.dataSourceNew = new DataSource({
      store: AppData.createStore("id", environment.api_erp_leads_agg),
      sort: [{ "selector": "created", "desc": true }],
      filter: [
        ["stage", "analysis"],
        ["hasMeeting", false]
      ]
    });

    this.dataSourceMeeting = new DataSource({
      store: AppData.createStore("id", environment.api_erp_leads_agg),
      sort: [{ "selector": "created", "desc": true }],
      filter: [
        ["stage", "analysis"],
        ["hasMeeting", true]
      ]
    });

    this.dataSourceQuoted = new DataSource({
      store: AppData.createStore("id", environment.api_erp_leads_agg),
      sort: [{ "selector": "created", "desc": true }],
      filter: [
        ["stage", "quoting"]
      ]
    });

    this.dataSourceNeg = new DataSource({
      store: AppData.createStore("id", environment.api_erp_leads_agg),
      sort: [{ "selector": "created", "desc": true }],
      filter: [
        ["stage", "negotiation"]
      ]
    });
  }

  ngOnInit() {
    this.querySort = [{ "selector": "created", "desc": true }];
    this.queryLimit = 15;

    this._leadApi.pipeline().subscribe(result => {
      this.initPipline(result);
    });

    this.filterStage = new FormControl("all");
    this.filterStage.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(500))
      .subscribe((value: string) => {
        this.queryParams = {};

        if (value === "all") {
          this.queryData(this.queryLimit, this.queryCurrentPage, true, this.querySort, this.queryFilter);
          this.searching = false;
        } else {
          this.queryData(this.queryLimit, this.queryCurrentPage, true, this.querySort, ["stage", value]);
          this.searching = true;
        }
      });

    this.filterDateType = this._formBuilder.group({
      type: ["all"],
      start: [null],
      end: [null]
    });
    this.filterDateType.valueChanges
      .subscribe(() => {
        var start = this.filterDateType.get("start").value;
        var end = this.filterDateType.get("end").value;

        var type = this.filterDateType.get("type").value;
        if (type === "all") {
          this.queryParams = {};
          this.queryData(this.queryLimit, this.queryCurrentPage, true, this.querySort, this.queryFilter);
          this.searching = false;
        } else if (start && end) {
          this.queryParams = { type: type, start: new Date(start).toISOString(), end: new Date(end).toISOString() };
          this.queryData(this.queryLimit, this.queryCurrentPage, true, this.querySort, this.queryFilter);
          this.searching = true;
        }
      });
  }

  queryData(take: number, skip: number, count: boolean, sort: any[], filter?: any[]) {
    this.query = new HttpParams()
      .set("take", `${take}`)
      .set("skip", `${skip}`)
      .set("requireTotalCount", `${count}`);

    this.query = this.queryAdd(this.query, this.queryParams);
    this.query = this.queryAddSort(this.query, sort);
    this.query = this.queryAddFilter(this.query, filter);

    this._leadAggApi.findAll(this.query).subscribe(results => {
      this.dataSource = { data: results.data, totalCount: results.totalCount };
    });
  }

  //drop(event: CdkDragDrop<any>) {
  //  if (event.previousContainer === event.container) {
  //    moveItemInArray(
  //      event.container.data,
  //      event.previousIndex,
  //      event.currentIndex
  //    );

  //  } else {
  //    transferArrayItem(
  //      event.previousContainer.data,
  //      event.container.data,
  //      event.previousIndex,
  //      event.currentIndex
  //    );

  //    if (event.container.id === 'meetingScheduled') {
  //      this.createMeeting(_.last(event.container.data));
  //    }

  //    switch (event.container.id) {
  //      case "meetingScheduled":
  //        this.createMeeting(_.last(event.container.data));
  //        break;
  //      case "negotiation":
  //        let data: any = _.last(event.container.data);
  //        this.update(data.id, { "stage": "negotiation" });
  //        break;
  //    }
  //  }
  //}

  update(id: any, data: any) {
    this._leadApi.update(id, data).subscribe(() => {
      this.appController.notice(`Lead updated`);
    });
  }

  createMeeting(lead: any) {
    this.appController.openModal(() => {
      this.refresh();
    }, ModalFormTaskMeetingQuickAddComponent, {
      width: AppConstant.SIZE.MODAL_MEDIUM,
      data: {
        title: lead.name,
        subject: { salesUserId: lead.salesUserId, userId: lead.userId },
        queryParams: { leadId: lead.id, parentKey: lead.id }
      }
    })
  }

  onSearch(value: string) {
    this.queryFilter = null;

    if (value !== '') {
      this.queryFilter = [["name", "contains", `${value}`], "or", ["tradingName", "contains", `${value}`]]
      this.queryData(this.queryLimit, this.queryCurrentPage, true, this.querySort, this.queryFilter);
      this.searching = true;
    } else {
      this.searching = false;
    }
  }

  initPipline(stats: any) {
    this.pipeline = [
      {
        dataSource: this.dataSourceNew,
        title: "Leads",
        value: stats.analysisMargin,
        count: stats.analysis,
        items: []
      },
      {
        dataSource: this.dataSourceMeeting,
        title: "Meeting Scheduled",
        value: stats.meetingMargin,
        count: stats.meeting,
        items: []
      },
      {
        dataSource: this.dataSourceQuoted,
        title: "Quoting",
        value: stats.quotedMargin,
        count: stats.quoted,
        items: []
      },
      {
        dataSource: this.dataSourceNeg,
        title: "Negotiations",
        value: stats.negotiationMargin,
        count: stats.negotiation,
        items: []
      },
    ];
  }

  duplicate(lead: any) {
    this.appController.confirm((proceed) => {
      if (proceed) {
        let elementCopy = _.omit(lead, ["id", "created", "user", "salesperson1", "salesperson2", "salesperson3"]);

        this._leadApi.create(elementCopy).subscribe(() => {
          this.appController.notice(`Duplicated lead ${lead.name}`);
          this.refresh();
        });
      }
    }, new Confirmation("file_copy",
      `Are you sure you want duplicate lead ${lead.name}?`,
      "Cancel", "Duplicate")
    );
  }

  @ViewChild('vc', { read: ViewContainerRef }) vc: ViewContainerRef;

  add(type: string, lead: any) {
    var dash = this.createComponent(this.vc, PartialTaskDashboardComponent);
    dash.instance.title = lead.name;
    dash.instance.taskApi = this._leadTaskApi;
    dash.instance.locationApi = this._locationLeadApi;
    dash.instance.queryParams = { leadId: lead.id, parentKey: lead.id };

    dash.instance.add(type);
  }

  private createComponent(vCref: ViewContainerRef, type: any): ComponentRef<PartialTaskDashboardComponent> {
    vCref.clear();

    let factory = this._cmpFctryRslvr.resolveComponentFactory(type);
    return this.vc.createComponent(factory) as any;
  }

  convert(lead: any) {
    this.appController.openModal((modalResults: any) => {
      this._prospectApi
        .convert(lead.id, modalResults)
        .subscribe(() => {
          this.appController.notice(`Converted ${lead.name} to customer`);
        });
    }, ModalFormLeadConvertToCustomerComponent,
      {
        width: AppConstant.SIZE.MODAL_MEDIUM,
        data: {
          prospectId: lead.id
        }
      });
  }

  delete(lead: any) {
    this.appController.confirm(proceed => {
      if (proceed) {
        this._leadApi
          .remove(lead.id)
          .subscribe(() => {
            this.appController.notice(`Removed lead ${lead.name}`);
            this.refresh();
          });
      }
    }, Confirmation.delete(`Are you sure you want delete lead ${lead.name}?`));
  }
}
