import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import RemoteFileSystemProvider from 'devextreme/file_management/remote_provider';
import { ApiService } from 'src/app/api/api.service';
import { SecurityService } from 'src/app/service/security.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-partial-file-list',
    templateUrl: './partial-file-list.component.html',
    styleUrls: ['./partial-file-list.component.scss']
})
export class PartialFileListComponent implements OnInit {

    @Output() selectionChanged = new EventEmitter();

    @Input() parentPath: string;
    @Input() parentKey: string;
    @Input() selectedPath: string;

    provider: RemoteFileSystemProvider;
    pathKeys = [];
    selectedKeys = [];

    ngOnInit() {
        const url = new URL(ApiService.getEndpoint(environment.api_erp_filesv2));
        url.search = new URLSearchParams({ access_token: localStorage.getItem(SecurityService.AccessTokenKey), area: this.parentPath, path: this.parentKey }).toString();

        this.provider = new RemoteFileSystemProvider({
            endpointUrl: url.toString(),
            requestHeaders: {
                Authorization: "Bearer " + localStorage.getItem(SecurityService.AccessTokenKey)
            }
        });

        if (this.selectedPath) {
            var dirs = this.selectedPath.substring(0, this.selectedPath.lastIndexOf("/")).split("/").filter(_ => _ !== "");
            if (dirs.length > 0) {
                this.pathKeys.push("/" + dirs[0]);

                dirs.reduce((prev, cur, index) => {
                    var p = "/" + prev + "/" + cur;

                    this.pathKeys.push(p);
                    return p;
                });
            }

            this.selectedKeys = [this.selectedPath];
        }
    }

    onSelectionChanged(e) {
        this.selectionChanged.emit(e);
    }
}
