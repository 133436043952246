import { NgModule } from '@angular/core';
import { DxDashboardControlModule } from 'devexpress-dashboard-angular';
import { DxAutocompleteModule, DxChartModule, DxDataGridModule, DxDateBoxModule, DxFileManagerModule, DxFileUploaderModule, DxHtmlEditorModule, DxListModule, DxLookupModule, DxNumberBoxModule, DxPopupModule, DxSelectBoxModule, DxTagBoxModule, DxTextAreaModule, DxToolbarModule } from 'devextreme-angular';

@NgModule({
    imports: [
        DxFileManagerModule,
        DxToolbarModule,
        DxAutocompleteModule,
        DxFileUploaderModule,
        DxDateBoxModule,
        DxChartModule,
        DxDataGridModule,
        DxHtmlEditorModule,
        DxListModule,
        DxLookupModule,
        DxSelectBoxModule,
        DxTagBoxModule,
        DxNumberBoxModule,
        DxTextAreaModule,
        DxPopupModule,
        DxDashboardControlModule
    ],
    exports: [
        DxFileManagerModule,
        DxToolbarModule,
        DxAutocompleteModule,
        DxFileUploaderModule,
        DxDateBoxModule,
        DxChartModule,
        DxDataGridModule,
        DxHtmlEditorModule,
        DxListModule,
        DxLookupModule,
        DxSelectBoxModule,
        DxTagBoxModule,
        DxNumberBoxModule,
        DxTextAreaModule,
        DxPopupModule,
        DxDashboardControlModule
    ]
})
export class AppUiDevExtremeModule { }
