import { Component } from '@angular/core';
import { Router } from '@angular/router';
import DataSource, { DataSourceOptions } from 'devextreme/data/data_source';
import { ApiService } from 'src/app/api/api.service';
import { StockRequestApi } from 'src/app/api/stock-request.api';
import { AppConstant } from 'src/app/app.constant';
import { AppController } from 'src/app/app.controller';
import { AppData } from 'src/app/app.data';
import { ModalSelectSupplierComponent } from 'src/app/modal/modal-select-supplier/modal-select-supplier.component';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-request-list',
    templateUrl: './request-list.component.html',
    styleUrls: ['./request-list.component.scss']
})
export class RequestListComponent {
    public readonly projectDataSource = new DataSource(AppData.createStore("id", environment.api_erp_project_requests));
    public readonly salesOrderDataSource = new DataSource(AppData.createStore(["orderNumber", "orderLine"], environment.api_erp_salesorder_requests));

    showSelectedAction = false;
    currentTabIndex = 0;
    selectedRowKeys: number[] | [{ orderNumber: number; orderLine: number }];
    selectedRowsData: any[];

    constructor(
        private readonly _stockRequestApi: StockRequestApi,
        private readonly _router: Router,
        private readonly _appController: AppController) {
    }

    onTabIndexChanged(e) {
        this.showSelectedAction = false;
        this.currentTabIndex = e;
    }

    public onSelectionChanged(e) {
        this.showSelectedAction = e.selectedRowKeys.length > 0;
        this.selectedRowKeys = e.selectedRowKeys;
        this.selectedRowsData = e.selectedRowsData;
    }

    public onEditorPreparing(e, type: "Projects" | "SalesOrders") {
        if (e.parentType === "dataRow") {
            if (e.dataField === "quantityAllocated") {
                e.editorOptions.min = 0;
                e.editorOptions.max = e.row.data.quantity;
            }

            if (e.dataField === "serials") {
                if (e.row.data.isNonStocked || !e.row.data.serialized) {
                    e.cancel = true;
                }
                else {
                    e.editorName = "dxTagBox";
                    e.editorOptions.value = e.value && e.value.map(_ => _.serial);

                    if (type === "Projects") {
                        e.editorOptions.onValueChanged = args => {
                            e.setValue(args.value.map(serial => ({ taskId: e.row.key, serial: serial })));
                        };
                        e.editorOptions.dataSource = {
                            store: AppData.createStore("serial", {
                                loadUrl: ApiService.getEndpoint(environment.api_erp_project_orders_serials),
                                loadParams: {
                                    taskId: e.row.key,
                                    warehouse: e.row.data.warehouse,
                                    code: e.row.data.code
                                }
                            }),
                            map(dataItem) {
                                return { taskId: e.row.key, serial: dataItem.serial };
                            }
                        } as DataSourceOptions;
                    } else {
                        e.editorOptions.onValueChanged = args => {
                            e.setValue(args.value.map(serial => ({ orderNumber: e.row.key.orderNumber, orderLine: e.row.key.orderLine, serial: serial })));
                        };
                        e.editorOptions.dataSource = {
                            store: AppData.createStore("serial", {
                                loadUrl: ApiService.getEndpoint(environment.api_erp_sales_order_lines_serials),
                                loadParams: {
                                    orderNumber: e.row.key.orderNumber,
                                    orderLine: e.row.key.orderLine,
                                    warehouse: e.row.data.warehouse,
                                    code: e.row.data.code
                                }
                            }),
                            map(dataItem) {
                                return { orderNumber: e.row.key.orderNumber, orderLine: e.row.key.orderLine, serial: dataItem.serial };
                            }
                        } as DataSourceOptions;
                    }
                }
            }
        }
    }

    public onRowUpdating(e) {
        if (!e.newData.serials) {
            e.newData.serials = e.oldData.serials;
        }
    }

    createPurchaseOrder() {
        this.selectSupplier(
            this.currentTabIndex === 0
                ? this._stockRequestApi.createProjectPurchaseOrder
                : this._stockRequestApi.createSalesOrderPurchaseOrder);
    }

    selectSupplier(callback: Function) {
        this._appController.openModal((account: string) => {
            if (account) {
                callback(account, this.selectedRowKeys)
                    .subscribe(result => {
                        this._appController.notice(`Purchase order created`);
                        this._router.navigate(["/purchase-order/", result.orderNumber]);
                    }, error => {
                        this._appController.notice(error);
                    });
            }
        }, ModalSelectSupplierComponent, {
            width: AppConstant.SIZE.MODAL_SMALL
        });
    }
}
