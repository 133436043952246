import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import DataSource from 'devextreme/data/data_source';
import { AppController } from 'src/app/app.controller';
import { AppData } from 'src/app/app.data';
import { SecurityService } from 'src/app/service/security.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-modal-select-customer',
  templateUrl: './modal-select-customer.component.html',
  styleUrls: ['./modal-select-customer.component.scss']
})
export class ModalSelectCustomerComponent implements OnInit {

  @ViewChild(MatExpansionPanel) panel: MatExpansionPanel;

  public readonly dataSource = new DataSource(AppData.customers());
  public readonly customerClasses = new DataSource(AppData.createStore("value", environment.api_erp_list_customer_classes));

  form: FormGroup;

  constructor(
    private readonly dialogRef: MatDialogRef<ModalSelectCustomerComponent>,
    private readonly _appController: AppController,
    private readonly _securityService: SecurityService) {
  }

  ngOnInit() {
    this.form = new FormGroup({
      account: new FormControl(null, [Validators.required]),
      name: new FormControl(null),
      customerClass: new FormControl(null),
      taxStatus: new FormControl("N"),
      salesperson1Id: new FormControl(this._securityService.getAuthenticatedUserId())
    });
  }

  create() {
    AppData.createStore("account", environment.api_erp_customers).insert(this.form.value).then(() => {
      this.form.reset();
      this.panel.close();

      this.dataSource.reload();
      this._appController.notice(`Customer created`);
    });
  }

  proceed() {
    if (this.form.valid) {
      this.dialogRef.close(this.form.value.account);
    }
  }
}
