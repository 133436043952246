import { HttpClientModule } from '@angular/common/http';
import { NgModule } from "@angular/core";
import { ActivityApi } from './activity.api';
import { BillingApi } from './billing.api';
import { CommissionApi } from './commission.api';
import { CustomerContractApi } from './customer-contract.api';
import { CustomerProjectApi } from './customer-project.api';
import { CustomerTaskApi } from './customer-task.api';
import { CustomerApi } from './customer.api';
import { DealerApi } from './dealer.api';
import { DepartmentApi } from './department.api';
import { InventoryMovementApi } from './inventory-movement.api';
import { InventoryReadingHardwareApi } from './inventory-reading-hardware.api';
import { InventoryReadingVoiceApi } from './inventory-reading-voice.api';
import { InventoryApi } from './inventory.api';
import { InvoiceApi } from './invoice.api';
import { JobApi } from './job.api';
import { LeadAggApi } from './lead-agg.api';
import { LeadPoolApi } from './lead-pool.api';
import { LeadTaskApi } from './lead-task.api';
import { LeadApi } from './lead.api';
import { ListJobClassApi } from './list-job-class.api';
import { ListSupplierClassApi } from './list-supplier-class.api';
import { LocationContactApi } from './location-contact.api';
import { LocationCustomerApi } from './location-customer.api';
import { LocationLeadApi } from './location-lead.api';
import { ProductClassApi } from './product-class.api';
import { ProjectFileApi } from './project-file.api';
import { ProjectOrderApi } from './project-order.api';
import { ProjectStepApi } from './project-step.api';
import { ProjectTaskApi } from './project-task.api';
import { ProjectApi } from './project.api';
import { ProspectApi } from './prospect.api';
import { PurchaseOrderLineApi } from './purchase-order-line.api';
import { PurchaseOrderApi } from './purchase-order.api';
import { QuoteDataApi } from './quote-data.api';
import { QuoteLookupReportApi } from './quote-lookup-report.api';
import { QuoteLookupTermApi } from './quote-lookup-term.api';
import { QuoteApi } from './quote.api';
import { SaleOrderLineApi } from './sale-order-line.api';
import { SaleOrderApi } from './sale-order.api';
import { SecurityApi } from './security.api';
import { ServiceLevelAgreementEscalationApi } from './service-level-agreement-escalation.api';
import { ServiceLevelAgreementLimitApi } from './service-level-agreement-limit.api';
import { ServiceLevelAgreementPlanApi } from './service-level-agreement-plan.api';
import { ServiceLevelAgreementTargetApi } from './service-level-agreement-target.api';
import { ServiceLevelAgreementApi } from './service-level-agreement.api';
import { StockRequestApi } from './stock-request.api';
import { SupplierApi } from './supplier.api';
import { TaskApi } from './task.api';
import { TaxCodeApi } from './tax-code.api';
import { TemplateProjectApi } from './template-project.api';
import { TicketEmailApi } from './ticket-email.api';
import { TicketNoteApi } from './ticket-note.api';
import { TicketTaskApi } from './ticket-task.api';
import { TicketApi } from './ticket.api';
import { UserPasswordApi } from './user-password.api';
import { UserPermissionApi } from './user-permission.api';
import { UserRoleApi } from './user-role.api';
import { UserApi } from './user.api';

@NgModule({
  providers: [
    SecurityApi,
    LeadApi,
    LeadAggApi,
    LocationLeadApi,
    LocationContactApi,
    LeadPoolApi,
    ProspectApi,
    QuoteApi,
    QuoteDataApi,
    CustomerApi,
    UserPasswordApi,
    UserPermissionApi,
    UserApi,
    UserRoleApi,
    LeadTaskApi,
    TaskApi,
    ProductClassApi,
    TaxCodeApi,
    DealerApi,
    DepartmentApi,
    TicketApi,
    SupplierApi,
    JobApi,
    ServiceLevelAgreementApi,
    InventoryApi,
    PurchaseOrderApi,
    PurchaseOrderLineApi,
    LocationCustomerApi,
    TemplateProjectApi,
    CustomerTaskApi,
    CommissionApi,
    CustomerProjectApi,
    ProjectApi,
    ProjectStepApi,
    ProjectFileApi,
    ProjectTaskApi,
    ProjectOrderApi,
    InvoiceApi,
    CustomerContractApi,
    TicketNoteApi,
    TicketTaskApi,
    TicketEmailApi,
    InventoryMovementApi,
    InventoryReadingHardwareApi,
    InventoryReadingVoiceApi,
    StockRequestApi,
    ServiceLevelAgreementPlanApi,
    ServiceLevelAgreementTargetApi,
    ServiceLevelAgreementLimitApi,
    ServiceLevelAgreementEscalationApi,
    ListJobClassApi,
    ActivityApi,
    BillingApi,
    ListSupplierClassApi,
    SaleOrderApi,
    SaleOrderLineApi,
    QuoteLookupTermApi,
    QuoteLookupReportApi
  ],
  imports: [
    HttpClientModule
  ]
})
export class ApiModule { }
