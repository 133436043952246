import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';

@Injectable()
export class CustomerProjectApi extends ApiService {
    constructor(httpClient: HttpClient) {
        super(environment.api_erp_projects, httpClient);
    }

    public deliver(orders: number[]): Promise<{ report: string; invoice: string }> {
        return new Promise((resolve, reject) => {
            this.post(this.getEndpoint(environment.api_erp_projects_deliver), orders, { observe: "response" })
                .subscribe(response => {
                    resolve({ report: response.headers.get("location"), invoice: response.body.invoice });
                }, error => {
                    reject(error);
                });
        });
    }

    public createSaleOrder(salesOrderType: "O" | "C", orders: number[]) {
        return this.post(
            this.getEndpoint(environment.api_erp_projects_salesorder),
            {
                salesOrderType: salesOrderType,
                orders: orders
            });
    }
}
