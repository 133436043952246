import { Component } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { AppData } from 'src/app/app.data';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-tax-code-list',
    templateUrl: './tax-code-list.component.html'
})
export class TaxCodeListComponent {

    public readonly basis = [{ text: "Inclusive", value: "I" }, { text: "Exclusive", value: "E" }];

    public readonly dataSource = new DataSource(AppData.createStore("code", environment.api_erp_tax_codes));

    onEditorPreparing(e) {
        if (e.parentType === "dataRow") {
            if (e.dataField === "code") {
                e.editorOptions.disabled = !e.row.isNewRow;
            }
        }
    }
}
