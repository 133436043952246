import { Component, Input, OnInit } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { AppData } from 'src/app/app.data';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-partial-ticket-orders',
    templateUrl: './partial-ticket-orders.component.html'
})
export class PartialTicketOrdersComponent implements OnInit {

    @Input() ticketId: string;

    public dataSource: DataSource

    ngOnInit() {
        this.dataSource = new DataSource({
            store: AppData.createStore("orderNumber", environment.api_erp_sales_orders, {
                loadParams: {
                    ticketId: this.ticketId
                }
            })
        });
    }
}
