import { Component, Input, OnInit } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { ApiService } from 'src/app/api/api.service';
import { AppController } from 'src/app/app.controller';
import { AppData } from 'src/app/app.data';
import { ModalFormContractItemQuickAddComponent } from 'src/app/modal/modal-form-contract-item-quick-add/modal-form-contract-item-quick-add.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-partial-contract-type-item-list',
  templateUrl: './partial-contract-type-item-list.component.html',
  styleUrls: ['./partial-contract-type-item-list.component.scss']
})
export class PartialContractTypeItemListComponent implements OnInit {

  @Input() contractId: string;
  @Input() contractType: "hardware" | "services";
  @Input() account: string;

  public dataSource: DataSource;

  constructor(public readonly appController: AppController) {
  }

  ngOnInit() {
    if (this.contractType === "hardware") {
      this.dataSource = new DataSource({
        store: AppData.createStore(["contractId", "code", "serial"], ApiService.getEndpoint(environment.api_erp_customer_contract) + "/" + this.contractType,{
          loadParams: {
            contractId: this.contractId
          }
        })
      });
    }

    if (this.contractType === "services") {
      this.dataSource = new DataSource({
        store: AppData.createStore("id", ApiService.getEndpoint(environment.api_erp_customer_contract) + "/" + this.contractType, {
          loadParams: {
            contractId: this.contractId
          }
        })
      });
    }
  }

  public add(contract?: any) {
    this.appController.openModal(() => {
      this.dataSource.reload();
    }, ModalFormContractItemQuickAddComponent, {
      width: "550px",
      data: {
        subject: contract,
        contractId: this.contractId,
        contractType: this.contractType,
        account: this.account
      }
    });
  }

  public delete(item: any, key: any) {
    AppData.delete(this.appController, this.dataSource, key, `Are you sure you want delete '${item.description}'?`, "Contract item deleted");
  }
}
