import { Component } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import dxDataGrid from 'devextreme/ui/data_grid';
import { ApiService } from 'src/app/api/api.service';
import { AppController } from 'src/app/app.controller';
import { AppData } from 'src/app/app.data';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-department-list',
    templateUrl: './department-list.component.html',
    styleUrls: ['./department-list.component.scss']
})
export class DepartmentListComponent {
    public readonly dataSource = new DataSource(AppData.createStore("id", environment.api_erp_departments));
    public readonly users = {
        store: AppData.createStore("id", {
            loadUrl: ApiService.getEndpoint(environment.api_erp_user)
        }),
        select: ["id", "name"],
        searchExpr: "name",
        sort: "name"
    };

    constructor(private readonly _appController: AppController) {
    }

    edit(e: { component: dxDataGrid; rowIndex: number }) {
        e.component.editRow(e.rowIndex);
    }

    delete(e: { component: dxDataGrid; rowIndex: number }) {
        AppData.deleteV2(this._appController, e);
    }
}
