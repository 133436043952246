import { Component, Input, OnInit } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { AppController } from 'src/app/app.controller';
import { AppData } from 'src/app/app.data';
import { ModalFormContractItemQuickAddComponent } from 'src/app/modal/modal-form-contract-item-quick-add/modal-form-contract-item-quick-add.component';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-partial-contract-list',
    templateUrl: './partial-contract-list.component.html',
    styleUrls: ['./partial-contract-list.component.scss']
})
export class PartialContractListComponent implements OnInit {

    public readonly periods = [36, 48, 60];

    @Input() account?: string;

    public dataSource: DataSource;

    constructor(private readonly _appController: AppController) {
    }

    ngOnInit() {
        this.dataSource = new DataSource({
            store: AppData.createStore("id", environment.api_erp_customer_contract, {
                loadParams: {
                    account: this.account
                }
            })
        });
    }

    onInitNewRow(e) {
        e.data.account = this.account;
    }

    onEditorPreparing(e) {
        if (e.parentType === "dataRow") {
            if (e.dataField === "notes") {
                e.editorName = "dxTextArea";
            }
        }
    }

    public addContactTypeItem(event: Event, contractType: string, contractId: number) {
        event.stopPropagation();

        this._appController.openModal(() => {
            this.dataSource.reload();
        }, ModalFormContractItemQuickAddComponent, {
            width: "550px",
            data: {
                contractId: contractId,
                contractType: contractType,
                account: this.account
            }
        });
    }
}
