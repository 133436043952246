export class QuoteLine {
  constructor(public description, public quantity: number, public price: number) {
  }
}

export class QuoteInfrastructure {
  constructor(public summaryOnly: boolean, public category, public description, public currentQuantity: number, public currentPrice: number, public esaQuantity: number, public esaPrice: number) {
  }
}

export class QuoteSummary {
  constructor(public description: string, public current: number, public esa: number, public type: string) {
  }
}
