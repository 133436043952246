import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';
import { HttpService } from './HttpService';

@Injectable()
export class ProjectOrderApi extends HttpService {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  convertStocked(id: number, code: string) {
    return this.post(this.getEndpoint(`${environment.api_erp_project_orders}/${id}/convert`), { code: code });
  }
}
