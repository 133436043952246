import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import DataSource from 'devextreme/data/data_source';
import { ApiService } from 'src/app/api/api.service';
import { AppData } from 'src/app/app.data';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-modal-select-lead',
  templateUrl: './modal-select-lead.component.html',
  styleUrls: ['./modal-select-lead.component.scss']
})
export class ModalSelectLeadComponent implements OnInit {
  public readonly dataSource = new DataSource({
    store: AppData.createStore("id", {
      loadUrl: ApiService.getEndpoint(environment.api_erp_leads_agg)
    }),
    select: ["id", "name"],
    sort: "name"
  });

  form: FormGroup;

  constructor(private readonly dialogRef: MatDialogRef<ModalSelectLeadComponent>) {
  }

  ngOnInit() {
    this.form = new FormGroup({
      id: new FormControl(null, [Validators.required])
    });
  }

  proceed() {
    if (this.form.valid) {
      this.dialogRef.close(this.form.value.id);
    }
  }
}
