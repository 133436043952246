import { Component, Input, OnInit } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import dxDataGrid from 'devextreme/ui/data_grid';
import { ApiService } from 'src/app/api/api.service';
import { AppController } from 'src/app/app.controller';
import { AppData } from 'src/app/app.data';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-partial-commission-list',
    templateUrl: './partial-commission-list.component.html',
    styleUrls: ['./partial-commission-list.component.scss']
})
export class PartialCommissionListComponent implements OnInit {

    @Input() userId: string;

    public readonly inventory = AppData.inventory();
    public readonly periods = {
        store: AppData.createStore("periodCode", {
            loadUrl: ApiService.getEndpoint(environment.api_erp_sales_periods)
        })
    };

    public incentive: DataSource;
    public sales: DataSource;
    public annuity: DataSource;

    constructor(private readonly _appController: AppController) {
    }

    ngOnInit(): void {
        this.incentive = new DataSource({
            store: AppData.createStore("id", environment.api_erp_comission_incentive, {
                loadParams: {
                    userId: this.userId
                }
            })
        });

        this.sales = new DataSource({
            store: AppData.createStore("id", environment.api_erp_comission_sales, {
                loadParams: {
                    userId: this.userId
                }
            })
        });

        this.annuity = new DataSource({
            store: AppData.createStore(["userId", "code"], environment.api_erp_comission_annuity, {
                loadParams: {
                    userId: this.userId
                }
            })
        });
    }

    onInitNewRow(e) {
        e.data.userId = this.userId;
    }

    onEditorPreparing(e) {
        if (e.parentType === "dataRow") {
            if (e.dataField === "code") {
                e.editorName = "dxLookup";
            }
        }
    }

    public edit(e: { component: dxDataGrid; rowIndex: number }) {
        e.component.editRow(e.rowIndex);
    }

    public delete(e: { component: dxDataGrid; rowIndex: number }) {
        AppData.deleteV2(this._appController, e);
    }

    getIncentiveDataSource(line: any) {
        if (!line.dataSource) {
            line.dataSource = new DataSource({
                store: AppData.createStore("id", {
                    loadUrl: ApiService.getEndpoint(environment.api_erp_tasks_agg)
                }),
                filter: [
                    ["userId", line.userId],
                    ["startTime", ">=", line.startSalesPeriod.startDate],
                    ["startTime", "<=", line.endSalesPeriod.endDate],
                    ["meetingType", "<>", "other"]
                ]
            });
        }

        return line.dataSource;
    }

    getSalesDataSource(line: any) {
        if (!line.dataSource) {
            line.dataSource = new DataSource({
                store: AppData.createStore("id", {
                    loadUrl: ApiService.getEndpoint(environment.api_quotes_quotes)
                }),
                filter: [
                    ["user.email", line.user.email],
                    ["complete", true],
                    ["completedAt", ">=", line.startSalesPeriod.startDate],
                    ["completedAt", "<=", line.endSalesPeriod.endDate]
                ]
            });
        }

        return line.dataSource;
    }

    getAnnuityDataSource(line: any) {
        if (!line.dataSource) {
            line.dataSource = new DataSource({
                store: AppData.createStore("id", {
                    loadUrl: ApiService.getEndpoint(environment.api_erp_sales_order_invoice_lines)
                }),
                filter: [
                    ["salesOrder.userId", line.userId],
                    ["salesOrderInvoice.invoiceDate", ">=", line.startSalesPeriod.startDate],
                    ["salesOrderInvoice.invoiceDate", "<=", line.endSalesPeriod.endDate],
                    ["code", line.code]
                ]
            });
        }

        return line.dataSource;
    }
}
