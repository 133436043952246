import { SelectionChange, SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { AppUtility } from 'src/app/app.utility';

@Component({
  selector: 'app-widget-data-table',
  templateUrl: './widget-data-table.component.html',
  styleUrls: ['./widget-data-table.component.scss']
})
export class WidgetDataTableComponent implements OnInit {

  @Input() dataSource: any;
  @Input() columnLabels: string[];
  @Input() columnKeys: string[];
  @Input() actionMenu: any[];
  @Input() pageSize: number;
  @Input() paginate: boolean = true;
  @Output() onPage: EventEmitter<any>;
  @Output() onSort: EventEmitter<any>;
  @Output() onSelect: EventEmitter<any[]>;
  @Input() multiDatatable: boolean = false;
  @Input() subDataTable: boolean = false;

  selectionModel: SelectionModel<any>;

  constructor(public readonly appUtility: AppUtility) {
    this.onPage = new EventEmitter<any>();
    this.onSort = new EventEmitter<any>();
    this.onSelect = new EventEmitter<any[]>();

    this.selectionModel = new SelectionModel<any>(true, []);
  }

  ngOnInit() {
    this.selectionModel.changed.subscribe((selectionChanged: SelectionChange<any>) => {
      this.onSelect.emit(this.selectionModel.selected);
    });
  }

  page(pageEvent: PageEvent) {
    console.debug("table paged", pageEvent);
    this.onPage.emit({
      page: (pageEvent.pageIndex * pageEvent.pageSize),
      size: pageEvent.pageSize,
      event: pageEvent
    });
  }

  sort(sortEvent: any) {
    console.debug("table sorted", sortEvent);
    this.onSort.emit({ field: sortEvent.active, desc: ((sortEvent.direction == 'desc') ? true : false), event: sortEvent });
  }

  isAllSelected(): boolean {
    return this.selectionModel.selected.length === (this.dataSource.data as []).length;
  }

  selectAll() {
    this.isAllSelected() ? this.selectionModel.clear() : (this.dataSource.data as []).forEach((item) => { this.selectionModel.select(item) });
  }
}
