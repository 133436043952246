import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from "lodash";
import { LeadApi } from 'src/app/api/lead.api';
import { ProspectApi } from 'src/app/api/prospect.api';
import { AppConstant } from 'src/app/app.constant';
import { AppController } from 'src/app/app.controller';
import { Confirmation } from 'src/app/modal/modal-confirmation/confirmation';
import { ModalFormLeadConvertToCustomerComponent } from 'src/app/modal/modal-form-lead-convert-to-customer/modal-form-lead-convert-to-customer.component';
import { BaseDataListing } from 'src/app/page/base-data-list.component';

@Component({
    selector: 'app-partial-lead-search-results',
    templateUrl: './partial-lead-search-results.component.html',
    styleUrls: ['./partial-lead-search-results.component.scss']
})
export class PartialLeadSearchResultsComponent extends BaseDataListing {

    @Input() dataSource: any;
    @Output() updateResults = new EventEmitter<any>();

    constructor(
        private readonly _leadApi: LeadApi,
        private readonly _prospectApi: ProspectApi,
        private readonly _router: Router,
        public appController: AppController) {
        super();
        this.actionMenu = [
            {
                label: "Duplicate", callBack: (element: any) => {
                    this.duplicate(element);
                }
            },
            {
                label: "Quote", callBack: (element: any) => {
                    this._router.navigate(["/quote", element.id]);
                }
            },
            {
                label: "Add Task", callBack: (element: any) => {
                }
            },
            {
                label: "Convert to Customer", callBack: (element: any) => {
                    this.convert(element);
                }
            },
            {
                label: "Edit", callBack: (element: any) => {
                    this.view(element);
                }
            },
            {
                label: "Delete", callBack: (element: any) => {
                    this.delete(element);
                }
            }
        ];
    }

    get columnLabels(): string[] {
        return [
            "",
            "Type",
            "Stage",
            "Name",
            "Trading Name",
            "Phone",
            "Web",
            "Salesperson",
            "",
        ];
    }

    get columnKeys(): string[] {
        return [
            "select",
            "type",
            "stage",
            "name",
            "tradingName",
            "phone",
            "web",
            "salesperson1.name,salesperson2.name,salesperson3.name",
            "action"
        ];
    }

    onSelect(selected: any[]): void {
    }

    add() {
        this._router.navigate(["/customer/lead"]);
    }

    view(element: any) {
        this._router.navigate(["/customer/lead", element.id]);
    }

    delete(element: any) {
        this.appController.confirm((proceed) => {
            if (proceed) {
                this._leadApi.remove(element.id).subscribe((results) => {
                    this.appController.notice(`Removed lead ${element.name}`);
                    this.refresh();
                }, (error) => {
                    this.appController.notice(`Failed to remove lead ${element.name}`);
                });
            }
        }, new Confirmation("delete_outline",
            `Are you sure you want delete lead ${element.name}?`,
            "Cancel", "Delete")
        );
    }

    duplicate(element: any) {
        this.appController.confirm((proceed) => {
            if (proceed) {
                let elementCopy: any = _.omit(element, ["id", "created", "user", "salesperson1", "salesperson2", "salesperson3"]);

                this._leadApi.create(elementCopy).subscribe((results) => {
                    this.appController.notice(`Duplicated lead ${element.name}`);
                    this.refresh();
                }, (error) => {
                    this.appController.notice(`Failed to duplicate lead ${element.name}`);
                });
            }
        }, new Confirmation("file_copy",
            `Are you sure you want duplicate lead ${element.name}?`,
            "Cancel", "Duplicate")
        );
    }

    convert(element: any) {
        this.appController.openModal((modalResults: any) => {
            this._prospectApi
                .convert(element.id, modalResults)
                .subscribe((results) => {
                    this.appController.notice(`Converted ${element.name} to customer`);
                }, (error) => {
                    this.appController.notice(`Failed to convert to customer`);
                });
        }, ModalFormLeadConvertToCustomerComponent,
            {
                width: AppConstant.SIZE.MODAL_MEDIUM,
                data: {
                    prospectId: element.id
                }
            });
    }
}
