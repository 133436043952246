import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import DataSource from 'devextreme/data/data_source';
import { LocationContactApi } from 'src/app/api/location-contact.api';
import { LocationCustomerApi } from 'src/app/api/location-customer.api';
import { ServiceLevelAgreementApi } from 'src/app/api/service-level-agreement.api';
import { AppController } from 'src/app/app.controller';
import { AppData } from 'src/app/app.data';
import { BaseForm } from 'src/app/page/base-form.component';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-modal-form-ticket-quick-add',
    templateUrl: './modal-form-ticket-quick-add.component.html',
    styleUrls: ['./modal-form-ticket-quick-add.component.scss']
})
export class ModalFormTicketQuickAddComponent extends BaseForm implements OnInit {

    public readonly customers = new DataSource(AppData.customers());
    public readonly users = AppData.users(true);
    public readonly jobCodes = new DataSource(AppData.createStore("value", environment.api_erp_list_job_classes));

    form: FormGroup;

    ticketLogged: boolean = false;
    ticket: any;

    locations: any[];
    locationContacts: any[];
    serviceLevelAgreements: any[];

    types: string[] = ["voice", "print", "security", "connectivity", "IT"];
    priorities: any[] = [
        { value: "1", label: "1 - High" },
        { value: "2", label: "2 - Med" },
        { value: "3", label: "3 - Low" }
    ];

    constructor(
        public dialogRef: MatDialogRef<ModalFormTicketQuickAddComponent>,
        private readonly _customerLocationApi: LocationCustomerApi,
        private readonly _locationContactApi: LocationContactApi,
        private readonly _serviceLevelAgreementApi: ServiceLevelAgreementApi,
        private readonly _formBuilder: FormBuilder,
        public readonly appController: AppController,
        private readonly _router: Router) {
        super();
    }

    ngOnInit() {
        this.form = this._formBuilder.group({
            userId: [null, [Validators.required]],
            locationId: [null, [Validators.required]],
            account: [null, [Validators.required]],
            accountName: [null, [Validators.required]],
            contactId: [null],
            priority: [null, [Validators.required]],
            type: [null, [Validators.required]],
            escalated: [false],
            status: ["new"],
            serviceAgreementId: [null],
            serviceAgreementDescription: [null],
            description: [null, [Validators.required]],
            jobCode: [null, [Validators.required]]
        });

        this.form.get("account").valueChanges
            .subscribe((account: string) => {
                this.customers.store().byKey(account).then(customer => {
                    if (customer) {
                        this.setValue(this.form, "accountName", customer.name);
                    }
                });

                this.getAccountLocations(account);
                this.getServiceLevelAgreements(account);
            });

        this.form.get("locationId").valueChanges
            .subscribe((locatonId: string) => {
                this.getLocationContacts(locatonId);
            });
    }

    getAccountLocations(customerId: string) {
        this._customerLocationApi
            .findAll(new HttpParams()
                .set("account", customerId)
                .set("select", JSON.stringify(["id", "name", "account", "address1", "address2", "address3", "addressCode"]))
            )
            .subscribe((results: any) => {
                this.locations = results.data;
            });
    }

    getLocationContacts(locationId: string) {
        this._locationContactApi
            .findAll(new HttpParams()
                .set("locationId", locationId)
                .set("select", JSON.stringify(["id", "name"]))
            )
            .subscribe((results: any) => {
                this.locationContacts = results.data;
            });
    }

    getServiceLevelAgreements(account: string) {
        this._serviceLevelAgreementApi.findAll(new HttpParams().set("account", account)).subscribe((results) => {
            this.serviceLevelAgreements = results.data;
        });
    }

    save() {
        if (this.form.valid) {
            let data = this.form.value;

            AppData.tickets().store.insert(data).then(ticket => {
                this.ticket = ticket;

                AppData.jobs().store.insert({
                    jobCode: data.jobCode,
                    ticketId: ticket.id,
                    userId: data.userId,
                    description: data.description,
                    scheduleTime: new Date()
                }).then(() => {
                    this.ticketLogged = true;
                });
            });
        }
    }

    view(ticketId: string) {
        this._router.navigate(["/support/ticket/", ticketId]);
        this.dialogRef.close(this.ticket);
    }
}
