import { Component } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { AppController } from 'src/app/app.controller';
import { AppData } from 'src/app/app.data';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss']
})
export class TaskListComponent {
  public readonly dataSource = new DataSource(AppData.createStore("id", environment.api_erp_tasks_agg));

  constructor(private readonly _appController: AppController) {
  }

  public delete(task: any) {
    AppData.delete(this._appController, this.dataSource, task.id, `Are you sure you want delete '${task.type}'?`, "Task deleted");
  }
}
