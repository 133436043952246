import { Component, Input, OnInit } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { ApiService } from 'src/app/api/api.service';
import { AppConstant } from 'src/app/app.constant';
import { AppController } from 'src/app/app.controller';
import { AppData } from 'src/app/app.data';
import { ModalFormProjectStepDocumentQuickAddComponent } from 'src/app/modal/modal-form-project/modal-form-project-step-document-quick-add/modal-form-project-step-document-quick-add.component';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-partial-project-step-document',
    templateUrl: './partial-project-step-document.component.html',
    styleUrls: ['./partial-project-step-document.component.scss']
})
export class PartialProjectStepDocumentComponent implements OnInit {

    @Input() account: string;
    @Input() projectId: number;

    public dataSource: DataSource;

    constructor(private readonly _appController: AppController) {
    }

    ngOnInit() {
        this.dataSource = new DataSource({
            store: AppData.createStore("id", {
                loadUrl: ApiService.getEndpoint(environment.api_erp_project_steps),
                loadParams: {
                    projectId: this.projectId
                }
            }),
            filter: ["stepType", "D"]
        });
    }

    getDataSource(step) {
        if (!step.dataSource) {
            step.dataSource = new DataSource(AppData.createStore("id", environment.api_erp_project_documents, {
                loadParams: {
                    stepId: step.id
                }
            }));
        }

        return step.dataSource;
    }

    edit(file, stepId) {
        this._appController.openModal(() => {
            this.dataSource.reload();
        }, ModalFormProjectStepDocumentQuickAddComponent, {
            width: AppConstant.SIZE.MODAL_LARGE,
            data: { subject: file, account: this.account, stepId: stepId }
        });
    }
}
