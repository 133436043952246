import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import { FormGroupService } from 'src/app/service/form.service';

@Component({
    selector: 'app-partial-quotation-summary',
    templateUrl: './partial-quotation-summary.component.html',
    styleUrls: ['./partial-quotation-summary.component.scss']
})
export class PartialQuotationSummaryComponent implements OnInit {

    @Input() subject: any;
    @Input() updateAnalysisRequested: EventEmitter<[]>;

    public dataSource: DataSource;

    constructor(private readonly formGroupService: FormGroupService) {
    }

    ngOnInit() {
        this.updateAnalysisRequested.subscribe(data => {
            this.dataSource = new DataSource({
                store: new ArrayStore({
                    data: data
                })
            });
        });

        this.formGroupService.formGroup.get("analysis").valueChanges
            .subscribe(newValue => {
                this.dataSource = new DataSource({
                    store: new ArrayStore({
                        data: newValue
                    })
                });
            });

        this.dataSource = new DataSource({
            store: new ArrayStore({
                data: this.subject?.analysis || []
            })
        });
    }
}
