import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';

@Injectable()
export class CommissionApi extends ApiService {
    constructor(httpClient: HttpClient) {
        super(environment.api_erp_comission_sales, httpClient);
    }

    findByUserId(id: any) {
        return this.find(id);
    }
}
