import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpService } from 'src/app/api/HttpService';
import { AppController } from 'src/app/app.controller';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'price-update',
    templateUrl: './price-update.component.html'
})
export class PriceUpdateComponent implements OnInit {
    public readonly updateTypes = ["PriceCode", "ProductClass", "Customer", "CustomerRating"];
    public readonly updateValues = ["Percentage", "Value"];

    form: FormGroup;

    constructor(
        private readonly _formBuilder: FormBuilder,
        private readonly _appController: AppController,
        private readonly http: HttpClient) {
    }

    ngOnInit() {
        this.form = this._formBuilder.group({
            updateType: [null, [Validators.required]],
            updateValue: [null, [Validators.required]],
            priceCode: [],
            productClass: [],
            customer: [],
            percentage: [],
            value: []
        });
    }

    submit() {
        if (this.form.valid) {
            let data = this.form.value;
            this.http.post(HttpService.getEndpoint(environment.api_erp_pricing), data)
                .subscribe(() => {
                    this._appController.notice("Pricing updated");
                }, error => {
                    this._appController.error(error.error);
                });
        }
    }
}
