import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api/api.service';
import { CustomerContractApi } from 'src/app/api/customer-contract.api';
import { CustomerApi } from 'src/app/api/customer.api';
import { InventoryReadingHardwareApi } from 'src/app/api/inventory-reading-hardware.api';
import { InventoryReadingVoiceApi } from 'src/app/api/inventory-reading-voice.api';
import { BaseDataListing } from 'src/app/page/base-data-list.component';

@Component({
  selector: 'app-readings-list',
  templateUrl: './readings-list.component.html',
  styleUrls: ['./readings-list.component.scss']
})
export class ReadingsListComponent extends BaseDataListing implements OnInit {

  apis: { [key: string]: ApiService };

  constructor(
    private readonly _inventoryReadingHardwareApi: InventoryReadingHardwareApi,
    private readonly _inventoryReadingVoiceApi: InventoryReadingVoiceApi,
    private readonly _customerApi: CustomerApi,
    private readonly _customerContractApi: CustomerContractApi) {

    super();
    this.apis = {
      inventoryReadingHardwareApi: _inventoryReadingHardwareApi,
      inventoryReadingVoiceApi: _inventoryReadingVoiceApi,
      customerApi: _customerApi,
      customerContractApi: _customerContractApi
    };
  }

  ngOnInit() {
  }

  onSelect(selected: any[]): void {
    console.debug("item selected", selected);
  }

  onSearch(value: string): void {
    this.queryFilter = null;
    console.debug("searching for ", value);

    // if (value !== '') {
    //   this.queryFilter = [["account", "contains", `${value}`], "or", ["name", "contains", `${value}`], "or", ["tradingName", "contains", `${value}`], "or", ["accountRef", "contains", `${value}`]]
    //   this.queryData(this.queryLimit, this.queryCurrentPage, true, this.querySort, this.queryFilter);
    // } else {
    //   this.queryData(this.queryLimit, this.queryCurrentPage, true, this.querySort, this.queryFilter);
    // }
  }
}
