import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DxFileUploaderComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import { InventoryApi } from 'src/app/api/inventory.api';
import { ProductClassApi } from 'src/app/api/product-class.api';
import { TaxCodeApi } from 'src/app/api/tax-code.api';
import { AppController } from 'src/app/app.controller';
import { AppData } from 'src/app/app.data';
import { BaseForm } from 'src/app/page/base-form.component';

@Component({
  selector: 'app-partial-inventory-product-information',
  templateUrl: './partial-inventory-product-information.component.html',
  styleUrls: ['./partial-inventory-product-information.component.scss']
})
export class PartialInventoryProductInformationComponent extends BaseForm implements OnInit {

  @ViewChild(DxFileUploaderComponent) uploader: DxFileUploaderComponent;

  @Input() code: string;
  @Input() product: any;

  public readonly warehouses = new DataSource(AppData.warehouses(true));

  form: FormGroup;
  productClasses: any[];
  taxCodes: any[];

  constructor(
    private readonly _inventoryApi: InventoryApi,
    private readonly _productClassApi: ProductClassApi,
    private readonly _taxCodeApi: TaxCodeApi,
    private readonly _formBuilder: FormBuilder,
    private readonly _appController: AppController,
    private readonly _router: Router) {
    super()
  }

  ngOnInit() {
    this.form = this._formBuilder.group({
      code: [null, [Validators.required]],
      description: [null, [Validators.required]],
      altDescription: [null],
      category: [null],
      unitOfMeasure: [null, [Validators.required]],
      productClass: [null, [Validators.required]],
      taxCode: [null, [Validators.required]],
      serialized: [false],
      phantomPart: [false],
      onHold: [false],
      listPriceCode: [null],
      defaultWarehouse: [null],
      yield: [null],
      includesFreeStock: [false],
      annuityType: [null],
      annuityValue: [null],
      image: this._formBuilder.group({
        image: [null]
      }),
      imageUrl: [null]
    });

    if (this.product?.code) {
      if (!this.product.image) {
        delete this.product.image;
      }

      this.product.imageUrl = this.product.image;
      this.form.patchValue(this.product);
      this.form.get("code").disable();
    }

    this.getProductClasses();
    this.getTaxCodes();
  }

  public onFileUploaded(e: { value?: File[] }) {
    if (e.value && e.value[0]) {
      var file = e.value[0];

      var fr = new FileReader();
      fr.onload = args => {
        var content = args.target.result as string;
        var base64 = content.split(',')[1];
        this.form.get("image").get("image").setValue(base64);
        this.form.get("image").markAsDirty();
        this.form.get("imageUrl").setValue(content);
      };
      fr.readAsDataURL(file);
    }
  }

  getTaxCodes() {
    this._taxCodeApi.findAll().subscribe((results: any) => {
      this.taxCodes = results.data;
    });
  }

  getProductClasses() {
    this._productClassApi.findAll().subscribe((results: any) => {
      this.productClasses = results.data;
    });
  }

  save() {
    if (this.form.valid) {
      let data = this.form.value;
      if (data.image?.image === null) {
        delete data.image;
      }

      if (!this.code) {
        this._inventoryApi.create(data)
          .subscribe(product => {
            this._appController.notice("Created stock code");
            this._router.navigate(["/inventory/", product.code]);
          });
      } else {
        this._inventoryApi.update(this.code, data)
          .subscribe(product => {
            if (!product.image) {
              delete product.image;
            }

            this.form.patchValue(product);
            this._appController.notice("Updated stock code");
            this.uploader.instance.reset();
          });
      }
    }
  }
}
