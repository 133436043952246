import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AvatarModule } from 'ngx-avatar';
import { ApiModule } from '../api/api.module';
import { AppUiDevExtremeModule } from '../app.ui.devextreme.module';
import { AppMaterialModule } from '../app.ui.material.module';
import { WidgetModule } from '../widget/widget.module';
import { PartialActivityListComponent } from './partial-activity-list/partial-activity-list.component';
import { PartialBillingLinesComponent } from './partial-billing/partial-billing-lines/partial-billing-lines.component';
import { PartialCommissionListComponent } from './partial-commission-list/partial-commission-list.component';
import { PartialContractCalculationComponent } from './partial-contract/partial-contract-calculation/partial-contract-calculation.component';
import { PartialContractListComponent } from './partial-contract/partial-contract-list/partial-contract-list.component';
import { PartialContractTypeItemListComponent } from './partial-contract/partial-contract-type-item-list/partial-contract-type-item-list.component';
import { PartialFileListComponent } from './partial-file-list/partial-file-list.component';
import { PartialInventoryCustomerComponent } from './partial-inventory/partial-inventory-customer/partial-inventory-customer.component';
import { PartialInventoryLinkComponent } from './partial-inventory/partial-inventory-link/partial-inventory-link.component';
import { PartialInventoryLoanComponent } from './partial-inventory/partial-inventory-loan/partial-inventory-loan.component';
import { PartialInventoryMovementsComponent } from './partial-inventory/partial-inventory-movements/partial-inventory-movements.component';
import { PartialInventoryProductInformationComponent } from './partial-inventory/partial-inventory-product-information/partial-inventory-product-information.component';
import { PartialInventoryProductPricingComponent } from './partial-inventory/partial-inventory-product-pricing/partial-inventory-product-pricing.component';
import { PartialInventoryReadingHardwareListComponent } from './partial-inventory/partial-inventory-reading-hardware-list/partial-inventory-reading-hardware-list.component';
import { PartialInventoryReadingVoiceListComponent } from './partial-inventory/partial-inventory-reading-voice-list/partial-inventory-reading-voice-list.component';
import { PartialInventorySerialsComponent } from './partial-inventory/partial-inventory-serials/partial-inventory-serials.component';
import { PartialInventorySupplierComponent } from './partial-inventory/partial-inventory-supplier/partial-inventory-supplier.component';
import { PartialInventoryWarehouseComponent } from './partial-inventory/partial-inventory-warehouse/partial-inventory-warehouse.component';
import { PartialInvoiceDashboardComponent } from './partial-invoice/partial-invoice-dashboard/partial-invoice-dashboard.component';
import { PartialLeadSearchResultsComponent } from './partial-lead-search-results/partial-lead-search-results.component';
import { PartialLocationListComponent } from './partial-location-list/partial-location-list.component';
import { PartialInventoryMovementAdjustmentComponent } from './partial-movements/partial-inventory-movement-adjustment/partial-inventory-movement-adjustment.component';
import { PartialInventoryMovementReceiveComponent } from './partial-movements/partial-inventory-movement-receive/partial-inventory-movement-receive.component';
import { PartialInventoryMovementTransferComponent } from './partial-movements/partial-inventory-movement-transfer/partial-inventory-movement-transfer.component';
import { PartialPbxListComponent } from './partial-pbx-list/partial-pbx-list.component';
import { PartialPermissionListComponent } from './partial-permission-list/partial-permission-list.component';
import { PartialProjectListComponent } from './partial-project/partial-project-list/partial-project-list.component';
import { PartialProjectStepDocumentComponent } from './partial-project/partial-project-step-document/partial-project-step-document.component';
import { PartialProjectStepTaskListComponent } from './partial-project/partial-project-step-task-list/partial-project-step-task-list.component';
import { PartialProjectStepTaskComponent } from './partial-project/partial-project-step-task/partial-project-step-task.component';
import { PartialProjectStockComponent } from './partial-project/partial-project-stock/partial-project-stock.component';
import { PartialPurchaseOrderFormHeaderComponent } from './partial-purchase-order/partial-purchase-order-form-header/partial-purchase-order-form-header.component';
import { PartialPurchaseOrderGrnComponent } from './partial-purchase-order/partial-purchase-order-grn/partial-purchase-order-grn.component';
import { PartialPurchaseOrderItemsComponent } from './partial-purchase-order/partial-purchase-order-items/partial-purchase-order-items.component';
import { PartialPurchaseOrderReceiveComponent } from './partial-purchase-order/partial-purchase-order-receive/partial-purchase-order-receive.component';
import { PartialFormQuoteDefaultComponent } from './partial-quotation/partial-form-quote-default/partial-form-quote-default.component';
import { PartialQuotationAccessoryComponent } from './partial-quotation/partial-quotation-accessory/partial-quotation-accessory.component';
import { PartialQuotationAdHocComponent } from './partial-quotation/partial-quotation-ad-hoc/partial-quotation-ad-hoc.component';
import { PartialQuotationHardwareComponent } from './partial-quotation/partial-quotation-hardware/partial-quotation-hardware.component';
import { PartialQuotationInfrastructureComponent } from './partial-quotation/partial-quotation-infrastructure/partial-quotation-infrastructure.component';
import { PartialQuotationMarginComponent } from './partial-quotation/partial-quotation-margin/partial-quotation-margin.component';
import { PartialQuotationRateComponent } from './partial-quotation/partial-quotation-rate/partial-quotation-rate.component';
import { PartialQuotationSecurityComponent } from './partial-quotation/partial-quotation-security/partial-quotation-security.component';
import { PartialQuotationSummaryComponent } from './partial-quotation/partial-quotation-summary/partial-quotation-summary.component';
import { PartialSaleOrderItemsComponent } from './partial-sale-order/partial-sale-order-items/partial-sale-order-items.component';
import { PartialServiceLevelAgreementEscalationComponent } from './partial-service-level-agreement/partial-service-level-agreement-escalation/partial-service-level-agreement-escalation.component';
import { PartialServiceLevelAgreementLimitComponent } from './partial-service-level-agreement/partial-service-level-agreement-limit/partial-service-level-agreement-limit.component';
import { PartialServiceLevelAgreementTargetComponent } from './partial-service-level-agreement/partial-service-level-agreement-target/partial-service-level-agreement-target.component';
import { PartialSlaListComponent } from './partial-service-level-agreement/partial-sla-list/partial-sla-list.component';
import { PartialSupplierInvoiceLinesComponent } from './partial-supplier/partial-supplier-invoice-lines/partial-supplier-invoice-lines.component';
import { PartialTaskDashboardComponent } from './partial-task-dashboard/partial-task-dashboard.component';
import { PartialTicketCommentComponent } from './partial-ticket/partial-ticket-comment/partial-ticket-comment.component';
import { PartialTicketJobsComponent } from './partial-ticket/partial-ticket-jobs/partial-ticket-jobs.component';
import { PartialTicketOrdersComponent } from './partial-ticket/partial-ticket-orders/partial-ticket-orders.component';

@NgModule({
    declarations: [
        PartialSlaListComponent,
        PartialSupplierInvoiceLinesComponent,
        PartialBillingLinesComponent,
        PartialLeadSearchResultsComponent,
        PartialProjectListComponent,
        PartialProjectStepDocumentComponent,
        PartialProjectStepTaskComponent,
        PartialPermissionListComponent,
        PartialPbxListComponent,
        PartialCommissionListComponent,
        PartialActivityListComponent,
        PartialTaskDashboardComponent,
        PartialLocationListComponent,
        PartialContractListComponent,
        PartialFileListComponent,
        PartialTicketCommentComponent,
        PartialTicketOrdersComponent,
        PartialTicketJobsComponent,
        PartialInventoryProductInformationComponent,
        PartialInventoryMovementsComponent,
        PartialInventorySerialsComponent,
        PartialContractTypeItemListComponent,
        PartialInvoiceDashboardComponent,
        PartialProjectStepTaskListComponent,
        PartialProjectStockComponent,
        PartialFormQuoteDefaultComponent,
        PartialInventoryProductPricingComponent,
        PartialInventoryCustomerComponent,
        PartialInventorySupplierComponent,
        PartialInventoryLinkComponent,
        PartialInventoryWarehouseComponent,
        PartialInventoryLoanComponent,
        PartialPurchaseOrderFormHeaderComponent,
        PartialPurchaseOrderItemsComponent,
        PartialPurchaseOrderReceiveComponent,
        PartialPurchaseOrderGrnComponent,
        PartialInventoryReadingHardwareListComponent,
        PartialInventoryReadingVoiceListComponent,
        PartialInventoryMovementAdjustmentComponent,
        PartialInventoryMovementTransferComponent,
        PartialInventoryMovementReceiveComponent,
        PartialServiceLevelAgreementLimitComponent,
        PartialServiceLevelAgreementTargetComponent,
        PartialServiceLevelAgreementEscalationComponent,
        PartialSaleOrderItemsComponent,
        PartialQuotationRateComponent,
        PartialQuotationAccessoryComponent,
        PartialQuotationAdHocComponent,
        PartialQuotationHardwareComponent,
        PartialQuotationInfrastructureComponent,
        PartialQuotationMarginComponent,
        PartialQuotationSecurityComponent,
        PartialQuotationSummaryComponent,
        PartialContractCalculationComponent
    ],
    imports: [
        CommonModule,
        AppMaterialModule,
        AppUiDevExtremeModule,
        AvatarModule,
        RouterModule,
        WidgetModule,
        ApiModule
    ],
    exports: [
        PartialSlaListComponent,
        PartialSupplierInvoiceLinesComponent,
        PartialBillingLinesComponent,
        PartialLeadSearchResultsComponent,
        PartialProjectListComponent,
        PartialProjectStepDocumentComponent,
        PartialProjectStepTaskComponent,
        PartialPermissionListComponent,
        PartialPbxListComponent,
        PartialCommissionListComponent,
        PartialActivityListComponent,
        PartialTaskDashboardComponent,
        PartialLocationListComponent,
        PartialContractListComponent,
        PartialFileListComponent,
        PartialTicketCommentComponent,
        PartialTicketOrdersComponent,
        PartialTicketJobsComponent,
        PartialInventoryProductInformationComponent,
        PartialInventoryMovementsComponent,
        PartialInventorySerialsComponent,
        PartialContractTypeItemListComponent,
        PartialInvoiceDashboardComponent,
        PartialProjectStepTaskListComponent,
        PartialProjectStockComponent,
        PartialFormQuoteDefaultComponent,
        PartialInventoryProductPricingComponent,
        PartialInventoryCustomerComponent,
        PartialInventorySupplierComponent,
        PartialInventoryLinkComponent,
        PartialInventoryWarehouseComponent,
        PartialInventoryLoanComponent,
        PartialPurchaseOrderFormHeaderComponent,
        PartialPurchaseOrderItemsComponent,
        PartialPurchaseOrderReceiveComponent,
        PartialPurchaseOrderGrnComponent,
        PartialInventoryReadingHardwareListComponent,
        PartialInventoryReadingVoiceListComponent,
        PartialInventoryMovementAdjustmentComponent,
        PartialInventoryMovementTransferComponent,
        PartialInventoryMovementReceiveComponent,
        PartialServiceLevelAgreementLimitComponent,
        PartialServiceLevelAgreementTargetComponent,
        PartialServiceLevelAgreementEscalationComponent,
        PartialSaleOrderItemsComponent,
        PartialQuotationRateComponent,
        PartialQuotationAccessoryComponent,
        PartialQuotationAdHocComponent,
        PartialQuotationHardwareComponent,
        PartialQuotationInfrastructureComponent,
        PartialQuotationMarginComponent,
        PartialQuotationSecurityComponent,
        PartialQuotationSummaryComponent,
        PartialContractCalculationComponent
    ]
})
export class PartialModule { }
