import { Component, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Router } from '@angular/router';
import { DxDashboardControlComponent } from 'devexpress-dashboard-angular';
import { DashboardParameterDialogExtension, ViewerApiExtension } from 'devexpress-dashboard/common';
import DataSource from 'devextreme/data/data_source';
import { custom } from 'devextreme/ui/dialog';
import { CustomerTaskApi } from 'src/app/api/customer-task.api';
import { LeadTaskApi } from 'src/app/api/lead-task.api';
import { LocationCustomerApi } from 'src/app/api/location-customer.api';
import { LocationLeadApi } from 'src/app/api/location-lead.api';
import { TicketTaskApi } from 'src/app/api/ticket-task.api';
import { UserApi } from 'src/app/api/user.api';
import { AppConstant } from 'src/app/app.constant';
import { AppController } from 'src/app/app.controller';
import { AppData } from 'src/app/app.data';
import { ModalFormTaskCallQuickAddComponent } from 'src/app/modal/modal-form-task/modal-form-task-call-quick-add/modal-form-task-call-quick-add.component';
import { ModalFormTaskEmailQuickAddComponent } from 'src/app/modal/modal-form-task/modal-form-task-email-quick-add/modal-form-task-email-quick-add.component';
import { ModalFormTaskMeetingQuickAddComponent } from 'src/app/modal/modal-form-task/modal-form-task-meeting-quick-add/modal-form-task-meeting-quick-add.component';
import { ModalFormTaskOtherQuickAddComponent } from 'src/app/modal/modal-form-task/modal-form-task-other-quick-add/modal-form-task-other-quick-add.component';
import { ModalFormTicketQuickAddComponent } from 'src/app/modal/modal-form-ticket-quick-add/modal-form-ticket-quick-add.component';
import { ModalSelectCustomerComponent } from 'src/app/modal/modal-select-customer/modal-select-customer.component';
import { ModalSelectLeadComponent } from 'src/app/modal/modal-select-lead/modal-select-lead.component';
import { SecurityService } from 'src/app/service/security.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {

  @ViewChild(DxDashboardControlComponent) dashboardControl: DxDashboardControlComponent;

  public readonly tasks = new DataSource({
    store: AppData.createStore("id", environment.api_erp_tasks_agg),
    filter: ["complete", false],
    sort: "startTime",
    pageSize: 5
  });
  public readonly headers;
  public dashboard: string;

  constructor(
    private readonly _userApi: UserApi,
    private readonly _customerTaskApi: CustomerTaskApi,
    private readonly _leadTaskApi: LeadTaskApi,
    private readonly _ticketTaskApi: TicketTaskApi,
    //private readonly _jobTaskApi: JobTaskApi,
    private readonly _customerLocationApi: LocationCustomerApi,
    private readonly _leadLocationApi: LocationLeadApi,
    private readonly _router: Router,
    public readonly securityService: SecurityService,
    public readonly appController: AppController) {

    _userApi.find(securityService.getAuthenticatedUserId())
      .subscribe(user => {
        this.dashboard = user.profile?.dashboard || user.profile?.department?.dashboard || user.profile?.department?.name || securityService.getAuthenticatedUser().role;

        this.dashboardControl.instance.unregisterExtension("dashboard-export");
        this.dashboardControl.instance.loadDashboard(this.dashboard).then(() => {
          var parametersExtension = this.dashboardControl.instance.findExtension("dashboard-parameter-dialog") as DashboardParameterDialogExtension;
          var parameters = parametersExtension.getParameters();

          var viewerExtension = this.dashboardControl.instance.findExtension("viewer-api") as ViewerApiExtension;

          if (!securityService.isInRole("administrator")) {
            var pUserId = parameters.getParameterByName("UserId");
            pUserId?.setValue(securityService.getAuthenticatedUserId());

            viewerExtension.setMasterFilter("salesperson", [[securityService.getAuthenticatedUser().given_name]]);
          }

          var updateDates = (from, to) => {
            var pFrom = parameters.getParameterByName("FromDate");
            pFrom?.setValue(from);

            var pTo = parameters.getParameterByName("ToDate");
            pTo?.setValue(to);
          };

          var dateRange = viewerExtension.getCurrentRange("dateFilterDashboardItem1");
          if (dateRange) {
            updateDates(dateRange.minimum, dateRange.maximum);
          }

          viewerExtension.on("itemMasterFilterStateChanged", e => {
            if (e.itemName === "dateFilterDashboardItem1") {
              updateDates(e.values[0][0], e.values[0][1]);
            }
          });
        });
      });

    this.headers = {
      Authorization: "Bearer " + securityService.getAuthenticatedUserToken()
    };
  }

  public logTicket() {
    this.appController.openModal(() => {
    }, ModalFormTicketQuickAddComponent, {
      width: AppConstant.SIZE.MODAL_MEDIUM
    });
  }

  public async addTask(type: string) {
    const module = await custom({
      title: "Module",
      message: "Add a task for a customer or lead?",
      buttons: [
        {
          text: "Customer",
          onClick() {
            return "customer";
          }
        },
        {
          text: "Lead",
          onClick() {
            return "lead";
          }
        }
      ]
    }).show();

    if (module === "customer") {
      this.appController.openModal((account: string) => {
        if (account) {
          AppData.customers().store.byKey(account).then(customer => {
            if (customer) {
              this.addOrEditTask(type, { account: account, name: customer.name, taskType: "C" });
            }
          });
        }
      }, ModalSelectCustomerComponent, {
        width: AppConstant.SIZE.MODAL_SMALL
      });
    }

    if (module === "lead") {
      this.appController.openModal((id: number) => {
        if (id) {
          AppData.leads().store.byKey(id).then(lead => {
            if (lead) {
              this.addOrEditTask(type, { leadId: id, name: lead.name, taskType: "L" });
            }
          });
        }
      }, ModalSelectLeadComponent, {
        width: AppConstant.SIZE.MODAL_SMALL
      });
    }
  }

  public addOrEditTask(type: string, task?: any) {
    switch (type) {
      case "email":
        this.appController.openModal(() => {
          this.tasks.reload();
        }, ModalFormTaskEmailQuickAddComponent, {
          width: AppConstant.SIZE.MODAL_MEDIUM,
          data: {
            title: this.getTitle(task),
            subject: task,
            services: { taskApi: this.getTaskApi(task.taskType) },
            queryParams: { account: task.account, leadId: task.leadId, ticketId: task.ticketId }
          }
        });
        break;

      case "task":
      case "other":
        this.appController.openModal(() => {
          this.tasks.reload();
        }, ModalFormTaskOtherQuickAddComponent, {
          width: AppConstant.SIZE.MODAL_MEDIUM,
          data: {
            title: this.getTitle(task),
            subject: task,
            services: { taskApi: this.getTaskApi(task.taskType) },
            queryParams: { account: task.account, leadId: task.leadId, ticketId: task.ticketId }
          }
        });
        break;

      case "meeting":
        this.appController.openModal(() => {
          this.tasks.reload();
        }, ModalFormTaskMeetingQuickAddComponent, {
          width: AppConstant.SIZE.MODAL_MEDIUM,
          data: {
            title: this.getTitle(task),
            subject: task,
            services: { taskApi: this.getTaskApi(task.taskType), locationApi: this.getLocationApi(task.taskType) },
            queryParams: { account: task.account, leadId: task.leadId, ticketId: task.ticketId }
          }
        });
        break;

      case "call":
        this.appController.openModal(() => {
          this.tasks.reload();
        }, ModalFormTaskCallQuickAddComponent, {
          width: AppConstant.SIZE.MODAL_MEDIUM,
          data: {
            title: this.getTitle(task),
            subject: task,
            services: { taskApi: this.getTaskApi(task.taskType) },
            queryParams: { account: task.account, leadId: task.leadId, ticketId: task.ticketId }
          }
        });
        break;
    }
  }

  public completeTask(e: MatCheckboxChange, task: any) {
    if (e.checked) {
      this.appController.getInput("Comment").then(comment => {
        this.getTaskApi(task.taskType)
          .update(task.id, { complete: true, completeComment: comment })
          .subscribe(() => {
            this.tasks.reload();
            this.appController.notice("Task completed");
          });
      }, () => {
        e.source.checked = false;
      });
    }
  }

  public deleteTask(task: any) {
    AppData.delete(this.appController, this.tasks, task.id, `Are you sure you want delete '${task.title}'?`, "Task deleted");
  }

  public onTaskClick(task: any) {
    switch (task.taskType) {
      case "C":
        this._router.navigate(["/customer/", task.account]);
        break;
      case "L":
        this._router.navigate(["/customer/lead/", task.leadId]);
        break;
      case "T":
        this._router.navigate(["/support/ticket/", task.ticketId]);
        break;
    }
  }

  private getTaskApi(leadType: string) {
    switch (leadType) {
      case "C":
        return this._customerTaskApi;
      case "L":
        return this._leadTaskApi;
      case "T":
        return this._ticketTaskApi;
    }
  }

  private getLocationApi(leadType: string) {
    switch (leadType) {
      case "C":
        return this._customerLocationApi;
      case "L":
        return this._leadLocationApi;
    }
  }

  private getTitle(element?: any) {
    return element.name || element.customerName || element.accountName;
  }
}
