import { SelectionModel } from '@angular/cdk/collections';
import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { ApiService } from 'src/app/api/api.service';
import { AppUtility } from 'src/app/app.utility';

@Component({
  selector: 'app-widget-data-grid',
  templateUrl: './widget-data-grid.component.html',
})
export class WidgetDataGridComponent implements OnInit {

  @Input() dataSource: any;
  @Input() widgetDataTableComponentConfiguration: WidgetDataTableComponentConfiguration;
  @Output() onSelect: EventEmitter<any[]>;
  @Input() api: ApiService;
  @Input() toolbar: any[];
  @Input() columns: WidgetDataTableComponentColumn[];

  @Input() columnLabels: string[];
  @Input() columnKeys: string[];
  @Input() actionMenu: any[];
  @Input() pageSize: number;
  @Input() paginate: boolean = true;
  @Output() onPage: EventEmitter<any>;
  @Output() onSort: EventEmitter<any>;

  @Input() multiDatatable: boolean = false;
  @Input() subDataTable: boolean = false;

  @Input() rowId: string = "id";

  selectionModel: SelectionModel<any>;

  constructor(public readonly appUtility: AppUtility) {
    this.onSelect = new EventEmitter<any[]>();
  }

  ngOnInit() {
    console.debug(" widgetDataTableComponentConfiguration ", this.widgetDataTableComponentConfiguration);
    this.columns = this.widgetDataTableComponentConfiguration.columns;
    this.initDatasource(this.api);
  }

  initDatasource(api?: ApiService) {
    if (api) {
      console.debug("initDatasource ", api);

      this.dataSource = new CustomStore({
        key: this.rowId,
        load: (loadOptions): Promise<any> => {

          console.debug("active datasrouce load options", loadOptions);

          function isNotEmpty(value: any): boolean {
            return value !== undefined && value !== null && value !== "";
          }

          let params: HttpParams = new HttpParams();

          //possible parameters list from data table
          [
            "skip",
            "take",
            "requireTotalCount",
            "requireGroupCount",
            "sort",
            "filter",
            "totalSummary",
            "group",
            "groupSummary"
          ].forEach((i) => {
            if (i in loadOptions && isNotEmpty(loadOptions[i]))
              params = params.set(i, JSON.stringify(loadOptions[i]));
          });

          return api.findAll(params)
            .toPromise().then((results: any) => {
              console.debug("active datasource data", results);
              return results;
            }).catch((error) => {
              console.error("active datasource data load failed", error);
            });
        }
      });

      console.debug(" Setting Up WidgetDataTableComponent ", api);
    }
  }

  // page(pageEvent: PageEvent) {
  //   console.debug("table paged", pageEvent);
  //   this.onPage.emit({
  //     page: (pageEvent.pageIndex * pageEvent.pageSize),
  //     size: pageEvent.pageSize,
  //     event: pageEvent
  //   });
  // }


  // sort(sortEvent: any) {
  //   console.debug("table sorted", sortEvent);
  //   this.onSort.emit({ field: sortEvent.active, desc: ((sortEvent.direction == 'desc') ? true : false), event: sortEvent });
  // }

  // isAllSelected(): boolean {
  //   return this.selectionModel.selected.length === (this.dataSource.data as []).length;
  // }

  // selectAll() {
  //   this.isAllSelected() ? this.selectionModel.clear() : (this.dataSource.data as []).forEach((item) => { this.selectionModel.select(item) });
  // }

  // filterKeys(columnKeys: string[]): string[] {
  //   let keys: string[] = [];
  //   columnKeys.forEach((value) => {
  //     keys.push(`filter_${value}`);
  //   });
  //   return keys;
  // }

  onToolbarPreparing(e) {
    if (this.toolbar) {
      console.debug(" onToolbarPreparing ", this.toolbar);
      (this.toolbar as []).forEach((toolbarItem) => {
        e.toolbarOptions.items.unshift(toolbarItem);
      });
    }
  }

  onSelectionChanged(e) {
    console.debug("selection changed", e.selectedRowsData);
    this.onSelect.emit(e.selectedRowsData);
  }

}

export class WidgetDataTableComponentColumn {
  key?: string;
  caption?: string;
  allowFiltering?: boolean = true;
  allowHeaderFiltering?: boolean = true;
  calculateCellValue?: Function;
}

export class WidgetDataTableComponentConfiguration {

  private _columns: WidgetDataTableComponentColumn[];

  addColumn(widgetDataTableComponentColumn: WidgetDataTableComponentColumn) {
    this._columns = (this._columns) ? this._columns : [] as WidgetDataTableComponentColumn[];
    this._columns.push(widgetDataTableComponentColumn);
  }

  get columns(): WidgetDataTableComponentColumn[] {
    return this._columns;
  }

  set columns(widgetDataTableComponentColumns: WidgetDataTableComponentColumn[]) {
    this._columns = widgetDataTableComponentColumns;
  }
}
