import { Component, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import dxDataGrid from 'devextreme/ui/data_grid';
import { custom } from 'devextreme/ui/dialog';
import { CustomerProjectApi } from 'src/app/api/customer-project.api';
import { ProjectOrderApi } from 'src/app/api/project-order.api';
import { AppConstant } from 'src/app/app.constant';
import { AppController } from 'src/app/app.controller';
import { AppData } from 'src/app/app.data';
import { ModalSelectInventoryComponent } from 'src/app/modal/modal-select-inventory/modal-select-inventory.component';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-partial-project-stock',
    templateUrl: './partial-project-stock.component.html',
    styleUrls: ['./partial-project-stock.component.scss']
})
export class PartialProjectStockComponent {

    @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent

    @Input() projectId: number;

    public readonly warehouses = AppData.warehouses();
    public readonly taxCodes = AppData.taxCodes();
    public dataSource: DataSource;

    showSelectedAction = false;
    selectedRowKeys: number[];
    selectedRowsData: any[];

    constructor(
        private readonly _customerProjectApi: CustomerProjectApi,
        private readonly _projectOrderApi: ProjectOrderApi,
        private readonly _appController: AppController,
        private readonly _router: Router) {
    }

    ngOnInit() {
        this.dataSource = new DataSource({
            store: AppData.createStore("id", environment.api_erp_project_orders, {
                loadParams: {
                    projectId: this.projectId,
                }
            })
        });
    }

    getBins(options: any) {
        if (options.data && options.data.warehouse) {
            return AppData.bins(options.data.warehouse);
        }

        return [];
    }

    getProductCodes(options: any) {
        if (options.data) {
            return AppData.inventory(options.data.warehouse);
        }

        return [];
    }

    onInitNewRow(e) {
        e.data.projectId = this.projectId;
    }

    onSelectionChanged(e) {
        this.showSelectedAction = e.selectedRowKeys.length > 0;
        this.selectedRowKeys = e.selectedRowKeys;
        this.selectedRowsData = e.selectedRowsData;
    }

    onEditorPreparing(e) {
        if (e.parentType === "dataRow") {
            if (e.dataField === "warehouse") {
                e.editorOptions.disabled = !e.row.isNewRow;
            }

            if (e.dataField === "bin") {
                e.editorOptions.disabled = !e.row.isNewRow || !e.row.data.warehouse || e.row.data.warehouse === "**";
            }

            if (e.dataField === "code") {
                e.editorOptions.disabled = !e.row.isNewRow || !e.row.data.warehouse;

                if (e.row.data.warehouse === "**") {
                    e.editorName = "dxTextBox";
                } else {
                    e.editorName = "dxLookup";
                }
            }

            if (e.dataField === "description") {
                e.editorOptions.disabled = !e.row.isNewRow || !e.row.data.code || (e.row.isNewRow && e.row.data.warehouse !== '**');
            }

            if (e.dataField === "unitOfMeasure") {
                e.editorOptions.disabled = !e.row.isNewRow || !e.row.data.code || (e.row.isNewRow && e.row.data.warehouse !== '**');
            }
        }
    }

    onWarehouseChanged = function (newData, value, currentRowData) {
        newData.bin = null;
        newData.code = null;
        newData.description = null;
        newData.unitOfMeasure = null;
        this.defaultSetCellValue(newData, value);
    }

    onCodeChanged = function (newData, value, currentRowData) {
        this.defaultSetCellValue(newData, value);

        if (currentRowData.warehouse === "**") {
            // Non stocked.
        } else {
            return AppData.inventory().store.byKey(value).then(product => {
                if (product) {
                    newData.description = product.description;
                    newData.unitOfMeasure = product.unitOfMeasure;
                    newData.taxCode = product.taxCode;
                }
            });
        }
    }

    async request() {
        var alreadyDelivered = this.selectedRowsData.some(_ => _.started);
        if (alreadyDelivered) {
            this._appController.notice("Please only select lines that have not been started.");
            return;
        }

        for (let key of this.selectedRowKeys) {
            await this.dataSource.store().update(key, {
                started: true
            });
        }

        this._appController.notice("Request sent", "success");
        this.dataGrid.instance.deselectAll();
        this.dataSource.reload();
    }

    deliver() {
        var noAllocatedQuantity = this.selectedRowsData.some(_ => !_.started || !_.quantityAllocated || _.quantityAllocated === 0);
        if (noAllocatedQuantity) {
            this._appController.notice("Please only select lines that have a quantity allocated to deliver.");
            return;
        }

        var alreadyDeliverd = this.selectedRowsData.some(_ => _.complete)
        if (alreadyDeliverd) {
            this._appController.notice("Please only select lines that have not been completed.");
            return;
        }

        this._customerProjectApi.deliver(this.selectedRowKeys).then(result => {
            this._appController.notice("Delivery note created", "success", 3000);
            this.dataGrid.instance.deselectAll();
            this.dataSource.reload();

            window.open(result.report, '_blank');
        });
    }

    async salesOrder() {
        var notDelivered = this.selectedRowsData.some(_ => !_.complete);
        if (notDelivered) {
            this._appController.notice("Please only select lines that have been complete.");
            return;
        }

        const salesOrderType = await custom({
            title: "Order type",
            message: "Create a order or a credit note?",
            buttons: [
                {
                    text: "Order",
                    onClick() {
                        return "O";
                    }
                },
                {
                    text: "Credit Note",
                    onClick() {
                        return "C";
                    }
                }
            ]
        }).show();

        this._customerProjectApi.createSaleOrder(salesOrderType, this.selectedRowKeys).subscribe(result => {
            this._appController.notice("Sales order created", "success", 3000);
            this._router.navigate(["/sales-order/", result.orderNumber]);
        }, error => {
            this._appController.notice(error);
        });
    }

    public edit(e: { component: dxDataGrid; rowIndex: number }) {
        e.component.editRow(e.rowIndex);
    }

    public delete(e: { component: dxDataGrid; rowIndex: number }) {
        AppData.deleteV2(this._appController, e);
    }

    convertStocked(order) {
        this._appController.openModal((code: string) => {
            if (code) {
                this._projectOrderApi.convertStocked(order.id, code).subscribe(() => {
                    this.dataSource.reload();
                    this._appController.notice(`Order converted`);
                });
            }
        }, ModalSelectInventoryComponent, {
            width: AppConstant.SIZE.MODAL_SMALL
        });
    }
}
