import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';
import { HttpService } from './HttpService';

@Injectable()
export class JobApi extends HttpService {
    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    public progress(id: number, data: { status: string }) {
        return this.post(`${this.getEndpoint(environment.api_erp_jobs)}/${id}/progress`, data);
    }

    public complete(id: number, data: { name: string; resolution: string; }) {
        return this.post(`${this.getEndpoint(environment.api_erp_jobs)}/${id}/complete`, data);
    }
}
