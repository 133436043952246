import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Confirmation } from './confirmation';

@Component({
  templateUrl: "./modal-confirmation.component.html",
  styleUrls: ['./modal-confirmation.component.scss']
})
export class ModalConfirmationComponent {
  constructor(
    private readonly dialogRef: MatDialogRef<ModalConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public confirmation: Confirmation) {
  }

  proceed() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
