import { Component, Input, OnInit } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import dxDataGrid from 'devextreme/ui/data_grid';
import { ApiService } from 'src/app/api/api.service';
import { AppController } from 'src/app/app.controller';
import { AppData } from 'src/app/app.data';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-partial-inventory-link',
    templateUrl: './partial-inventory-link.component.html',
    styleUrls: ['./partial-inventory-link.component.scss']
})
export class PartialInventoryLinkComponent implements OnInit {

    @Input() code: string;

    public readonly inventory = AppData.inventory();
    public dataSource: DataSource;

    constructor(private readonly _appController: AppController) {
    }

    ngOnInit() {
        this.dataSource = new DataSource({
            store: AppData.createStore(["code", "linkedCode"], environment.api_erp_inventory_links, {
                loadParams: {
                    code: this.code
                }
            })
        });
    }

    onInitNewRow(e) {
        e.data.code = this.code;
        e.data.quantity = 1;
    }

    onEditorPreparing(e) {
        if (e.parentType === "dataRow") {
            if (e.dataField === "linkedCode") {
                e.editorName = "dxLookup";
                e.editorOptions.disabled = !e.row.isNewRow;
                e.editorOptions.itemTemplate = "lookupTemplate";
            }
        }
    }

    onCodeChanged = function (newData, value, currentRowData) {
        this.defaultSetCellValue(newData, value);

        return AppData.createStore("code", {
            loadUrl: ApiService.getEndpoint(environment.api_erp_inventory)
        }).byKey(value).then(result => {
            if (result) {
                newData.description = result.description;
            }
        });
    }

    public edit(e: { component: dxDataGrid; rowIndex: number }) {
        e.component.editRow(e.rowIndex);
    }

    public delete(e: { component: dxDataGrid; rowIndex: number }) {
        AppData.deleteV2(this._appController, e);
    }
}
