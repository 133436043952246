import { Component, Input, OnInit } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { AppData } from 'src/app/app.data';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-partial-billing-lines',
    templateUrl: './partial-billing-lines.component.html'
})
export class PartialBillingLinesComponent implements OnInit {

    @Input() runId: number;

    public dataSource: DataSource

    ngOnInit() {
        this.dataSource = new DataSource({
            store: AppData.createStore("id", environment.api_erp_billing_lines, {
                loadParams: {
                    runId: this.runId
                }
            })
        });
    }

    onEditorPreparing(e) {
        if (e.parentType === "dataRow") {
            if (e.dataField === "notes") {
                e.editorName = "dxTextArea";
            }
        }
    }
}
