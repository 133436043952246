import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';

@Injectable()
export class UserPasswordApi extends ApiService {
  //erp/account/signout/
  constructor(httpClient: HttpClient) {
    super(environment.api_erp_user_password, httpClient);
  }

  public update(data: any) {
    return this.put(
      this.endpoint,
      data);
  }
}
