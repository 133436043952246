import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/api/api.service';
import { AppController } from 'src/app/app.controller';
import { AppData } from 'src/app/app.data';
import { BaseForm } from 'src/app/page/base-form.component';
import { SecurityService } from 'src/app/service/security.service';

@Component({
    selector: 'app-modal-form-task-call-quick-add',
    templateUrl: './modal-form-task-call-quick-add.component.html',
    styleUrls: ['./modal-form-task-call-quick-add.component.scss']
})
export class ModalFormTaskCallQuickAddComponent extends BaseForm implements OnInit {

    public readonly users = AppData.users();
    public readonly salespersons = AppData.users();

    private title: string;
    private subject: any;
    private readonly services: { taskApi: ApiService; };
    private readonly queryParams: any;

    form: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<ModalFormTaskCallQuickAddComponent>,
        @Inject(MAT_DIALOG_DATA) data: any,
        private readonly _formBuilder: FormBuilder,
        private readonly appController: AppController,
        private readonly securityService: SecurityService) {
        super();

        this.title = data.title;
        this.subject = data.subject;
        this.services = data.services;
        this.queryParams = data.queryParams;
    }

    ngOnInit() {
        this.form = this._formBuilder.group({
            id: [null],
            title: [`Call: ${this.title}`, [Validators.required]],
            type: ["call", [Validators.required]],

            userId: [this.securityService.getAuthenticatedUserId(), [Validators.required]],
            users: [null],

            startTime: [this.roundMinutes(new Date()), [Validators.required]],
            description: [null, [Validators.required]],
        });

        if (this.subject) {
            if (this.subject.users) {
                this.subject.users = this.subject.users.map(user => user.userId);
            }

            this.form.patchValue(this.subject);
        }
    }

    roundMinutes(date: Date) {
        date.setHours(date.getHours() + Math.round(date.getMinutes() / 60));
        date.setMinutes(0, 0, 0);

        return date;
    }

    save() {
        if (this.form.valid) {
            let data = this.form.value;
            if (data.users) {
                data.users = data.users.map(userId => ({ taskId: data.id, userId: userId }));
            } else {
                data.users = [];
            }

            if (!data.id) {
                this.services.taskApi
                    .create({ ...data, ...this.queryParams })
                    .subscribe((results: any) => {
                        results = { ...data, ...results };
                        this.appController.notice(`Call saved successfully`);
                        this.form.patchValue({ id: results.id });
                        this.dialogRef.close(results);
                    }, (error) => {
                        this.appController.notice(`Failed to save call`);
                    });
            } else {
                this.services.taskApi
                    .update(data.id, { ...data, ...this.queryParams })
                    .subscribe((results: any) => {
                        results = { ...data, ...results };
                        this.form.patchValue(results);
                        this.appController.notice(`Call updated successfully`);
                        this.dialogRef.close(results);
                    }, (error) => {
                        this.appController.notice(`Failed to update call`);
                    });
            }
        }
    }
}
