import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api/api.service';
import { PurchaseOrderApi } from 'src/app/api/purchase-order.api';
import { AppController } from 'src/app/app.controller';
import { AppData } from 'src/app/app.data';
import { BaseForm } from 'src/app/page/base-form.component';
import { SecurityService } from 'src/app/service/security.service';

@Component({
    selector: 'app-partial-purchase-order-form-header',
    templateUrl: './partial-purchase-order-form-header.component.html',
    styleUrls: ['./partial-purchase-order-form-header.component.scss']
})
export class PartialPurchaseOrderFormHeaderComponent extends BaseForm implements OnInit {

    @Input() public orderNumber: string;
    @Input() public services: { [key: string]: ApiService };
    @Output() statusChanged = new EventEmitter<string>();

    public readonly suppliers = AppData.suppliers();
    public readonly users = AppData.users(true);

    form: FormGroup;

    constructor(
        private readonly _purchaseOrderApi: PurchaseOrderApi,
        private readonly _formBuilder: FormBuilder,
        private readonly _securityService: SecurityService,
        private readonly _appController: AppController,
        private readonly _router: Router) {
        super();
    }

    ngOnInit() {
        this.form = this._formBuilder.group({
            orderNumber: [null],
            orderStatus: ["N"],

            account: [null, [Validators.required]],
            accountName: [null, [Validators.required]],
            taxStatus: [null, [Validators.required]],
            supplierPurchaseOrder: [null],

            orderDate: [new Date()],

            contactName: [null],
            contactEmail: [null, [Validators.email]],
            contactPhone: [null],

            address1: [null],
            address2: [null],
            address3: [null],
            addressCode: [null],

            userId: [this._securityService.getAuthenticatedUserId()],
            notes: [null],

            // not sent to server
            address: [null]
        });

        if (this.orderNumber) {
            this._purchaseOrderApi.find(this.orderNumber)
                .subscribe(purchaseOrder => {
                    purchaseOrder.address = [purchaseOrder.address1, purchaseOrder.address2, purchaseOrder.address3, purchaseOrder.addressCode].join();
                    this.form.patchValue(purchaseOrder);
                    this.form.get("account").disable();
                    this.form.get("taxStatus").disable();

                    if (purchaseOrder.orderStatus === "C") {
                        this.form.get("orderStatus").disable();
                    }
                });
        }

        this.form.get("account").valueChanges
            .subscribe((account: string) => {
                AppData.suppliers().store.byKey(account).then(supplier => {
                    if (supplier) {
                        this.setValue(this.form, "accountName", supplier.name);
                        this.setValue(this.form, "taxStatus", supplier.taxStatus);

                        this.setValue(this.form, "address", [supplier.address1, supplier.address2, supplier.address3, supplier.addressCode].join());
                        this.setValue(this.form, "address1", supplier.address1);
                        this.setValue(this.form, "address2", supplier.address2);
                        this.setValue(this.form, "address3", supplier.address3);
                        this.setValue(this.form, "addressCode", supplier.addressCode);
                    }
                });
            });
    }

    addressChanged(value: string[]) {
        if (value) {
            this.setValue(this.form, "address1", value[0]);
            this.setValue(this.form, "address2", value[1] + "," + value[2]);
            this.setValue(this.form, "address3", value[4]);
            this.setValue(this.form, "addressCode", value[3]);

            this.form.markAsDirty();
        }
    }

    save = () => {
        if (this.form.valid) {
            let data = this.form.value;
            if (!data.orderNumber) {
                this.services.purchaseOrderApi.create(data)
                    .subscribe(purchaseOrder => {
                        this._appController.notice(`Created purchase order`);
                        this._router.navigate(["/purchase-order/", purchaseOrder.orderNumber]);
                    });
            } else {
                this.services.purchaseOrderApi.update(data.orderNumber, data)
                    .subscribe(purchaseOrder => {
                        this.form.patchValue(purchaseOrder);
                        this.statusChanged.emit(purchaseOrder.orderStatus);
                        this._appController.notice(`Updated purchase order`);
                    });
            }
        }
    }

    email = () => {
        this._purchaseOrderApi.email(this.form.value.orderNumber, this.form.value.contactEmail)
            .subscribe(() => {
                this._appController.notice("Email sent");
            });
    }

    print = () => {
        window.open(`https://prometheansociety.com/erp/reports/report?name=PurchaseOrder_Invoice&PurchaseOrder=${this.form.value.orderNumber}`, '_blank');
    }
}
