import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import DataSource from 'devextreme/data/data_source';

@Component({
  selector: 'app-modal-form-select',
  templateUrl: './modal-form-select.component.html',
  styleUrls: ['./modal-form-select.component.scss']
})
export class ModalFormSelectComponent implements OnInit {

  form: FormGroup;

  label: string;
  valueKey: string;
  labelKey: string;

  dataSource: DataSource | any[];
  editable = false;
  multiple = false;
  grouped = false;

  constructor(
    public dialogRef: MatDialogRef<ModalFormSelectComponent>,
    @Inject(MAT_DIALOG_DATA) data: any) {

    this.label = data.label;
    this.valueKey = data.valueKey;
    this.labelKey = data.labelKey;
    this.dataSource = data.dataSource;
    this.editable = data.editable || false;
    this.multiple = data.multiple || false;

    if (this.dataSource instanceof DataSource) {
      this.grouped = this.dataSource.group() !== undefined;
    }
  }

  ngOnInit() {
    this.form = new FormGroup({
      selectControl: new FormControl(null, [Validators.required])
    });
  }

  proceed() {
    if (this.form.valid) {
      this.dialogRef.close(this.form.value.selectControl);
    }
  }
}
