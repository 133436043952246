import { HttpParams } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ProjectTaskApi } from 'src/app/api/project-task.api';
import { AppConstant } from 'src/app/app.constant';
import { AppController } from 'src/app/app.controller';
import { Confirmation } from 'src/app/modal/modal-confirmation/confirmation';
import { ModalFormProjectStepTaskQuickAddComponent } from 'src/app/modal/modal-form-project/modal-form-project-step-task-quick-add/modal-form-project-step-task-quick-add.component';
import { BaseDataListing } from 'src/app/page/base-data-list.component';

@Component({
  selector: 'app-partial-project-step-task-list',
  templateUrl: './partial-project-step-task-list.component.html',
  styleUrls: ['./partial-project-step-task-list.component.scss']
})
export class PartialProjectStepTaskListComponent extends BaseDataListing implements OnInit {

  @Input() queryParams: any;

  constructor(
    private readonly _taskApi: ProjectTaskApi,
    private readonly appController: AppController) {
    super();

    this.actionMenu = [
      {
        label: "Mark as Not Applicable", callBack: (element: any) => {
          this.update(element, { "notApplicable": true });
        }
      },
      {
        label: "edit", callBack: (element: any) => {
          this.add(element);
        }
      },
      {
        label: "Delete", callBack: (element: any) => {
          this.appController.confirm(result => {
            if (result) {
              this.remove(element);
            }
          }, new Confirmation("delete_outline",
            `Are you sure you want delete task ${element.name}?`,
            "Cancel", "Delete")
          );
        }
      }
    ];
  }

  update(element: any, data: any) {
    this._taskApi.update(element.id, data).subscribe(() => {
      this.datasourceRemoveByKeyValue(this.dataSource, "id", element.id);
      this.appController.notice(`Successfully updated task ${element.name}`);
    });
  }

  remove(element: any) {
    this._taskApi.remove(element.id).subscribe(() => {
      this.appController.notice(`Successfully deleted task ${element.name}`);
      this.refresh();
    });
  }

  ngOnInit() {
    this.queryData(this.queryLimit, this.queryCurrentPage, true, this.querySort, this.queryFilter);
  }

  queryData(take: number, skip: number, count: boolean, sort: any[], filter?: any[]) {
    this.query = new HttpParams()
      .set("take", `${take}`)
      .set("skip", `${skip}`)
      .set("requireTotalCount", `${count}`)

    this.query = this.queryAdd(this.query, this.queryParams);
    this.query = this.queryAddSort(this.query, sort);
    this.query = this.queryAddFilter(this.query, filter);

    this._taskApi
      .findAll(this.query)
      .subscribe((results: any) => {
        this.dataSource = results;
      });
  }

  get columnLabels(): string[] {
    return [
      "",
      "Started",
      "Complete",
      "ETA",
      "Start Date",
      "End Date",
      "User",
    ];
  }

  get columnKeys(): string[] {
    return [
      "name",
      "started",
      "complete",
      "eta|date|DD/MM/YYYY",
      "startedDate|date|DD/MM/YYYY",
      "completeDate|date|DD/MM/YYYY",
      "user|avatar",
      "action"
    ];
  }

  add(subject?: any) {
    this.appController.openModal(() => {
      this.refresh();
    }, ModalFormProjectStepTaskQuickAddComponent, {
      width: AppConstant.SIZE.MODAL_MEDIUM,
      data: {
        queryParams: this.queryParams, subject: subject
      }
    })
  }
}
