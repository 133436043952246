import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DxFileUploaderComponent } from 'devextreme-angular';
import { ApiService } from 'src/app/api/api.service';
import { CommissionApi } from 'src/app/api/commission.api';
import { DealerApi } from 'src/app/api/dealer.api';
import { DepartmentApi } from 'src/app/api/department.api';
import { UserPasswordApi } from 'src/app/api/user-password.api';
import { UserRoleApi } from 'src/app/api/user-role.api';
import { UserApi } from 'src/app/api/user.api';
import { AppController } from 'src/app/app.controller';
import { BaseForm } from 'src/app/page/base-form.component';
import { SecurityService } from 'src/app/service/security.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent extends BaseForm implements OnInit {

  @ViewChild(DxFileUploaderComponent) uploader: DxFileUploaderComponent;

  form: FormGroup;
  roles: any[];
  vehicles: string[] = ["Company", "Own", "None"];
  departments: any[];
  dealers: any[];
  managers: any[];
  apis: { [key: string]: ApiService };

  constructor(
    private readonly _userApi: UserApi,
    private readonly _userRoleApi: UserRoleApi,
    public readonly appController: AppController,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _formBuilder: FormBuilder,
    private readonly _departmentApi: DepartmentApi,
    private readonly _dealerApi: DealerApi,
    private readonly _userPasswordApi: UserPasswordApi,
    private readonly _commissionApi: CommissionApi,
    private readonly _router: Router,
    public readonly securityService: SecurityService) {
    super();

    this.apis = {
      dealerApi: _dealerApi,
      departmentApi: _departmentApi,
      commissionApi: _commissionApi,
      userRoleApi: _userRoleApi,
      userPasswordApi: _userPasswordApi
    }
  }

  ngOnInit() {
    this.form = this._formBuilder.group({
      id: [null],
      // password: [null],
      name: [null, [Validators.required]],
      image: this._formBuilder.group({
        image: [null]
      }),
      imageUrl: [null],
      profile: this._formBuilder.group({
        jobTitle: [null],
        bio: [null],
        phoneExtension: [null],
        phoneNumber: [null],
        dealer: [null],
        dealerId: [null],
        departmentId: [null],
        department: [null],
        managerId: [null],
        manager: [null],
        vechicle: [null],
        salary: [null],
        birthday: [null],
        vehicle: [null],
        dashboard: [null],
        allowNotifications: [false]
      }),

      //needed for password change, FIXME: add and remove based on tab changes
      passwordChange: this._formBuilder.group({
        currentPassword: [null],
        newPassword: [null],
        confirmNewPassword: [null]
      }),

      email: [null, [Validators.required]],
      role: [null],
    });

    this.getDealers();
    this.getDepartments();
    this.getRoles();

    this._activatedRoute.params.subscribe((params) => {
      if (params.id) {
        this._userApi.find(params.id).subscribe(user => {
          if (user) {
            if (!user.image) {
              delete user.image;
            }

            user.imageUrl = user.image;
            user.role = user.roles[0].role.name;
            this.form.patchValue(user);
          }
        });
      }
    });

    this.form.get("profile.departmentId").valueChanges
      .subscribe((departmentId) => {
        if (this.departments) {
          for (var i = 0; i < this.departments.length; i++) {
            if (this.departments[i].id === departmentId) {
              this.getDepartmentManagerUserById(this.departments[i].managerUserId);
            }
          }
        }
      });
  }

  onFileUploaded(e: { value?: File[] }) {
    if (e.value && e.value[0]) {
      var file = e.value[0];

      var fr = new FileReader();
      fr.onload = args => {
        var content = args.target.result as string;
        var base64 = content.split(',')[1];
        this.form.get("image").get("image").setValue(base64);
        this.form.get("image").markAsDirty();
        this.form.get("imageUrl").setValue(content);
      };
      fr.readAsDataURL(file);
    }
  }

  getRoles() {
    this.apis.userRoleApi.findAll().subscribe((results) => {
      this.roles = results.data;
    });
  }

  getDealers() {
    this._dealerApi.findAll().subscribe((results) => {
      this.dealers = results.data;
    });
  }

  getDepartments() {
    this._departmentApi.findAll().subscribe((results) => {
      this.departments = results.data;
    });
  }

  getDepartmentManagerUserById(userId: any) {
    this._userApi.find(userId).subscribe((results) => {
      this.managers = results.data;
    });
  }

  changePassword() {
    var passwordForm = this.form.get('passwordChange');
    if (passwordForm.valid) {
      let data = passwordForm.value;

      (this.apis.userPasswordApi as UserPasswordApi).update(data).subscribe(() => {
        passwordForm.reset();
        this.appController.notice("Password updated");
      }, (error) => {
        this.appController.notice(`Password update failed, possibly incorrect current password`);
      })
    }
  }

  save() {
    if (this.form.valid) {
      let data = this.form.value;
      if (data.image?.image === null) {
        delete data.image;
      }

      if (!data.id) {
        this._userApi.create(data)
          .subscribe(() => {
            this.appController.notice(`Created user`);
            this.uploader.instance.reset();
          });
      } else {
        this._userApi.update(data.id, data)
          .subscribe(user => {
            //if (!user.image) {
            //  delete user.image;
            //}

            this.appController.notice(`Updated user`);
            this.uploader.instance.reset();
          });
      }
    }
  }

  cancel() {
    this._router.navigate(["/settings/user/list"]);
  }
}
