import { HttpParams } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api/api.service';
import { AppConstant } from 'src/app/app.constant';
import { AppController } from 'src/app/app.controller';
import { AppUtility } from 'src/app/app.utility';
import { Confirmation } from 'src/app/modal/modal-confirmation/confirmation';
import { ModalFormServiceLevelAgreementPlanEscalationComponent } from 'src/app/modal/modal-form-service-level-agreement-plan/modal-form-service-level-agreement-plan-escalation/modal-form-service-level-agreement-plan-escalation.component';
import { BaseDataListing } from 'src/app/page/base-data-list.component';

@Component({
  selector: 'app-partial-service-level-agreement-escalation',
  templateUrl: './partial-service-level-agreement-escalation.component.html',
  styleUrls: ['./partial-service-level-agreement-escalation.component.scss']
})
export class PartialServiceLevelAgreementEscalationComponent extends BaseDataListing implements OnInit {

  @Input() services: { [key: string]: ApiService };
  @Input() subject: any;
  @Input() queryParams: any;

  constructor(
    public readonly appController: AppController,
    public readonly appUtility: AppUtility) {
    super();

    this.actionMenu = [
      {
        label: "Edit", callBack: (element: any) => {
          this.add(element);
        }
      },
      {
        label: "Delete", callBack: (element: any) => {
          this.appController.confirm((proceed) => {
            if (proceed) {
              console.debug("delete", element);
              this.services.serviceLevelAgreementEscalationApi.remove(element.id).subscribe((results) => {
                console.debug("deleted ", results);
                // this.datasourceRemoveByKeyValue(this.dataSource, "id", element.id);
                this.refresh();
                this.appController.notice(`Successfully delete SLA Plan Escalation`);
              }, (error) => {
                console.error("failed to delete ", error);
                this.appController.notice(`Failed to delete SLA Plan Escalation`);
              });
            }
          }, new Confirmation("delete_outline",
            `Are you sure you want delete SLA Plan Escalation?`,
            "Cancel", "Delete")
          );
        }
      }
    ];
  }

  ngOnInit() {
    this.queryData(this.queryLimit, this.queryCurrentPage, true, this.querySort, this.queryFilter);
  }

  queryData(take: number, skip: number, count: boolean, sort: any[], filter?: any[]) {
    this.query = new HttpParams()
      .set("take", `${take}`)
      .set("skip", `${skip}`)
      .set("requireTotalCount", `${count}`)

    this.query = this.queryAdd(this.query, this.queryParams);
    this.query = this.queryAddSort(this.query, sort);
    this.query = this.queryAddFilter(this.query, filter);

    console.debug("query ", this.query.toString());

    this.services.serviceLevelAgreementEscalationApi
      .findAll(this.query)
      .subscribe((results: any) => {
        console.debug(" commision", results);
        this.dataSource = results;
      }, (error) => {
        console.debug("failed to obtain  commision", error);
      });
  }

  onSelect(selected: any[]): void {
    console.debug("item selected", selected);
  }

  get columnLabels(): string[] {
    return [
      "Wait Time",
      "Wait Time Unit",
      "Notify User",
      ""
    ];
  }

  get columnKeys(): string[] {
    return [
      "waitTime",
      "waitTimeUnit",
      "notifyUser.name",
      "action"
    ];
  }

  add(element?: any) {
    this.appController.openModal((results: any) => {
      console.debug("add SLA Plan Target", results);
      this.save(results);
    }, ModalFormServiceLevelAgreementPlanEscalationComponent,
      {
        width: AppConstant.SIZE.MODAL_MEDIUM,
        height: AppConstant.SIZE.MODAL_MEDIUM,
        data: { subject: element, services: this.services, queryParams: { servicePlanId: this.subject.id } }
      });
  }

  save(subject: any) {
    if (subject) {
      if (!subject.id) {
        this.services.serviceLevelAgreementEscalationApi.create(subject).subscribe((results: any) => {
          console.debug("saved SLA Plan", results);
          this.appController.notice(`Successfully created SLA Plan Escalation`);
          this.refresh();
        }, (error) => {
          console.error("failed to save SLA", error);
          this.appController.notice("Failed to save SLA Plan Escalation");
        });
      } else {
        this.services.serviceLevelAgreementEscalationApi.update(subject.id, this.appUtility.omit(subject, ["id"])).subscribe((results: any) => {
          console.debug("updated SLA", results);
          this.appController.notice(`Successfully updated SLA Plan Escalation`);
          this.refresh();
        }, (error) => {
          console.error("failed to save SLA Plan Escalation", error);
          this.appController.notice("Failed to save SLA Plan Escalation");
        });
      }
    }
  }

}
