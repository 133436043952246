import { ComponentType } from '@angular/cdk/portal';
import { EventEmitter, Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppConstant } from './app.constant';
import { Confirmation } from './modal/modal-confirmation/confirmation';
import { ModalConfirmationComponent } from './modal/modal-confirmation/modal-confirmation.component';
import { ModalFormInputComponent } from './modal/modal-form-input/modal-form-input.component';

@Injectable()
export class AppController {
  public readonly notificationsRead = new EventEmitter();

  private _loading = false;

  constructor(
    private readonly _matSnackBar: MatSnackBar,
    private readonly _dialog: MatDialog) {
  }

  public openModal<TComponent, TResult>(onClose: (result?: TResult) => void, component: ComponentType<TComponent>, config: MatDialogConfig<any> = {}): Promise<TResult> {
    config.disableClose = config.disableClose ?? true;
    config.position = { top: "45px" };

    return new Promise(resolve => {
      const modal = this._dialog.open<TComponent, any, any>(component, config);
      modal.afterClosed().subscribe(result => {
        onClose?.call(this, result);
        resolve(result);
      });
    });
  }

  public getInput(label: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.openModal((result: string) => {
        if (result) {
          resolve(result);
        } else {
          reject();
        }
      }, ModalFormInputComponent, {
        width: AppConstant.SIZE.MODAL_SMALL,
        data: { label: label }
      });
    });
  }

  public confirm(onClose: (result: boolean) => void, confirmation: Confirmation) {
    return this.openModal(onClose, ModalConfirmationComponent, {
      width: "450px",
      height: "400px",
      data: confirmation
    });
  }

  public confirmV2(confirmation: Confirmation): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.openModal((result: boolean) => {
        if (result) {
          resolve(result);
        } else {
          reject();
        }
      }, ModalConfirmationComponent, {
        width: "450px",
        height: "400px",
        data: confirmation
      });
    });
  }

  public notice(message: string, action?: string, duration = 3000) {
    return this._matSnackBar.open(message, action, {
      duration: duration,
    });
  }

  public error(error: any) {
    if (error.errors) {
      error = error.errors[Object.keys(error.errors)[0]][0];
    }

    this.notice(error);
  }

  set loading(loading: boolean) {
    setTimeout(() => {
      this._loading = loading;
    }, 250);
  }

  get loading(): boolean {
    return this._loading;
  }
}
