import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export class HttpService {
  constructor(private readonly httpClient: HttpClient) {
  }

  public getEndpoint(path: string) {
    return HttpService.getEndpoint(path);
  }

  public static getEndpoint(path: string) {
    return environment.api_erp_host + path;
  }

  protected get<T>(endpoint: string, options?: any) {
    return this.httpClient
      .get<T>(endpoint, options)
      .pipe(catchError(this.handleError.bind(this)));
  }

  protected post<T>(endpoint: string, body: any, options?: any) {
    return this.httpClient
      .post<T>(endpoint, body, options)
      .pipe(catchError(this.handleError.bind(this)));
  }

  protected put<T>(endpoint: string, body: any, options?: any) {
    return this.httpClient
      .put<T>(endpoint, body, options)
      .pipe(catchError(this.handleError.bind(this)));
  }

  protected delete<T>(endpoint: string, options?: any) {
    return this.httpClient
      .delete<T>(endpoint, options)
      .pipe(catchError(this.handleError.bind(this)));
  }

  private handleError(error: HttpErrorResponse) {
    return throwError(error.error);
  }
}
