import { HttpParams } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserApi } from 'src/app/api/user.api';
import { AppController } from 'src/app/app.controller';

@Component({
  selector: 'app-modal-form-service-level-agreement-plan-escalation',
  templateUrl: './modal-form-service-level-agreement-plan-escalation.component.html',
  styleUrls: ['./modal-form-service-level-agreement-plan-escalation.component.scss']
})
export class ModalFormServiceLevelAgreementPlanEscalationComponent implements OnInit {

  private readonly subject: any;
  private readonly queryParams: any;

  form: FormGroup;

  timeUnits: string[] = ["min", "hour", 'day', "month"];
  users: any[];

  constructor(
    public dialogRef: MatDialogRef<ModalFormServiceLevelAgreementPlanEscalationComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private readonly _userApi: UserApi,
    private readonly _formBuilder: FormBuilder,
    public readonly appController: AppController) {

    this.subject = data.subject;
    this.queryParams = data.queryParams;
  }

  ngOnInit() {
    this.getUsers();

    this.form = this._formBuilder.group({
      id: [null],
      servicePlanId: [this.queryParams.servicePlanId, [Validators.required]],
      waitTime: [null, [Validators.required]],
      waitTimeUnit: [null, [Validators.required]],
      notifyUserId: [null, [Validators.required]],
    });

    if (this.subject) {
      this.form.patchValue(this.subject);
    }
  }

  getUsers() {
    this._userApi.findAll(new HttpParams().set("select", JSON.stringify(["id", "name"]))).subscribe((results) => {
      console.debug("results", results);
      this.users = results.data;
    });
  }

  save() {
    if (this.form.valid) {
      this.dialogRef.close(this.form.value);
    }
  }
}
