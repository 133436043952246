import { HttpParams } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import DataSource from 'devextreme/data/data_source';
import { CustomerContractApi } from 'src/app/api/customer-contract.api';
import { InventoryReadingHardwareApi } from 'src/app/api/inventory-reading-hardware.api';
import { AppController } from 'src/app/app.controller';
import { AppData } from 'src/app/app.data';

@Component({
    selector: 'app-modal-form-inventory-reading-hardware',
    templateUrl: './modal-form-inventory-reading-hardware.component.html',
    styleUrls: ['./modal-form-inventory-reading-hardware.component.scss']
})
export class ModalFormInventoryReadingHardwareComponent implements OnInit {

    public readonly customers = new DataSource(AppData.customers());
    private readonly subject: any;

    form: FormGroup;

    updatable: boolean = false;
    contracts: any[];
    meters: any[];
    code: string;

    constructor(
        public dialogRef: MatDialogRef<ModalFormInventoryReadingHardwareComponent>,
        @Inject(MAT_DIALOG_DATA) data: any,
        private readonly _customerContractApi: CustomerContractApi,
        private readonly _inventoryReadingHardwareApi: InventoryReadingHardwareApi,
        private readonly _formBuilder: FormBuilder,
        public readonly appController: AppController) {

        this.subject = data.subject;
    }

    ngOnInit() {
        this.form = this._formBuilder.group({
            id: [null],
            account: [null, [Validators.required]],
            serial: [null, [Validators.required]],
            meter: [null, [Validators.required]],
            date: [null, [Validators.required]],
            previousReading: [null, [Validators.required]],
            currentReading: [null, [Validators.required]],
            invoice: [null]
        });

        if (this.subject) {
            this.form.patchValue(this.subject);
            // this.updatable = true;
        }

        this.form.get("account").valueChanges
            .subscribe((account) => {
                this._customerContractApi
                    .findAll(new HttpParams().set("account", account), "hardware")
                    .subscribe((results) => {
                        this.contracts = results.data;
                    });
            });

        this.form.get("serial").valueChanges
            .subscribe((serial) => {
                this._customerContractApi
                    .findAll(new HttpParams().set("serial", serial).set("code", this.code), "hardware/meters")
                    .subscribe((results) => {
                        this.meters = results.data;
                    });
            });
    }

    onSerialChanged(e, contract) {
        this.code = contract.code;
    }

    save() {
        if (this.form.valid) {
            let data = this.form.value;
            if (!data.id) {

                this._inventoryReadingHardwareApi.create(data).subscribe((results) => {
                    // this.updatable = true;
                    this.dialogRef.close(results);
                    this.appController.notice(`Inventory Reading created`);
                }, (error) => {
                    this.appController.notice(`Failed to save hardware reading`);
                });
            } else {
                this._inventoryReadingHardwareApi.update(data.id, data).subscribe((results) => {
                    // this.updatable = true;
                    this.dialogRef.close(results);
                    this.appController.notice(`Inventory Reading updated`);
                }, (error) => {
                    this.appController.notice(`Failed to update hardware reading`);
                });
            }
        }
    }
}
