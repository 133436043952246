import { Component } from '@angular/core';
import { Router } from '@angular/router';
import DataSource from 'devextreme/data/data_source';
import dxAutocomplete from 'devextreme/ui/autocomplete';
import { ApiService } from 'src/app/api/api.service';
import { AppData } from 'src/app/app.data';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'global-search',
    templateUrl: './global-search.component.html',
    styleUrls: ['./global-search.component.scss']
})
export class GlobalSearchComponent {

    readonly dataSource = new DataSource({
        store: AppData.createStore(null, {
            loadUrl: ApiService.getEndpoint(environment.api_erp_search)
        }),
        group: "type"
    });

    constructor(private readonly _router: Router) {
    }

    onItemClick(e: { component: dxAutocomplete; itemData: any; }) {
        if (e.itemData) {
            switch (e.itemData.type) {
                case "Customer":
                    this._router.navigate(["/customer/", e.itemData.key]);
                    break;
                case "Lead":
                    this._router.navigate(["/customer/lead/", e.itemData.key]);
                    break;
                case "Supplier":
                    //this.router.navigate(["/supplier/list/", id]);
                    break;
                case "Inventory":
                    this._router.navigate(["/inventory/", e.itemData.key]);
                    break;
                case "Ticket":
                    this._router.navigate(["/support/ticket/", e.itemData.key]);
                    break;
            }
        }

        setTimeout(() => e.component.reset());
    }
}
