import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';

@Injectable()
export class QuoteApi extends ApiService {
  constructor(private readonly _httpClient: HttpClient) {
    super(environment.api_quotes_quotes, _httpClient);
  }

  public createByTypeAndLeadId(data: any, quoteType: string, leadId: number) {
    var apiEndpoint = this.getEndpoint(`${environment.api_quotes}/${quoteType}/create?leadid=${leadId}`);
    return this.post(
      apiEndpoint,
      this.formEncoded(data),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
      });
  }

  public updateByTypeAndLeadId(id: number, data: any, quoteType: string) {
    var apiEndpoint = this.getEndpoint(`${environment.api_quotes}/${quoteType}/update/${id}`);
    return this.post(
      apiEndpoint,
      this.formEncoded(data),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
      });
  }

  public remove(id: string | number) {
    var apiEndpoint = this.getEndpoint(environment.api_quotes_quotes);
    return this.delete(
      apiEndpoint,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        },
        body: new HttpParams().set("key", id.toString()).toString()
      });
  }

  public duplicate(id: number, quoteType: string): Promise<number> {
    return new Promise((resolve, reject) => {
      var handleRedirect = (error: HttpErrorResponse) => {
        var index = error.url.lastIndexOf("/");
        var id = error.url.substr(index + 1);
        resolve(parseFloat(id));
        return EMPTY;
      };

      var apiEndpoint = this.getEndpoint(`${environment.api_quotes}/${quoteType}/duplicate/${id}`);
      return this._httpClient
        .get(apiEndpoint, { observe: 'response' })
        .pipe(
          catchError(handleRedirect.bind(this))
        ).subscribe(response => {
          var id = response.headers.get("x-quote");
          resolve(parseFloat(id));
        }, error => {
            reject(error);
        });
    });
  }

  public convertToProject(quoteId: number, customer: string | null, projectId: number | null) {
    var apiEndpoint = this.getEndpoint(`${environment.api_quotes_convert}/${quoteId}/convert`);
    return this.post(apiEndpoint, { customer: customer, projectId: projectId });
  }

  public assignUser(quoteId: number, userId: string) {
    var apiEndpoint = this.getEndpoint(`${environment.api_quotes_move}/?id=${quoteId}&userId=${userId}`);
    return this.post(apiEndpoint, null);
  }

  private formEncoded(data: any): string {
    const body = new URLSearchParams();

    Object.keys(data)
      .forEach((key) => {
        if (data[key] instanceof Array) {
          (data[key] as any[]).forEach((element, index) => {
            (Object.keys(element) as []).forEach((dataSetKey: string) => {
              body.append(`${key}[${index}][${dataSetKey}]`, element[dataSetKey]);
            });
          });

        } else if (data[key] instanceof Object) {
          (Object.keys(data[key]) as []).forEach((dataSetKey: string) => {
            body.append(`${key}[0][${dataSetKey}]`, data[key][dataSetKey]);
          });
        }
        else {
          body.append(key, data[key]);
        }
      });

    return body.toString();
  }
}
