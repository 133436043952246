import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';

@Injectable()
export class QuoteLookupTermApi extends ApiService {
    constructor(httpClient: HttpClient) {
        super(environment.api_erp_quotes_lookup_terms, httpClient);
    }
}
