import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import DataSource from 'devextreme/data/data_source';
import { ApiService } from 'src/app/api/api.service';
import { InventoryMovementApi } from 'src/app/api/inventory-movement.api';
import { InventoryApi } from 'src/app/api/inventory.api';
import { AppController } from 'src/app/app.controller';
import { AppData } from 'src/app/app.data';
import { ModalFormInventoryMovementComponent } from 'src/app/modal/modal-form-inventory/modal-form-inventory-movement/modal-form-inventory-movement.component';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-partial-inventory-movement-adjustment',
    templateUrl: './partial-inventory-movement-adjustment.component.html',
    styleUrls: ['./partial-inventory-movement-adjustment.component.scss']
})
export class PartialInventoryMovementAdjustmentComponent implements OnInit {

    @Input() code: string;
    @Input() defaultWarehouse: string;
    @Input() dialogRef: MatDialogRef<ModalFormInventoryMovementComponent>;

    public readonly warehouses = new DataSource(AppData.warehouses(true));
    public bins: DataSource;
    public inventory: DataSource;
    public serials: DataSource;

    form: FormGroup;
    showBins: boolean;
    showSerials: boolean;

    constructor(
        private readonly _inventoryApi: InventoryApi,
        private readonly _inventoryMovementApi: InventoryMovementApi,
        private readonly _formBuilder: FormBuilder,
        private readonly appController: AppController) {
    }

    ngOnInit() {
        this.form = this._formBuilder.group({
            warehouse: [null, [Validators.required]],
            bin: [null],
            code: [{ value: null, disabled: true }, [Validators.required]],
            quantity: [{ value: null, disabled: true }, [Validators.required]],
            unitCost: [0, [Validators.required]],
            enteredCost: [null, [Validators.required]],
            reference: [null, [Validators.required]],
            referenceDate: [null],
            notes: [null],
            serials: [null],
        });

        this.form.get("warehouse").valueChanges
            .subscribe((warehouse: string) => {
                this.inventory = new DataSource({
                    ...AppData.inventory(warehouse),
                    ...{
                        filter: this.code ? ["code", this.code] : null
                    }
                });

                this.form.get("code").setValue(this.code);
                this.form.get("code").enable();

                this.warehouses.store().byKey(warehouse).then(warehouse => {
                    this.showBins = warehouse.multipleBins;
                });

                this.bins = new DataSource(AppData.bins(warehouse));
            });

        this.form.get("bin").valueChanges
            .subscribe((bin: string) => {
                this.form.get("serials").setValue(null);

                if (this.form.value.code) {
                    this.serials?.filter(["bin", bin]);
                    this.serials?.reload();
                }
            });

        this.form.get("code").valueChanges
            .subscribe((code: string) => {
                if (!code) {
                    return;
                }

                this.form.get("quantity").enable();
                this.form.get("serials").setValue(null);

                this.inventory.store().byKey(code).then(product => {
                    this.showSerials = product.serialized;
                });

                this._inventoryApi.unitCost(this.form.value.warehouse, code).subscribe((cost: number) => {
                    this.form.patchValue({
                        unitCost: cost || 0,
                        enteredCost: cost || 0
                    });
                });
            });

        this.form.get("quantity").valueChanges
            .subscribe((quantity: number) => {
                if (quantity < 0) {
                    this.serials = new DataSource({
                        store: AppData.createStore(null, {
                            loadUrl: ApiService.getEndpoint(environment.api_erp_inventory_serials),
                            loadParams: {
                                warehouse: this.form.value.warehouse,
                                code: this.form.value.code,
                            }
                        }),
                        filter: this.form.value.bin ? ["bin", this.form.value.bin] : null
                    });
                }
            });

        if (this.defaultWarehouse) {
            this.form.get("warehouse").setValue(this.defaultWarehouse);
        }
    }

    onCustomItemCreating(e) {
        var currentItems = e.component.option("items");
        currentItems.unshift(e.text);

        e.component.option("items", currentItems);
        e.customItem = { serial: e.text };
    }

    save() {
        if (this.form.valid) {
            this._inventoryMovementApi.adjustment(this.form.value)
                .subscribe(() => {
                    this.appController.notice("Adjustment successful.");
                    this.dialogRef.close();
                }, (error) => {
                    this.appController.error(error);
                });
        }
    }
}
