import { Component, Input, OnInit } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { AppData } from 'src/app/app.data';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-partial-invoice-dashboard',
    templateUrl: './partial-invoice-dashboard.component.html',
    styleUrls: ['./partial-invoice-dashboard.component.scss']
})
export class PartialInvoiceDashboardComponent implements OnInit {
    @Input() account: string;

    dataSource: DataSource;
    balances: any = {};

    ngOnInit() {
        this.dataSource = new DataSource({
            store: AppData.createStore(["account", "invoice", "documentType"], environment.api_erp_invoices, {
                loadParams: {
                    account: this.account
                }
            })
        });

        AppData.query(environment.api_erp_invoices_balances, { account: this.account }).then(result => {
            this.balances = result;
        });
    }
}
