import { Component } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import dxDataGrid from 'devextreme/ui/data_grid';
import { AppController } from 'src/app/app.controller';
import { AppData } from 'src/app/app.data';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dealer-list',
  templateUrl: './dealer-list.component.html',
  styleUrls: ['./dealer-list.component.scss']
})
export class DealerListComponent {
  public readonly dataSource = new DataSource(AppData.createStore("id", environment.api_erp_dealers));

  constructor(private readonly _appController: AppController) {
  }

  edit(e: { component: dxDataGrid; rowIndex: number }) {
    e.component.editRow(e.rowIndex);
  }

  delete(e: { component: dxDataGrid; rowIndex: number }) {
    AppData.deleteV2(this._appController, e,);
  }
}
