import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import { QuoteDataApi } from 'src/app/api/quote-data.api';

@Component({
    selector: 'app-partial-quotation-rate',
    templateUrl: './partial-quotation-rate.component.html',
    styleUrls: ['./partial-quotation-rate.component.scss']
})
export class PartialQuotationRateComponent implements OnInit {

    @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;

    @Input() subject: any;
    @Input() calculate: EventEmitter<void>;
    @Output() changed = new EventEmitter<any>();

    public selectableRates: any[];
    public dataSource: DataSource;

    constructor(private readonly _quoteDataApi: QuoteDataApi) {
    }

    ngOnInit() {
        this.dataSource = new DataSource({
            store: new ArrayStore({
                data: this.subject?.rates || []
            }),
            reshapeOnPush: true
        });

        this.getRates();

        this.calculate.subscribe(async () => {
            await this.dataGrid.instance.refresh();
            this.dataGridChanged();
        });
    }

    add() {
        this.dataGrid.instance.addRow();
    }

    onCustomItemCreating(e) {
        e.customItem = { description: e.text };
    }

    getRates() {
        this._quoteDataApi.getRates().subscribe(results => {
            this.selectableRates = results["supreme"];

            if (!this.subject) {
                this.addDefaults(results);
            }
        });
    }

    addDefaults(results: any) {
        var defaultRates = (results["fixed"] as any[]).map(item => ({
            description: item.description,
            saving: item.saving,
            discount: item.discount,
            currentRate: item.telkom || 0,
            currentCost: 0,
            esaRate: item.esa || 0,
            esaCost: 0
        }));

        defaultRates.push({
            description: "-",
            saving: 0,
            discount: 0,
            currentRate: 0,
            currentCost: 0,
            esaRate: 0,
            esaCost: 0
        });

        var changes = defaultRates.map(rate => ({ type: "insert", data: rate }));
        this.dataSource.store().push(changes);
    }

    esaRateDisplayValue(e) {
        if (e.discount) {
            return e.discount * 100 + "%";
        }

        return e.esaRate;
    }

    esaCostCellValue(e) {
        if (e.discount) {
            if (e.currentRate > 0) {
                return e.currentRate * e.discount;
            }

            return e.currentCost * e.discount;
        }

        if (e.currentCost && e.currentRate > 0) {
            var unit = e.currentCost / e.currentRate;
            var tmp = unit - (unit * e.saving || 0);

            return e.esaRate * tmp;
        }

        return e.esaCost || 0;
    }

    callRateSelectionChanged(e, info) {
        if (e.selectedItem) {
            info.data.description = e.selectedItem.description;
            info.data.discount = e.selectedItem.discount;

            info.data.currentRate = 0;
            info.data.currentCost = e.selectedItem.price;
            info.data.esaRate = 0;
        }
    }

    dataGridChanged() {
        this.dataSource.items().forEach((row, index) => {
            var value = this.dataGrid.instance.cellValue(index, "esaCost");
            this.dataSource.store().update(row, { esaCost: value });
        });

        this.changed.emit({
            rates: this.dataSource.items(),
            current: this.dataGrid.instance.getTotalSummaryValue("currentCost"),
            esa: this.dataGrid.instance.getTotalSummaryValue("esaCost")
        });
    }
}
