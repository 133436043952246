import { HttpParams } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { QuoteLookupReportApi } from 'src/app/api/quote-lookup-report.api';
import { QuoteLookupTermApi } from 'src/app/api/quote-lookup-term.api';
import { FormGroupService } from 'src/app/service/form.service';
import { SecurityService } from 'src/app/service/security.service';

@Component({
  selector: 'app-partial-form-quote-default',
  templateUrl: './partial-form-quote-default.component.html',
  styleUrls: ['./partial-form-quote-default.component.scss']
})
export class PartialFormQuoteDefaultComponent implements OnInit {

  reportTypes: string[];
  terms: any[];

  @Input() subject: any;

  constructor(
    private readonly _formBuilder: FormBuilder,
    private readonly _quoteLookupTerms: QuoteLookupTermApi,
    private readonly _quoteLookupReportApi: QuoteLookupReportApi,
    public readonly securityService: SecurityService,
    public readonly formGroupService: FormGroupService) {
  }

  ngOnInit() {
    this.getQuoteTerms();
    this.getReportTypes(this.formGroupService.getValue("quoteType"));

    var updateTerms = (terms) => {
      var description = this.terms.find(_ => _.name === terms).description;

      var settlement = this.formGroupService.getFloat("settlement");
      if (settlement > 0) {
        var settlementName = this.formGroupService.getValue("settlementName");
        description += " In lieu of fully furnished order ESA will settle " + settlementName + " to the amount of R" + settlement + ".";
      }

      this.formGroupService.formGroup.get("termsDescription").setValue(description);
    };

    this.formGroupService.mergeFormControls(this.getForm(this.subject));
    this.formGroupService.formGroup.get("terms").valueChanges
      .subscribe(terms => {
        updateTerms(terms);
      });

    this.formGroupService.formGroup.get("settlement").valueChanges
      .subscribe(() => {
        var terms = this.formGroupService.getValue("terms");
        updateTerms(terms);
      });
  }

  private getForm(subject?: any): FormGroup {
    let formGroup = this._formBuilder.group({
      quoteMaster: [null],
      quoteName: [null],
      report: [null, [Validators.required]],
      masterType: [false],
      approved: [false],
      surveyed: [false],
      siteSurveyComment: [null],
      complete: [false],
      termsType: [null, [Validators.required]],
      terms: [null, [Validators.required]],
      termsDescription: [null, [Validators.required]],
      margin: [0.0],
      marginType: ['Value'],
      escalation: [null],
      period: [null],
      factor: [null],
      settlement: [0.0],
      settlementName: [null]
    });

    if (subject) {
      formGroup.patchValue(subject)
    }

    return formGroup;
  }

  private getReportTypes(quoteType: string) {
    this._quoteLookupReportApi
      .findAll(new HttpParams().set("type", quoteType))
      .subscribe((results) => {
        this.reportTypes = results.data;
      });
  }

  private getQuoteTerms() {
    this._quoteLookupTerms.findAll().subscribe((results) => {
      this.terms = results.data;
    });
  }

  public onUploaded(e: { value?: File[] }) {
    if (e.value && e.value[0]) {
      var file = e.value[0];

      var fr = new FileReader();
      fr.onload = args => {
        var content = args.target.result as string;
        var base64 = content.split(',')[1];
        this.formGroupService.setValue("companyLogo", base64);
      };
      fr.readAsDataURL(file);
    }
  }
}
