import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';

@Injectable()
export class PurchaseOrderLineApi extends ApiService {
  constructor(httpClient: HttpClient) {
    super(environment.api_erp_purchase_order_lines, httpClient);
  }

  public receive(orderNumber: string, data: any[]) {
    var apiEndpoint = `${this.getEndpoint(environment.api_erp_purchase_order_receive)}/${orderNumber}`;
    return this.post(
      apiEndpoint,
      data);
  }
}
