import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';

@Injectable()
export class TemplateProjectApi extends ApiService {
  constructor(httpClient: HttpClient) {
    super(environment.api_erp_project_template, httpClient);
  }

  public customer(account: string, templateId: number) {
    var apiEndpoint = this.endpoint + "/customer";
    return this.post(apiEndpoint, { account: account, templateId: templateId });
  }
}
