import { Component, Input, OnInit } from '@angular/core';
import { AppData } from 'src/app/app.data';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-partial-contract-calculation',
    templateUrl: './partial-contract-calculation.component.html'
})
export class PartialContractCalculationComponent implements OnInit {

    @Input() contractId: number;

    public calc: any

    async ngOnInit() {
        this.calc = await AppData.query(environment.api_erp_customer_contract_calculation, { contractId: this.contractId });;
    }
}
