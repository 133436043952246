import { Component } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { UserApi } from 'src/app/api/user.api';
import { AppConstant } from 'src/app/app.constant';
import { AppController } from 'src/app/app.controller';
import { AppData } from 'src/app/app.data';
import { ModalFormUserQuickAddComponent } from 'src/app/modal/modal-form-user-quick-add/modal-form-user-quick-add.component';
import { ModalSelectUserComponent } from 'src/app/modal/modal-select-user/modal-select-user.component';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-user-list',
    templateUrl: './user-list.component.html',
    styleUrls: ['./user-list.component.scss']
})
export class UserListComponent {

    public readonly dataSource = new DataSource(AppData.createStore("id", environment.api_erp_user));

    constructor(
        private readonly _userApi: UserApi,
        public readonly appController: AppController) {
    }

    moveTickets(user) {
        this.appController.openModal((userId: string) => {
            if (userId) {
                this._userApi.moveTickets(user.id, userId).subscribe(() => {
                    this.appController.notice("Tickets moved.");
                });
            }
        }, ModalSelectUserComponent, {
            width: AppConstant.SIZE.MODAL_SMALL
        });
    }

    moveLeads(user) {
        this.appController.openModal((userId: string) => {
            if (userId) {
                this._userApi.moveLeads(user.id, userId).subscribe(() => {
                    this.appController.notice("Leads moved.");
                });
            }
        }, ModalSelectUserComponent, {
            width: AppConstant.SIZE.MODAL_SMALL
        });
    }

    add() {
        this.appController.openModal(() => {
            this.dataSource.reload();
        }, ModalFormUserQuickAddComponent, {
            width: AppConstant.SIZE.MODAL_MEDIUM
        });
    }
}
