import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import query from 'devextreme/data/query';
import { QuoteDataApi } from 'src/app/api/quote-data.api';
import { QuoteInfrastructure } from 'src/app/model/quote';
import { MultiSelect } from "../MultiSelect";

@Component({
    selector: 'app-partial-quotation-infrastructure',
    templateUrl: './partial-quotation-infrastructure.component.html',
    styleUrls: ['./partial-quotation-infrastructure.component.scss']
})
export class PartialQuotationInfrastructureComponent extends MultiSelect implements OnInit {

    @Input() subject: any;
    @Input() calculate: EventEmitter<void>;
    @Output() changed = new EventEmitter<any>();

    public selectableInfrastructure: any[];

    constructor(private readonly _quoteDataApi: QuoteDataApi) {
        super();
    }

    ngOnInit() {
        this.dataSource = new DataSource({
            store: new ArrayStore({
                data: this.subject?.infrastructure || []
            }),
            reshapeOnPush: true
        });

        this.getInfrastructure(this.subject);

        this.calculate.subscribe(async () => {
            await this.dataGrid.instance.refresh();
            this.dataGridChanged();
        });
    }

    onInitNewRow(e) {
        e.data.currentQuantity = 1;
        e.data.currentPrice = 0;
    }

    onCustomItemCreating(e) {
        e.customItem = { code: e.text, description: e.text, esaQuantity: 1, esaPrice: 0 };
    }

    getInfrastructure(subject?: any) {
        this._quoteDataApi.getInfrastructureProducts().subscribe(results => {
            this.selectableInfrastructure = query(results.data).groupBy("category").toArray();

            if (!subject) {
                this.addDefaults(results.data);
            }
        });
    }

    addDefaults(selectableInfrastructure: any[]) {
        let basicServiceLevelAgreement = new QuoteInfrastructure(false, "ESA Services", "Basic Service Level Agreement", 0, 0, 1, 350);
        let huntingFacilities = new QuoteInfrastructure(false, "Telkom", "Hunting Facilities", 0, 0, 0, 0);

        if (basicServiceLevelAgreement) {
            this.dataSource.store().insert(basicServiceLevelAgreement).then(() => {
                this.dataSource.reload().then(() => {
                    this.dataGridChanged();
                });
            });
        }

        if (huntingFacilities) {
            this.dataSource.store().insert(huntingFacilities).then(() => {
                this.dataSource.reload().then(() => {
                    this.dataGridChanged();
                });
            });
        }
    }

    infrastructureSelectionChanged(e, cellInfo) {
        if (e.selectedItem) {
            cellInfo.data.description = e.selectedItem.description;

            if (!cellInfo.data.category) {
                cellInfo.data.category = e.selectedItem.category;
            }

            if (!cellInfo.data.esaQuantity) {
                cellInfo.data.esaQuantity = 1;
            }

            if (!cellInfo.data.esaPrice) {
                cellInfo.data.esaPrice = e.selectedItem.price;
            }
        }
    }

    currentTotalCellValue(e: QuoteInfrastructure) {
        if (e.currentQuantity && e.currentPrice) {
            return e.currentQuantity * e.currentPrice;
        }

        return 0;
    }

    esaTotalCellValue(e: QuoteInfrastructure) {
        if (e.esaQuantity && e.esaPrice) {
            return e.esaQuantity * e.esaPrice;
        }

        return 0;
    }

    dataGridChanged() {
        this.changed.emit({
            infrastructure: this.dataSource.items().flatMap(_ => _.items),
            grouped: this.dataSource.items()
        });
    }
}
