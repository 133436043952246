import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';
import { HttpService } from './HttpService';

@Injectable()
export class ActivityApi extends HttpService {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  read() {
    var apiEndpoint = ApiService.getEndpoint(environment.api_erp_activity + "/read");
    return this.post(apiEndpoint, null);
  }
}
