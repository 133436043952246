import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import DataSource from 'devextreme/data/data_source';
import { AppData } from 'src/app/app.data';

@Component({
  selector: 'app-modal-select-supplier',
  templateUrl: './modal-select-supplier.component.html',
  styleUrls: ['./modal-select-supplier.component.scss']
})
export class ModalSelectSupplierComponent implements OnInit {
  public readonly dataSource = new DataSource(AppData.suppliers());

  form: FormGroup;

  constructor(private readonly dialogRef: MatDialogRef<ModalSelectSupplierComponent>) {
  }

  ngOnInit() {
    this.form = new FormGroup({
      account: new FormControl(null, [Validators.required])
    });
  }

  proceed() {
    if (this.form.valid) {
      this.dialogRef.close(this.form.value.account);
    }
  }
}
