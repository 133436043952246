import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';

@Component({
  selector: 'app-modal-product-select',
  templateUrl: './modal-product-select.component.html',
  styleUrls: ['./modal-product-select.component.scss']
})
export class ModalProductSelectComponent {
  public readonly products: DataSource;

  constructor(
    public dialogRef: MatDialogRef<ModalProductSelectComponent>,
    @Inject(MAT_DIALOG_DATA) data: any) {

    this.products = new DataSource({
      store: new ArrayStore({ data: data.products })
    });
  }

  select(item: any) {
    this.dialogRef.close(item);
  }

  private getDescription(description: string) {
    return description?.replace(/\\r\\n/g, "<br />");
  }
}
