import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';
import { HttpService } from './HttpService';

@Injectable()
export class TicketApi extends HttpService {
    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    public merge(data: any) {
        return this.post(
            this.getEndpoint(environment.api_erp_ticket_merge),
            data);
    }

    public split(data: any) {
        return this.post(
            this.getEndpoint(environment.api_erp_ticket_split),
            data);
    }
}
