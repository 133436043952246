import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { QuoteCalculationHelper } from 'src/app/helper/quote-calculation.helper';
import { FormGroupService } from 'src/app/service/form.service';

@Component({
  selector: 'app-quote-form-line',
  templateUrl: './quote-form-line.component.html',
  styleUrls: ['./quote-form-line.component.scss']
})
export class QuoteFormLineComponent implements OnInit {

  readonly calculateRequested: EventEmitter<boolean> = new EventEmitter();

  @Input() subject: any;

  constructor(
    private readonly _formBuilder: FormBuilder,
    private readonly _quoteCalculationHelper: QuoteCalculationHelper,
    public formGroupService: FormGroupService) {
  }

  ngOnInit() {
    this.formGroupService.mergeFormControls(this.getForm(this.subject));
  }

  ngAfterViewInit() {
    this.formGroupService.formGroup
      .valueChanges.subscribe(() => {
        this.calculate(true);
      });
  }

  private getForm(subject?: any): FormGroup {
    let formGroup: FormGroup = this._formBuilder.group({
      hardware: [null],
      hardwareTotal: [0.0],
      customLines: [null],
      customLinesTotal: [0.0]
    });

    if (subject) {
      formGroup.patchValue(subject);
    }

    return formGroup;
  }

  securityChanged(e) {
    this.formGroupService.setValue("hardware", e.security, false);
    this.formGroupService.setValue("hardwareTotal", e.total, false);
    this.calculate(true);
  }

  adHocChanged(e) {
    this.formGroupService.setValue("customLines", e.adHocs, false);
    this.formGroupService.setValue("customLinesTotal", e.total, false);
    this.calculate(true);
  }

  calculate(silent?: boolean) {
    if (!silent) {
      this.calculateRequested.emit();
    }

    let total = this.formGroupService.getFloat("hardwareTotal") + this.formGroupService.getFloat("customLinesTotal");
    let termsType = this.formGroupService.getValue("termsType");
    let margin = this.formGroupService.getFloat("margin");
    let marginType = this.formGroupService.getValue("marginType");
    let period = this.formGroupService.getFloat("period");
    let esc = this.formGroupService.getFloat("escalation", 0);

    let totalMargin = this._quoteCalculationHelper.calculateMargin(margin, marginType, total) || 0;
    this.formGroupService.setValue("totalMargin", totalMargin, false);

    if (termsType === "Rental") {
      var factor = this._quoteCalculationHelper.calculateFactor(total, margin, marginType, period, esc);
      this.formGroupService.setValue("factor", factor, false);

      total += totalMargin;
      total *= factor;
    } else {
      total += margin;
    }

    this.formGroupService.setValue("totalMonthly", total, false);
    this.formGroupService.setValue("totalAnnual", total * 12, false);
  }
}
