import { Component, Input } from '@angular/core';
import dxDataGrid from 'devextreme/ui/data_grid';
import { AppController } from 'src/app/app.controller';
import { AppData } from 'src/app/app.data';

@Component({
    selector: 'app-menu-delete',
    templateUrl: './menu-delete.component.html'
})
export class WidgetMenuDeleteComponent {
    @Input() data!: { component: dxDataGrid; rowIndex: number };

    constructor(private readonly _appController: AppController) {
    }

    delete() {
        AppData.deleteV2(this._appController, this.data);
    }
}
