import { Component, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DxDataGridComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import { ApiService } from 'src/app/api/api.service';
import { LeadPoolApi } from 'src/app/api/lead-pool.api';
import { AppConstant } from 'src/app/app.constant';
import { AppController } from 'src/app/app.controller';
import { AppData } from 'src/app/app.data';
import { ModalCsvDataImportComponent } from 'src/app/modal/modal-csv-data-import/modal-csv-data-import.component';
import { ModalSelectUserComponent } from 'src/app/modal/modal-select-user/modal-select-user.component';
import { SecurityService } from 'src/app/service/security.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-lead-pool-list',
    templateUrl: './lead-pool-list.component.html',
    styleUrls: ['./lead-pool-list.component.scss']
})
export class LeadPoolListComponent {

    @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent

    readonly dataSource = new DataSource(AppData.createStore("id", ApiService.getEndpoint(environment.api_erp_lead_pool)));
    readonly departments = new DataSource(AppData.createStore("id", ApiService.getEndpoint(environment.api_erp_departments)));
    readonly users = {
        store: AppData.createStore("id", {
            loadUrl: ApiService.getEndpoint(environment.api_erp_user)
        }),
        select: ["id", "name"],
        searchExpr: "name",
        sort: "name"
    };

    showSelectedAction = false;
    selectedRowKeys: number[];
    selectedRowsData: [];

    filterValue: any[];
    assignDepartmentId = new FormControl(null, [Validators.required]);
    assignValue = new FormControl(null, [Validators.required]);
    assignUntilDate = new FormControl(null, [Validators.required]);

    constructor(
        private readonly _leadPoolApi: LeadPoolApi,
        private readonly _securityService: SecurityService,
        private readonly _appController: AppController) {
    }

    assignSelected() {
        this._appController.openModal(async (userId: string) => {
            if (userId) {
                var store = this.dataSource.store();
                for (var leadId of this.selectedRowKeys) {
                    await store.update(leadId, { salesperson1Id: userId })
                }

                this.dataSource.reload();
            }
        }, ModalSelectUserComponent, {
            width: AppConstant.SIZE.MODAL_SMALL
        });
    }

    convertSelected() {
        this._leadPoolApi.convert(this.selectedRowKeys)
            .subscribe(() => {
                this.dataSource.reload();
                this._appController.notice(`Leads converted`);
            }, error => {
                this._appController.notice(error);
            });
    }

    import() {
        this._appController.openModal(async (results: any[]) => {
            var store = this.dataSource.store();
            //FIXME: move to utilities

            for (var item of results) {
                item.salesperson1Id = this._securityService.getAuthenticatedUserId();
                await store.insert(item);
            }

            this.dataSource.reload();
        }, ModalCsvDataImportComponent,
            {
                width: AppConstant.SIZE.MODAL_LARGE,
                data: {
                    columnLabels: [
                        "Company",
                        "Contact Person",
                        "Job Title",
                        "Email",
                        "Phone",
                        "Turn Over",
                        "Source",
                    ],
                    columnKeys: [
                        "company,^address1,address2,address3,addressCode",
                        "name",
                        "email|mail",
                        "phone|call",
                        "turnover",
                        "source"
                    ]
                }
            });
    }

    assign() {
        if (this.assignDepartmentId.valid && this.assignValue.valid && this.assignUntilDate.valid) {
            this._leadPoolApi.assign({ filter: this.dataGrid.instance.getCombinedFilter(), departmentId: this.assignDepartmentId.value, take: this.assignValue.value, assignUntilDate: this.assignUntilDate.value }).subscribe(() => {
                this._appController.notice("Assign complete");
            });
        }
    }

    public onSelectionChanged(e) {
        this.showSelectedAction = e.selectedRowKeys.length > 0;
        this.selectedRowKeys = e.selectedRowKeys;
        this.selectedRowsData = e.selectedRowsData;
    }
}
