import { Component, OnInit } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { ActivityApi } from 'src/app/api/activity.api';
import { ApiService } from 'src/app/api/api.service';
import { AppController } from 'src/app/app.controller';
import { AppData } from 'src/app/app.data';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss']
})
export class NotificationListComponent implements OnInit {

  public readonly dataSource = new DataSource({
    store: AppData.createStore("id", {
      loadUrl: ApiService.getEndpoint(environment.api_erp_activity)
    }),
    sort: [{ selector: "activityTime", desc: true }],
    pageSize: 20
  });

  constructor(
    private readonly activityApi: ActivityApi,
    private readonly appController: AppController) {
  }

  ngOnInit() {
    this.activityApi.read().subscribe(() => {
      this.appController.notificationsRead.emit();
    });
  }
}
