import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';

@Injectable()
export class SecurityApi extends ApiService {
  constructor(httpClient: HttpClient) {
    super(environment.api_erp_auth, httpClient);
  }

  authenticate(email: string, password: string) {
    return this.post(
      this.endpoint,
      { email: email, password: password });
  }

  reset(email: string) {
    return this.post(
      this.endpoint + "/reset",
      { email: email });
  }

  logout() {
  }
}
