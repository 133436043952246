import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/api/api.service';
import { AppController } from 'src/app/app.controller';

@Component({
  selector: 'app-modal-form-service-level-agreement-plan-limit',
  templateUrl: './modal-form-service-level-agreement-plan-limit.component.html',
  styleUrls: ['./modal-form-service-level-agreement-plan-limit.component.scss']
})
export class ModalFormServiceLevelAgreementPlanLimitComponent implements OnInit {

  private readonly subject: any;
  private readonly services: { [key: string]: ApiService };
  private readonly queryParams: any;

  form: FormGroup;

  jobClasses: any[];

  constructor(
    public dialogRef: MatDialogRef<ModalFormServiceLevelAgreementPlanLimitComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private readonly _formBuilder: FormBuilder,
    public readonly appController: AppController) {

    this.services = data.services;
    this.subject = data.subject;
    this.queryParams = data.queryParams;
  }

  ngOnInit() {
    this.getJobClasses();

    this.form = this._formBuilder.group({
      id: [null],
      servicePlanId: [this.queryParams.servicePlanId, [Validators.required]],
      jobCode: [null, [Validators.required]],
      description: [null],
      quantity: [null, [Validators.required]],
    });

    if (this.subject) {
      this.form.patchValue(this.subject);
    }
  }

  getJobClasses() {
    this.services.listJobClassApi.findAll().subscribe((results) => {
      console.debug("results", results);
      this.jobClasses = results.data;
    }, (error) => {
      console.error("failed to find users", error);
    });
  }

  save() {
    if (this.form.valid) {
      this.dialogRef.close(this.form.value);
    }
  }
}
