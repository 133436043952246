import { Component, Input, OnInit } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import dxDataGrid from 'devextreme/ui/data_grid';
import { QuoteApi } from 'src/app/api/quote.api';
import { AppConstant } from 'src/app/app.constant';
import { AppController } from 'src/app/app.controller';
import { AppData } from 'src/app/app.data';
import { ModalSelectUserComponent } from 'src/app/modal/modal-select-user/modal-select-user.component';
import { QuoteService } from 'src/app/service/quote.service';
import { SecurityService } from 'src/app/service/security.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-partial-quotation-list',
  templateUrl: './quotation-list.component.html',
  styleUrls: ['./quotation-list.component.scss']
})
export class QuotationListComponent implements OnInit {

  @Input() public leadId?: string;
  @Input() public company?: string;

  public dataSource: DataSource;

  constructor(
    private readonly _quoteApi: QuoteApi,
    private readonly _quoteService: QuoteService,
    private readonly _appController: AppController,
    private readonly _securityService: SecurityService) {
  }

  ngOnInit() {
    this.dataSource = new DataSource({
      store: AppData.createStore("id", environment.api_quotes_quotes, {
        loadParams: {
          leadId: this.leadId,
          company: this.company
        }
      })
    });
  }

  canDelete() {
    return !this._securityService.isInRole("sales");
  }

  delete(e: { component: dxDataGrid; rowIndex: number }) {
    AppData.deleteV2(this._appController, e);
  }

  download(quote) {
    window.open(quote.download, "_blank");
  }

  convertToProject(quote) {
    this._quoteService.convertToProject(quote.id)
  }

  assign(quote) {
    this._appController.openModal((userId: string) => {
      if (userId) {
        this._quoteApi.assignUser(quote.id, userId)
          .subscribe(() => {
            this.dataSource.reload();
            this._appController.notice("User updated.");
          });
      }
    }, ModalSelectUserComponent, {
      width: AppConstant.SIZE.MODAL_SMALL
    });
  }

  async restore(url) {
    const result = await fetch(
      url, {
      method: "post",
      headers: {
        "Authorization": "Bearer " + localStorage.getItem(SecurityService.AccessTokenKey)
      }
    });

    if (result.ok) {
      this.dataSource.reload();
      this._appController.notice("Quote version restored");
    }
  }
}
