import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/api/api.service';
import { AppController } from 'src/app/app.controller';
import { AppData } from 'src/app/app.data';
import { BaseForm } from 'src/app/page/base-form.component';
import { SecurityService } from 'src/app/service/security.service';

@Component({
  selector: 'app-modal-form-task-other-quick-add',
  templateUrl: './modal-form-task-other-quick-add.component.html',
  styleUrls: ['./modal-form-task-other-quick-add.component.scss']
})
export class ModalFormTaskOtherQuickAddComponent extends BaseForm implements OnInit {

  public readonly users = AppData.users();

  private title: string;
  private subject: any;
  private readonly services: { taskApi: ApiService; };
  private readonly queryParams: any;

  form: FormGroup;

  types = ["task", "communication"];

  constructor(
    public dialogRef: MatDialogRef<ModalFormTaskOtherQuickAddComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private readonly _formBuilder: FormBuilder,
    private readonly appController: AppController,
    private readonly securityService: SecurityService) {
    super();

    this.title = data.title;
    this.subject = data.subject;
    this.services = data.services;
    this.queryParams = data.queryParams;
  }

  ngOnInit() {
    this.form = this._formBuilder.group({
      type: [null, [Validators.required]]
    });

    if (this.subject) {
      this.form.patchValue(this.subject);
    }

    this.form.get("type").valueChanges
      .subscribe((value) => {
        let taskGroup: FormGroup;
        switch (value) {
          case "task":
            taskGroup = this._formBuilder.group({
              userId: [this.securityService.getAuthenticatedUserId(), [Validators.required]],
              startTime: [null, [Validators.required]],
              description: [null, [Validators.required]],
            });

            Object.keys(taskGroup.controls).forEach((key) => {
              this.form.addControl(key, taskGroup.controls[key]);
            });

            if (this.subject) {
              this.form.patchValue(this.subject);
            }
            break;

          case "communication":
            taskGroup = this._formBuilder.group({
              sendTo: [null],
              category: [null],
              subCategory: [null],
              customerNote: [null]
            });

            Object.keys(taskGroup.controls).forEach((key) => {
              this.form.addControl(key, taskGroup.controls[key]);
            });

            break;
        }
      });
  }

  save() {
    if (this.form.valid) {
      let data = this.form.value;
      if (!data.id) {
        this.services.taskApi
          .create({ ...data, ...this.queryParams })
          .subscribe((results: any) => {
            results = { ...data, ...results };
            this.appController.notice(`Task saved successfully`);
            this.form.patchValue({ id: results.id });
            this.dialogRef.close(results);
          }, (error) => {
            this.appController.notice(`Failed to save Task`);
          });
      } else {
        this.services.taskApi
          .update(data.id, { ...data, ...this.queryParams })
          .subscribe((results: any) => {
            results = { ...data, ...results };
            this.form.patchValue(results);
            this.appController.notice(`Task updated successfully`);
            this.dialogRef.close(results);
          }, (error) => {
            this.appController.notice(`Failed to update Task`);
          });
      }
    }
  }
}
