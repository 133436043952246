import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AvatarModule } from 'ngx-avatar';
import { NgxMaskModule } from 'ngx-mask';
import { ApiModule } from '../api/api.module';
import { AppUiDevExtremeModule } from '../app.ui.devextreme.module';
import { AppMaterialModule } from '../app.ui.material.module';
import { QuoteCalculationHelper } from '../helper/quote-calculation.helper';
import { ModalModule } from '../modal/modal.module';
import { QuotationListComponent } from '../partial/partial-quotation/partial-quotation-list/quotation-list.component';
import { PartialModule } from '../partial/partial.module';
import { WidgetModule } from '../widget/widget.module';
import { BillingListComponent } from './billing/billing-list/billing-list.component';
import { InvoiceListComponent } from './billing/invoice-list/invoice-list.component';
import { ContainerComponent } from './container.component';
import { CustomerFormComponent } from './customer/customer-form/customer-form.component';
import { CustomerListComponent } from './customer/customer-list/customer-list.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DealerListComponent } from './dealer/dealer-list/dealer-list.component';
import { DepartmentListComponent } from './department/department-list/department-list.component';
import { InventoryListComponent } from './inventory/inventory-list/inventory-list.component';
import { InventoryViewComponent } from './inventory/inventory-view/inventory-view.component';
import { RequestListComponent } from './inventory/request-list/request-list.component';
import { JobClassListComponent } from './job/job-class-list/job-class-list.component';
import { JobListComponent } from './job/job-list/job-list.component';
import { LeadFormComponent } from './lead/lead-form/lead-form.component';
import { LeadPipelineComponent } from './lead/lead-pipeline/lead-pipeline.component';
import { LeadPoolListComponent } from './lead/lead-pool/lead-pool-list.component';
import { LoginFormComponent } from './login/login-form/login-form.component';
import { ResetPasswordFormComponent } from './login/reset-password-form/reset-password-form.component';
import { NotificationListComponent } from './notification/notification-list/notification-list.component';
import { PageRoutingModule } from './page.route';
import { FinancePeriodListComponent } from './period/finance-period-list/finance-period-list.component';
import { SalesPeriodListComponent } from './period/sales-period-list/sales-period-list.component';
import { PriceUpdateComponent } from './pricing/price-update/price-update.component';
import { ProductClassListComponent } from './product-class/product-class-list/product-class-list.component';
import { ProjectFormComponent } from './project/project-form/project-form.component';
import { ProjectListComponent } from './project/project-list/project-list.component';
import { PurchaseOrderFormComponent } from './purchase-order/purchase-order-form/purchase-order-form.component';
import { PurchaseOrderListComponent } from './purchase-order/purchase-order-list/purchase-order-list.component';
import { QuoteFormLineComponent } from './quote/quote-form/quote-form-line/quote-form-line.component';
import { QuoteFormPrintComponent } from './quote/quote-form/quote-form-print/quote-form-print.component';
import { QuoteFormSolutionComponent } from './quote/quote-form/quote-form-solution/quote-form-solution.component';
import { QuoteFormVoiceComponent } from './quote/quote-form/quote-form-voice/quote-form-voice.component';
import { QuoteViewComponent } from './quote/quote-view/quote-view.component';
import { ReadingsListComponent } from './readings/readings-list/readings-list.component';
import { SaleOrderFormComponent } from './sale/sale-order-form/sale-order-form.component';
import { SaleOrderListComponent } from './sale/sale-order-list/sale-order-list.component';
import { ServiceLevelAgreementListComponent } from './service-level-agreement/service-level-agreement-list/service-level-agreement-list.component';
import { SupplierDebitNoteComponent } from './supplier/supplier-debit-note/supplier-debit-note.component';
import { SupplierListComponent } from './supplier/supplier-list/supplier-list.component';
import { TaskListComponent } from './task/task-list/task-list.component';
import { TaxCodeListComponent } from './tax-code/tax-code-list/tax-code-list.component';
import { TicketListComponent } from './ticket/ticket-list/ticket-list.component';
import { TicketViewComponent } from './ticket/ticket-view/ticket-view.component';
import { UserListComponent } from './user/user-list/user-list.component';
import { UserProfileComponent } from './user/user-profile/user-profile.component';
import { WarehouseListComponent } from './warehouse/warehouse-list/warehouse-list.component';


@NgModule({
    declarations: [
        LoginFormComponent,
        ResetPasswordFormComponent,
        ContainerComponent,
        LeadFormComponent,
        LeadPipelineComponent,
        LeadPoolListComponent,
        CustomerListComponent,
        DashboardComponent,
        QuotationListComponent,
        TaskListComponent,
        ProductClassListComponent,
        TaxCodeListComponent,
        DealerListComponent,
        DepartmentListComponent,
        UserListComponent,
        TicketListComponent,
        SupplierListComponent,
        SupplierDebitNoteComponent,
        JobListComponent,
        JobClassListComponent,
        ServiceLevelAgreementListComponent,
        WarehouseListComponent,
        InventoryListComponent,
        PurchaseOrderListComponent,
        CustomerFormComponent,
        NotificationListComponent,
        QuoteViewComponent,
        UserProfileComponent,
        QuoteFormPrintComponent,
        QuoteFormSolutionComponent,
        QuoteFormVoiceComponent,
        QuoteFormLineComponent,
        ProjectFormComponent,
        TicketViewComponent,
        InventoryViewComponent,
        ProjectListComponent,
        PurchaseOrderFormComponent,
        ReadingsListComponent,
        InvoiceListComponent,
        BillingListComponent,
        RequestListComponent,
        SaleOrderFormComponent,
        SaleOrderListComponent,
        PriceUpdateComponent,
        SalesPeriodListComponent,
        FinancePeriodListComponent
    ],
    imports: [
        CommonModule,
        NgxMaskModule.forRoot(),
        ReactiveFormsModule,
        AppMaterialModule,
        AppUiDevExtremeModule,
        AvatarModule,
        WidgetModule,
        ModalModule,
        ApiModule,
        PageRoutingModule,
        PartialModule
    ],
    providers: [
        QuoteCalculationHelper
    ]
})
export class PageModule { }
