import { Component, Input, OnInit } from '@angular/core';
import DataSource, { DataSourceOptions } from 'devextreme/data/data_source';
import { ApiService } from 'src/app/api/api.service';
import { AppData } from 'src/app/app.data';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-partial-supplier-invoice-lines',
    templateUrl: './partial-supplier-invoice-lines.component.html'
})
export class PartialSupplierInvoiceLinesComponent implements OnInit {

    @Input() invoice: number;
    @Input() goodsReceivedNumber: string;

    public dataSource: DataSource;
    public lines: DataSourceOptions;

    ngOnInit() {
        this.dataSource = new DataSource({
            store: AppData.createStore(["invoice", "entryNumber"], environment.api_erp_supplier_invoice_lines, {
                loadParams: {
                    invoice: this.invoice
                }
            })
        });

        this.lines = {
            store: AppData.createStore("code", environment.api_erp_goods_received_lines, {
                loadParams: {
                    goodsReceivedNumber: this.goodsReceivedNumber
                }
            })
        }
    }

    onInitNewRow(e) {
        e.data.invoice = this.invoice;
    }

    onEditorPreparing(e) {
        if (e.parentType === "dataRow") {
            if (e.dataField === "code") {
                e.editorName = "dxLookup";
                e.editorOptions.disabled = !e.row.isNewRow;
            }

            if (e.dataField === "unitOfMeasure" || e.dataField === "quantity" || e.dataField === "price") {
                e.editorOptions.disabled = !e.row.data.code;
            }

            if (e.dataField === "serials") {
                if (e.row.data.isNonStocked || !e.row.data.serialized) {
                    e.cancel = true;
                }
                else {
                    e.editorName = "dxTagBox";
                    e.editorOptions.onValueChanged = args => {
                        e.setValue(args.value.map(serial => ({ invoice: e.row.key.invoice, entryNumber: e.row.key.entryNumber, serial: serial })));
                    };
                    e.editorOptions.dataSource = {
                        store: AppData.createStore("serial", {
                            loadUrl: ApiService.getEndpoint(environment.api_erp_supplier_invoice_lines_serials),
                            loadParams: {
                                invoice: e.row.key.invoice,
                                entryNumber: e.row.key.entryNumber,
                                goodsReceivedNumber: this.goodsReceivedNumber,
                                code: e.row.data.code
                            }
                        }),
                        map(dataItem) {
                            return { invoice: e.row.key.invoice, entryNumber: e.row.key.entryNumber, serial: dataItem.serial };
                        }
                    } as DataSourceOptions;
                }
            }
        }
    }

    onCodeChanged = (newData, value, currentRowData) => {
        return this.lines.store.byKey(value).then(line => {
            newData.warehouse = line.warehouse;
            newData.bin = line.bin;
            newData.code = value;
            newData.quantity = line.quantityReceived;
            newData.unitOfMeasure = line.unitOfMeasure;
            newData.price = line.unitCost;
            newData.isNonStocked = line.isNonStocked;
            newData.isStocked = line.isStocked;
            newData.serialized = line.serialized;
            newData.price = line.unitCost;
        });
    }
}
