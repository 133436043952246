import { HttpClient, HttpParameterCodec, HttpParams } from '@angular/common/http';
import { HttpService } from './HttpService';

export class ApiService extends HttpService {
  public endpoint: string;

  constructor(private readonly path: string, httpClient: HttpClient) {
    super(httpClient);

    this.endpoint = this.getEndpoint(path);
  }

  public find(id: string | number | null, httpParams?: HttpParams) {
    var apiEndpoint = (id) ? `${this.endpoint}/${id}` : this.endpoint;
    return this.get(
      apiEndpoint,
      {
        params: httpParams
      });
  }

  public findAll(httpParams?: HttpParams, path?: string | number) {
    var apiEndpoint = (path) ? `${this.endpoint}/${path}` : this.endpoint;
    return this.get(
      apiEndpoint,
      {
        params: httpParams
      });
  }

  // TODO: add function to generate path variables
  public create(data: object, path?: string | number) {
    var apiEndpoint = (path) ? `${this.endpoint}/${path}` : this.endpoint;
    let body = new HttpParams({ encoder: new HttpUrlEncodingCodec() })
      .set("values", JSON.stringify(data));
    return this.post(
      apiEndpoint,
      body.toString(),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
      });
  }

  public update(id: object| string | number, data: any, path?: string[]) {
    var key = typeof id === "object" ? JSON.stringify(id) : id.toString();

    var apiEndpoint = (path) ? `${this.endpoint}/${path.join("/")}` : this.endpoint;
    let body = new HttpParams({ encoder: new HttpUrlEncodingCodec() })
      .set("key", key)
      .set("values", JSON.stringify(data));
    return this.put(
      apiEndpoint,
      body.toString(),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
      });
  }

  public remove(id: string | number, path?: string[]) {
    var apiEndpoint = (path) ? `${this.endpoint}/${path.join("/")}` : this.endpoint;
    return this.delete(
      apiEndpoint,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        },
        body: new HttpParams().set("key", id.toString()).toString()
      });
  }
}

class HttpUrlEncodingCodec implements HttpParameterCodec {
  encodeKey(k: string): string { return this.standardEncoding(k); }
  encodeValue(v: string): string { return this.standardEncoding(v); }
  decodeKey(k: string): string { return decodeURIComponent(k); }
  decodeValue(v: string) { return decodeURIComponent(v); }

  private standardEncoding(v: string) {
    return encodeURIComponent(v);
  }
}
