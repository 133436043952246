import { HttpParams } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api/api.service';
import { AppConstant } from 'src/app/app.constant';
import { AppController } from 'src/app/app.controller';
import { ModalCsvDataImportComponent } from 'src/app/modal/modal-csv-data-import/modal-csv-data-import.component';
import { ModalFormInventoryReadingVoiceComponent } from 'src/app/modal/modal-form-inventory/modal-form-inventory-reading-voice/modal-form-inventory-reading-voice.component';
import { BaseDataListing } from 'src/app/page/base-data-list.component';
import { SecurityService } from 'src/app/service/security.service';

@Component({
  selector: 'app-partial-inventory-reading-voice-list',
  templateUrl: './partial-inventory-reading-voice-list.component.html',
  styleUrls: ['./partial-inventory-reading-voice-list.component.scss']
})
export class PartialInventoryReadingVoiceListComponent extends BaseDataListing implements OnInit {

  @Input() subject: any;
  @Input() services: { [key: string]: ApiService }
  @Input() queryParams: any;

  constructor(public readonly appController: AppController,
    public readonly securityService: SecurityService) {
    super();
  }

  ngOnInit() {
    this.queryData(this.queryLimit, this.queryCurrentPage, true, this.querySort, this.queryFilter);
  }

  get columnLabels(): string[] {
    return [
      "Account", "Total Land Line", "Total Mobile", "Total International", "Total Special", ""
    ];
  }

  get columnKeys(): string[] {
    return [
      "account", "totalLandline", "totalMobile", "totalInternational", "totalSpecial", "action"
    ];
  }

  queryData(take: number, skip: number, count: boolean, sort: any[], filter?: any[]) {
    if (this.services.inventoryReadingVoiceApi) {
      this.query = new HttpParams()
        .set("take", `${take}`)
        .set("skip", `${skip}`)
        .set("requireTotalCount", `${count}`);

      this.query = this.queryAdd(this.query, this.queryParams);
      this.query = this.queryAddSort(this.query, sort);
      this.query = this.queryAddFilter(this.query, filter);

      console.debug("query ", this.query.toString());

      this.services.inventoryReadingVoiceApi.findAll(this.query).subscribe((results: any) => {
        console.debug("voice readings", results);
        this.dataSource = results;
      }, (error) => {
        console.debug("failed to obtain voice reading", error);
      });
    } else {
      console.error("unable to obtain inventory Reading Voice Api");
    }
  }

  onSelect(selected: any[]): void {
    console.debug("item selected", selected);
  }

  onSearch(value: string): void {
    // this.queryFilter = null;
    // console.debug("searching for ", value);

    // if (value !== '') {

    //   this.queryFilter = [
    //     ["account", "contains", `${value}`], "or",
    //     ["name", "contains", `${value}`], "or",
    //     // ["tradingName", "contains", `${value}`], "or", 
    //     // ["accountRef", "contains", `${value}`], "and",
    //     ["companyName", "=", `${this.subject.tradingName}`]
    //   ];
    //   this.queryData(this.queryLimit, this.queryCurrentPage, true, this.querySort, this.queryFilter);
    // }
  }

  download(href: string) {
    window.location.href = href;
  }

  add(element?: any) {
    this.appController.openModal((results: any) => {
      console.debug("modal result", results);
      if (results) {
        this.appController.notice(`Successfully saved reading`, null, 2000);
        this.refresh();
      }
    }, ModalFormInventoryReadingVoiceComponent, {
      width: AppConstant.SIZE.MODAL_MEDIUM,
      data: { services: this.services, subject: element }
    });
  }

  import() {
    console.debug("import ");
    this.appController.openModal((results: any[]) => {
      console.debug("modal result", results);

      if (results) {
        //FIXME: move to utilities
        results.forEach((item) => {
          // var valid = ajv.validate(leadJSONSchema, item);
          // console.debug("schema validated", valid);
          if (item.account) {
            this.apis.inventoryReadingHardwareApi.create(item).subscribe((results) => {
              console.debug("added reading", results);
            }, (error) => {
              console.error("failed to create reading", item, "error ", error);
            });
          }
        });
      }
    }, ModalCsvDataImportComponent,
      {
        width: AppConstant.SIZE.MODAL_LARGE, height: AppConstant.SIZE.MODAL_LARGE, data: {
          columnLabels: [
            "Account",
            "Serial",
            "Meter",
            "Date",
            "Previous Reading",
            "Current Reading"
          ],
          columnKeys: [
            "account",
            "serial",
            "meter",
            "date",
            "previouseReading",
            "currentReading"
          ]
        }
      });
  }

}
