import { Component, Input, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import DataSource from 'devextreme/data/data_source';
import { ApiService } from 'src/app/api/api.service';
import { AppConstant } from 'src/app/app.constant';
import { AppController } from 'src/app/app.controller';
import { AppData } from 'src/app/app.data';
import { ModalFormTaskCallQuickAddComponent } from 'src/app/modal/modal-form-task/modal-form-task-call-quick-add/modal-form-task-call-quick-add.component';
import { ModalFormTaskEmailQuickAddComponent } from 'src/app/modal/modal-form-task/modal-form-task-email-quick-add/modal-form-task-email-quick-add.component';
import { ModalFormTaskMeetingQuickAddComponent } from 'src/app/modal/modal-form-task/modal-form-task-meeting-quick-add/modal-form-task-meeting-quick-add.component';
import { ModalFormTaskOtherQuickAddComponent } from 'src/app/modal/modal-form-task/modal-form-task-other-quick-add/modal-form-task-other-quick-add.component';

@Component({
    selector: 'app-partial-task-dashboard',
    templateUrl: './partial-task-dashboard.component.html',
    styleUrls: ['./partial-task-dashboard.component.scss']
})
export class PartialTaskDashboardComponent implements OnInit {

    @Input() title: string;
    @Input() taskApi: ApiService;
    @Input() locationApi: ApiService;
    @Input() queryParams: any;

    dataSource: DataSource;
    onlyComplete = false;

    constructor(private readonly _appController: AppController) {
    }

    ngOnInit() {
        this.dataSource = new DataSource({
            store: AppData.createStore("id", this.taskApi.endpoint, {
                loadParams: this.queryParams
            }),
            sort: [{ selector: "startTime", desc: true }],
            pageSize: 5
        });
    }

    onOnlyCompleteChanged(e: MatCheckboxChange) {
        this.onlyComplete = e.checked;

        this.dataSource.filter(this.onlyComplete ? ["complete", true] : null);
        this.dataSource.reload();
    }

    complete(e: MatCheckboxChange, task: any) {
        if (e.checked) {
            this._appController.getInput("Comment").then(comment => {
                this.taskApi
                    .update(task.id, { complete: true, completeComment: comment })
                    .subscribe(() => {
                        this.dataSource.reload();
                        this._appController.notice("Task completed");
                    });
            }, () => {
                e.source.checked = false;
            });
        }
    }

    add(type: string, task?: any) {
        switch (type) {
            case "email":
                this._appController.openModal(() => {
                    this.dataSource.reload();
                }, ModalFormTaskEmailQuickAddComponent, {
                    width: AppConstant.SIZE.MODAL_MEDIUM,
                    data: {
                        title: this.title,
                        subject: task,
                        services: { taskApi: this.taskApi },
                        queryParams: this.queryParams
                    }
                });
                break;

            case "task":
            case "other":
                this._appController.openModal(() => {
                    this.dataSource.reload();
                }, ModalFormTaskOtherQuickAddComponent, {
                    width: AppConstant.SIZE.MODAL_MEDIUM,
                    data: {
                        title: this.title,
                        subject: task,
                        services: { taskApi: this.taskApi },
                        queryParams: this.queryParams
                    }
                });
                break;

            case "meeting":
                this._appController.openModal(() => {
                    this.dataSource.reload();
                }, ModalFormTaskMeetingQuickAddComponent, {
                    width: AppConstant.SIZE.MODAL_MEDIUM,
                    data: {
                        title: this.title,
                        subject: task,
                        services: { taskApi: this.taskApi, locationApi: this.locationApi },
                        queryParams: this.queryParams
                    }
                });
                break;

            case "call":
                this._appController.openModal(() => {
                    this.dataSource.reload();
                }, ModalFormTaskCallQuickAddComponent, {
                    width: AppConstant.SIZE.MODAL_MEDIUM,
                    data: {
                        title: this.title,
                        subject: task,
                        services: { taskApi: this.taskApi },
                        queryParams: this.queryParams
                    }
                });
                break;
        }
    }

    delete(task: any) {
        AppData.delete(this._appController, this.dataSource, task.id, `Are you sure you want delete '${task.title}'?`, "Task deleted");
    }
}
