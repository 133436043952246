import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import DataSource from 'devextreme/data/data_source';
import { debounceTime } from 'rxjs/operators';
import { ApiService } from 'src/app/api/api.service';
import { InventoryApi } from 'src/app/api/inventory.api';
import { SaleOrderLineApi } from 'src/app/api/sale-order-line.api';
import { AppController } from 'src/app/app.controller';
import { AppData } from 'src/app/app.data';
import { SecurityService } from 'src/app/service/security.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-modal-form-sale-order-line',
    templateUrl: './modal-form-sale-order-line.component.html',
    styleUrls: ['./modal-form-sale-order-line.component.scss']
})
export class ModalFormSaleOrderLineComponent implements OnInit {

    public readonly warehouses = new DataSource(AppData.warehouses());
    public readonly taxCodes = new DataSource(AppData.taxCodes());
    public bins: DataSource;
    public inventory: DataSource;
    public priceCodes: DataSource;
    public serials: DataSource;

    private readonly subject: any;
    private readonly orderNumber: string;
    public readonly orderType: string;
    private readonly account: string;

    form: FormGroup;
    showBins: boolean;
    serialized: boolean;

    constructor(
        public dialogRef: MatDialogRef<ModalFormSaleOrderLineComponent>,
        @Inject(MAT_DIALOG_DATA) data: any,
        private readonly _inventoryApi: InventoryApi,
        private readonly _salesOrderLineApi: SaleOrderLineApi,
        private readonly _formBuilder: FormBuilder,
        private readonly _appController: AppController,
        private readonly _securityService: SecurityService) {
        this.subject = data.subject;
        this.orderNumber = data.orderNumber;
        this.orderType = data.orderType;
        this.account = data.account;
    }

    ngOnInit() {
        this.form = this._formBuilder.group({
            orderNumber: [this.orderNumber, [Validators.required]],
            orderLine: [null],

            warehouse: [null, [Validators.required]],
            bin: [null],
            code: [{ value: null, disabled: true }, [Validators.required]],
            description: [null, [Validators.required]],
            unitOfMeasure: [{ value: null, disabled: true }, [Validators.required]],

            orderQuantity: [null, [Validators.required]],
            shipQuantity: [0, [Validators.required]],
            serials: [null],

            taxCode: [null, [Validators.required]],
            priceCode: [null, [Validators.required]],
            price: [null],
            total: [null]
        });

        if (this.subject) {
            this.form.patchValue(this.subject);

            this.form.get("warehouse").disable();
            this.form.get("code").disable();
            this.form.get("unitOfMeasure").disable();

            if (this.subject.isStocked) {
                this.updatePriceCodes(this.subject.code);
            }

            this.inventory = new DataSource(AppData.inventory(this.subject.warehouse));
            this.inventory.store().byKey(this.subject.code).then(product => {
                if (product) {
                    this.serialized = product.serialized;

                    this.updateSerials(this.subject.orderLine, this.subject.warehouse, this.subject.code);
                }
            });
        }

        this.form.get("warehouse").valueChanges
            .pipe(debounceTime(500))
            .subscribe((warehouse: string) => {
                this.warehouses.store().byKey(warehouse).then(warehouse => {
                    this.showBins = warehouse.multipleBins;
                });

                this.bins = new DataSource(AppData.bins(warehouse));
                this.inventory = new DataSource(AppData.inventory(warehouse));

                this.form.get("bin").setValue(null);
                this.form.get("code").setValue(null);
                this.form.get("code").enable();
                this.form.get("description").setValue(null);
                this.form.get("unitOfMeasure").setValue(null);

                if (warehouse === "**") {
                    this.form.get("unitOfMeasure").enable();
                    this.form.get("priceCode").setValue(".");
                } else {
                    this.form.get("unitOfMeasure").disable();
                    this.form.get("priceCode").enable();
                }
            });

        this.form.get("bin").valueChanges
            .subscribe((bin: string) => {
                this.form.get("serials").setValue(null);

                if (this.form.value.code) {
                    this.serials?.filter(["bin", bin]);
                    this.serials?.reload();
                }
            });

        this.form.get("code").valueChanges
            .subscribe((code: string) => {
                this.form.get("serials").setValue(null);

                if (this.form.value.warehouse === "**") {
                    this.serialized = false;
                    return;
                }

                this.inventory.store().byKey(code).then(product => {
                    if (product) {
                        this.serialized = product.serialized;

                        this.form.patchValue({
                            description: product.description,
                            unitOfMeasure: product.unitOfMeasure,
                            taxCode: product.taxCode
                        });
                    }
                });

                this.updatePriceCodes(code);
                this.updateSerials(this.form.value.orderLine || 0, this.form.value.warehouse, code);

                this._inventoryApi.priceCode(code, this.account).subscribe((priceCode: string) => {
                    this.form.get("priceCode").setValue(priceCode);
                });
            });

        this.form.get("orderQuantity").valueChanges
            .subscribe((orderQuantity: number) => {
                this.form.get("total").setValue(orderQuantity * this.form.value.price);
            });

        this.form.get("priceCode").valueChanges
            .subscribe((priceCode: string) => {
                this._inventoryApi.sellingPrice(this.form.value.code, this.account, priceCode).subscribe((price: number) => {
                    this.form.get("price").setValue(price || 0);
                });
            });

        this.form.get("price").valueChanges
            .subscribe((price: number) => {
                this.form.get("total").setValue(this.form.value.orderQuantity * price);
            });

        if (!this.subject) {
            var defaultWarehouse = this._securityService.getAuthenticatedUser().warehouse;
            if (defaultWarehouse) {
                this.form.get("warehouse").setValue(defaultWarehouse);
            }
        }
    }

    updatePriceCodes(code: string) {
        this.priceCodes = new DataSource(AppData.createStore("priceCode", environment.api_erp_inventory_pricecodes, {
            loadParams: {
                code: code
            }
        }));
    }

    updateSerials(orderLine: number, warehouse: string, code: string) {
        this.serials = new DataSource({
            store: AppData.createStore("serial", {
                loadUrl: ApiService.getEndpoint(environment.api_erp_sales_order_lines_serials),
                loadParams: {
                    orderNumber: this.orderNumber,
                    orderLine: orderLine,
                    warehouse: warehouse,
                    code: code
                }
            }),
            filter: this.form.value.bin ? ["bin", this.form.value.bin] : null,
            map: (dataItem) => {
                return { orderNumber: this.orderNumber, orderLine: this.form.value.orderLine, serial: dataItem.serial };
            }
        });
    }

    onCustomItemCreating(e) {
        var currentItems = e.component.option("items");
        currentItems.unshift(e.text);

        e.component.option("items", currentItems);
        e.customItem = { orderNumber: this.orderNumber, orderLine: this.form.value.orderLine, serial: e.text };
    }

    save() {
        if (this.form.valid) {
            let data = this.form.value;
            if (data.orderNumber && data.orderLine) {
                this._salesOrderLineApi.update(JSON.stringify({ orderNumber: this.orderNumber, orderLine: data.orderLine }), data)
                    .subscribe((results) => {
                        this.dialogRef.close(results);
                    }, error => {
                        this._appController.notice(error);
                    });
            } else {
                this._salesOrderLineApi.create(data)
                    .subscribe((results) => {
                        this.dialogRef.close(results);
                    }, error => {
                        this._appController.notice(error);
                    });
            }
        }
    }
}
