import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-modal-form-input',
    templateUrl: './modal-form-input.component.html',
    styleUrls: ['./modal-form-input.component.scss']
})
export class ModalFormInputComponent {

    label: string;
    userInputControl = new FormControl(null, [Validators.required]);

    constructor(
        private readonly dialogRef: MatDialogRef<ModalFormInputComponent>,
        @Inject(MAT_DIALOG_DATA) data: any) {
        this.label = data.label;
    }

    proceed() {
        if (this.userInputControl.valid) {
            this.dialogRef.close(this.userInputControl.value);
        }
    }
}
