import { DomSanitizer } from '@angular/platform-browser'
import { PipeTransform, Pipe } from "@angular/core";

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private _domSanitizer: DomSanitizer) {
  }

  transform(value) {
    return this._domSanitizer.bypassSecurityTrustHtml(value);
  }
}
