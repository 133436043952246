import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from "lodash";
import * as moment from "moment";

@Injectable()
export class AppUtility {

  getValue(item: any, key: string): any {

    if (key.indexOf("|") !== -1) {
      let keyParts: string[] = key.split("|");
      // console.debug("key parts ", keyParts, " key ", key);
      let subject: any = _.get(item, keyParts[0]);

      switch (keyParts[1]) {
        case 'date': return (subject) ? moment(subject).format(keyParts[2]) : subject;
        case 'avatar': return (subject) ? `<img matTooltip="${subject}" class="header_profile-image-container_image header_profile-image-container_image--size24 remove--p-top border--white border--width-2 border--solid border--rounded"
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSf_Bf0-x44hsGqqcQwrTcNeLUSnYjlDuoql-hQHydDdBwxeCT2&s">`: null;
        case 'link': return (subject) ? `<a class="item-clickable" matTooltip="Open in new tab" href="${subject}" target="_blank">${subject}</a>` : null;
        case 'mail': return (subject) ? `<a class="item-clickable" matTooltip="E-Mail" href="mailto:${subject}">${subject}</a>` : null;
        case 'call': return (subject) ? `<a class="item-clickable" matTooltip="Call" href="tel:${subject}">${subject}</a>` : null;
        case 'checkbox': return `<input type="checkbox" readonly="true" disabled "${subject ? 'checked' : ''}">`;

        default: return `no formatter keyParts[1]`;
      }
    } if (key.indexOf("?") !== -1) {
      let keyParts: string[] = key.split("?");
      let keyCondition: string[] = keyParts[1].split(":");
      let subject: any = _.get(item, keyParts[0]);

      let value: boolean = subject as boolean;
      // console.debug("conditional split", keyParts, " condition ", keyCondition, " value ", value);
      if (value) {
        return keyCondition[0];
      } else {
        return (keyCondition[1] !== 'null') ? keyCondition[1] : null;
      }
    }

    return _.get(item, key);
  }

  getKeyValue(item: any, key: string, seperator: string = " "): any {
    let value: any = "";
    if (key.indexOf(",") === -1) {

      value = this.getValue(item, key);

    } else {
      key.split(",").forEach((itemKey) => {

        let valueItem = this.getValue(item, itemKey);

        if (valueItem) {
          value += valueItem + seperator
        }
      })
    }
    return _.isString(value) ? _.trimEnd(value, seperator) : value;
  }

  hasSecondary(key: string): boolean {
    if (key.indexOf("^")) {
      return true;
    }
    return false;
  }

  getSecondary(key: string): string[] {
    return key.split("^");
  }

  invokeCallBack(callBack: Function, data?: any) {
    console.debug("invokeCallBack, with data ", data);
    if (callBack) {
      callBack.call(this, data);
    }
  }

  merge(subject, source): any {
    return _.merge(subject, source);
  }

  omit(data: any, omit: string[]): any {
    return _.omit(data, omit);
  }

  queryAdd(query: HttpParams, queryParams: any): HttpParams {
    if (queryParams) {
      _.keys(queryParams).forEach((key) => {
        console.debug("adding query params, key ", key, "value ", queryParams[key])
        query = query.set(key, queryParams[key]);
      })
    }
    return query;
  }

  queryAddSort(query: HttpParams, sort: any[]): HttpParams {
    if (sort) {
      if (sort.length > 0) {
        query = query.set("sort", JSON.stringify(sort));
      }
    }

    return query;
  }

  queryAddFilter(query: HttpParams, filter: any[]): HttpParams {
    if (filter) {
      if (filter.length > 0) {
        query = query.set("filter", JSON.stringify(filter));
      }
    }

    return query;
  }

  queryObject(subject: any): string {
    return JSON.stringify(subject);
  }

  queryAddSelect(query: HttpParams, select: string[]): HttpParams {
    if (select) {
      if (select.length > 0) {
        query = query.set("select", JSON.stringify(select));
      }
    }

    return query;
  }

  findObject(subject: any, key: string, value: any): any {
    return _.find(subject, [key, value]);
  }

  combineObjectValues(subject: any, keys: string[], seperator: string = ", ", defaultValue: any = null): string {
    let combined: string = "";
    keys.forEach((key) => {
      let value: any = _.get(subject, key);
      combined += (value) ? value + seperator : "";
    });

    return (combined === "") ? defaultValue : _.trimEnd(combined, seperator);
  }
}
