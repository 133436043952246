import { Component, Input } from '@angular/core';
import dxDataGrid from 'devextreme/ui/data_grid';

@Component({
    selector: 'app-menu-edit',
    templateUrl: './menu-edit.component.html'
})
export class WidgetMenuEditComponent {
    @Input() data!: { component: dxDataGrid; rowIndex: number };

    edit() {
        this.data.component.editRow(this.data.rowIndex);
    }
}
