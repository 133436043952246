import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable()
export class FormGroupService {
  private _formGroup: FormGroup;

  get formGroup(): FormGroup {
    return this._formGroup;
  }

  set formGroup(formGroup: FormGroup) {
    this._formGroup = formGroup;
  }

  public mergeFormControls(target: FormGroup) {
    Object.keys(target.controls).forEach((key) => {
      this.formGroup.addControl(key, target.controls[key]);
    });
  }

  public getValue(key: string, defaultValue: string = ""): any {
    let formControl = this.formGroup.get(key);
    return formControl?.value || defaultValue;
  }

  public getFloat(key: string, defaultValue: number = 0.0): number {
    let formControl = this.formGroup.get(key);
    if (formControl) {
      return parseFloat(formControl.value) || defaultValue;
    }
    return defaultValue;
  }

  public getBoolean(key: string, defaultValue: boolean = false): boolean {
    let formControl = this.formGroup.get(key);
    return formControl?.value || defaultValue;
  }

  public setValue(key: string, value: any, emitEvent: boolean = true) {
    this.formGroup.get(key).setValue(value, { emitEvent: emitEvent });
  }
}
