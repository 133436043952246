import { Injectable } from '@angular/core';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import Store from 'devextreme/data/abstract_store';
import CustomStore from 'devextreme/data/custom_store';
import DataSource, { DataSourceOptions } from 'devextreme/data/data_source';
import dxDataGrid from 'devextreme/ui/data_grid';
import notify from 'devextreme/ui/notify';
import { environment } from 'src/environments/environment';
import { ApiService } from './api/api.service';
import { AppController } from './app.controller';
import { Confirmation } from './modal/modal-confirmation/confirmation';
import { SecurityService } from "./service/security.service";

interface CustomDataSource extends DataSource {
    store(): Store;
}

interface CustomDataSourceOptions extends DataSourceOptions {
    store: Store;
}

@Injectable()
export class AppData {
    public static createStore(key: string | string[], urlOrOptions: string | AspNetData.Options, options?: AspNetData.Options) {

        if (typeof urlOrOptions === "string") {
            if (!urlOrOptions.startsWith("http")) {
                urlOrOptions = ApiService.getEndpoint(urlOrOptions);
            }

            urlOrOptions = {
                key: key,
                loadUrl: urlOrOptions,
                insertUrl: urlOrOptions,
                updateUrl: urlOrOptions,
                deleteUrl: urlOrOptions
            };
        } else {
            urlOrOptions.key = key;
        }

        if (options) {
            urlOrOptions = { ...urlOrOptions, ...options };
        }

        urlOrOptions.onInserted = () => {
            notify("Created successfully", "success", 3000);
        };
        urlOrOptions.onUpdated = () => {
            notify("Updated successfully", "success", 3000);
        };
        urlOrOptions.onRemoved = () => {
            notify("Removed successfully", "error", 3000);
        };
        urlOrOptions.onBeforeSend = (method, ajaxOptions) => {
            ajaxOptions.headers = {
                Authorization: "Bearer " + localStorage.getItem(SecurityService.AccessTokenKey)
            };
        };
        urlOrOptions.errorHandler = (e) => {
            notify(e.message, "error", 3000);
        };

        options = urlOrOptions;
        const store = AspNetData.createStore(options);

        return new CustomStore({
            key: options.key,
            useDefaultSearch: true,

            load(loadOptions) {
                return store.load(loadOptions);
            },
            async byKey(key) {
                try {
                    return await AppData.query(options.loadUrl + "/" + encodeURIComponent(key));
                } catch (e) {
                    return await store.byKey(key);
                }
            },
            insert(values) {
                return store.insert(values);
            },
            update(key, values) {
                return store.update(key, values);
            },
            remove(key) {
                return store.remove(key);
            },
            totalCount(loadOptions) {
                return store.totalCount(loadOptions);
            }
        });
    }

    public static async query(url: string, params?: object) {
        if (!url.startsWith("http")) {
            url = ApiService.getEndpoint(url);
        }

        if (params) {
            var u = new URL(url);
            var searchParams = u.searchParams;

            for (let key in params) {
                searchParams.append(key, params[key]);
            }

            u.search = searchParams.toString();
            url = u.toString();
        }

        const response = await fetch(
            url,
            {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem(SecurityService.AccessTokenKey)
                }
            });

        if (response.ok) {
            return await response.json();
        } else {
            return Promise.reject(response.statusText);
        }
    }

    public static async deleteV2(appController: AppController, e: { component: dxDataGrid; rowIndex: number }) {
        appController.confirmV2(Confirmation.delete("Are you sure you want to delete this record?")).then(() => {
            e.component.deleteRow(e.rowIndex);
        });
    }

    /**
    * @deprecated The method should not be used
    */
    public static delete(appController: AppController, dataSource: DataSource, key: object, confirmText: string, successText: string) {
        appController.confirmV2(Confirmation.delete(confirmText)).then(() => {
            dataSource.store().remove(key).then(() => {
                dataSource.reload();
                appController.notice(successText);
            });
        });
    }

    public static users(paginate = false): CustomDataSource {
        return new DataSource({
            store: AppData.createStore("id", {
                loadUrl: ApiService.getEndpoint(environment.api_erp_user)
            }),
            select: ["id", "name"],
            searchExpr: "name",
            sort: "name",
            paginate: paginate
        });
    }

    public static customers(): CustomDataSourceOptions {
        return {
            store: AppData.createStore("account", {
                loadUrl: ApiService.getEndpoint(environment.api_erp_customers)
            }),
            select: ["account", "name"],
            searchExpr: ["account", "name"],
            sort: "name"
        };
    }

    public static suppliers(): CustomDataSourceOptions {
        return {
            store: AppData.createStore("account", {
                loadUrl: ApiService.getEndpoint(environment.api_erp_suppliers)
            }),
            select: ["account", "name"],
            searchExpr: ["account", "name"],
            sort: "name"
        };
    }

    public static warehouses(stocked = false): CustomDataSourceOptions {
        return {
            store: AppData.createStore("warehouse", {
                loadUrl: ApiService.getEndpoint(environment.api_erp_warehouse)
            }),
            select: ["warehouse", "description"],
            searchExpr: ["warehouse", "description"],
            sort: "warehouse",
            filter: stocked ? ["warehouse", "<>", "**"] : undefined
        };
    }

    public static bins(warehouse: string): CustomDataSourceOptions {
        return {
            store: AppData.createStore("bin", {
                loadUrl: ApiService.getEndpoint(environment.api_erp_warehouse_bin),
                loadParams: { warehouse: warehouse }
            }),
            select: ["bin", "description"],
            searchExpr: ["bin", "description"],
            sort: "bin"
        };
    }

    public static inventory(warehouse?: string): CustomDataSourceOptions {
        return {
            store: AppData.createStore("code", {
                loadUrl: ApiService.getEndpoint(environment.api_erp_inventory),
                loadParams: { warehouse: warehouse }
            }),
            select: ["code", "description"],
            searchExpr: ["code", "description"],
            sort: "code"
        };
    }

    public static tickets(): CustomDataSourceOptions {
        return {
            store: AppData.createStore("id", environment.api_erp_tickets)
        };
    }

    public static jobs(ticketId?: string): CustomDataSourceOptions {
        return {
            store: AppData.createStore("id", environment.api_erp_jobs, {
                loadParams: {
                    ticketId: ticketId
                }
            })
        };
    }

    public static leads(): CustomDataSourceOptions {
        return {
            store: AppData.createStore("id", environment.api_erp_leads)
        };
    }

    public static taxCodes(): CustomDataSourceOptions {
        return {
            store: AppData.createStore("code", {
                loadUrl: ApiService.getEndpoint(environment.api_erp_tax_codes)
            }),
            select: ["code", "description"],
            searchExpr: ["code", "description"],
            sort: "code"
        };
    }
}
