import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserApi } from 'src/app/api/user.api';
import { BaseForm } from 'src/app/page/base-form.component';

@Component({
  selector: 'app-modal-project-step',
  templateUrl: './modal-project-step.component.html',
  styleUrls: ['./modal-project-step.component.scss']
})
export class ModalProjectStepComponent extends BaseForm implements OnInit {

  public readonly subject: any;

  form: FormGroup
  users: any[];

  constructor(
    public dialogRef: MatDialogRef<ModalProjectStepComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private readonly _userApi: UserApi,
    private readonly _formBuilder: FormBuilder) {
    super();

    this.subject = data.subject;
  }

  ngOnInit() {

    this.getUsers();

    this.form = this._formBuilder.group({
      id: [null],
      name: [null, [Validators.required]],
      description: [null],
      duration: [null],
      userId: [null],
      notApplicable: [false],
      projectId: [null]
    });

    if (this.subject) {
      this.form.patchValue(this.subject);
    }
  }

  getUsers() {
    this._userApi.findAll().subscribe(results => {
      console.debug("results", results);
      this.users = results.data;
    }, (error: any) => {
      console.error("failed to find users", error);
    });
  }
}
