import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import DataSource from 'devextreme/data/data_source';
import * as _ from "lodash";
import { ListSupplierClassApi } from 'src/app/api/list-supplier-class.api';
import { SupplierApi } from 'src/app/api/supplier.api';
import { AppController } from 'src/app/app.controller';
import { AppData } from 'src/app/app.data';
import { AppUtility } from 'src/app/app.utility';
import { Confirmation } from 'src/app/modal/modal-confirmation/confirmation';
import { BaseDataListing } from 'src/app/page/base-data-list.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-supplier-list',
  templateUrl: './supplier-list.component.html',
  styleUrls: ['./supplier-list.component.scss']
})
export class SupplierListComponent extends BaseDataListing implements OnInit {

  public readonly cancellationTerms = new DataSource(AppData.createStore("value", environment.api_erp_list_cancellationterms));

  form: FormGroup;
  currentSupplier: any;
  supplierClasses: any[];
  taxStatuses: string[] = ["N", "E"];
  canEditAccount: boolean = true;

  constructor(
    private readonly _supplierApi: SupplierApi,
    private readonly _listSupplierClassApi: ListSupplierClassApi,
    private readonly _formBuilder: FormBuilder,
    public readonly appUtility: AppUtility,
    public readonly appController: AppController) {

    super();

    this.apis = {
      supplierApi: _supplierApi,
      listSupplierClassApi: _listSupplierClassApi
    }

    // this.querySort = [{ "selector": "created", "desc": false }];
    this.actionMenu = {
      edit: (element: any) => {
        console.debug("edit ", element);
        this.getSupplier(element);
      },
      delete: (element: any) => {
        console.debug("delete ", element);

        this.appController.confirm((proceed) => {
          if (proceed) {
            console.debug("delete", element);
            this.apis.supplierApi.remove(element.account).subscribe((results) => {
              console.debug("deleted ", results);
              // this.datasourceAssignData(this.datasourceRemoveByKeyValue(this.dataSource, "account", element.account));
              // console.debug("update datasource ", this.dataSource);
              this.refresh();
            }, (error) => {
              console.error("failed to delete ", error);
            })
          }
        }, new Confirmation("delete_outline",
          `Are you sure you want delete supplier ${element.account}?`,
          "Cancel", "Delete")
        );
      }
    };
  }

  initActionMenu() {
    this.actionMenu = [];
  }

  ngOnInit() {

    this.getSupplierClass();

    this.form = this._formBuilder.group({
      id: [null],
      name: [null, [Validators.required]],
      account: [null, Validators.required],
      accountRef: [null],
      contactName: [null],
      contactPhone: [null],
      contactEmail: [null, [Validators.email]],
      contactEmailCopy: [null, [Validators.email]],
      vatNumber: [null],
      taxStatus: [null],
      supplierClass: [null, Validators.required],
      paymentTerms: [null],
      cancellationTerms: [null],
      address: [null],
      address1: [null],
      address2: [null],
      address3: [null],
      addressCode: [null],
    });

    this.queryData(this.queryLimit, this.queryCurrentPage, true, this.querySort, this.queryFilter);
  }

  getSupplier(element) {
    this.apis.supplierApi.find(element.account).subscribe((results) => {
      console.debug("supplier", results);
      this.resetForm();

      this.canEditAccount = false;
      results["address"] = this.appUtility.combineObjectValues(results, ['address1', 'address2', 'address3', 'addressCode']);
      this.currentSupplier = results;
      this.form.patchValue(results);
    }, (error) => {
      console.error("Unable to obtain supplier", error);
    })
  }

  getSupplierClass() {
    this.apis.listSupplierClassApi.findAll().subscribe((results) => {
      console.debug("supplier list", results);
      this.supplierClasses = results.data;
    }, (error) => {
      console.error("failed to obtain supplier class", error);
    })
  }
  get columnLabels(): string[] {
    return ['Account', 'Name', 'Email', 'Phone', 'On-Hold', ''];
  }

  get columnKeys(): string[] {
    return ['account', 'name', 'contactEmail', 'contactPhone', 'onHold', 'action'];
  }

  queryData(take: number, skip: number, count: boolean, sort: any[], filter?: any[]) {
    this.query = new HttpParams()
      .set("take", `${take}`)
      .set("skip", `${skip}`)
      .set("requireTotalCount", `${count}`);

    this.query = this.queryAdd(this.query, this.queryParams);
    this.query = this.queryAddSort(this.query, sort);
    this.query = this.queryAddFilter(this.query, filter);

    console.debug("query ", this.query);

    this._supplierApi.findAll(this.query,

    ).subscribe((results: any) => {
      console.debug("tax codes", results);
      this.dataSource = results;

      if (this.currentSupplier == undefined || this.currentSupplier == null) {
        // this.currentSupplier = _.first((this.dataSource.data as []));
        this.getSupplier(_.first((this.dataSource.data as [])))
        // this.canEditAccount = false;
        // this.form.patchValue(this.currentSupplier);
      }

    }, (error) => {
      console.debug("failed to obtain tax codes list", error);
    })
  }

  onSearch(value: string) {
    console.debug("search ", value);
    this.queryFilter = null;
    console.debug("searching for ", value);
    this.resetForm();

    if (value !== '') {
      this.queryFilter = [["account", "contains", `${value}`], "or", ["name", "contains", `${value}`], "or", ["accountRef", "contains", `${value}`]]
      this.queryData(this.queryLimit, this.queryCurrentPage, true, this.querySort, this.queryFilter);
    } else {
      this.queryData(this.queryLimit, this.queryCurrentPage, true, this.querySort);
    }
  }

  submit() {
    if (this.form.valid) {
      let data = this.form.value;

      if (this.canEditAccount) {
        this.apis.supplierApi.create(data).subscribe((results: any) => {
          console.debug("saved Supplier", results);
          this.appController.notice(`Successfully created Supplier, ${results.name}`);
          this.form.patchValue(results);
          this.canEditAccount = false;
          this.refresh();
        }, (error) => {
          console.error("failed to save Supplier", error);
          this.appController.notice("Failed to save Supplier");
        });
      } else {
        console.debug("updating with ", data, " omitted ", this.appUtility.omit(data, ["account"]));
        this.apis.supplierApi.update(data.account, this.appUtility.omit(data, ["account"])).subscribe((results: any) => {
          console.debug("updated Supplier", results);
          this.appController.notice(`Successfully updated Supplier, ${results.name}`);
          this.form.patchValue(results);
          this.refresh();
        }, (error) => {
          console.error("failed to save Supplier", error);
          this.appController.notice("Failed to save Supplier");
        });
      }
    }
  }

  new() {
    this.resetForm();
  }

  addressChanged(value: string[]) {
    if (value) {
      this.form.get("address1").setValue(value[0]);
      this.form.get("address2").setValue(value[1] + "," + value[2]);
      this.form.get("address3").setValue(value[4]);
      this.form.get("addressCode").setValue(value[3]);

      this.form.markAsDirty();
    }
  }

  resetForm() {
    this.currentSupplier = null;
    this.canEditAccount = true;
    this.form.reset();
  }

  getFormValue(form: FormGroup, key: string): any {
    return form.get(key).value;
  }

  onCustomItemCreating(e) {
    e.customItem = { value: e.text, text: e.text };
  }
}
