import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { QuoteDataApi } from 'src/app/api/quote-data.api';
import { AppConstant } from 'src/app/app.constant';
import { AppController } from 'src/app/app.controller';
import { QuoteCalculationHelper } from 'src/app/helper/quote-calculation.helper';
import { ModalProductSelectComponent } from 'src/app/modal/modal-product-select/modal-product-select.component';
import { FormGroupService } from 'src/app/service/form.service';

@Component({
    selector: 'app-quote-form-print',
    templateUrl: './quote-form-print.component.html',
    styleUrls: ['./quote-form-print.component.scss']
})
export class QuoteFormPrintComponent implements OnInit {

    readonly calculateRequested: EventEmitter<boolean> = new EventEmitter();

    @Input() subject: any;

    private _products: any[];

    public product: any;
    public defaultAccessories: any[];

    constructor(
        private readonly _quoteDataApi: QuoteDataApi,
        private readonly _appController: AppController,
        private readonly _formBuilder: FormBuilder,
        private readonly _quoteCalculationHelper: QuoteCalculationHelper,
        public formGroupService: FormGroupService) {
    }

    ngOnInit() {
        this.getProducts();
        this.formGroupService.mergeFormControls(this.getForm(this.subject));
    }

    ngAfterViewInit() {
        this.formGroupService.formGroup.valueChanges
            .subscribe(() => {
                this.calculate(true);
            });
    }

    private getForm(subject?: any): FormGroup {
        let formGroup: FormGroup = this._formBuilder.group({
            product: [null],
            productDescription: [null],
            productCost: [null],
            quantity: [1],
            costBlackWhite: [0.0],
            costColour: [0.0],
            origCostBlackWhite: [0.0],
            origCostColour: [0.0],
            tier: [null],
            accessories: [null],
            accessoriesTotal: [0.0],
            customLines: [null],
            customLinesTotal: [0.0]
        });

        if (subject) {
            formGroup.patchValue(subject);
        }

        return formGroup;
    }

    getProducts() {
        this._quoteDataApi.getPrint().subscribe(results => {
            this._products = (results.data as any[]).map(item => ({
                code: item.code,
                description: item.description,
                longDescription: item.long_description,
                spec: item.spec,
                image: "https://prometheansociety.com" + item.image,
                price: item.price,
                item: item
            }));

            if (this.subject && this.subject.product) {
                this.product = this._products.find(_ => _.description === this.subject.product);

                //var product = this._products.find(_ => _.description === this.subject.product);
                //this.setSelectedProduct(product);
            }
        });
    }

    selectProduct() {
        this._appController.openModal((modalItem: any) => {
            this.setSelectedProduct(modalItem);
        }, ModalProductSelectComponent, {
            width: AppConstant.SIZE.MODAL_LARGE,
            data: { products: this._products }
        });
    }

    setSelectedProduct(product: any) {
        this.product = product;

        if (product) {
            if (product.item.required) {
                for (let requiredItem of product.item.required) {
                    product.item.accessories.push(requiredItem);
                }
            }

            this.formGroupService.setValue("product", product.description);
            this.formGroupService.setValue("productDescription", product.spec?.replace(/\\r\\n/g, "\r\n"));
            this.formGroupService.setValue("productCost", product.price);
            this.formGroupService.setValue("quantity", 1);
            this.formGroupService.setValue("costBlackWhite", product.item.cpc.bw || 0);
            this.formGroupService.setValue("costColour", product.item.cpc.colour || 0);

            this.formGroupService.setValue("origCostBlackWhite", product.item.cpc.bw || 0);
            this.formGroupService.setValue("origCostColour", product.item.cpc.colour || 0);
        }
    }

    accessoriesChanged(e) {
        this.formGroupService.setValue("accessories", e.accessories, false);
        this.formGroupService.setValue("accessoriesTotal", e.total, false);
        this.calculate(true);
    }

    adHocChanged(e) {
        this.formGroupService.setValue("customLines", e.adHocs, false);
        this.formGroupService.setValue("customLinesTotal", e.total), false;
        this.calculate(true);
    }

    calculate(silent?: boolean) {
        if (!silent) {
            this.calculateRequested.emit();
        }

        let termsType = this.formGroupService.getValue("termsType");

        let total = this.formGroupService.getFloat("productCost", 0.0) + this.formGroupService.getFloat("accessoriesTotal") + this.formGroupService.getFloat("customLinesTotal");
        total *= this.formGroupService.getFloat("quantity");
        total += this.formGroupService.getFloat("settlement", 0.0);

        let margin = this.formGroupService.getFloat("margin");
        let marginType = this.formGroupService.getValue("marginType");
        let period = this.formGroupService.getFloat("period");
        let esc = this.formGroupService.getFloat("escalation", 0);

        let totalMargin = this._quoteCalculationHelper.calculateMargin(margin, marginType, total) || 0;
        this.formGroupService.setValue("totalMargin", totalMargin, false);

        var tier = this.formGroupService.getFloat("tier");

        total += totalMargin;
        total += ((this.formGroupService.getFloat("origCostBlackWhite") - this.formGroupService.getFloat("costBlackWhite")) * tier * period) / 100 || 0;
        total += ((this.formGroupService.getFloat("origCostColour") - this.formGroupService.getFloat("costColour")) * tier * period) / 100 || 0;

        if (termsType === "Rental") {
            var factor = this._quoteCalculationHelper.calculateFactor(total, margin, marginType, period, esc);
            this.formGroupService.setValue("factor", factor, false);

            total *= factor;
        }

        this.formGroupService.setValue("totalMonthly", total, false);
        this.formGroupService.setValue("totalAnnual", total * 12, false);
    }
}
