import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import { QuoteLine } from 'src/app/model/quote';

@Component({
  selector: 'app-partial-quotation-ad-hoc',
  templateUrl: './partial-quotation-ad-hoc.component.html',
  styleUrls: ['./partial-quotation-ad-hoc.component.scss']
})
export class PartialQuotationAdHocComponent implements OnInit {

  @ViewChild("ad_hoc_grid") dataGrid: DxDataGridComponent;

  @Input() subject: any;
  @Input() calculate: EventEmitter<void>;
  @Output() changed = new EventEmitter<any>();

  public dataSource: DataSource;

  ngOnInit() {
    this.dataSource = new DataSource({
      store: new ArrayStore({
        data: this.subject?.adHocs || []
      }),
      reshapeOnPush: true
    });

    this.calculate.subscribe(async () => {
      await this.dataGrid.instance.refresh();
      this.dataGridChanged();
    });
  }

  add() {
    this.dataGrid.instance.addRow();
  }

  adHocTotalCellValue(e: QuoteLine) {
    if (e.quantity && e.price) {
      return e.quantity * e.price;
    }

    return 0;
  }

  dataGridChanged() {
    this.changed.emit({
      adHocs: this.dataSource.items(),
      total: this.dataGrid.instance.getTotalSummaryValue("total")
    });
  }
}
