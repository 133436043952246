import { Component } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { ApiService } from 'src/app/api/api.service';
import { BillingApi } from 'src/app/api/billing.api';
import { AppController } from 'src/app/app.controller';
import { AppData } from 'src/app/app.data';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-billing-list',
    templateUrl: './billing-list.component.html',
    styleUrls: ['./billing-list.component.scss']
})
export class BillingListComponent {

    public readonly dataSource = new DataSource(AppData.createStore("id", environment.api_erp_billing));
    public readonly runTypes = [{ text: "Main", value: "M" }, { text: "Adhoc", value: "A" }];
    public readonly periods = {
        store: AppData.createStore("periodCode", {
            loadUrl: ApiService.getEndpoint(environment.api_erp_finance_periods)
        })
    };

    constructor(
        private readonly _billingApi: BillingApi,
        private readonly _appController: AppController) {
    }

    calculate(run: any) {
        this._appController.notice(`Calculating bill run`);
        this._billingApi.calculate(run.id)
            .subscribe(() => {
                this._appController.notice(`Calculated bill run`);
            });
    }

    export(run: any) {
        window.location.href = `https://prometheansociety.com/erp/api/billing/${run.id}/export`;
    }

    email(run: any) {
        this._billingApi.email(run.id);
    }

    print(run: any) {
        window.open(`https://prometheansociety.com/erp/reports/report?name=Billing&RunId=${run.id}`, '_blank');
    }
}
