import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DxFileUploaderComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import query from 'devextreme/data/query';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ApiService } from 'src/app/api/api.service';
import { LeadTaskApi } from 'src/app/api/lead-task.api';
import { LeadApi } from 'src/app/api/lead.api';
import { LocationContactApi } from 'src/app/api/location-contact.api';
import { LocationLeadApi } from 'src/app/api/location-lead.api';
import { AppConstant } from 'src/app/app.constant';
import { AppController } from 'src/app/app.controller';
import { AppData } from 'src/app/app.data';
import { Confirmation } from 'src/app/modal/modal-confirmation/confirmation';
import { ModalFormSelectComponent } from 'src/app/modal/modal-form-select/modal-form-select.component';
import { ModalSelectCustomerComponent } from 'src/app/modal/modal-select-customer/modal-select-customer.component';
import { SecurityService } from 'src/app/service/security.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-lead-form',
    templateUrl: './lead-form.component.html',
    styleUrls: ['./lead-form.component.scss']
})
export class LeadFormComponent implements OnInit {

    @ViewChild(DxFileUploaderComponent) uploader: DxFileUploaderComponent;

    public readonly users = AppData.users(true);
    public readonly salespersons = AppData.users(true);
    public readonly search = new DataSource({
        store: AppData.createStore(null, {
            loadUrl: ApiService.getEndpoint(environment.api_erp_list_search)
        })
    });

    public form: FormGroup;

    public margin = 0;
    public outstandingTaskCount = 0;

    public selectedIndex = 0;
    public meetingDate: Date;
    public quotedDate: Date;

    public stages = ["analysis", "quoting", "negotiation", "won", "lost"];
    public types = ["notset", "voice", "print", "CCTV", "video", "web", "aircons"];
    public probability = [0, 25, 50, 75, 100];

    constructor(
        private readonly _leadApi: LeadApi,
        private readonly _leadTaskApi: LeadTaskApi,
        private readonly _leadLocationApi: LocationLeadApi,
        private readonly _locationContactApi: LocationContactApi,
        private readonly _activatedRouter: ActivatedRoute,
        private readonly _formBuilder: FormBuilder,
        private readonly _router: Router,
        public readonly appController: AppController,
        public readonly securityService: SecurityService) {
    }

    ngOnInit() {
        this.form = this._formBuilder.group({
            id: [null],
            leadType: ['L'],

            name: [null, [Validators.required]],
            tradingName: [null],

            type: ['notset', [Validators.required]],
            stage: [null, [Validators.required]],
            stageComment: [null],
            probability: [0],
            leadSource: [null],

            contactName: [null, [Validators.required]],
            contactEmail: [null, [Validators.required, Validators.email]],
            contactPhone: [null, [Validators.required]],

            phone: [null, [Validators.required]],
            web: [null],

            turnover: [null],
            staff: [null],
            equipment: [null],

            salesperson1Id: [this.securityService.getAuthenticatedUserId(), [Validators.required]],
            salesperson1: [{ value: null, disabled: true }],
            salesperson2Id: [null],
            salesperson2: [{ value: null, disabled: true }],
            salesperson3Id: [null],
            salesperson3: [{ value: null, disabled: true }],

            negotiation: [{ value: null, disabled: true }],
            created: [{ value: null, disabled: true }],

            address1: [null],
            address2: [null],
            address3: [null],
            addressCode: [null],

            quoted: [false],
            quotedDocumentName: [null],
            file: this._formBuilder.group({
                file: [null],
                fileName: [null]
            }),

            // not sent to server
            address: [null]
        });

        this._activatedRouter.params.subscribe(params => {
            if (params.id) {
                this.form.get("contactName").disable();
                this.form.get("contactEmail").disable();
                this.form.get("contactPhone").disable();

                this._leadApi.find(params.id)
                    .subscribe(lead => {
                        if (lead) {
                            if (lead.locations && lead.locations.length > 0) {
                                lead.address = [lead.locations[0].address1, lead.locations[0].address2, lead.locations[0].address3, lead.locations[0].addressCode].join();

                                if (lead.locations[0].contacts && lead.locations[0].contacts.length > 0) {
                                    lead.contactName = lead.locations[0].contacts[0].name;
                                    lead.contactEmail = lead.locations[0].contacts[0].email;
                                    lead.contactPhone = lead.locations[0].contacts[0].phone1;
                                }
                            }

                            new DataSource(AppData.createStore("id", environment.api_quotes_quotes, {
                                loadParams: {
                                    leadId: lead.id
                                }
                            })).load().then((quotes: any[]) => {
                                query(quotes).min("margin").then((margin: number) => {
                                    this.margin = margin;
                                });

                                if (lead.tasks.length > 0) {
                                    setTimeout(() => this.selectedIndex = 1);
                                    this.meetingDate = lead.tasks.find(_ => _.type === "meeting")?.startTime;
                                }

                                if (quotes.length > 0) {
                                    setTimeout(() => this.selectedIndex = 2);
                                    this.quotedDate = quotes[0].createdAt;
                                } else {
                                    if (lead.quoted && !lead.quotedDocumentName) {
                                        this.appController.notice(`Lead has no quote attached. Please attach a quote`);
                                    }
                                }
                            });

                            this.form.patchValue(lead);
                        }
                    });

                this.getOutstandingTasks(params.id);
            }
        });

        this.form.get("name").valueChanges
            .subscribe((name: string) => {
                if (!this.form.value.tradingName) {
                    this.form.get("tradingName").setValue(name);
                }
            });

        this.form.get("stage").valueChanges
            .pipe(distinctUntilChanged(), debounceTime(500))
            .subscribe((stage: string) => {
                if (!this.form.get('stage').dirty) {
                    return;
                }

                if (stage === 'lost') {
                    this.selectLostReason();
                } else {
                    this.submit();
                }
            });
    }

    addressChanged(value: string[]) {
        if (value) {
            this.form.patchValue({
                address1: value[0],
                address2: value[1] + "," + value[2],
                address3: value[4],
                addressCode: value[3]
            });

            this.form.markAsDirty();
        }
    }

    onSelectionChanged(e) {
        if (e.selectedItem) {
            this.form.patchValue({
                name: e.selectedItem.name,
                tradingName: e.selectedItem.tradingName
            });

            if (e.selectedItem.locations && e.selectedItem.locations.length > 0) {
                this.form.patchValue({
                    address: [e.selectedItem.locations[0].address1, e.selectedItem.locations[0].address2, e.selectedItem.locations[0].address3, e.selectedItem.locations[0].addressCode].join(),
                    address1: e.selectedItem.locations[0].address1,
                    address2: e.selectedItem.locations[0].address2,
                    address3: e.selectedItem.locations[0].address3,
                    addressCode: e.selectedItem.locations[0].addressCode,
                    contactName: e.selectedItem.locations[0].contacts[0].name,
                    contactEmail: e.selectedItem.locations[0].contacts[0].email,
                    contactPhone: e.selectedItem.locations[0].contacts[0].phone1
                });
            }
        }
    }

    public onFileUploaded(e: { value?: File[] }) {
        if (e.value && e.value[0]) {
            var file = e.value[0];

            var fr = new FileReader();
            fr.onload = args => {
                var content = args.target.result as string;
                var base64 = content.split(',')[1];
                this.form.get("file").get("file").setValue(base64);
                this.form.get("file").get("fileName").setValue(file.name);
                this.form.get("file").markAsDirty();
            };
            fr.readAsDataURL(file);
        }
    }

    selectLostReason() {
        this.appController.openModal((value: string) => {
            this.form.get("stageComment").setValue(value);
            this.submit();
        }, ModalFormSelectComponent, {
            width: AppConstant.SIZE.MODAL_SMALL,
            data: {
                label: "Reason",
                valueKey: "reason",
                labelKey: "reason",
                editable: true,
                dataSource: [
                    { "reason": "Client wasn't ready to make any changes" },
                    { "reason": "Lost to competitor" },
                    { "reason": "Cost-savings was too small" },
                    { "reason": "Cost too expensive" },
                    { "reason": "Too early in contract" }
                ]
            }
        });
    }

    getOutstandingTasks(leadId: number) {
        this._leadTaskApi.countOutstanding(leadId).subscribe((results) => {
            this.outstandingTaskCount = results;
        });
    }

    convertToCustomer() {
        this.appController.openModal((account: string) => {
            if (account) {
                this._leadApi.convertToCustomer(this.form.value.id, { account: account, customerClass: "none" }).subscribe(() => {
                    this.appController.notice("Customer created");
                    this._router.navigate(["/customer/", account]);
                });
            }
        }, ModalSelectCustomerComponent, {
            width: AppConstant.SIZE.MODAL_SMALL
        });
    }

    async submit() {
        if (this.form.valid) {
            let data = this.form.value;
            if (!data.id) {
                this._leadApi.create(data)
                    .subscribe(lead => {
                        this.appController.notice("Lead created");
                        this.uploader?.instance?.reset();

                        const location = {
                            leadId: lead.id,
                            name: "Main Location",
                            description: "Main Location",
                            address1: data.address1,
                            address2: data.address2,
                            address3: data.address3,
                            addressCode: data.addressCode,
                            phone: data.phone
                        };

                        this._leadLocationApi.create(location)
                            .subscribe(location => {
                                const locationContact = {
                                    locationId: location.id,
                                    name: data.contactName,
                                    email: data.contactEmail,
                                    phone1: data.contactPhone,
                                    title: "Contact",
                                    primary: true
                                };

                                this._locationContactApi.create(locationContact).subscribe(() => {
                                    this._router.navigate(["/customer/lead/", lead.id]);
                                });
                            });
                    });
            } else {
                const lead = await AppData.leads().store.byKey(data.id);
                if (lead?.locations.length > 0) {
                    const location = lead.locations[0];
                    location.address1 = data.address1;
                    location.address2 = data.address2;
                    location.address3 = data.address3;
                    location.addressCode = data.addressCode;

                    await this._leadLocationApi.update(location.id, location).toPromise();

                    if (location.contacts?.length > 0) {
                        const contact = location.contacts[0];
                        contact.name = data.contactName;
                        contact.email = data.contactEmail;
                        contact.phone1 = data.contactPhone;

                        await this._locationContactApi.update(contact.id, contact).toPromise();
                    }
                } else if (data.contactName || data.contactPhone || data.contactEmail) {
                    try {
                        await this.appController.confirmV2(new Confirmation("", "Contact information will not be saved. Do you want to continue?"));
                    } catch (e) {
                        return;
                    }
                }

                AppData.leads().store.update(data.id, data).then(lead => {
                    this.form.patchValue(lead, { emitEvent: false });
                    this.uploader?.instance?.reset();
                });
            }
        }
    }
}
