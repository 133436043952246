import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/api/api.service';
import { AppController } from 'src/app/app.controller';

@Component({
  selector: 'app-modal-form-service-level-agreement-plan-target',
  templateUrl: './modal-form-service-level-agreement-plan-target.component.html',
  styleUrls: ['./modal-form-service-level-agreement-plan-target.component.scss']
})
export class ModalFormServiceLevelAgreementPlanTargetComponent implements OnInit {

  private readonly subject: any;
  private readonly services: { [key: string]: ApiService };
  private readonly queryParams: any;

  form: FormGroup;

  priorities: any[] = [{ name: "High", value: "1" }, { name: "Medium", value: "3" }, { name: "Low", value: "3" }];
  timeUnits: string[] = ["min", "hour", 'day', "month"];

  constructor(
    public dialogRef: MatDialogRef<ModalFormServiceLevelAgreementPlanTargetComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private readonly _formBuilder: FormBuilder,
    public readonly appController: AppController) {

    this.services = data.services;
    this.subject = data.subject;
    this.queryParams = data.queryParams;
  }

  ngOnInit() {
    this.form = this._formBuilder.group({
      id: [null],
      servicePlanId: [this.queryParams.servicePlanId, [Validators.required]],
      priority: [null, [Validators.required]],
      respondTime: [null, [Validators.required]],
      respondTimeUnit: [null, [Validators.required]],
      resolveTime: [null, [Validators.required]],
      resolveTimeUnit: [null, [Validators.required]],
      businessHoursRate: [null],
      afterHoursRate: [null]
    });

    if (this.subject) {
      this.form.patchValue(this.subject);
    }
  }

  save() {
    if (this.form.valid) {
      this.dialogRef.close(this.form.value);
    }
  }
}
