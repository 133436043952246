import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { User } from 'src/app/model/user';

@Injectable()
export class SecurityService implements CanActivate {

  public static readonly UserKey = "zahxa6Oorailucheingieh6Eezu5ooPh8Vailohfee5ya1iengoi7aivohjishaiZivuhoT3Ei4kohvie1aechoh3ejoh9nah0Ee";
  public static readonly AccessTokenKey = "eeDiruiM4reiph5iegheiboanae8Echahwee5eel5emaabeeke2ahh1zohr2quae1ohtic3eHoiMeeheiXohtae0aep0heihu2Ai";
  public static readonly PermissionsKey = "Permissions";
  private jwt: JwtHelperService;

  constructor(private readonly _router: Router) {
  }

  public isAuthenticated = () => this.getAuthenticatedUserToken();

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (localStorage.getItem(SecurityService.AccessTokenKey)) {
      return true;
    } else {
      return this._router.parseUrl('login');
    }
  }

  public isInRole(roles: string | string[]) {
    if (typeof roles === "string") {
      roles = [roles];
    }

    var user = (this.jwt ?? (this.jwt = new JwtHelperService())).decodeToken(this.getAuthenticatedUserToken()) as User;
    return roles.includes(user.role);
  }

  public hasAccess(key: string) {
    var keys = localStorage.getItem(SecurityService.PermissionsKey);
    if (keys) {
      var k = JSON.parse(keys) as string[];
      return k.includes(key);
    }

    return false;
  }

  public getAuthenticatedUserToken() {
    return localStorage.getItem(SecurityService.AccessTokenKey);
  }

  public getAuthenticatedUser() {
    var user = localStorage.getItem(SecurityService.UserKey);
    if (user) {
      return JSON.parse(user) as User;
    }

    return null;
  }

  public getAuthenticatedUserId() {
    return this.getAuthenticatedUser()?.nameid;
  }

  public logout() {
    // this._securityApi.logout().
    localStorage.clear();
  }
}
