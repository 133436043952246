import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';

@Injectable()
export class LeadApi extends ApiService {
  constructor(httpClient: HttpClient) {
    super(environment.api_erp_leads, httpClient);
  }

  public pipeline() {
    var apiEndpoint = this.getEndpoint(environment.api_erp_leads_agg_pipeline);
    return this.get(apiEndpoint);
  }

  public convertToCustomer(id: number, data: { account: string; customerClass: string; }) {
    var apiEndpoint = this.getEndpoint(environment.api_erp_lead_prospects) + "/" + id + "/convert";
    return this.post(apiEndpoint, data);
  }
}
