import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';
import { HttpService } from './HttpService';

@Injectable()
export class StockRequestApi extends HttpService {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  createProjectPurchaseOrder = (supplier: string, orders: number[]) => {
    return this.post(this.getEndpoint(environment.api_erp_project_requests_purchaseorder), {
      supplier: supplier,
      orders: orders
    });
  }

  createSalesOrderPurchaseOrder = (supplier: string, lines: [{ orderNumber: number; orderLine: number }]) => {
    return this.post(this.getEndpoint(environment.api_erp_salesorder_requests_purchaseorder), {
      supplier: supplier,
      lines: lines
    });
  }
}
