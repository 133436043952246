import { Component, Input, OnInit } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { AppData } from 'src/app/app.data';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-partial-purchase-order-grn',
    templateUrl: './partial-purchase-order-grn.component.html'
})
export class PartialPurchaseOrderGrnComponent implements OnInit {

    @Input() public orderNumber: string;

    public dataSource: DataSource

    ngOnInit() {
        this.dataSource = new DataSource({
            store: AppData.createStore("goodsReceivedNumber", environment.api_erp_goods_received, {
                loadParams: {
                    orderNumber: this.orderNumber
                }
            })
        });
    }
}
