import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InventoryApi } from 'src/app/api/inventory.api';

@Component({
    selector: 'app-inventory-view',
    templateUrl: './inventory-view.component.html',
    styleUrls: ['./inventory-view.component.scss']
})
export class InventoryViewComponent implements OnInit {

    product: any;
    code: string;

    constructor(
        private readonly _inventoryApi: InventoryApi,
        private readonly _activatedRoute: ActivatedRoute) {
    }

    ngOnInit() {
        this._activatedRoute.params.subscribe((params) => {
            if (params.code) {
                this.code = params.code;

                this._inventoryApi.find(params.code)
                    .subscribe(results => {
                        this.product = results;
                    });
            } else {
                this.product = {};
            }
        });
    }
}
