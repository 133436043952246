/// <reference types="@types/googlemaps" />

import { MapsAPILoader } from '@agm/core';
import { Component, ElementRef, EventEmitter, forwardRef, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-widget-address-field',
  templateUrl: './widget-address-field.component.html',
  styleUrls: ['./widget-address-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WidgetAddressFieldComponent),
      multi: true
    }
  ]
})
export class WidgetAddressFieldComponent implements OnInit, ControlValueAccessor {

  @ViewChild("addressSearchInput", { static: true }) addressSearchInput: ElementRef;

  @Output() addressChanged = new EventEmitter<string[]>();
  @Input() label: string;

  addressSearch: FormControl;
  onChange;

  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone) {
  }

  ngOnInit() {
    this.addressSearch = new FormControl(null);
    this.addressSearch.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(1000))
      .subscribe((newValue: string) => {
        if (newValue) {
          var parts = newValue.split(",");
          while (parts.length < 5) {
            parts.push("");
          }

          this.onChange(newValue);
          this.addressChanged.emit(parts.map(_ => _.trim()));
        }
      });

    this.mapsAPILoader.load().then(() => {
      let autocomplete = new google.maps.places.Autocomplete(this.addressSearchInput.nativeElement, {
        types: ["address"]
      });

      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          var address = autocomplete.getPlace().formatted_address;
          var value = address.split(",").map(_ => _.trim());

          this.onChange(address);
          this.addressChanged.emit(value);
        });
      });
    });
  }

  writeValue(obj: any) {
    if (this.addressSearch.value !== obj) {
      this.addressSearch.setValue(obj);
    }
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
  }

  setDisabledState?(isDisabled: boolean) {
  }
}
