import { Component } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { ApiService } from 'src/app/api/api.service';
import { AppData } from 'src/app/app.data';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-sales-period-list',
    templateUrl: './sales-period-list.component.html'
})
export class SalesPeriodListComponent {
    public readonly dataSource = new DataSource(AppData.createStore("periodCode", environment.api_erp_sales_periods));
    public readonly periods = {
        store: AppData.createStore("periodCode", {
            loadUrl: ApiService.getEndpoint(environment.api_erp_finance_periods)
        })
    };

    onEditorPreparing(e) {
        if (e.parentType === "dataRow") {
            if (e.dataField === "periodCode") {
                e.editorOptions.disabled = !e.row.isNewRow;
            }
        }
    }
}
