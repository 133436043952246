import { Component, Input, OnInit } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { ApiService } from 'src/app/api/api.service';
import { AppConstant } from 'src/app/app.constant';
import { AppController } from 'src/app/app.controller';
import { AppData } from 'src/app/app.data';
import { ModalProjectStepComponent } from 'src/app/modal/modal-project-step/modal-project-step.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-partial-project-step-task',
  templateUrl: './partial-project-step-task.component.html',
  styleUrls: ['./partial-project-step-task.component.scss']
})
export class PartialProjectStepTaskComponent implements OnInit {

  @Input() projectId: number;

  pipeline = [
    {
      group: "todo",
      title: "To-Do",
      items: []
    },
    {
      group: "inprogress",
      title: "In Progress",
      items: []
    },
    {
      group: "done",
      title: "Done",
      items: []
    }
  ];

  constructor(private readonly appController: AppController) {
  }

  ngOnInit() {
    new DataSource({
      store: AppData.createStore(null, {
        loadUrl: ApiService.getEndpoint(environment.api_erp_project_steps),
        loadParams: { projectId: this.projectId }
      }),
      filter: ["stepType", "T"]
    }).load().then(result => {
      result.forEach(step => {
        if (step.tasks.every(_ => _.complete)) {
          this.addToPiplineListByGroupName("done", step);
        } else if (step.tasks.some(_ => _.started || _.complete)) {
          this.addToPiplineListByGroupName("inprogress", step);
        } else {
          this.addToPiplineListByGroupName("todo", step);
        }
      });
    });
  }

  add(element?: any) {
    // this.appController.openModal((results: any) => {
    // }, ModalFormLocationContactQuickAddComponent,
    //   { width: "466px", data: { subject: element, services: this.services, queryParams: this.queryParams } });
  }

  view(step: any) {
    this.appController.openModal(() => {
      this.pipeline.forEach(_ => _.items = []);
      this.ngOnInit();
    },
      ModalProjectStepComponent,
      {
        width: AppConstant.SIZE.MODAL_LARGE,
        data: { subject: step }
      });
  }

  addToPiplineListByGroupName(name: string, step) {
    this.pipeline.find(_ => _.group === name).items.push(step);
  }
}
