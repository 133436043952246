import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProjectTaskApi } from 'src/app/api/project-task.api';
import { UserApi } from 'src/app/api/user.api';
import { AppController } from 'src/app/app.controller';
import { BaseForm } from 'src/app/page/base-form.component';

@Component({
  selector: 'app-modal-form-project-step-task-quick-add',
  templateUrl: './modal-form-project-step-task-quick-add.component.html',
  styleUrls: ['./modal-form-project-step-task-quick-add.component.scss']
})
export class ModalFormProjectStepTaskQuickAddComponent extends BaseForm implements OnInit {

  private readonly subject: any;
  private readonly queryParams: any;

  form: FormGroup;

  users: any[];
  statuses: string[] = ["Pending", "In-Progress", "Done"];

  constructor(
    public dialogRef: MatDialogRef<ModalFormProjectStepTaskQuickAddComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private readonly _userApi: UserApi,
    private readonly _taskApi: ProjectTaskApi,
    private readonly _formBuilder: FormBuilder,
    public readonly appController: AppController) {
    super();

    this.subject = data.subject;
    this.queryParams = data.queryParams;
  }

  ngOnInit() {
    this.form = this._formBuilder.group({
      id: [null],
      name: [null, [Validators.required]],
      userId: [null, [Validators.required]],
      stepId: [this.queryParams.stepId, [Validators.required]],
      notApplicable: [false],
      description: [null],
      started: [false],
      status: ["Pending"],
      complete: [false],
      duration: [false]
    });

    if (this.subject) {
      if (this.subject.started) {
        this.subject.status = "In-Progress";
      }

      if (this.subject.complete) {
        this.subject.status = "Done";
      }

      this.form.patchValue(this.subject);
    }

    this.getUsers();
  }

  getUsers() {
    this._userApi.findAll().subscribe((results) => {
      console.debug("results", results);
      this.users = results.data;
    });
  }

  setStatus() {
    switch (this.getValue(this.form, "status")) {
      case "Pending":
        this.setValue(this.form, "started", false);
        this.setValue(this.form, "complete", false);
        break;
      case "In-Progress":
        this.setValue(this.form, "started", true);
        this.setValue(this.form, "complete", false);
        break;
      case "Done":
        this.setValue(this.form, "started", true);
        this.setValue(this.form, "complete", true);
        break;
    }
  }
  save() {
    this.setStatus();

    if (this.form.valid) {
      let data = this.form.value;
      if (!data.id) {
        this._taskApi.create(data).subscribe((results) => {
          console.debug("saved project step task", results);
          this.appController.notice(`Successfully saved task ${data.name}`);
          this.dialogRef.close(results);

        }, (error) => {
          console.error("failed to save project step task", error);
          this.appController.notice(`failed to save task ${data.name}`);
        });
      } else {
        this._taskApi.update(data.id, data).subscribe((results) => {
          console.debug("updated project step task", results);
          this.appController.notice(`Successfully updated task ${data.name}`);
          this.dialogRef.close(results);
        }, (error) => {
          console.error("failed to update project step task", error);
          this.appController.notice(`failed to update task ${data.name}`);
        });
      }
    }
  }
}
