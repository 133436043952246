import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LocationCustomerApi } from 'src/app/api/location-customer.api';
import { LocationLeadApi } from 'src/app/api/location-lead.api';
import { AppController } from 'src/app/app.controller';

@Component({
  selector: 'app-modal-form-location-quick-add',
  templateUrl: './modal-form-location-quick-add.component.html',
  styleUrls: ['./modal-form-location-quick-add.component.scss']
})
export class ModalFormLocationQuickAddComponent implements OnInit {

  private readonly subject: any;
  private readonly account?: string;
  private readonly leadId?: number;

  public form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ModalFormLocationQuickAddComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private readonly _customerLocationApi: LocationCustomerApi,
    private readonly _leadLocationApi: LocationLeadApi,
    private readonly _formBuilder: FormBuilder,
    private readonly appController: AppController) {

    this.subject = data.subject;
    this.account = data.account;
    this.leadId = data.leadId;
  }

  ngOnInit() {
    this.form = this._formBuilder.group({
      id: [null],
      account: [this.account],
      leadId: [this.leadId],
      name: [null, [Validators.required]],
      description: [null],
      phone: [null],
      address1: [null],
      address2: [null],
      address3: [null],
      addressCode: [null],
      latitude: [null],
      longitude: [null],

      // not sent to server
      address: [null]
    });

    if (this.subject) {
      this.subject.address = [this.subject.address1, this.subject.address2, this.subject.address3, this.subject.addressCode].join();
      this.form.patchValue(this.subject);
    }
  }

  addressChanged(value: string[]) {
    if (value) {
      this.form.get("address1").setValue(value[0]);
      this.form.get("address2").setValue(value[1] + "," + value[2]);
      this.form.get("address3").setValue(value[4]);
      this.form.get("addressCode").setValue(value[3]);

      this.form.markAsDirty();
    }
  }

  save() {
    if (this.form.valid) {
      let data = this.form.value;
      if (!data.id) {
        this.getLocationApi()
          .create(data)
          .subscribe((results: any) => {
            this.appController.notice("Location created");
            this.dialogRef.close(results);
          });
      } else {
        this.getLocationApi()
          .update(data.id, data)
          .subscribe((results: any) => {
            this.appController.notice("Location updated");
            this.dialogRef.close(results);
          });
      }
    }
  }

  private getLocationApi() {
    if (this.account) {
      return this._customerLocationApi;
    }

    return this._leadLocationApi;
  }
}
