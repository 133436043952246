import { Component, Input, OnInit } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import dxDataGrid from 'devextreme/ui/data_grid';
import { AppController } from 'src/app/app.controller';
import { AppData } from 'src/app/app.data';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-partial-inventory-product-pricing',
  templateUrl: './partial-inventory-product-pricing.component.html',
  styleUrls: ['./partial-inventory-product-pricing.component.scss']
})
export class PartialInventoryProductPricingComponent implements OnInit {

  @Input() code: string;

  public dataSource: DataSource;

  constructor(private readonly _appController: AppController) {
  }

  ngOnInit() {
    this.dataSource = new DataSource({
      store: AppData.createStore(["code", "priceCode"], environment.api_erp_inventory_pricing, {
        loadParams: {
          code: this.code
        }
      })
    });
  }

  onInitNewRow(e) {
    e.data.code = this.code;
  }

  onEditorPreparing(e) {
    if (e.parentType === "dataRow") {
      if (e.dataField === "priceCode") {
        e.editorOptions.disabled = !e.row.isNewRow;
      }
    }
  }

  public edit(e: { component: dxDataGrid; rowIndex: number }) {
    e.component.editRow(e.rowIndex);
  }

  public delete(e: { component: dxDataGrid; rowIndex: number }) {
    AppData.deleteV2(this._appController, e);
  }
}
