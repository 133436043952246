import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as emailValidator from 'email-validator';
import { ApiService } from 'src/app/api/api.service';
import { BaseForm } from 'src/app/page/base-form.component';
import { SecurityService } from 'src/app/service/security.service';

@Component({
  selector: 'app-modal-form-task-email-quick-add',
  templateUrl: './modal-form-task-email-quick-add.component.html',
  styleUrls: ['./modal-form-task-email-quick-add.component.scss']
})
export class ModalFormTaskEmailQuickAddComponent extends BaseForm implements OnInit {

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  private readonly subject: any;
  private readonly services: { taskApi: ApiService; };
  private readonly queryParams: any;

  public form: FormGroup;
  public invites: string[];

  constructor(
    public dialogRef: MatDialogRef<ModalFormTaskEmailQuickAddComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private readonly _formBuilder: FormBuilder,
    private readonly securityService: SecurityService) {
    super();

    this.subject = data.subject;
    this.services = data.services;
    this.queryParams = data.queryParams;
  }

  ngOnInit() {
    this.form = this._formBuilder.group({
      title: [null, [Validators.required]],
      type: ["email", [Validators.required]],

      userId: [this.securityService.getAuthenticatedUserId(), [Validators.required]],

      description: [null, [Validators.required]],

      email: [null],
      sendEmail: [true]
    });

    if (this.subject) {
      if (this.subject.email) {
        this.invites = this.subject.email.split(",")
      }

      this.form.patchValue(this.subject);
    }
  }

  public addToInvite(event: MatChipInputEvent): void {
    this.invites = (!this.invites) ? new Array<string>() : this.invites

    const input = event.input;
    const value = event.value?.trim() || "";

    if (emailValidator.validate(value)) {
      this.invites.push(value);
    }

    if (input) {
      input.value = '';
    }
  }

  public removeInvite(subject: any): void {
    if (this.invites) {
      const index = this.invites.indexOf(subject);
      if (index >= 0) {
        this.invites.splice(index, 1);
      }
    }
  }

  public save() {
    this.setValue(this.form, "email", this.invites?.join());

    if (this.form.valid) {
      this.services.taskApi
        .create({ ...this.form.value, ...this.queryParams })
        .subscribe((results: any) => {
          this.form.patchValue(results);
          this.dialogRef.close(results);
        });
    }
  }
}
