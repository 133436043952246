import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/api/api.service';
import { LeadApi } from 'src/app/api/lead.api';
import { LocationContactApi } from 'src/app/api/location-contact.api';
import { LocationLeadApi } from 'src/app/api/location-lead.api';
import { QuoteApi } from 'src/app/api/quote.api';
import { AppController } from 'src/app/app.controller';
import { FormGroupService } from 'src/app/service/form.service';
import { QuoteService } from 'src/app/service/quote.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-quote-view',
    templateUrl: './quote-view.component.html',
    styleUrls: ['./quote-view.component.scss']
})
export class QuoteViewComponent implements OnInit {

    lead: any;
    locations: any[];
    locationContacts: any[];
    subject: any;

    constructor(
        private readonly _leadApi: LeadApi,
        private readonly _quoteApi: QuoteApi,
        private readonly _locationLeadApi: LocationLeadApi,
        private readonly _locationContactApi: LocationContactApi,
        private readonly _quoteService: QuoteService,
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _router: Router,
        private readonly _formBuilder: FormBuilder,
        private readonly _appController: AppController,
        public formGroupService: FormGroupService) {
    }

    ngOnInit() {
        this.initBaseForm();

        this._activatedRoute.params.subscribe((params) => {
            if (params.leadId) {
                this.getLead(params.leadId);

                if (params.quoteId) {
                    this.getQuote(params.leadId, params.quoteId);
                }
            }
        });
    }

    private initBaseForm() {
        this.formGroupService.formGroup = this._formBuilder.group({
            id: [null],
            leadId: [null],//[null, [Validators.required]],
            locationId: [null],//[null, [Validators.required]],
            locationContactId: [{ value: null, disabled: true }, [Validators.required]],
            quoteType: [{ value: null, disabled: true }, [Validators.required]],
            companyName: [null, [Validators.required]],
            companyAddress: [null],
            companyContact: [null],
            companyPhone: [null],
            companyEmail: [null, [Validators.email]],
            companyLogo: [null],
            totalHardware: [0.0],
            totalMargin: [0.0],
            totalMonthly: [0.0],
            totalAnnual: [0.0]
        });

        this.formGroupService.formGroup.get("locationId").valueChanges
            .subscribe((value: any) => {
                let location = this.locations?.find(_ => _.id === value);
                if (location) {
                    this.formGroupService.setValue("companyAddress", location.address1);
                    this.formGroupService.formGroup.get("locationContactId").enable();
                    this.getLocationContacts(value);
                }
            });

        this.formGroupService.formGroup.get("locationContactId").valueChanges
            .subscribe((value: any) => {
                let locationContact = this.locationContacts?.find(_ => _.id === value);
                if (locationContact) {
                    this.formGroupService.formGroup.get("quoteType").enable();
                    this.formGroupService.setValue("companyContact", locationContact.name);
                    this.formGroupService.setValue("companyEmail", locationContact.email);
                    this.formGroupService.setValue("companyPhone", locationContact.phone1);
                }
            });
    }

    private getQuote(leadId: number, quoteId: number) {
        this._quoteApi.find(quoteId).subscribe(quote => {
            this.subject = quote;

            this.formGroupService.formGroup.patchValue(quote);
            this.formGroupService.formGroup.get("locationId").disable();
        });
    }

    private getLead(leadId: number) {
        this._leadApi.find(leadId)
            .subscribe(lead => {
                this.lead = lead;

                if (lead) {
                    this.formGroupService.setValue("leadId", leadId);
                    this.formGroupService.setValue("companyName", lead.name);
                    this.getLocations(leadId);
                }
            });
    }

    private getLocations(leadId) {
        this._locationLeadApi
            .find(null, new HttpParams().set("leadId", leadId))
            .subscribe(locations => {
                if (locations) {
                    this.locations = locations.data;
                }
            });
    }

    private getLocationContacts(locationId) {
        this._locationContactApi.find(null, new HttpParams().set("locationId", locationId))
            .subscribe(contacts => {
                if (contacts) {
                    this.locationContacts = contacts.data;

                    if (this.locationContacts.length < 1) {
                        this._appController.notice(`Location selected has no contacts, please choose another location`);
                    }
                }
            });
    }

    public save() {
        if (this.formGroupService.formGroup.valid) {
            let data = this.formGroupService.formGroup.value;
            if (!data.id) {
                this._quoteApi
                    .createByTypeAndLeadId(data, data.quoteType, data.leadId)
                    .subscribe(() => {
                        this._appController.notice("Created quote");
                        this._router.navigate(["/customer/lead/", data.leadId]);
                    });
            } else {
                this._quoteApi
                    .updateByTypeAndLeadId(data.id, data, this.subject.quoteType || data.quoteType)
                    .subscribe(() => {
                        this._appController.notice("Updated quote");
                        this._router.navigate(["/customer/lead/", data.leadId]);
                    });
            }
        }
    }

    public commission() {
        var id = this.formGroupService.getFloat("id");
        var type = this.formGroupService.getValue("quoteType");

        var endpoint = ApiService.getEndpoint(environment.api_quotes + `/${type}/comm/${id}`);
        window.open(endpoint, '_blank');
    }

    public duplicate() {
        var id = this.formGroupService.getFloat("id");
        var type = this.formGroupService.getValue("quoteType");

        this._quoteApi.duplicate(id, type).then(newQuoteId => {
            this._router.navigate(["/quote/", this.lead.id, newQuoteId]);
            this._appController.notice("Updated quote");
        });
    }

    public convert() {
        var id = this.formGroupService.getFloat("id");
        this._quoteService.convertToProject(id);
    }

    public download() {
        var id = this.formGroupService.getFloat("id");
        var type = this.formGroupService.getValue("quoteType");

        var endpoint = ApiService.getEndpoint(environment.api_quotes + `/${type}/download/${id}`);
        window.open(endpoint, '_blank');
    }
}
