import { Component, Input, OnInit } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { ApiService } from 'src/app/api/api.service';
import { AppConstant } from 'src/app/app.constant';
import { AppController } from 'src/app/app.controller';
import { AppData } from 'src/app/app.data';
import { ModalFormInventoryMovementComponent } from 'src/app/modal/modal-form-inventory/modal-form-inventory-movement/modal-form-inventory-movement.component';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-partial-inventory-movements',
    templateUrl: './partial-inventory-movements.component.html',
    styleUrls: ['./partial-inventory-movements.component.scss']
})
export class PartialInventoryMovementsComponent implements OnInit {
    @Input() code: string;
    @Input() defaultWarehouse: string;

    public dataSource: DataSource;

    constructor(private readonly _appController: AppController) {
    }

    ngOnInit() {
        this.dataSource = new DataSource({
            store: AppData.createStore(["journal", "journalEntry"], {
                loadUrl: ApiService.getEndpoint(environment.api_erp_inventory_movements),
                loadParams: {
                    code: this.code
                }
            })
        });
    }

    add() {
        this._appController.openModal(() => {
            this.dataSource.reload();
        }, ModalFormInventoryMovementComponent, {
            width: AppConstant.SIZE.MODAL_LARGE,
            data: {
                code: this.code,
                defaultWarehouse: this.defaultWarehouse
            }
        });
    }
}
