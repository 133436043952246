import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import DataSource from 'devextreme/data/data_source';
import { CustomerProjectApi } from 'src/app/api/customer-project.api';
import { AppData } from 'src/app/app.data';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-project-form',
    templateUrl: './project-form.component.html',
    styleUrls: ['./project-form.component.scss']
})
export class ProjectFormComponent implements OnInit {

    public tasks: DataSource;

    form: FormGroup;
    showGantt = false;

    constructor(
        private readonly _customerProjectApi: CustomerProjectApi,
        private readonly _formBuilder: FormBuilder,
        private readonly _activatedRouter: ActivatedRoute) {
    }

    ngOnInit() {
        this.form = this._formBuilder.group({
            id: [null],
            account: [null],
            accountName: [null],
            complete: [false],
            name: [null],
            eta: [null],
            userId: [null]
        });

        this._activatedRouter.params.subscribe(params => {
            if (params.projectId) {
                this.getProject(params.projectId);
                this.getTasks(params.projectId);
            }

            if (params.tab === "gantt") {
                this.showGantt = true;
            }
        })
    }

    getProject(id: number) {
        this._customerProjectApi.find(id).subscribe((results: any) => {
            this.form.patchValue(results);
        });
    }

    getTasks(projectId: number) {
        this.tasks = new DataSource({
            store: AppData.createStore("id", environment.api_erp_project_tasks, {
                loadParams: {
                    projectId: projectId
                },
            }),
            filter: ["notApplicable", false],
            map(dataItem) {
                if (!dataItem.startedDate) {
                    dataItem.startedDate = new Date().toDateString();
                }
                else {
                    dataItem.startedDate = new Date(dataItem.startedDate).toDateString();
                }

                dataItem.eta = new Date(dataItem.eta).toDateString();
                return dataItem;
            }
        });
    }
}
